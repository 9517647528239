import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout'
import { getChevronStyling, getChevronImg, getChevronImgSize } from '../Campaigns/chevronStyles'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import NavigationSlideItem from './SlideItem'

const Alignment = styled(Flex)`
  flex-direction: column;
  align-items: ${(props) => {
    if (props.position === 'LEFT') {
      return 'flex-start'
    }
    if (props.position === 'RIGHT') {
      return 'flex-end'
    }
    return 'center'
  }};
  position: relative;
  margin-bottom: 24px;
`

const Chevron = styled(Flex)`
  z-index: 2;
  width: 40px;
  min-width: 40px;
  height: 40px;
  ${(props) => getChevronStyling(props.widgetButtonStyle, props.widgetChevronSecondaryColor, props.isStories)}
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${(props) => (props.isDisabled ? `cursor: auto; opacity: 0.5;` : '')}
  ${(props) => (props.isSmallControls ? `width: 32px; height: 32px; min-width: 32px;` : '')}
`

const ChevronImage = styled(Flex)`
  ${(props) =>
    props.isStories
      ? `svg {
    fill: ${(props) => props.widgetChevronPrimaryColor || 'black'} !important;
    stroke: ${(props) => props.widgetChevronPrimaryColor || 'black'} !important;
  }`
      : ''}
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  align-items: center;
  justify-content: center;
  width: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  height: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  svg {
    width: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
    height: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  }
  ${(props) => (props.isSmallControls ? `width: 16px !important; height: 16px !important; min-width: 16px !important;` : '')}
`

const LeftChevron = styled(Chevron)`
  ${(props) => props.widgetArrowPosition === 'INSIDE' && 'margin-left: 8px;'}
  ${(props) => props.hide && 'display:none;'}
`

const RightChevron = styled(Chevron)`
  ${(props) => props.widgetArrowPosition === 'INSIDE' && 'margin-right: 8px;'}
  ${(props) => props.hide && 'display:none;'}
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
`

const Title = styled.h2`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily.replace(';') : 'inherit')} !important;
  font-weight: ${(props) => props.fontWeight || '700'} !important;
  font-size: ${(props) => props.fontSize || '50'}px !important;
  line-height: ${(props) => props.fontSize || '50'}px !important;
  text-align: ${(props) => props.textAlign || 'center'} !important;
  color: ${(props) => props.color || 'black'};
  text-decoration: none !important;
  margin-bottom: ${(props) => (props.isStories ? 0 : 16)}px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  width: ${(props) => props.width};
  padding-bottom: 0px !important;
  word-break: break-word;
`

const getMaxVideoWidth = (dimensions) => {
  if (dimensions === 'LANDSCAPE') {
    return 640
  }
  if (dimensions === 'SQUARE') {
    return 489
  }
  if (dimensions === 'SQUISHED_PORTRAIT') {
    return 338
  }
  if (dimensions === 'CIRCLE') {
    return 489
  }
  // PORTRAIT
  return 338
}

const getVideoHeight = (dimensions, videoWidth) => {
  if (dimensions === 'LANDSCAPE') {
    return (videoWidth / 640) * 370
  }
  if (dimensions === 'SQUARE') {
    return videoWidth
  }
  if (dimensions === 'SQUASHED_PORTRAIT') {
    return (videoWidth / 338) * 500
  }
  if (dimensions === 'CIRCLE') {
    return videoWidth
  }
  // PORTRAIT
  return (videoWidth / 338) * 600
}

const getArrowWidth = (position, noArrows, isStories) => {
  if (noArrows || isStories) {
    return 0
  }
  if (position === 'BELOW') {
    return 0
  }
  if (position === 'INSIDE') {
    return 0
  }
  if (position === 'OUTSIDE') {
    return 56
  }
  // OVERLAP
  return 20
}

const getDimensionsForResponsive = (campaign) => {
  const { videos, desktopVideos, widgetThumbnailDimensions, marginX, widgetGapWidth, widgetArrowPosition, widgetIsPartialStart } =
    campaign
  const videosToShow = desktopVideos > videos.length ? videos.length : desktopVideos
  const noArrows = videos.length <= desktopVideos
  const maxVideoWidth = getMaxVideoWidth(widgetThumbnailDimensions)
  const arrowsWidth = getArrowWidth(widgetArrowPosition, noArrows, false)
  const containerWidth =
    document.getElementById('__clipara-embed') &&
    document.getElementById('__clipara-embed').clientWidth - (marginX ? marginX * 2 : 0)
  const gapWidth = widgetGapWidth
  const maxContainerWidth = (maxVideoWidth + gapWidth) * videosToShow - gapWidth + arrowsWidth * 2 // Minus one gap width for start/end
  const containerHeight = document.getElementById('__clipara-embed') && document.getElementById('__clipara-embed').clientHeight
  const containerWidthWithoutArrows = containerWidth - arrowsWidth * 2
  const isSmallControls = containerWidth < 700
  const containerCanFitFullWidth = containerWidthWithoutArrows - maxContainerWidth > 0
  const videoWidth = containerCanFitFullWidth
    ? maxVideoWidth
    : (containerWidthWithoutArrows - widgetGapWidth * videosToShow + gapWidth) / videosToShow
  const videoHeight = getVideoHeight(widgetThumbnailDimensions, videoWidth)
  const maxContainerWidthFactoringNoArrows = maxContainerWidth - arrowsWidth
  const chevronContainerHeight = videoHeight
  const carouselContainerWidth = containerCanFitFullWidth ? `${maxContainerWidth}px` : `calc(100% - ${arrowsWidth}px)`
  const slideAdjust = widgetIsPartialStart ? (noArrows ? 0 : videoWidth / 2) : 0
  return {
    videosToShow,
    noArrows,
    maxVideoWidth,
    arrowsWidth,
    containerWidth,
    gapWidth,
    maxContainerWidth,
    containerHeight,
    containerWidthWithoutArrows,
    isSmallControls,
    containerCanFitFullWidth,
    videoWidth,
    videoHeight,
    maxContainerWidthFactoringNoArrows,
    chevronContainerHeight,
    carouselContainerWidth,
    slideAdjust,
    borderRadius: campaign.borderRadius
  }
}

const EmbedCarousel = ({ campaign, setShowFullscreenPlayer, setActiveFullScreenVideo }) => {
  const [resize, setResize] = useState()
  const {
    videos,
    position,
    fontSize,
    fontWeight,
    widgetFontFamily,
    textAlign,
    text,
    titleColor,
    videoObjects,
    widgetArrowPosition,
    widgetButtonStyle,
    widgetChevronPrimaryColor,
    widgetChevronSecondaryColor,
    widgetGapWidth,
    desktopVideos,
    widgetIsPartialStart
  } = campaign
  const [activeVideo, setActiveVideo] = useState(0)

  const {
    videosToShow,
    noArrows,
    arrowsWidth,
    maxContainerWidth,
    isSmallControls,
    containerCanFitFullWidth,
    videoWidth,
    videoHeight,
    maxContainerWidthFactoringNoArrows,
    chevronContainerHeight,
    carouselContainerWidth,
    slideAdjust,
    borderRadius,
    isCircle,
    isStories,
    containerWidth
  } = getDimensionsForResponsive(campaign)

  useEffect(() => {
    function handleResize() {
      setResize(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  const leftChevronOnClick = (e) => e.stopPropagation() || instanceRef.current?.prev()
  const rightChevronOnClick = (e) => e.stopPropagation() || instanceRef.current?.next()

  const [sliderRef, instanceRef] = useKeenSlider({
    drag: true,
    loop: true,
    initial: 0,
    renderMode: 'performance',
    slideChanged(s) {},
    animationEnded(s) {
      setActiveVideo(s.track.details.rel)
    },
    slides: {
      perView: videosToShow,
      spacing: widgetGapWidth,
      origin: widgetIsPartialStart ? 'center' : 'auto'
    }
  })

  //
  //
  // NEW FOR DASH
  useEffect(() => {
    setResize(window.innerWidth)
    instanceRef.current?.update()
  }, [containerWidth])
  useEffect(() => {
    setActiveVideo(0)
  }, [campaign.widgetFixedThumbnail])
  //
  //
  //

  return (
    <>
      <Alignment position={position}>
        {text ? (
          <Title
            isStories={isStories}
            color={titleColor}
            fontSize={fontSize}
            fontWeight={fontWeight}
            fontFamily={widgetFontFamily}
            textAlign={textAlign}
            width={containerCanFitFullWidth ? `${maxContainerWidth + arrowsWidth}px` : '100%'}
          >
            {text}
          </Title>
        ) : null}
        <Flex width={containerCanFitFullWidth ? `${maxContainerWidthFactoringNoArrows + arrowsWidth}px` : '100%'}>
          {!noArrows ? (
            <Flex
              height={chevronContainerHeight}
              alignItems="center"
              minWidth={arrowsWidth}
              width={arrowsWidth}
              display="flex !important"
            >
              <LeftChevron
                widgetChevronSecondaryColor={widgetChevronSecondaryColor}
                isStories={isStories}
                widgetButtonStyle={widgetButtonStyle}
                hide={widgetArrowPosition === 'BELOW' || widgetArrowPosition === 'CENTER_BELOW'}
                widgetArrowPosition={widgetArrowPosition}
                isSmallControls={isSmallControls}
                onClick={leftChevronOnClick}
              >
                <ChevronImage
                  isStories={isStories}
                  widgetChevronPrimaryColor={widgetChevronPrimaryColor}
                  widgetButtonStyle={widgetButtonStyle}
                  isSmallControls={isSmallControls}
                  alt="chevron icon"
                  width={getChevronImgSize(widgetButtonStyle, isStories)}
                  height={getChevronImgSize(widgetButtonStyle, isStories)}
                >
                  {getChevronImg(widgetButtonStyle, widgetChevronPrimaryColor, isStories)}
                </ChevronImage>
              </LeftChevron>
            </Flex>
          ) : null}
          <Box width="auto" ref={sliderRef} className="keen-slider">
            {videos.map((video, i) => {
              return (
                <NavigationSlideItem
                  campaign={campaign}
                  key={video.id}
                  videoHeight={videoHeight}
                  videoWidth={videoWidth}
                  activeVideoIndex={activeVideo}
                  videosToShow={videosToShow}
                  i={i}
                  video={video}
                  videoObjects={videoObjects}
                  borderRadius={borderRadius}
                  isStories={isStories}
                  isCircle={isCircle}
                  setShowFullscreenPlayer={setShowFullscreenPlayer}
                  setActiveFullScreenVideo={setActiveFullScreenVideo}
                  isNavigation={true}
                />
              )
            })}
          </Box>
          {!noArrows ? (
            <Flex
              height={chevronContainerHeight}
              justifyContent="flex-end"
              alignItems="center"
              minWidth={arrowsWidth}
              width={arrowsWidth}
              display="flex !important"
            >
              <RightChevron
                widgetChevronSecondaryColor={widgetChevronSecondaryColor}
                isStories={isStories}
                widgetButtonStyle={widgetButtonStyle}
                hide={widgetArrowPosition === 'BELOW' || widgetArrowPosition === 'CENTER_BELOW'}
                widgetArrowPosition={widgetArrowPosition}
                isSmallControls={isSmallControls}
                onClick={rightChevronOnClick}
              >
                <ChevronImage
                  isStories={isStories}
                  widgetChevronPrimaryColor={widgetChevronPrimaryColor}
                  widgetButtonStyle={widgetButtonStyle}
                  isSmallControls={isSmallControls}
                  alt="chevron icon"
                  width={getChevronImgSize(widgetButtonStyle, isStories)}
                  height={getChevronImgSize(widgetButtonStyle, isStories)}
                >
                  {getChevronImg(widgetButtonStyle, widgetChevronPrimaryColor, isStories)}
                </ChevronImage>
              </RightChevron>
            </Flex>
          ) : null}
        </Flex>
        {!noArrows && (widgetArrowPosition === 'BELOW' || widgetArrowPosition === 'CENTER_BELOW') ? (
          <Flex
            justifyContent={widgetArrowPosition === 'CENTER_BELOW' ? 'center' : 'flex-end'}
            alignItems="center"
            display="flex !important"
            mt="16px"
            width={carouselContainerWidth}
            maxWidth={carouselContainerWidth}
          >
            <LeftChevron
              widgetChevronSecondaryColor={widgetChevronSecondaryColor}
              isStories={isStories}
              widgetButtonStyle={widgetButtonStyle}
              widgetArrowPosition={widgetArrowPosition}
              onClick={leftChevronOnClick}
              mr="8px"
            >
              <ChevronImage
                isStories={isStories}
                widgetChevronPrimaryColor={widgetChevronPrimaryColor}
                widgetButtonStyle={widgetButtonStyle}
                isSmallControls={isSmallControls}
                alt="chevron icon"
                width={getChevronImgSize(widgetButtonStyle, isStories)}
                height={getChevronImgSize(widgetButtonStyle, isStories)}
              >
                {getChevronImg(widgetButtonStyle, widgetChevronPrimaryColor, isStories)}
              </ChevronImage>
            </LeftChevron>
            <RightChevron
              widgetChevronSecondaryColor={widgetChevronSecondaryColor}
              isStories={isStories}
              widgetButtonStyle={widgetButtonStyle}
              widgetArrowPosition={widgetArrowPosition}
              onClick={rightChevronOnClick}
            >
              <ChevronImage
                isStories={isStories}
                widgetChevronPrimaryColor={widgetChevronPrimaryColor}
                widgetButtonStyle={widgetButtonStyle}
                isSmallControls={isSmallControls}
                alt="chevron icon"
                width={getChevronImgSize(widgetButtonStyle, isStories)}
                height={getChevronImgSize(widgetButtonStyle, isStories)}
              >
                {getChevronImg(widgetButtonStyle, widgetChevronPrimaryColor, isStories)}
              </ChevronImage>
            </RightChevron>
          </Flex>
        ) : null}
      </Alignment>
    </>
  )
}

export default EmbedCarousel
