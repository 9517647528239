import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, stringPadLeft, formatSeconds } from '../ui/helpers'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { H1, H2, H3, H6, Text, Span, GradientFont, Error, Label } from '../ui/Typography'

import { Flex, Box, Container } from '../ui/Layout'
import Image from '../ui/Image'
import { Input } from '../ui/Input'
import Icon from '../ui/Icon'
import Button from '../ui/Button'
import { BigDarkSpinner } from '../ui/Spinner'
import SideNav from '../Components/SideNav'
import VideoPlayer from '../Components/VideoPlayer'
import formikFormWrapper from '../formikFormWrapper'
import { toast } from 'react-toastify'
import { useVideos } from '../VideoProvider'
import bluebird from 'bluebird'

import api from '../api'
import { useAuth } from '../Auth'
import { ProgressBar, ProgressBarProgress, SpinningIcon } from './VideoUpload'
import { getAllowedVideoDuration, getNumberOfAllowedVideos } from '../Plans'

const AUTH_STATUS = {
  LOADING: 'LOADING',
  AUTHED: 'AUTHED',
  NOT_AUTHED: 'NOT_AUTHED'
}

const STEPS = {
  IN_PROGRESS: 'IN_PROGRESS',
  SELECT_VIDEO: 'SELECT_VIDEO'
}

const Grid = styled(Flex)`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (min-width: 1500px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 1800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 2200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 2500px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

const ImageComp = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-position: center;
`
const VideoComp = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
`
const LineClamp = styled(Box)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const HoverControl = styled(Flex)`
  position: relative;
  .thumbnail {
    display: none;
  }
`

const VideoDisplay = ({ videoIds, videos, selectedVideoIds, setSelectedVideoIds }) => {
  const { videos: existingVideos } = useVideos()

  return videos.map((video) => {
    const onClick = (e) => {
      e.preventDefault()
      if (selectedVideoIds && selectedVideoIds.includes(video.id)) {
        setSelectedVideoIds(selectedVideoIds.filter((id) => id !== video.id))
      } else {
        setSelectedVideoIds([video.id, ...selectedVideoIds])
      }
    }

    const alreadyInLibrary = existingVideos.some((ev) => {
      return ev.instagramId === video.id
    })
    return (
      <Container
        border={selectedVideoIds.includes(video.id) ? `4px solid rgba(120, 149, 255, 1)` : 'transparent'}
        onClick={onClick}
        hover
        p="0px"
        key={video.id}
        width="100%"
        height="500px"
        justifyContent="space-between"
        position="relative"
        overflowX="hidden"
      >
        {alreadyInLibrary ? (
          <Button
            position="absolute"
            zIndex={1}
            pill
            left="12px"
            top="12px"
            mb="8px"
            backgroundColor="#7895FF"
            variant="blue"
            small
            label="In library"
          />
        ) : null}
        <HoverControl
          onMouseEnter={async () => {
            try {
              document.getElementById(`video-${video.id}`).play()
            } catch (e) {}
          }}
          onMouseLeave={() => {
            try {
              document.getElementById(`video-${video.id}`).pause()
              document.getElementById(`video-${video.id}`).currentTime = 0
            } catch (e) {}
          }}
          overflow="hidden"
          height="100%"
          position="relative"
        >
          <VideoComp muted id={`video-${video.id}`} loading="lazy" width="100%" height="100%" src={video.mediaUrl} />
          <ImageComp id={`thumbnail`} loading="lazy" width="100%" height="100%" src={video.thumbnailUrl} />
        </HoverControl>
        <Flex flexDirection="column" py="8px" pb="16px" minHeight="100px" height="100px" justifyContent="space-between">
          <Box position="absolute" bottom="16px" left="16px">
            <Text lightLight fontSize="12px" fontWeight="600">
              {dayjs(video.timestamp).format('DD MMM')}
            </Text>
          </Box>
          <LineClamp wordBreak="break-all" mb="0px" px="8px">
            <H6 wordBreak="break-all" mb="0px" px="8px">
              {video.caption && video.caption}
            </H6>
          </LineClamp>
        </Flex>
      </Container>
    )
  })
}

const SelectedContainer = styled(Flex)`
  z-index: 2;
  background-color: #111111;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 700px;
  position: fixed;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  ${(props) => (props.overLimits ? `background: linear-gradient(225deg, #BB65FF 0%, #4C6FFF 100%);` : '')}
`

let interval

const InstagramUpload = ({ isLoading, closeModal, isModal, setUrl, uploadFromUrl, error }) => {
  const { videos: existingVideos } = useVideos()
  const { user } = useAuth()
  const [authStatus, setAuthStatus] = useState(AUTH_STATUS.LOADING)
  const [username, setUsername] = useState('')
  const [videos, setVideos] = useState()
  const [selectedVideoIds, setSelectedVideoIds] = useState([])
  const [pagination, setPagination] = useState()
  const [overLimits, setOverLimits] = useState(false)
  const [percentProgress, setPercentProgress] = useState(0)
  const [step, setStep] = useState(STEPS.SELECT_VIDEO)
  const [finished, setFinished] = useState(false)

  const limit = getAllowedVideoDuration(user)
  const selectedDuration =
    selectedVideoIds &&
    selectedVideoIds.length &&
    Math.round(
      selectedVideoIds.reduce((acc, b) => {
        if (!document.getElementById(`video-${b}`)) {
          return acc
        }
        return acc + document.getElementById(`video-${b}`)?.duration
      }, 0)
    )
  const totalExistingDuration =
    existingVideos && existingVideos.length
      ? Math.round(
          existingVideos.reduce((acc, b) => {
            return acc + b.duration
          }, 0)
        )
      : 0
  const remainingDuration =
    limit - (totalExistingDuration + selectedDuration) > 0 ? limit - (totalExistingDuration + selectedDuration) : 0
  useEffect(() => {
    const asyncFunc = async () => {
      const res = await api.get('/instagram/get-instagram-auth')
      if (res.data.success) {
        setAuthStatus(AUTH_STATUS.AUTHED)
        setUsername(res.data.payload.username)
        const videoRes = await api.get('/instagram/get-instagram-videos')
        setVideos(videoRes.data.payload.videos)
        setPagination(videoRes.data.payload.pagination)
      } else {
        setAuthStatus(AUTH_STATUS.NOT_AUTHED)
      }
    }
    asyncFunc()
  }, [])

  useEffect(() => {
    if (limit) {
      if (totalExistingDuration + selectedDuration > limit) {
        setOverLimits(true)
        return
      } else {
        setOverLimits(false)
      }
    }
  }, [selectedVideoIds])

  const getMoreVideos = async () => {
    const videoRes = await api.get(`/instagram/get-instagram-videos?next=${encodeURIComponent(pagination.next)}`)
    setVideos(videos.concat(videoRes.data.payload.videos))
    setPagination(videoRes.data.payload.pagination)
  }

  const startUpload = async () => {
    setStep(STEPS.IN_PROGRESS)
    let temp = 0
    interval = setInterval(() => {
      setPercentProgress(100 / selectedVideoIds.length + temp > 100 ? 100 : 100 / selectedVideoIds.length + temp)
      temp = temp + 100 / selectedVideoIds.length
    }, 2000)
    const uploadRes = await api.post(`/video/multi-create-from-url`, {
      videos: videos.filter((el) => selectedVideoIds.includes(el.id))
    })
  }

  useEffect(() => {
    if (percentProgress === 100) {
      clearInterval(interval)
      setFinished(true)
    }
  }, [percentProgress])

  if (step === STEPS.IN_PROGRESS) {
    return (
      <Flex flexDirection="column" alignItems="center" position="relative">
        <H1 mb="12px">{`${finished ? 'Uploaded' : 'Uploading'} ${selectedVideoIds.length} video${
          selectedVideoIds.length > 1 ? 's' : ''
        }`}</H1>
        <SpinningIcon finished={finished} mb="32px" width="60px" height="60px" icon="logo-no-text" />
        <Flex flexDirection="column" alignItems="center" pt="32px" mb="32px">
          <ProgressBar mb="8px" width="100%">
            <ProgressBarProgress finished={finished} width={`${percentProgress}%`} />
          </ProgressBar>
        </Flex>
        {closeModal ? (
          <Flex justifyContent="flex-end">
            <Button
              onClick={!finished ? () => null : closeModal}
              isDisabled={!finished}
              width="200px"
              mb="8px"
              variant="black"
              label="Continue"
            />
          </Flex>
        ) : (
          <Flex justifyContent="flex-end">
            <a href="/videos/upload">
              <Button isDisabled={!finished} mb="8px" variant="black" label="Add another video" />
            </a>
            <Box width="32px" />
            <Link to="/campaigns/create?step=ON_SITE_OFF_SITE">
              <Button isDisabled={!finished} mb="8px" variant="black" label="Create campaign" />
            </Link>
          </Flex>
        )}
      </Flex>
    )
  }

  return (
    <Flex flexDirection="column" alignItems="center" position="relative">
      {authStatus === AUTH_STATUS.AUTHED ? (
        <Flex width="auto" position="absolute" top="-85px" right="24px" alignItems="center">
          <Text fontWeight="700" mr="16px">
            @{username}
          </Text>
          <Button variant="softBlue" label="Disconnect" onClick={() => setAuthStatus(AUTH_STATUS.NOT_AUTHED)} />
        </Flex>
      ) : null}

      {authStatus === AUTH_STATUS.NOT_AUTHED ? (
        <Flex flexDirection="column" alignItems="center" position="relative" maxWidth="400px">
          <Text mb="16px" textAlign="center" light>
            {isModal
              ? 'Connect your Instagram account to upload videos & reels from your instagram page. You will be taken to your video library to complete the process.'
              : 'Connect your Instagram account to upload videos & reels from your instagram page'}
          </Text>
          <Button
            mb="100px"
            variant="black"
            label="Connect Account"
            renderLeftIcon={() => <Icon icon="instagram-white" width={12} height={12} />}
            onClick={() => {
              window.location.href = `https://api.instagram.com/oauth/authorize?client_id=627679246097202&redirect_uri=${
                process.env.NODE_ENV === 'production'
                  ? 'https://api.getclipara.com/instagram/auth'
                  : 'https://nails-vulnerability-deaths-dash.trycloudflare.com/instagram/auth'
              }&scope=user_profile,user_media&response_type=code`
            }}
          />
          <Text mb="16px" light>
            or upload from a post
          </Text>
          <Input
            mb="16px"
            width="100%"
            placeholder="e.g. https://www.instagram.com/reel/CoNYtWVpZYL/"
            dark
            onChange={(e) => setUrl(e.target.value)}
          />
          <Button mb="8px" isLoading={isLoading} variant="softBlue" label="Upload" onClick={uploadFromUrl} />
          <Error>{error}</Error>
        </Flex>
      ) : null}

      {videos && videos.length && authStatus === AUTH_STATUS.AUTHED ? (
        <>
          <Grid height="100%" px="24px" pb="16px">
            <VideoDisplay selectedVideoIds={selectedVideoIds} setSelectedVideoIds={setSelectedVideoIds} videos={videos} />
          </Grid>
          {pagination && pagination.next ? (
            <Button mb="8px" isLoading={isLoading} variant="softBlue" label="Load more" onClick={getMoreVideos} />
          ) : null}
          {selectedVideoIds.length > 0 ? (
            <SelectedContainer overLimits={overLimits}>
              <Flex ml="24px">
                {!overLimits ? (
                  <Text fontWeight="500" color="white" mr="24px">
                    {`${selectedVideoIds.length} selected`}
                  </Text>
                ) : (
                  <Text fontWeight="500" color="white">
                    Upgrade now to upload {selectedVideoIds.length} video{selectedVideoIds.length > 1 ? 's' : ''}.
                  </Text>
                )}
              </Flex>
              <Flex width="auto" alignItems="center">
                {!overLimits ? (
                  <Button
                    mr="16px"
                    borderColor="white"
                    isLoading={isLoading}
                    variant="black"
                    label="Upload"
                    isDisabled={overLimits}
                    onClick={overLimits ? () => null : startUpload}
                    background="transparent"
                  />
                ) : (
                  <Link to="/account?tab=BILLING">
                    <Button
                      minWidth="136px"
                      mr="16px"
                      borderColor="white"
                      isLoading={isLoading}
                      variant="black"
                      label="Upgrade plan"
                      background="transparent"
                    />
                  </Link>
                )}
                <Text onClick={() => setSelectedVideoIds([])} cursor="pointer" mr="16px" fontWeight="500" color="white">
                  Cancel
                </Text>
              </Flex>
            </SelectedContainer>
          ) : null}
        </>
      ) : null}
    </Flex>
  )
}

export default InstagramUpload
