import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { colors, w } from '../ui/helpers.js'
import { H1, H2, Text, Span, GradientFont, Error } from '../ui/Typography.js'
import { Flex } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Button from '../ui/Button.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import { PrivacyTermsComponent } from './Login.js'
import ChatButton from '../Components/ChatButton.js'
import { titleCase } from '../Components/helpers.js'
import { USER_ROLES } from './types.js'

const SignInText = styled(Text)`
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`

const FormInput = formikFormWrapper(Input)

const Login = (props) => {
  let auth = useAuth()
  let navigate = useNavigate()
  const [search, setSearch] = useSearchParams()
  const isInvite = search.get('senderName')
  const [error, setError] = useState('')
  return (
    <Formik
      initialValues={{
        firstName: search.get('firstName') || '',
        lastName: search.get('lastName') || '',
        // email: search.get('email') || '',
        email: isInvite ? search.get('email') && search.get('email').replace(' ', '+') : '',
        password: '',
        shopifyShopId: search.get('shopifyShopId') || undefined
      }}
      validate={(values) => {
        const errors = {}
        if (!values.email) {
          errors.email = 'Required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = 'Invalid email address'
        }
        if (!values.password) {
          errors.password = 'Required'
        } else if (values.password.length < 8) {
          errors.password = 'Must be 8 characters or more'
        } else if (values.password.length > 70) {
          errors.password = 'Must be less than 70 characters'
        }
        if (!values.firstName) {
          errors.firstName = 'Required'
        }
        if (!values.lastName) {
          errors.lastName = 'Required'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setError('')
        setSubmitting(true)
        console.log((values, { setSubmitting }))
        const res = await api.post('/signup', { organisationId: search.get('organisationId') || undefined, ...values })
        if (res.data.success) {
          const authRes = await api.get('/auth')
          auth.setUser(authRes.data.payload)

          if (authRes.data.payload.role === USER_ROLES.USER) {
            return navigate('/')
          }
          navigate('/account-type')
        } else {
          setError(res.data.message)
        }
        setSubmitting(false)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
        /* and other goodies */
      }) => (
        <Flex>
          <ChatButton topRightFixed />
          <Flex
            display={w < 981 ? 'none' : 'flex'}
            flexDirection="column"
            p="76px"
            width="586px"
            minWidth="586px"
            height="100%"
            minHeight="100vh"
            bg={colors.softBlue}
          >
            <Image mb="160px" src="/logo.svg" width={186} />
            <GradientFont mb="25px">I see it’s your first time here.</GradientFont>
            <Text light>Welcome to Clipara. You’re going to love it.</Text>
          </Flex>
          <Flex position="relative" flexDirection="column" alignItems="center" width="100%" p="76px">
            <Flex flexDirection="column" maxWidth="535px">
              {isInvite ? (
                <>
                  <H1 mb="12px">Join {titleCase(search.get('senderName') || '')} on Clipara</H1>
                  <H2 mb="30px">Create your account to get started</H2>
                </>
              ) : (
                <>
                  <H1 mb="12px">Create your account</H1>
                  <H2 mb="30px">It’s free and easy</H2>
                </>
              )}
              <Flex mb="55px">
                <Field
                  name="firstName"
                  placeholder="Enter your name"
                  label="First Name"
                  large
                  type="text"
                  component={FormInput}
                  mr="28px"
                />
                <Field
                  name="lastName"
                  large
                  placeholder="Enter your last name"
                  label="Last Name"
                  type="text"
                  component={FormInput}
                />
              </Flex>
              <Field
                name="email"
                label="Email"
                disabled={isInvite}
                large
                mb="55px"
                placeholder="Type your email address"
                type="email"
                component={FormInput}
              />
              <Field
                name="password"
                label="Password"
                large
                mb="55px"
                placeholder="Type your password"
                type="password"
                component={FormInput}
              />
              <Button
                mb="8px"
                large
                disabled={isSubmitting}
                isLoading={isSubmitting}
                variant="black"
                label="Register"
                onClick={handleSubmit}
              />
              <Error mb="8px">{error}</Error>
              <SignInText light>
                Already have an account?{' '}
                <Link to={'/login' + (window.location.href.includes('?') ? `?${window.location.href.split('?')[1]}` : '')}>
                  <Span link>Sign in</Span>
                </Link>
              </SignInText>
            </Flex>
            <PrivacyTermsComponent />
          </Flex>
        </Flex>
      )}
    </Formik>
  )
}

export default Login
