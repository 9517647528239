import './App.css'
import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { BrowserRouter as Router, Routes, Route, Redirect, Link, Navigate, useNavigate, useLocation } from 'react-router-dom'
import Home from './Home/index.js'
import Videos from './Videos/index.js'
import VideoUpload from './Videos/VideoUpload.js'
import Images from './Images/index.js'
import ImageUpload from './Images/ImageUpload.js'
import Analytics from './Analytics/index.js'
import Campaigns from './Campaigns/index.js'
import CampaignEdit from './Campaigns/CampaignEdit.js'
import Login from './Auth/Login.js'
import Signup from './Auth/Signup.js'
import SendPasswordReset from './Auth/SendPasswordReset.js'
import PasswordReset from './Auth/PasswordReset.js'
import WelcomeModal from './Welcome/Modal.js'
import Account from './Account/index.js'
import BillingPage from './Billing/index.js'
import AccountType from './AccountType/index.js'
import ShopifyInstall from './ShopifyInstall/index.js'
import Integrations from './Integrations/index.js'
import { RequireAuth, AuthProvider } from './Auth.js'
import SideNav, { TABS } from './Components/SideNav.js'
import { Flex, MobileContainer } from './ui/Layout.js'
import { H1, H2, Text, Span, GradientFont, Error } from './ui/Typography.js'
import { colors, fontFamily } from './ui/helpers'
import { ToastContainer } from 'react-toastify'
import { ModalProvider } from './Modal'
import { VideoProvider } from './VideoProvider'

import PageTrackingComponent from './PageTracking'
import 'react-toastify/dist/ReactToastify.css'

const StyledToastContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
    z-index: 99999999999999;
  }
  .Toastify__toast {
    height: 40px !important;
    min-height: 40px !important;
    background-color: black;
    border-radius: 8px;
    color: white;
    text-align: center;
    font-family: ${fontFamily};
    font-size: 14px;
    font-weight: 700;
    padding-top: 4px;
    z-index: 99999999999999;
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`

function App() {
  ReactTooltip.rebuild()
  return (
    <>
      <Router>
        <VideoProvider>
          <AuthProvider>
            <ModalProvider>
              <WelcomeModal />
              <PageTrackingComponent />
              <Flex>
                <StyledToastContainer
                  style={{ width: 'fit-content' }}
                  position="bottom-center"
                  autoClose={2000}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss={false}
                  draggable
                  pauseOnHover
                  limit={1}
                  closeButton={false}
                />
                <SideNav />
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <RequireAuth>
                        <Home />
                      </RequireAuth>
                    }
                  />
                  <Route exact path="/signup" element={<Signup />} />
                  <Route exact path="/login" element={<Login />} />
                  <Route exact path="/password-reset" element={<PasswordReset />} />
                  <Route exact path="/send-password-reset" element={<SendPasswordReset />} />
                  <Route
                    exact
                    path="/integrations"
                    element={
                      <RequireAuth>
                        <Integrations />
                      </RequireAuth>
                    }
                  />
                  <Route
                    exact
                    path="/account"
                    element={
                      <RequireAuth>
                        <Account />
                      </RequireAuth>
                    }
                  />

                  <Route
                    exact
                    path="/videos"
                    element={
                      <RequireAuth>
                        <Videos />
                      </RequireAuth>
                    }
                  />
                  <Route
                    exact
                    path="/videos/upload"
                    element={
                      <RequireAuth>
                        <VideoUpload />
                      </RequireAuth>
                    }
                  />
                  <Route
                    exact
                    path="/images"
                    element={
                      <RequireAuth>
                        <Images />
                      </RequireAuth>
                    }
                  />
                  <Route
                    exact
                    path="/images/upload"
                    element={
                      <RequireAuth>
                        <ImageUpload />
                      </RequireAuth>
                    }
                  />

                  <Route
                    exact
                    path="/campaigns"
                    element={
                      <RequireAuth>
                        <Campaigns />
                      </RequireAuth>
                    }
                  />
                  <Route
                    exact
                    path="/campaigns/create"
                    element={
                      <RequireAuth>
                        <CampaignEdit keyProp={'create'} key={Math.random()} />
                      </RequireAuth>
                    }
                  />
                  <Route
                    exact
                    path="/campaigns/edit/:campaignId"
                    element={
                      <RequireAuth>
                        <CampaignEdit />
                      </RequireAuth>
                    }
                  />

                  <Route
                    exact
                    path="/analytics"
                    element={
                      <RequireAuth>
                        <Analytics />
                      </RequireAuth>
                    }
                  />
                  <Route
                    exact
                    path="/billing"
                    element={
                      <RequireAuth>
                        <BillingPage />
                      </RequireAuth>
                    }
                  />
                  <Route
                    exact
                    path="/account-type"
                    element={
                      <RequireAuth>
                        <AccountType />
                      </RequireAuth>
                    }
                  />
                  <Route
                    exact
                    path="/shopify-install"
                    element={
                      <RequireAuth>
                        <ShopifyInstall />
                      </RequireAuth>
                    }
                  />
                </Routes>
              </Flex>
            </ModalProvider>
          </AuthProvider>
        </VideoProvider>
      </Router>
      <ReactTooltip effect="solid" multiline="true" />
    </>
  )
}

export default App
