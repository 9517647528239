import React, { useCallback, useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize } from '../ui/helpers.js'
import { H1, H2, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input, TextArea } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import CodeCopy, { copyOnClick } from '../ui/CodeCopy.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import Toggle from '../ui/Toggle.js'
import Select from '../ui/Select.js'
import ColorPicker from '../ui/ColorPicker.js'
import formikFormWrapper from '../formikFormWrapper.js'
import { toast } from 'react-toastify'
import { useModal } from '../Modal.js'
import ModalContainer from '../ui/ModalContainer.js'
import { useAuth } from '../Auth.js'
import api from '../api.js'

const AddLogoModal = ({ showReferModal, setShowReferModal }) => {
  if (!showReferModal) {
    return null
  }

  const { user, setUser } = useAuth()

  return (
    <ModalContainer autoHeight interiorClose width="900px" p={0} isOpen={true} setIsOpen={setShowReferModal}>
      <Flex flexDirection="column" alignItems="center" p="24px" py="54px">
        <Flex alignItems="center" justifyContent="center" mb="16px">
          <Icon mr="16px" icon="present-black" width={22} height={22} />
          <H3 mb="0px" fontSize="28px" lineHeight="38px">
            Refer & Earn - 20% Revenue Share
          </H3>
        </Flex>
        <Text textAlign="center" maxWidth="600px" fontSize="16px" lineHeight="26px" mb="24px">
          Help others get more out of their video content. Recommend Clipara to other users and earn cash or credit in return.
        </Text>

        <Text fontSize="16px" lineHeight="26px" mb="24px" light>
          Who are you most likely to be referring?
        </Text>
        <a href="https://app.partnerjam.com/signup?id=rQWEqOz" target="_blank" rel="noreferrer">
          <Button variant="softBlue" width="200px" large label="Shopify merchants" />
        </a>
        <Text my="16px">or</Text>
        <a href="https://clipara.tolt.io/login" target="_blank" rel="noreferrer">
          <Button mb="24px" variant="black" width="200px" large label="Everybody else" />
        </a>
        <Text light>You’ll be redirected to an external provider for our referral program.</Text>
      </Flex>
    </ModalContainer>
  )
}

export default AddLogoModal
