import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, formatSeconds } from './helpers'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from './Typography'
import { Flex, Box, Container } from './Layout'
import { Input } from './Input'
import Icon from './Icon'
import Button from './Button'
import { BigDarkSpinner } from './Spinner'
import api from '../api'

import ModalContainer from './ModalContainer'
import { uniq, difference } from 'ramda'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/react'
import { useAuth } from '../Auth'
import bluebird from 'bluebird'
import { ProgressBar, ProgressBarProgress, SpinningIcon } from '../Videos/VideoUpload'

const DragDropContainer = styled(Flex)`
  width: 100%;
  height: 300px;
  border-radius: 16px;
  overflow: hidden;
  background-color: #f7fafc;
  cursor: pointer;
  border: 2px dashed #e1e8ff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

const StyledInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  background-color: red;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const getHeightAndWidthFromDataUrl = (dataURL) =>
  new Promise((resolve) => {
    const img = new Image()
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width
      })
    }
    img.src = dataURL
  })

const ImageUploadModal = ({ isOpen, setIsOpen, saveFunction }) => {
  const [files, setFiles] = useState()
  const { user } = useAuth()
  const [isFinished, setIsFinished] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [numberUploaded, setNumberUploaded] = useState(0)

  console.log({ files })
  useEffect(() => {
    const func = async () => {
      if (files && files.length) {
        setIsUploading(true)
        const urls = await bluebird.mapSeries(files, async (file, i) => {
          let formData = new FormData()
          const fileAsDataURL = window.URL.createObjectURL(file)
          const { height, width } = await getHeightAndWidthFromDataUrl(fileAsDataURL)

          formData.append('file', file)
          formData.append('name', file.name)
          formData.append('path', 'generic')
          formData.append('height', height)
          formData.append('width', width)

          console.log(formData)
          const res = await api.post('/images/generic-upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          setNumberUploaded(i)
          await bluebird.delay(500)
          console.log(res.data)
          return res.data.payload.url
        })
        setIsFinished(true)
        setTimeout(() => {
          saveFunction && saveFunction(urls)
          setIsOpen(false)
          setIsUploading(false)
        }, 100)
      }
    }
    func()
  }, [files])

  const UploadInner = (
    <>
      <H1 mb="12px">{`${isFinished ? 'Uploaded' : 'Uploading'} ${files?.length} image${files?.length > 1 ? 's' : ''}`}</H1>
      <SpinningIcon finished={isFinished} mb="32px" width="60px" height="60px" icon="logo-no-text" />
      <ProgressBar mb="8px" width="100%">
        <ProgressBarProgress
          finished={numberUploaded === files?.length - 1}
          width={numberUploaded === files?.length - 1 ? `100%` : `${(numberUploaded / (files?.length - 1)) * 100}%`}
        />
      </ProgressBar>
      <Flex justifyContent="flex-end" mt="24px">
        <Box width="auto" borderBottom="1px solid rgba(228, 236, 247, 1)" mb="8px">
          <Text textAlign="right" mb="8px" width="100%" fontWeight="600" fontSize="15px">
            {numberUploaded + 1} uploaded
          </Text>
        </Box>
      </Flex>
      <Text textAlign="right" light width="100%" fontWeight="600" mb="48px">
        of {files?.length} images
      </Text>
    </>
  )

  return (
    <ModalContainer interiorClose p="24px" width="700px" autoHeight isOpen={isOpen} setIsOpen={setIsOpen} onClose={() => null}>
      <Flex flexDirection="column" alignItems="center" pt="20px">
        {isUploading ? (
          UploadInner
        ) : (
          <>
            <H1 mb="32px">Select images to upload</H1>
            <DragDropContainer>
              <Icon icon="videos-icon-purple" width="50px" height="50px" />
              <Text fontSize="14px" fontWeight="500" mt="16px">
                Drag & Drop
              </Text>
              <Text fontSize="14px" fontWeight="500" mb="8px">
                or
              </Text>
              <Button mb="8px" variant="black" label="Select files" />
              <StyledInput
                type="file"
                id="clipara-file-picker"
                name="file-picker"
                accept="image/jpg, image/png, image/jpeg"
                multiple="multiple"
                onChange={(e) => {
                  const filePicker = document.getElementById('clipara-file-picker')
                  console.log(filePicker.files)
                  if (filePicker.files[0]) {
                    setFiles(Array.from(filePicker.files))
                  }
                }}
              />
            </DragDropContainer>
          </>
        )}
      </Flex>
    </ModalContainer>
  )
}

export default ImageUploadModal
