import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Flex, Box } from '../../ui/Layout'
import { baseUrl, formatCurrency, formatSeconds } from '../helpers'

const MobileContainer = styled(Flex)`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: white;
  z-index: 2147483647;
  flex-direction: column;
  transform: ${(props) => (props.show ? 'translate(0%, 0%);' : 'translate(0%, 100%);')};
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  border-radius: 24px 24px 0px 0px;
  border: 1px solid #e1e8ff;
  box-shadow: 0px -3px 8px -1px #3232470d;
  box-shadow: 0px 0px 1px 0px #0c1a4b3d;
`

const DesktopContainer = styled(Flex)`
  width: 500px;
  height: 250px;
  position: fixed;
  top: 200px;
  left: 50%;
  transform: translate(-50%, 0%);
  background-color: white;
  z-index: 2147483647;
  flex-direction: column;
  border-radius: 24px;
  border: 1px solid #e1e8ff;
  justify-content: space-between;
  box-shadow: 0px -3px 8px -1px #3232470d;
  box-shadow: 0px 0px 1px 0px #0c1a4b3d;
`

const InnerContainer = styled(Flex)`
  box-sizing: border-box;
  flex-direction: column;
  padding: 24px;
`

const Header = styled.p`
  font-weight: 400 !important;
  line-height: 24px !important;
  font-size: 16px !important;
  text-align: center;
  color: #7a7a9d !important;
  padding: 0px !important;
  text-align: left;
  margin: 0px !important;
  margin-bottom: 4px !important;
`

const SubHeader = styled.p`
  font-weight: 400 !important;
  line-height: 24px !important;
  font-size: 16px !important;
  text-align: center;
  color: #000000 !important;
  padding: 0px !important;
  text-align: left;
  margin: 0px !important;
`

const Title = styled.p`
  font-weight: 600 !important;
  line-height: 24px !important;
  font-size: 18px !important;
  text-align: center;
  color: #000000 !important;
  padding: 0px !important;
  text-align: left;
  margin: 0px !important;
  margin-bottom: 16px !important;
`

const CloseContainer = styled(Flex)`
  cursor: pointer;
  position: absolute;
  right: 6px;
  top: 6px;
  width: 40px !important;
  height: 40px !important;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

export const VideoDetailsPopover = ({ showDetailsPopover, setShowDetailsPopover, activeResult, campaign }) => {
  const popover = useRef(null)
  const isImage = activeResult.type === 'IMAGE'
  const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  useEffect(() => {
    if (showDetailsPopover) {
      setTimeout(() => document.addEventListener('click', closeMenu), 1)
    } else {
      document.removeEventListener('click', closeMenu)
    }
  }, [showDetailsPopover])

  const closeMenu = (event, override) => {
    if ((popover && popover.current && !popover.current.contains(event.target)) || override) {
      document.removeEventListener('click', closeMenu)
      setShowDetailsPopover(false)
    }
  }

  const TitleComp = <Title>{activeResult.type === 'VIDEO' ? 'Video' : 'Image'} Details</Title>

  const OurLogo = (
    <Flex justifyContent="center" mb="4px">
      {campaign && campaign.organisationId !== 747 && campaign.organisationId !== 2 ? (
        <a target="_blank" rel="noreferrer" href="https://www.getclipara.com/?utm_source=playershare">
          <img alt="powered by clipara" width={102} height={24} src={`${baseUrl}/__clipara-powered-by-inline-grey.svg`} />
        </a>
      ) : null}
    </Flex>
  )

  if (w < 700) {
    return (
      <>
        {showDetailsPopover ? (
          <Flex
            backgroundColor="rgba(0,0,0,0.3)"
            zIndex={2147483647}
            position="fixed"
            width="100%"
            height="100%"
            left="0px"
            top="0px"
          >
            <div />
          </Flex>
        ) : null}
        <MobileContainer ref={popover} show={showDetailsPopover}>
          <InnerContainer mb="16px">
            <Flex mb="4px" flexDirection="column">
              {TitleComp}
            </Flex>
            {!isImage ? (
              <Flex justifyContent="space-between">
                <Flex width="auto" flexDirection="column">
                  <Header>Duration</Header>
                  <SubHeader>{formatSeconds(activeResult.duration)}</SubHeader>
                </Flex>
                <Flex width="auto" flexDirection="column">
                  <Header>Aspect Ratio</Header>
                  <SubHeader>{activeResult.aspectRatio}</SubHeader>
                </Flex>
                {activeResult.maxHeight ? (
                  <Flex width="auto" flexDirection="column">
                    <Header>Resolution</Header>
                    <SubHeader>
                      {Math.round(activeResult.maxHeight * activeResult.aspectRatioCalc)} x {activeResult.maxHeight}
                    </SubHeader>
                  </Flex>
                ) : null}
              </Flex>
            ) : (
              <Flex>
                <Flex width="auto" flexDirection="column">
                  <Header>Width</Header>
                  <SubHeader>{Math.round(activeResult.width)}px</SubHeader>
                </Flex>
                <Flex ml="70px" width="auto" flexDirection="column">
                  <Header>Height</Header>
                  <SubHeader>{Math.round(activeResult.height)}px</SubHeader>
                </Flex>
              </Flex>
            )}
          </InnerContainer>
          {OurLogo}
        </MobileContainer>
      </>
    )
  }

  if (!showDetailsPopover) {
    return null
  }

  return (
    <>
      <Flex
        backgroundColor="rgba(0,0,0,0.3)"
        zIndex={2147483647}
        position="fixed"
        width="100%"
        height="100%"
        left="0px"
        top="0px"
      >
        <div />
      </Flex>
      <DesktopContainer ref={popover} show={showDetailsPopover}>
        <CloseContainer
          ref={close}
          onClick={(e) => {
            closeMenu(e, true)
          }}
        >
          <img width="12px" height="12px" alt="close preview" src={`${baseUrl}/__clipara-cross-black.svg`} />
        </CloseContainer>
        <InnerContainer>
          <Flex mb="30px" flexDirection="column">
            {TitleComp}
          </Flex>
          {!isImage ? (
            <Flex>
              <Flex width="150px" flexDirection="column">
                <Header>Duration</Header>
                <SubHeader>{formatSeconds(activeResult.duration)}</SubHeader>
              </Flex>
              <Flex width="150px" flexDirection="column">
                <Header>Aspect Ratio</Header>
                <SubHeader>{activeResult.aspectRatio}</SubHeader>
              </Flex>
              {activeResult.maxHeight ? (
                <Flex width="150px" flexDirection="column">
                  <Header>Resolution</Header>
                  <SubHeader>
                    {Math.round(activeResult.maxHeight * activeResult.aspectRatioCalc)} x {activeResult.maxHeight}
                  </SubHeader>
                </Flex>
              ) : null}
            </Flex>
          ) : (
            <Flex>
              <Flex width="auto" flexDirection="column">
                <Header>Width</Header>
                <SubHeader>{Math.round(activeResult.width)}px</SubHeader>
              </Flex>
              <Flex ml="70px" width="auto" flexDirection="column">
                <Header>Height</Header>
                <SubHeader>{Math.round(activeResult.height)}px</SubHeader>
              </Flex>
            </Flex>
          )}
        </InnerContainer>
        {OurLogo}
      </DesktopContainer>
    </>
  )
}
