import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize, capitalizeFirstLetter } from '../../ui/helpers'
import { H1, H2, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../../ui/Typography'
import { Flex, Box, Container } from '../../ui/Layout'
import Image from '../../ui/Image'
import { Input, TextArea } from '../../ui/Input'
import Icon from '../../ui/Icon'
import CodeCopy, { copyOnClick } from '../../ui/CodeCopy.js'
import VideoPreview from '../../ui/VideoPreview'
import CarouselPreview from '../../Components/CarouselPreview'

import WidgetPreview from '../../Components/WidgetPreview'
import Button from '../../ui/Button'
import { BigDarkSpinner } from '../../ui/Spinner'
import Toggle from '../../ui/Toggle'
import ToggleRedGreen from '../../ui/ToggleRedGreen'
import Select from '../../ui/Select'
import ColorPicker from '../../ui/ColorPicker'
import formikFormWrapper from '../../formikFormWrapper'
import { toast } from 'react-toastify'
import { useModal } from '../../Modal'
import SlideOverContainer from '../../ui/SlideOverContainer'
import CreateModal from './ShareModal'
import ChatButton from '../../Components/ChatButton'
import InstallBanner from '../../Components/InstallBanner'
import { F, uniqBy } from 'ramda'

const FormInput = formikFormWrapper(Input)

import api from '../../api'
import { useAuth } from '../../Auth'

import {
  STEPS,
  CAMPAIGN_RULE_TYPES,
  CAMPAIGN_STATUS,
  campaignRuleTypesOptions,
  WIDGET_POSITIONS,
  widgetPositionOptions,
  widgetRuleTypeOptions,
  widgetTypeDisplayName,
  WIDGET_RULE_TYPES,
  RULE_TYPES,
  ruleTypeOptions,
  ruleTypeOptionsShoppable,
  WIDGET_TYPES
} from '../types'

const TABS = {
  ONE: 'ONE',
  MULTI: 'MULTI'
}

const SHOPPABLE_TABS = {
  QUICK: 'QUICK',
  MANUAL: 'MANUAL'
}

const UrlRulesSection = ({ values, selectedVideos, setFieldValue, showUltimateInstallModal, submit }) => {
  const { user } = useAuth()
  const [products, setProducts] = useState([])
  const addedVideoProducts = (selectedVideos || [])
    .reduce((acc, a) => {
      if (a.linkedProducts) {
        return acc.concat(a.linkedProducts)
      }
      return acc
    }, [])
    .sort((a, b) => (a.product.handle > b.product.handle ? 1 : -1))

  const allProducts = uniqBy((p) => p.id, addedVideoProducts.concat(products))
  const productSelectOptions = allProducts
    ? uniqBy(
        (v) => v.value,
        allProducts.map((product) => {
          return { value: product.product.handle, label: product.product.title }
        })
      )
    : []
  const selectedVideoProduct = (allProducts || []).find((p) => p.product.handle === values.widgetRuleUrl0)
    ? (allProducts || []).find((p) => p.product.handle === values.widgetRuleUrl0).product.handle
    : null

  useEffect(() => {
    const asyncFunc = async () => {
      try {
        const shopifyRes = await api.get(`/shopify/get-products?limit=250&fetchAll=true`)
        setProducts(
          shopifyRes.data.payload.products.map((p) => {
            return { id: p.id, product: p }
          })
        )
      } catch (e) {
        console.log(e)
      }
    }
    if (user.shopifyShopUrl && showUltimateInstallModal) {
      asyncFunc()
    }
  }, [showUltimateInstallModal])

  return (
    <Flex flexDirection="column">
      <Text fontWeight="400" mb="8px" light fontSize="12px">
        Show campaign based on:
      </Text>
      <Select
        mb="24px"
        width="100%"
        value={values.matchType}
        onChange={(option) => {
          setFieldValue(`ruleType`, option.value)
          setFieldValue(`matchType`, option.value)
        }}
        options={user.shopifyShopUrl ? ruleTypeOptionsShoppable : ruleTypeOptions}
      />
      {values.matchType === RULE_TYPES.SHOPIFY_PRODUCT ? (
        <>
          <Text mb="8px" fontWeight="400" fontSize="12px" light>
            Show campaign on product page for:
          </Text>
          <Flex flexDirection="column" alignItems="baseline" mb="24px">
            <Select
              mb="8px"
              width="552px"
              value={selectedVideoProduct}
              onChange={(option) => {
                setFieldValue(`widgetRuleType0`, WIDGET_RULE_TYPES.CONTAINS)
                setFieldValue(`widgetRuleUrl0`, option.value)
              }}
              options={productSelectOptions}
              placeholder="Select option"
              mr="8px"
            />
          </Flex>
        </>
      ) : null}
      {values.matchType === RULE_TYPES.URL_RULES ? (
        <>
          <Text mb="8px" fontWeight="400" fontSize="12px" light>
            Show widget where Page URL:
          </Text>
          <Flex flexDirection="row" alignItems="baseline" mb="24px">
            <Box>
              <Select
                mb="8px"
                width="200px"
                value={values[`widgetRuleType0`]}
                onChange={(option) => setFieldValue(`widgetRuleType0`, option.value)}
                options={widgetRuleTypeOptions}
                placeholder="Select option"
                mr="8px"
              />
              {values.widgetRuleUrl0 && !values.widgetRuleType0 ? <Error>Rule type required</Error> : null}
            </Box>
            <Field width="100%" height="36px" name={`widgetRuleUrl0`} dark component={FormInput} />
          </Flex>
        </>
      ) : null}
      {values.matchType === RULE_TYPES.HOMEPAGE ? (
        <Text fontWeight="400" fontSize="12px" light mb="24px">
          Your campaign will show on the homepage of your website.
        </Text>
      ) : null}
      <Button
        mb="24px"
        variant="grey"
        label="Save page rule"
        onClick={async () => {
          await submit(undefined, true)
          toast('Page rules saved')
        }}
      />
    </Flex>
  )
}

const PlatformInstructions = () => {
  return (
    <Flex flexDirection="column">
      <Flex borderTop="1px solid rgba(237, 242, 247, 1)" mb="32px" />
      <Text width="100%" fontWeight="400" mb="8px" light textAlign="left">
        Follow the instructions below for various platforms. Don’t see a guide for you? Let us know in the live chat and we’ll add
        it.
      </Text>
      <Flex flexWrap="wrap">
        <Flex width="200px" alignItems="center" mx="24px" mb="8px">
          <Icon width="10px" height="10px" icon="logo-icon-black" mr="8px" />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://clipara.notion.site/Installing-Clipara-on-Squarespace-d78d58516b584f6fa288435b593c63fd"
          >
            <Text fontWeight="400">Squarespace</Text>
          </a>
        </Flex>
        <Flex width="200px" alignItems="center" mx="24px" mb="8px">
          <Icon width="10px" height="10px" icon="logo-icon-black" mr="8px" />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://clipara.notion.site/Installing-Clipara-on-Webflow-840297be5dd94bbc90ab5b646f6f1652"
          >
            <Text fontWeight="400">Webflow</Text>
          </a>
        </Flex>
        <Flex width="200px" alignItems="center" mx="24px" mb="8px">
          <Icon width="10px" height="10px" icon="logo-icon-black" mr="8px" />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://clipara.notion.site/Installing-Clipara-on-Wordpress-e09da8f655c74fcd81215a2f25a1dca3"
          >
            <Text fontWeight="400">Wordpress</Text>
          </a>
        </Flex>
        <Flex width="200px" alignItems="center" mx="24px" mb="8px">
          <Icon width="10px" height="10px" icon="logo-icon-black" mr="8px" />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://clipara.notion.site/Installing-Clipara-on-Big-Cartel-4b391b9475064815b300d0e8f7c6e508"
          >
            <Text fontWeight="400">Big Cartel</Text>
          </a>
        </Flex>
        <Flex width="200px" alignItems="center" mx="24px" mb="8px">
          <Icon width="10px" height="10px" icon="logo-icon-black" mr="8px" />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://clipara.notion.site/Installing-Clipara-on-Framer-1332949172b74d4e8b5b4a8fd0c575b0"
          >
            <Text fontWeight="400">Framer</Text>
          </a>
        </Flex>
        <Flex width="200px" alignItems="center" mx="24px" mb="8px">
          <Icon width="10px" height="10px" icon="logo-icon-black" mr="8px" />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://clipara.notion.site/Installing-Clipara-on-Wix-94099c7e2be24219b2e0a5e1ca407dbb"
          >
            <Text fontWeight="400">Wix</Text>
          </a>
        </Flex>
      </Flex>
    </Flex>
  )
}

const NavigationLinkWarning = ({ showNavigationLinkWarning }) => {
  if (!showNavigationLinkWarning) {
    return null
  }
  return (
    <Flex backgroundColor="#FEE6E4" px="24px" py="8px" borderRadius="8px">
      <Text color="rgba(207, 70, 85, 1)" fontWeight="400" lineHeight="18px">
        One or more of your navigation elements doesn't have a link. Make sure all videos have a link before installing.
      </Text>
    </Flex>
  )
}

const NonShoppable = ({
  values,
  setShowUltimateInstallModal,
  showUltimateInstallModal,
  selectedVideos,
  setFieldValue,
  submit,
  isFloater,
  showNavigationLinkWarning
}) => {
  const [activeTab, setActiveTab] = useState(TABS.ONE)
  const { user } = useAuth()
  return (
    <Flex flexDirection="column" alignItems="center">
      <Toggle
        label1="One page"
        label2="Multiple pages"
        value1={TABS.ONE}
        value2={TABS.MULTI}
        selected={activeTab}
        setSelected={setActiveTab}
        width="180px"
        mb="24px"
      />
      <NavigationLinkWarning showNavigationLinkWarning={showNavigationLinkWarning} />
      <Flex borderTop="1px solid rgba(237, 242, 247, 1)" mb="32px" />

      {activeTab === TABS.ONE ? (
        <>
          <Text width="100%" fontWeight="500" fontSize="16px" mb="8px" textAlign="left">
            Installation
          </Text>
          <Text width="100%" fontWeight="400" mb="8px" light textAlign="left">
            Paste this code into where you want to embed it on your site:
          </Text>
          <CodeCopy
            width="100%"
            id="install-modal-code-snippet"
            code={`<div data-campaign-id="${values.id}" id="__clipara-embed" style="width:100%;"></div>
<script>if(window.__clipara||(window.__clipara={}),window.__clipara.organisationId=${user.organisationId},!document.querySelector('[src$="getclipara.com/widget.js"]')){let t=document.createElement("script");t.src="https://widget.getclipara.com/widget.js",t.defer=!1,document.body.appendChild(t)}</script>`}
          />
          <Text mt="-16px" mb="24px" width="100%" fontSize="12px" lineHeight="16px" lightLight>
            Depending on the platform you use, your embed might only show up on the live published version of your site
          </Text>
        </>
      ) : null}
      {activeTab === TABS.MULTI ? (
        <>
          <Text width="100%" fontWeight="500" fontSize="16px" mb="8px" textAlign="left">
            Page rules
          </Text>
          <Text width="100%" fontWeight="400" fontSize="14px" mb="16px" light textAlign="left">
            Tell us where you want the campaign to appear:
          </Text>
          <UrlRulesSection
            submit={submit}
            values={values}
            setShowUltimateInstallModal={setShowUltimateInstallModal}
            showUltimateInstallModal={showUltimateInstallModal}
            selectedVideos={selectedVideos}
            setFieldValue={setFieldValue}
          />
          <Flex borderTop="1px solid rgba(237, 242, 247, 1)" mb="32px" />
          <Text width="100%" fontWeight="500" fontSize="16px" mb="8px" textAlign="left">
            Installation
          </Text>
          <Text width="100%" fontWeight="400" mb="8px" light textAlign="left">
            1. Make sure you have the Clipara script installed in the header of your site
          </Text>
          <CodeCopy
            width="100%"
            id="install-modal-code-snippet"
            code={`<script>window.__clipara = {};window.__clipara.organisationId = ${user.organisationId};</script>
<script defer src="https://widget.getclipara.com/widget.js" type="text/javascript"></script>`}
          />
          <Text mt="-16px" mb="24px" width="100%" fontSize="12px" lineHeight="16px" lightLight>
            You only need to do this once.
          </Text>
          {isFloater ? null : (
            <>
              <Text width="100%" fontWeight="400" mb="8px" light textAlign="left">
                2. Then copy and paste this snippet into the page and section you want your campaign
              </Text>
              <CodeCopy
                width="100%"
                id="install-modal-code-snippet"
                code={`<div id="__clipara-embed" style="width:100%;"></div>`}
              />
            </>
          )}
          <Text mt="-16px" mb="24px" fontSize="12px" lineHeight="16px" lightLight>
            Depending on the platform you use, your embed might only show up on the live published version of your site
          </Text>
        </>
      ) : null}
      <PlatformInstructions />
    </Flex>
  )
}

const CodeInline = styled(Span)`
  background: #eef2f7;
  padding: 4px;
  border-radius: 8px;
  font-weight: 500;
`

const CustomLauncher = ({
  values,
  setShowUltimateInstallModal,
  showUltimateInstallModal,
  selectedVideos,
  setFieldValue,
  submit,
  isFloater,
  showNavigationLinkWarning
}) => {
  const [activeTab, setActiveTab] = useState(TABS.ONE)
  const { user } = useAuth()
  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex borderTop="1px solid rgba(237, 242, 247, 1)" mb="32px" />
      <Text width="100%" fontWeight="500" fontSize="16px" mb="8px" textAlign="left">
        Installation
      </Text>
      <Text width="100%" fontWeight="400" mb="8px" light textAlign="left">
        To open the Clipara video player add the following script to the {`<head>`} or {`<body>`} section of your website.
      </Text>
      <CodeCopy
        width="100%"
        id="install-modal-code-snippet"
        code={`<script>if(window.__clipara||(window.__clipara={}),window.__clipara.organisationId=${user.organisationId},!document.querySelector('[src$="getclipara.com/widget.js"]')){let t=document.createElement("script");t.src="https://widget.getclipara.com/widget.js",t.defer=!1,document.body.appendChild(t)}</script>`}
      />
      <Text width="100%" fontWeight="400" mb="8px" light textAlign="left">
        Then to open the popup on clicking a button (or any other element), you need to add the{' '}
        <CodeInline>data-campaign-id="{values.id}"</CodeInline> attribute to an existing button on your page.
      </Text>
      <Text width="100%" fontWeight="400" mb="8px" light textAlign="left">
        For example:
      </Text>
      <CodeCopy
        width="100%"
        id="install-modal-code-snippet-1"
        code={`<button data-campaign-id="${values.id}">example</button>`}
      />
      {/* <Text mt="-16px" mb="24px" width="100%" fontSize="12px" lineHeight="16px" lightLight>
        Depending on the platform you use, your embed might only show up on the live published version of your site
      </Text> */}
      {/* <PlatformInstructions /> */}
    </Flex>
  )
}

const Shoppable = ({
  values,
  setShowUltimateInstallModal,
  showUltimateInstallModal,
  selectedVideos,
  setFieldValue,
  submit,
  isFloater,
  showNavigationLinkWarning
}) => {
  const [activeTab, setActiveTab] = useState(SHOPPABLE_TABS.QUICK)
  const { user } = useAuth()
  return (
    <Flex flexDirection="column" alignItems="center">
      <Toggle
        label1="Quick"
        label2="Manual (Theme 1.0)"
        value1={SHOPPABLE_TABS.QUICK}
        value2={SHOPPABLE_TABS.MANUAL}
        selected={activeTab}
        setSelected={setActiveTab}
        width="180px"
        mb="24px"
      />
      <NavigationLinkWarning showNavigationLinkWarning={showNavigationLinkWarning} />
      <Flex borderTop="1px solid rgba(237, 242, 247, 1)" mb="32px" />
      <Text width="100%" fontWeight="500" fontSize="16px" mb="8px" textAlign="left">
        Page rules
      </Text>
      <Text width="100%" fontWeight="400" fontSize="14px" mb="16px" light textAlign="left">
        Tell us where you want the campaign to appear:
      </Text>
      <UrlRulesSection
        submit={submit}
        values={values}
        setShowUltimateInstallModal={setShowUltimateInstallModal}
        showUltimateInstallModal={showUltimateInstallModal}
        selectedVideos={selectedVideos}
        setFieldValue={setFieldValue}
      />
      <Flex borderTop="1px solid rgba(237, 242, 247, 1)" mb="32px" />

      {isFloater ? null : (
        <>
          <Text width="100%" fontWeight="500" fontSize="16px" mb="8px" textAlign="left">
            Installation
          </Text>

          {activeTab === SHOPPABLE_TABS.QUICK ? (
            <>
              <Text width="100%" fontWeight="400" fontSize="14px" mb="16px" light textAlign="left">
                In your theme editor add a section or block then drag it into place.
              </Text>
              <Flex
                boxShadow="4px 4px 0px 0px rgba(0, 0, 0, 1);"
                border="2px solid rgba(0, 0, 0, 1)"
                borderRadius="16px"
                overflow="hidden"
                width="calc(100% - 16px)"
              >
                <img width="100%" alt="shopify embed" src="/illustrations/shopifyEmbed.gif" />
              </Flex>
            </>
          ) : null}
          {activeTab === SHOPPABLE_TABS.MANUAL ? (
            <>
              <Text width="100%" fontWeight="400" fontSize="14px" mb="16px" light textAlign="left">
                Copy and paste this code snippet into where you want your campaign to appear.
              </Text>
              <Flex height="100%" flexDirection="column" alignItems="flex-end" justifyContent="center">
                <CodeCopy id="embed-code-snippet" width="100%" code={`<div id="__clipara-embed" style="width:100%;"></div>`} />
                <Button
                  width="166px"
                  variant="softBlue"
                  label="Copy Embed Code"
                  onClick={() => {
                    copyOnClick({
                      id: 'embed-code-snippet',
                      code: `<div id="__clipara-embed" style="width:100%;"></div>`,
                      toastMessage: 'Snippet copied!'
                    })
                  }}
                />
              </Flex>
            </>
          ) : null}
        </>
      )}
    </Flex>
  )
}

const Footer = styled(Flex)`
  z-index: 1;
  bottom: 0px;
  right: 0px;
  width: 600px;
  background-color: white;
  position: absolute;
  box-shadow: 0px -2px 8px 0px rgba(184, 180, 180, 0.25);
  justify-content: flex-end;
  padding: 8px;
  padding-right: 16px;
`

const UltimateInstallModal = ({
  submit,
  values,
  setShowUltimateInstallModal,
  showUltimateInstallModal,
  selectedVideos,
  setFieldValue,
  activeWidget
}) => {
  const [activeTab, setActiveTab] = useState(TABS.ONE)
  const { user } = useAuth()
  const isFloater = values.widget.type === WIDGET_TYPES.FLOATING
  const numberOfLinks = Object.values(values.buttonLinks || {}).filter((el) => el).length
  const notEnoughLinks = numberOfLinks !== selectedVideos.length
  const showNavigationLinkWarning = values.isNavigationCampaign && notEnoughLinks
  return (
    <SlideOverContainer
      interiorClose
      p="24px"
      autoHeight
      overflowY="unset"
      isOpen={showUltimateInstallModal}
      setIsOpen={setShowUltimateInstallModal}
    >
      <Flex flexDirection="column" alignItems="center" pb="84px" overflowY="scroll" noScrollBar>
        <H3 fontSize="28px" mb="24px">
          Install your widget
        </H3>
        {values.isCustomLauncher ? (
          <CustomLauncher
            submit={submit}
            values={values}
            setShowUltimateInstallModal={setShowUltimateInstallModal}
            showUltimateInstallModal={showUltimateInstallModal}
            selectedVideos={selectedVideos}
            setFieldValue={setFieldValue}
            isFloater={isFloater}
            showNavigationLinkWarning={showNavigationLinkWarning}
          />
        ) : null}
        {user.shopifyShopUrl && !values.isCustomLauncher ? (
          <Shoppable
            submit={submit}
            values={values}
            setShowUltimateInstallModal={setShowUltimateInstallModal}
            showUltimateInstallModal={showUltimateInstallModal}
            selectedVideos={selectedVideos}
            setFieldValue={setFieldValue}
            isFloater={isFloater}
            showNavigationLinkWarning={showNavigationLinkWarning}
          />
        ) : null}
        {!user.shopifyShopUrl && !values.isCustomLauncher ? (
          <NonShoppable
            submit={submit}
            values={values}
            setShowUltimateInstallModal={setShowUltimateInstallModal}
            showUltimateInstallModal={showUltimateInstallModal}
            selectedVideos={selectedVideos}
            setFieldValue={setFieldValue}
            isFloater={isFloater}
            showNavigationLinkWarning={showNavigationLinkWarning}
          />
        ) : null}
        <Footer>
          <Button
            renderRightIcon={() => <Icon icon="chevron-right-white" ml="16px" width={10} height={10} />}
            variant="black"
            label="Done"
            onClick={() => {
              setShowUltimateInstallModal(false)
              submit(undefined, true)
            }}
          />
        </Footer>
      </Flex>
    </SlideOverContainer>
  )
}

export default UltimateInstallModal
