import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Icon from '../ui/Icon.js'
import ReactTooltip from 'react-tooltip'

const ChevronImage = styled(Icon)`
  transform: rotate(${(props) => (props.isOpen ? '-90' : '-270')}deg);
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  width: 20px;
  height: 20px;
`

export const IconBox = styled(Flex)`
  width: 36px;
  height: 36px;
  background: ${(props) =>
    props.iconBgColor === 'purple'
      ? 'linear-gradient(225deg, #bb65ff 0%, #4c6fff 100%)'
      : 'linear-gradient(225deg, rgba(41, 39, 46, 0.74) 0%, #27272E 100%)'};
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 24px;
`

const CollapsableSection = ({
  children,
  label,
  startsOpen,
  openCollapsible,
  setOpenCollapsible,
  topBorder,
  icon,
  iconBgColor,
  noContentPadding
}) => {
  ReactTooltip.rebuild()
  const [isOpen, setIsOpen] = useState(startsOpen)
  const headerRef = useRef()

  useEffect(() => {
    if (openCollapsible && openCollapsible !== label) {
      setIsOpen(false)
    }
  }, [openCollapsible])
  return (
    <Flex
      pt="4px"
      borderBottom="1px solid rgba(237, 242, 247, 1)"
      borderTop={topBorder ? '1px solid rgba(237, 242, 247, 1)' : 'none'}
    >
      <Flex
        flexDirection="column"
        onClick={(e) => {
          if (headerRef.current && (headerRef.current === e.target || headerRef.current.contains(e.target))) {
            setIsOpen(!isOpen)
            setOpenCollapsible && setOpenCollapsible(label)
            e.stopPropagation()
          }
        }}
      >
        <Flex cursor={'pointer'} ref={headerRef} alignItems="center" pb="16px" pt="12px" px="24px">
          <Flex alignItems="center">
            {icon && (
              <IconBox iconBgColor={iconBgColor}>
                <Icon width={16} height={16} icon={icon} />
              </IconBox>
            )}
            <H3 lightLight mb="0" fontWeight={500}>
              {label}
            </H3>
          </Flex>

          <ChevronImage isOpen={isOpen} icon="chevron-right-grey" alt="chevron icon" width="20px" height="20px" />
        </Flex>
        {isOpen ? (
          <Flex
            px={noContentPadding ? '0px' : '24px'}
            flexDirection="column"
            onClick={(e) => {
              if (headerRef.current && headerRef.current === e.target) {
                return
              }
            }}
          >
            {children}
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  )
}

export default CollapsableSection
