import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../../ui/Layout.js'
import { baseUrl } from '../helpers.js'

const ImageExpandButtonComp = styled(Flex)`
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px !important;
  height: 50px !important;
  border-radius: 40px !important;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  background-color: rgba(78, 78, 78, 0.4) !important;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
`

export const ImageExpandButton = () => {
  return (
    <ImageExpandButtonComp className="hover">
      <img width="19.5px" height="19.5px" alt="close preview" src={`${baseUrl}/__clipara-expand-arrows-white.svg`} />
    </ImageExpandButtonComp>
  )
}
