import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize } from '../../ui/helpers.js'
import { H1, H2, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../../ui/Typography.js'
import { Flex, Box, Container } from '../../ui/Layout.js'
import Image from '../../ui/Image.js'
import { Input, TextArea } from '../../ui/Input.js'
import Icon from '../../ui/Icon.js'
import CodeCopy from '../../ui/CodeCopy.js'
import VideoPreview from '../../ui/VideoPreview.js'
import CarouselPreview from '../../Components/CarouselPreview.js'

import WidgetPreview from '../../Components/WidgetPreview.js'
import Button from '../../ui/Button.js'
import { BigDarkSpinner } from '../../ui/Spinner.js'
import Toggle from '../../ui/Toggle.js'
import Select from '../../ui/Select.js'
import ColorPicker from '../../ui/ColorPicker.js'
import formikFormWrapper from '../../formikFormWrapper.js'
import { toast } from 'react-toastify'
import { useModal } from '../../Modal.js'
import ModalContainer from '../../ui/ModalContainer.js'
import ChatButton from '../../Components/ChatButton'

const FormInput = formikFormWrapper(Input)

import api from '../../api.js'
import { useAuth } from '../../Auth.js'

import {
  STEPS,
  CAMPAIGN_RULE_TYPES,
  CAMPAIGN_STATUS,
  campaignRuleTypesOptions,
  WIDGET_POSITIONS,
  widgetPositionOptions,
  widgetRuleTypeOptions,
  widgetTypeDisplayName,
  getSelectWidgetOptions,
  getSelectNavigationWidgetOptions,
  getSelectInlineWidgetOptions
} from '../types'

const ChoiceContainer = styled(Container)`
  border: 1px solid #e1e8ff;
  padding: 8px;
  cursor: pointer;
  width: 30%;
  max-width: 350px;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
  &&:hover {
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.2), 0px 4px 20px -2px rgba(50, 50, 71, 0.2);
    border: 2px solid #7895ff;
    margin-top: -1px;
  }
`

const InnerContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  height: 200px;
  background: linear-gradient(225deg, #f1f1f5 0%, #e4ecf7 100%);
  margin-bottom: 24px;
  position: relative;
`

const BottomRightIcon = styled(Icon)`
  position: absolute;
  bottom: 8px;
  right: 8px;
`

const DisplayType = ({
  step,
  setStep,
  values,
  selectedVideos,
  openFullscreenDesktopPreview,
  openFullscreenMobilePreview,
  videoObjects,
  setFieldValue,
  submit,
  widgets,
  setWidgets
}) => {
  const [search, setSearch] = useSearchParams()
  const [showWidgetSelectModal, setShowWidgetSelectModal] = useState(false)
  const widgetModalOptions = values.isNavigationCampaign
    ? getSelectNavigationWidgetOptions(widgets)
    : values.isInlineCampaign
    ? getSelectInlineWidgetOptions(widgets)
    : getSelectWidgetOptions(widgets)
  return (
    <>
      <Flex height="100vh" flexDirection="column" alignItems="center">
        <Flex onClick={() => setStep(STEPS.CAMPAIGN_TYPE)} cursor="pointer" position="fixed" left="24px" top="24px" width="auto">
          <Icon mt="4px" mr="16px" icon="chevron-left-lightlight" height="16px" width="16px" />
          <Text lightLight fontSize="14px" mb="30px" textAlign="left">
            Back to Campaign
          </Text>
        </Flex>
        <H1 fontSize="28px" mt="72px" mb="72px" textAlign="left">
          How do you want to display your campaign?
        </H1>
        <Flex mb="50px" justifyContent="center" px="50px">
          {widgetModalOptions && widgetModalOptions.length ? (
            <ChoiceContainer onClick={() => setShowWidgetSelectModal(true)}>
              <InnerContainer>
                <img src="/example-player.svg" alt="example-player" />
                <BottomRightIcon icon="cog-purple" width="24px" height="24px" />
              </InnerContainer>
              <H3 fontSize="16px" mb="16px">
                Existing Widget
              </H3>
              <Text light textAlign="center" mx="24px" mb="32px">
                Use a widget style you’ve already created and added to your site
              </Text>
            </ChoiceContainer>
          ) : null}
          <ChoiceContainer onClick={() => setStep(STEPS.WIDGET_TYPE)}>
            <InnerContainer>
              <img src="/example-player.svg" alt="example-player" />
              <BottomRightIcon icon="paintbrush-purple" width="24px" height="24px" />
            </InnerContainer>
            <H3 fontSize="16px" mb="16px">
              Create a new widget
            </H3>
            <Text light textAlign="center" mx="24px" mb="32px">
              Design a new widget or embed a new style to your site
            </Text>
          </ChoiceContainer>
        </Flex>
      </Flex>
      <ModalContainer
        overflowY="undefined"
        interiorClose
        p="24px"
        width="500px"
        height="300px"
        isOpen={showWidgetSelectModal}
        setIsOpen={setShowWidgetSelectModal}
      >
        <H3 width="100%">Select Existing Widget</H3>
        <Select
          mb="8px"
          fullWidth
          value={widgets && widgets.find((w) => w.id === values.widgetId)}
          onChange={(option) => {
            setFieldValue(`widgetId`, option.value)
          }}
          options={widgetModalOptions}
          placeholder="Select"
        />
        <Button
          onClick={() => {
            setStep(STEPS.EDIT_EMBED)
            setFieldValue(`showInstallModal`, true)
          }}
          variant="black"
          label="Use this widget"
          position="absolute"
          bottom="24px"
          right="24px"
        />
      </ModalContainer>
    </>
  )
}

export default DisplayType
