import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate, useLocation } from 'react-router-dom'
import axios from './api'
import { useCookies } from 'react-cookie'
import Spinner from './ui/Spinner'
import { Flex, Box } from './ui/Layout'
import { GradientFont } from './ui/Typography'
import { colors } from './ui/helpers'
import api from './api.js'
import moment from 'moment'

let VideoContext = React.createContext()

export const VideoProvider = ({ children }) => {
  const [videos, setVideoState] = useState([])
  const [images, setImagesState] = useState([])

  const refresh = async (count = 0) => {
    if (count > 10) {
      return
    }

    const res = await api.get('/video/get')

    if (res.data.success) {
      setVideoState(res.data.payload)
      setImagesState(res.data.imagePayload)
      if (
        res.data.payload.find((video) => !video.playbackId && moment(video.createdAt).isAfter(moment().subtract(10, 'minutes')))
      ) {
        console.log('video with no playbackId found refresh')
        setTimeout(() => refresh(count + 1), count < 5 ? 3000 : 10000)
      }
    }
  }

  const setVideos = (newVideos, callBack) => {
    setVideoState(newVideos)
    if (newVideos.find((video) => !video.playbackId)) {
      console.log('video with no playbackId found')
      setTimeout(() => refresh(0, callBack), 5000)
    }
  }

  const props = { videos, images, setVideos, refresh }
  return <VideoContext.Provider value={props}>{children}</VideoContext.Provider>
}

export const useVideos = () => {
  return React.useContext(VideoContext)
}
