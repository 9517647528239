import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout'
import { loadVideoLite, baseUrl, formatSeconds } from './helpers'
import { getAspectRatio } from './EmbedCarousel'

const PlayButton = styled.svg`
  fill: none !important;
  width: ${(props) => props.width}px !important;
  height: ${(props) => props.height}px !important;
  position: absolute;
  z-index: 2;

  @keyframes cliparapulse {
    0% {
      box-shadow: 0 0 0 0 ${(props) => props.playButtonColor};
    }
    100% {
      box-shadow: 0 0 0 8px #fbb32f01;
    }
  }
  border-radius: 60px;
  cursor: pointer;
  ${(props) => props.animate && `animation: cliparapulse 1500ms infinite;`}
  @media(max-width: 1000px) {
    width: 40px !important;
    height: 40px !important;
  }
  ${(props) => {
    if (props.widgetPlayButtonPosition === 'BOTTOM_LEFT') {
      return `bottom: 16px; left: 16px;`
    }
    if (props.widgetPlayButtonPosition === 'BOTTOM_RIGHT') {
      return `bottom: 16px; right: 16px;`
    }
    return ` top: 50%; left: 50%; transform: translate(-50%, -50%);`
  }}
`

const Title = styled.h2`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily.replace(';') : 'inherit')} !important;
  font-weight: ${(props) => props.fontWeight || '700'} !important;
  font-size: ${(props) => props.fontSize || '50'}px !important;
  line-height: ${(props) => props.fontSize || '50'}px !important;
  text-align: ${(props) => props.textAlign || 'center'} !important;
  color: ${(props) => props.color || 'black'};
  text-decoration: none !important;
  margin-bottom: 16px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  width: ${(props) => props.width}px;
  max-width: 100%;
  padding-bottom: 0px !important;
  word-break: break-word;
`

const VideoDuration = styled.p`
  position: absolute;
  bottom: ${(props) => props.durationPadding || 16}px;
  right: ${(props) => props.durationPadding || 16}px;
  color: white !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  margin: 0px !important;
  z-index: 2;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

const VideoImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  border-radius: ${(props) => props.borderRadius || 0}px;
  cursor: pointer;
`

const VideoContainer = styled(Flex)`
  ${(props) => (props.hasLoaded ? 'visibility: visible;' : '')};
  border-radius: ${(props) => props.borderRadius || 0}px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`

const Video = styled.video`
  border-radius: ${(props) => props.borderRadius || 0}px;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  z-index: 1;
  cursor: pointer;
`

const Picture = styled.picture`
  width: 100%;
  height: 100%;
`

const PlayButtonComponent = ({ campaign, pbWidth }) => {
  return (
    <PlayButton
      widgetPlayButtonPosition={campaign.widgetPlayButtonPosition}
      animate={campaign.playButtonAnimation}
      playButtonColor={campaign.playButtonColor || '#333333'}
      width={pbWidth}
      height={pbWidth}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {campaign.widgetPlayButtonStyle === 'FILLED' ? (
        <>
          <circle cx="30" cy="30" r="30" fill={campaign.playButtonColor || '#333333'} />
          <path
            d="M45 28.268C46.3333 29.0378 46.3333 30.9623 45 31.7321L24 43.8564C22.6667 44.6262 21 43.664 21 42.1244L21 17.8756C21 16.336 22.6667 15.3738 24 16.1436L45 28.268Z"
            fill="white"
          />
        </>
      ) : (
        <>
          <circle cx="30" cy="30" r="28.5" stroke={campaign.playButtonColor || '#333333'} stroke-width="3" />
          <path
            d="M46.1097 29.2683C47.4431 30.0381 47.4431 31.9626 46.1097 32.7324L24.9451 44.9518C23.6118 45.7216 21.9451 44.7594 21.9451 43.2198L21.9451 18.781C21.9451 17.2414 23.6118 16.2791 24.9451 17.0489L46.1097 29.2683Z"
            fill={campaign.playButtonColor || '#333333'}
          />
        </>
      )}
    </PlayButton>
  )
}

const WIDTH_TO_HEIGHT = {
  LANDSCAPE: (x) => (x / 16) * 9,
  PORTRAIT: (x) => (x / 9) * 16,
  SQUARE: (x) => x
}

const EmbedSingle = ({ campaign, setShowFullscreenPlayer, setActiveFullScreenVideo }) => {
  const [resize, setResize] = useState()
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)

  const {
    videos,
    playButtonColor,
    position,
    borderRadius,
    marginY,
    marginX = 0,
    fontSize,
    fontWeight,
    widgetFontFamily,
    textAlign,
    text,
    titleColor,
    durationDisplay,
    responsive,
    maxWidth,
    dimensions,
    isShoppable,
    mobileDimensions
  } = campaign
  const [trackedView, setTrackedView] = useState(false)
  const singleEmbed = useRef()

  useEffect(() => {
    function handleResize() {
      setResize(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  const onClick = async () => {
    if (videos.length > 1 || isShoppable) {
      setShowFullscreenPlayer(true)
    }
    if (!videoPlaying) {
      console.log('PLAY')

      if (!hasLoaded) {
        await loadVideoLite({ id: `__clipara-single-embed-${video.id}`, video, campaign })
        document.getElementById(`__clipara-single-embed-${video.id}`).addEventListener('ended', () => {
          setVideoPlaying(false)
        })
        setHasLoaded(true)
      } else {
        document.getElementById(`__clipara-single-embed-${video.id}`).play()
      }
      setVideoPlaying(true)
    } else {
      console.log('PAUSE')
      setVideoPlaying(false)
      document.getElementById(`__clipara-single-embed-${video.id}`).pause()
    }
  }

  // NEW FOR DASH
  const videoWidth = Math.round(
    responsive
      ? document.getElementById('__clipara-embed') && document.getElementById('__clipara-embed').clientWidth
      : maxWidth < (document.getElementById('__clipara-embed') && document.getElementById('__clipara-embed').clientWidth)
      ? maxWidth
      : document.getElementById('__clipara-embed') && document.getElementById('__clipara-embed').clientWidth
  )

  // NEW FOR DASH
  useEffect(() => {
    setResize(window.innerWidth)
  }, [videoWidth])

  const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  const dimensionsToUse = w < 700 && mobileDimensions === 'PORTRAIT' ? 'PORTRAIT' : dimensions
  const video = videos[0]
  const isVideo = video.type === 'VIDEO'
  const imageHeight = Math.round(WIDTH_TO_HEIGHT[dimensionsToUse](videoWidth))
  const thumbnailToUse = w < 700 ? video.customMobileThumbnail || video.customThumbnail : video.customThumbnail
  const imageUrl = thumbnailToUse
    ? thumbnailToUse + `-/scale_crop/${videoWidth}x${imageHeight}/center/`
    : `https://image.mux.com/${video.playbackId}/thumbnail.png?time=${
        video.thumbnailTime || 0.0
      }&width=${videoWidth}&height=${imageHeight}&fit_mode=crop`
  const isSmallPb = videoWidth < 175
  const pbWidth = isSmallPb ? 36 : 60
  const durationPadding = borderRadius > 32 ? borderRadius / 2 : 16
  const webpUrl = thumbnailToUse
    ? thumbnailToUse + `-/scale_crop/${videoWidth}x${imageHeight}/center/-/format/webp/`
    : imageUrl.replace('.png', '.webp')

  return (
    <>
      {text ? (
        <Title
          color={titleColor}
          fontSize={fontSize}
          fontWeight={fontWeight}
          fontFamily={widgetFontFamily}
          textAlign={textAlign}
          width={videoWidth}
        >
          {text}
        </Title>
      ) : null}
      <Flex
        height={imageHeight}
        minHeight={imageHeight}
        onClick={onClick}
        position="relative"
        maxWidth={responsive ? '100%' : maxWidth || '100%'}
        ref={singleEmbed}
      >
        {videos.length === 1 && !isShoppable ? (
          <VideoContainer hasLoaded={hasLoaded} borderRadius={borderRadius}>
            <Video
              id={`__clipara-single-embed-${video.id}`}
              borderRadius={borderRadius}
              preload="metadata"
              playsInline
              webkit-playsInline
              x-webkit-airplay="allow"
            />
          </VideoContainer>
        ) : null}
        {isVideo ? (
          <Picture>
            <source srcset={webpUrl} type="image/webp" />
            <source srcset={imageUrl} type="image/png" />
            <VideoImage
              height={imageHeight}
              minHeight={imageHeight}
              draggable="false"
              borderRadius={borderRadius}
              loading="lazy"
              src={imageUrl}
              alt={video.name}
            />
          </Picture>
        ) : (
          <Picture>
            <VideoImage
              height={imageHeight}
              minHeight={imageHeight}
              draggable="false"
              borderRadius={borderRadius}
              loading="lazy"
              src={`https://clipara.b-cdn.net/${video.path}?aspect_ratio=${getAspectRatio(dimensionsToUse)}&width=${Math.round(
                videoWidth * 2
              )}`}
              alt={video.name}
            />
          </Picture>
        )}
        <PlayButtonComponent pbWidth={50} campaign={campaign} />
        {durationDisplay && !videoPlaying ? (
          <VideoDuration durationPadding={durationPadding}>{video.duration ? formatSeconds(video.duration) : ''}</VideoDuration>
        ) : null}
      </Flex>
    </>
  )
}

export default EmbedSingle
