import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize } from '../ui/helpers.js'
import { H1, H2, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import SideNav from '../Components/SideNav.js'
import VideoPlayer from '../Components/VideoPlayer.js'
import formikFormWrapper from '../formikFormWrapper.js'
import { toast } from 'react-toastify'
import { useVideos } from '../VideoProvider.js'
import bluebird from 'bluebird'

import api from '../api.js'
import { useAuth } from '../Auth.js'
import { getAllowedVideoDuration, getNumberOfAllowedVideos } from '../Plans.js'
import { ProgressBar, ProgressBarProgress, SpinningIcon } from '../Videos/VideoUpload.js'
window.URL = window.URL || window.webkitURL

const getHeightAndWidthFromDataUrl = (dataURL) =>
  new Promise((resolve) => {
    const img = new Image()
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width
      })
    }
    img.src = dataURL
  })

const ImageUpload = ({ isModal, closeModal }) => {
  const [files, setFiles] = useState()
  const [isFinished, setIsFinished] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [numberUploaded, setNumberUploaded] = useState(0)

  useEffect(() => {
    const func = async () => {
      if (files.length) {
        setIsUploading(true)
        await bluebird.each(files, async (file, i) => {
          let formData = new FormData()
          const fileAsDataURL = window.URL.createObjectURL(file)
          const { height, width } = await getHeightAndWidthFromDataUrl(fileAsDataURL)
          console.log({ height, width })

          formData.append('file', file)
          formData.append('name', file.name)
          formData.append('height', height)
          formData.append('width', width)

          console.log(formData)
          const res = await api.post('/images/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          setNumberUploaded(i)
          await bluebird.delay(500)
          console.log(res.data)
        })
      }
      setIsFinished(true)
      setIsUploading(false)
      // refresh()
    }
    func()
  }, [files])

  const UploadingInnards = (
    <>
      <H1 mb="12px">{`${isFinished ? 'Uploaded' : 'Uploading'} ${files?.length} image${files?.length > 1 ? 's' : ''}`}</H1>
      <SpinningIcon finished={isFinished} mb="32px" width="60px" height="60px" icon="logo-no-text" />
      {files?.map((file, i) => {
        return (
          <Flex key={file?.name} flexDirection="column" alignItems="center" pt="32px">
            <Text width="100%" textAlign="left" mb="8px" fontWeight="500">
              {file?.name}
            </Text>
            <ProgressBar mb="8px" width="100%">
              <ProgressBarProgress finished={i <= numberUploaded} width={i <= numberUploaded ? `100%` : '0%'} />
            </ProgressBar>
            <Flex justifyContent="space-between">
              <Text lightLight>{Math.round(file?.size / 10000) / 100} MB</Text>
            </Flex>
          </Flex>
        )
      })}
      <Flex justifyContent="flex-end" mt="24px">
        <Box width="auto" borderBottom="1px solid rgba(228, 236, 247, 1)" mb="8px">
          <Text textAlign="right" mb="8px" width="100%" fontWeight="600" fontSize="15px">
            {numberUploaded + 1} uploaded
          </Text>
        </Box>
      </Flex>
      <Text textAlign="right" light width="100%" fontWeight="600" mb="48px">
        of {files?.length} images
      </Text>
    </>
  )

  const showUploadScreen = isUploading || isFinished

  if (showUploadScreen && !isModal) {
    return (
      <Flex padding="60px">
        <Container alignItems="center">
          {UploadingInnards}
          <Flex justifyContent="flex-end">
            <a href="/images/upload">
              <Button isDisabled={numberUploaded + 1 !== files?.length} mb="8px" variant="black" label="Add another image" />
            </a>
            <Box width="32px" />
            <Link to="/campaigns/create?step=ON_SITE_OFF_SITE">
              <Button isDisabled={numberUploaded + 1 !== files?.length} mb="8px" variant="black" label="Create campaign" />
            </Link>
          </Flex>
        </Container>
      </Flex>
    )
  }

  if (showUploadScreen && isModal) {
    return (
      <Flex position="relative" flexDirection="column" alignItems="center" width="100%" p="76px">
        <Flex
          width="auto"
          justifyContent="center"
          position="absolute"
          top="0px"
          left="24px"
          zIndex="1"
          cursor="pointer"
          onClick={closeModal}
        >
          <Icon mt="4px" mr="16px" icon="chevron-left-lightlight" height="16px" width="16px" />
          <Text lightLight>Back</Text>
        </Flex>
        {UploadingInnards}
        <Flex justifyContent="center">
          <Button onClick={closeModal} width="200px" mb="8px" variant="black" label="Continue" />
        </Flex>
      </Flex>
    )
  }

  const Innards = (
    <>
      <H1 mb="32px">Choose images to upload</H1>
      <Button mb="8px" variant="black" label="Select images" onClick={() => document.getElementById('file-picker').click()} />

      <input
        type="file"
        id="file-picker"
        name="file-picker"
        accept="image/jpg, image/png, image/jpeg"
        hidden
        multiple="multiple"
        onChange={(e) => {
          const filePicker = document.getElementById('file-picker')
          console.log(filePicker.files)
          if (filePicker.files[0]) {
            setFiles(Array.from(filePicker.files))
            // setStep(STEPS.IN_PROGRESS)
          }
        }}
      />
    </>
  )

  if (isModal) {
    return (
      <Flex position="relative" flexDirection="column" alignItems="center" width="100%" p="76px">
        <Flex
          width="auto"
          justifyContent="center"
          position="absolute"
          top="0px"
          left="24px"
          zIndex="1"
          cursor="pointer"
          onClick={closeModal}
        >
          <Icon mt="4px" mr="16px" icon="chevron-left-lightlight" height="16px" width="16px" />
          <Text lightLight>Back</Text>
        </Flex>
        {Innards}
      </Flex>
    )
  }

  return (
    <Flex padding="60px">
      <Container alignItems="center">{Innards}</Container>
    </Flex>
  )
}

export default ImageUpload
