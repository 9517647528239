import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize } from '../../ui/helpers.js'
import { H1, H2, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../../ui/Typography.js'
import { Flex, Box, Container } from '../../ui/Layout.js'
import Image from '../../ui/Image.js'
import { Input, TextArea } from '../../ui/Input.js'
import Icon from '../../ui/Icon.js'
import CodeCopy from '../../ui/CodeCopy.js'
import VideoPreview from '../../ui/VideoPreview.js'
import CarouselPreview from '../../Components/CarouselPreview.js'

import WidgetPreview from '../../Components/WidgetPreview.js'
import Button from '../../ui/Button.js'
import { BigDarkSpinner } from '../../ui/Spinner.js'
import Toggle from '../../ui/Toggle.js'
import Select from '../../ui/Select.js'
import ColorPicker from '../../ui/ColorPicker.js'
import formikFormWrapper from '../../formikFormWrapper.js'
import { toast } from 'react-toastify'
import { useModal } from '../../Modal.js'
import ModalContainer from '../../ui/ModalContainer.js'
import CreateModal from './ShareModal.js'
import ChatButton from '../../Components/ChatButton'
import { isFreePlan } from '../../Plans.js'

const FormInput = formikFormWrapper(Input)

import api from '../../api.js'
import { useAuth } from '../../Auth.js'

import {
  STEPS,
  CAMPAIGN_RULE_TYPES,
  CAMPAIGN_STATUS,
  campaignRuleTypesOptions,
  WIDGET_POSITIONS,
  widgetPositionOptions,
  widgetRuleTypeOptions,
  widgetTypeDisplayName,
  getSelectWidgetOptions
} from '../types'

const ChoiceContainer = styled(Container)`
  ${(props) => props.disabled && `background-color:#f7f7f7;`}
  position: relative;
  border: 1px solid #e1e8ff;
  padding: 16px;
  cursor: pointer;
  width: 360px;
  max-width: 360px;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 16px;

  &&:hover {
    border: 2px solid #7895ff;
    margin-top: -1px;
  }
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.2), 0px 4px 10px -2px rgba(50, 50, 71, 0.2);
`

const HorizontalChoiceContainer = styled(Container)`
  ${(props) => props.disabled && `background-color:#f7f7f7;`}
  position: relative;
  border: 1px solid #e1e8ff;
  padding: 0px;
  cursor: pointer;
  width: 450px;
  max-width: 450px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 32px;
  border: 2px solid transparent;
  &&:hover {
    border: 2px solid #7895ff;
    margin-top: -1px;
  }
  flex-direction: row;
  overflow: hidden;
  &&:hover {
    .gif {
      display: block;
    }
  }
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.2), 0px 4px 10px -2px rgba(50, 50, 71, 0.2);
`

const InnerContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  height: 135px;
  background: linear-gradient(225deg, #f1f1f5 0%, #e4ecf7 100%);
  margin-bottom: 74px;
  position: relative;
`

const GifInnerContainer = styled(Flex)`
  padding: 16px;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 152px;
  min-width: 152px;
  height: 239px;

  background: linear-gradient(225deg, #f1f1f5 0%, #e4ecf7 100%);
  img {
    min-width: 120px;
    height: 207px;
    width: 120px;
    position: absolute;
    left: 0px;
    top: 0px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    object-fit: cover;
  }
  .gif {
    display: none;
  }
`

const BottomRightIcon = styled(Icon)`
  position: absolute;
  bottom: 20px;
  right: 16px;
`

const DisplayType = ({
  step,
  setStep,
  values,
  selectedVideos,
  openFullscreenDesktopPreview,
  openFullscreenMobilePreview,
  videoObjects,
  setFieldValue,
  submit,
  widgets,
  setWidgets
}) => {
  let { user, setUser } = useAuth()
  const navigate = useNavigate()
  return (
    <>
      <Flex height="100vh" flexDirection="column" alignItems="center">
        <Flex position="fixed" left="24px" top="24px" width="auto">
          <Flex onClick={() => setStep(STEPS.ON_SITE_OFF_SITE)} cursor="pointer">
            <Icon mt="4px" mr="16px" icon="chevron-left-lightlight" height="16px" width="16px" />
            <Text lightLight fontSize="14px" mb="30px" textAlign="left">
              Back
            </Text>
          </Flex>
        </Flex>
        <ChatButton topRightFixed />
        <H1 fontSize="28px" mt="72px" mb="72px" textAlign="left">
          First, lets choose a campaign type
        </H1>
        <Flex flexWrap="wrap" maxWidth={'1200px'} mb="50px" justifyContent="center" px="50px">
          {!values.isShareCampaign ? (
            <>
              {user.isContentCreator ? (
                <HorizontalChoiceContainer
                  onClick={() => {
                    setFieldValue('isDownloadCampaign', true)
                    setStep(STEPS.DOWNLOAD_TYPE)
                  }}
                >
                  <GifInnerContainer>
                    <img src="/illustrations/widgetGifs/download-static.png" alt="download" />
                    <img className="gif" src="/illustrations/widgetGifs/download-GIF.gif" alt="download" />
                  </GifInnerContainer>
                  <Flex flexDirection="column" p="16px" px="32px" alignItems="flex-start" mt="60px">
                    <H3 fontSize="20px" mb="8px">
                      Downloadable Content
                    </H3>
                    <Text light mb="16px">
                      All content will have download functionality added
                    </Text>
                  </Flex>
                </HorizontalChoiceContainer>
              ) : null}
              {user.shopifyShopUrl ? (
                <HorizontalChoiceContainer
                  disabled={!user.shopifyShopUrl}
                  onClick={() => {
                    if (user.shopifyShopUrl) {
                      setFieldValue('isShoppable', true)
                      setStep(STEPS.SELECT_VIDEO, { isShoppable: true })
                    } else {
                      navigate(`/integrations`)
                    }
                  }}
                >
                  <GifInnerContainer>
                    <img src="/illustrations/widgetGifs/shopping-static.png" alt="shoppable" />
                    <img className="gif" src="/illustrations/widgetGifs/shopping-GIF.gif" alt="shoppable" />
                  </GifInnerContainer>
                  <Flex flexDirection="column" p="16px" px="32px" alignItems="flex-start" mt="60px">
                    <H3 opacity={!user.shopifyShopUrl ? 0.7 : 1} fontSize="20px" mb="8px">
                      Shoppable Video
                    </H3>
                    <Text opacity={!user.shopifyShopUrl ? 0.7 : 1} light mb="16px">
                      Embeds open to a shoppable player
                    </Text>
                  </Flex>
                </HorizontalChoiceContainer>
              ) : null}

              <HorizontalChoiceContainer
                onClick={() => {
                  setFieldValue('isShoppable', false)
                  setStep(STEPS.SELECT_VIDEO)
                }}
              >
                <GifInnerContainer>
                  <img src="/illustrations/widgetGifs/player-static.png" alt="button" />
                  <img className="gif" src="/illustrations/widgetGifs/player-GIF.gif" alt="button" />
                </GifInnerContainer>
                <Flex flexDirection="column" p="16px" px="32px" alignItems="flex-start" mt="60px">
                  <H3 fontSize="20px" mb="8px">
                    Full Screen
                  </H3>
                  <Text light>Embeds open up to a full screen swipeable experience</Text>
                </Flex>
              </HorizontalChoiceContainer>

              <HorizontalChoiceContainer
                onClick={() => {
                  setFieldValue('isShoppable', false)
                  setFieldValue('isInlineCampaign', true)
                  setStep(STEPS.SELECT_VIDEO)
                }}
              >
                <GifInnerContainer>
                  <img src="/illustrations/widgetGifs/inline-static.png" alt="button" />
                  <img className="gif" src="/illustrations/widgetGifs/inline-GIF.gif" alt="button" />
                </GifInnerContainer>
                <Flex flexDirection="column" p="16px" px="32px" alignItems="flex-start" mt="60px">
                  <H3 fontSize="20px" mb="8px">
                    In-Line
                  </H3>
                  <Text light>Videos play in place in the page</Text>
                </Flex>
              </HorizontalChoiceContainer>
              <HorizontalChoiceContainer
                disabled={isFreePlan(user)}
                onClick={() => {
                  if (isFreePlan(user)) {
                    return
                  }
                  setFieldValue('isShoppable', false)
                  setFieldValue('isNavigationCampaign', true)
                  setStep(STEPS.SELECT_VIDEO)
                }}
              >
                <GifInnerContainer>
                  <img src="/illustrations/widgetGifs/nav-static.png" alt="button" />
                  <img className="gif" src="/illustrations/widgetGifs/nav-GIF.gif" alt="button" />
                </GifInnerContainer>
                <Flex p="16px" alignItems="flex-start" flexDirection="column" mt="60px">
                  <H3 opacity={isFreePlan(user) ? 0.7 : 1} fontSize="20px" mb="8px">
                    Navigation
                  </H3>
                  <Text opacity={isFreePlan(user) ? 0.7 : 1} light>
                    Embeds link through to other pages
                  </Text>
                </Flex>
                {isFreePlan(user) ? (
                  <Link to="/account?tab=BILLING">
                    <Button
                      position="absolute"
                      top="16px"
                      right="16px"
                      background="linear-gradient(225deg, #BB65FF 0%, #4C6FFF 100%)"
                      borderRadius="100px"
                      renderLeftIcon={() => <Icon width={11} height={16} icon="lightening-white" />}
                      variant="black"
                      label="Upgrade"
                    />
                  </Link>
                ) : null}
              </HorizontalChoiceContainer>
            </>
          ) : null}
          {values.isShareCampaign ? (
            <Flex flexWrap="wrap" maxWidth={'1200px'} mb="50px" justifyContent="center" px="50px">
              {user.shopifyShopUrl ? (
                <HorizontalChoiceContainer
                  disabled={!user.shopifyShopUrl}
                  onClick={() => {
                    if (user.shopifyShopUrl) {
                      setFieldValue('isShoppable', true)
                      setStep(STEPS.SELECT_VIDEO, { isShoppable: true })
                    } else {
                      navigate(`/integrations`)
                    }
                  }}
                >
                  <GifInnerContainer>
                    <img src="/illustrations/widgetGifs/shopping-static.png" alt="shoppable" />
                    <img className="gif" src="/illustrations/widgetGifs/shopping-GIF.gif" alt="shoppable" />
                  </GifInnerContainer>
                  <Flex flexDirection="column" p="16px" px="32px" alignItems="flex-start" mt="60px">
                    <H3 opacity={!user.shopifyShopUrl ? 0.7 : 1} fontSize="20px" mb="8px">
                      Shoppable Video
                    </H3>
                    <Text opacity={!user.shopifyShopUrl ? 0.7 : 1} light mb="16px">
                      Embeds open to a shoppable player
                    </Text>
                  </Flex>
                </HorizontalChoiceContainer>
              ) : null}
              {user.isContentCreator ? (
                <HorizontalChoiceContainer
                  onClick={() => {
                    setFieldValue('isDownloadCampaign', true)
                    setStep(STEPS.SELECT_VIDEO)
                  }}
                >
                  <GifInnerContainer>
                    <img src="/illustrations/widgetGifs/download-static.png" alt="download" />
                    <img className="gif" src="/illustrations/widgetGifs/download-GIF.gif" alt="download" />
                  </GifInnerContainer>
                  <Flex flexDirection="column" p="16px" px="32px" alignItems="flex-start" mt="60px">
                    <H3 fontSize="20px" mb="8px">
                      Downloadable Content
                    </H3>
                    <Text light mb="16px">
                      All content will have download functionality added
                    </Text>
                  </Flex>
                </HorizontalChoiceContainer>
              ) : null}

              <HorizontalChoiceContainer
                onClick={() => {
                  setFieldValue('isShoppable', false)
                  setStep(STEPS.SELECT_VIDEO)
                }}
              >
                <GifInnerContainer>
                  <img src="/illustrations/widgetGifs/player-static.png" alt="button" />
                  <img className="gif" src="/illustrations/widgetGifs/player-GIF.gif" alt="button" />
                </GifInnerContainer>
                <Flex flexDirection="column" p="16px" px="32px" alignItems="flex-start" mt="60px">
                  <H3 fontSize="20px" mb="8px">
                    Full Screen
                  </H3>
                  <Text light>Embeds open up to a full screen swipeable experience</Text>
                </Flex>
              </HorizontalChoiceContainer>
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    </>
  )
}

export default DisplayType
