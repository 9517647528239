import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../../ui/Layout'
import { baseUrl } from '../helpers'

const Popover = styled(Flex)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  width: ${(props) => props.width || '500px'};
  left:0;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  ${(props) => (props.widgetSearchBorderRadius === 'ROUND' ? 'border-radius: 20px;' : '')}}
  ${(props) => (props.widgetSearchBorderRadius === 'SOFT' ? 'border-radius: 8px;' : '')}}
  ${(props) => (props.widgetSearchBorderRadius === 'SHARP' ? 'border-radius: 0px;' : '')}}
`

const Option = styled.p`
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  color: #425466 !important;
`

const Launcher = styled(Flex)`
  width: 130px;
  padding-left: 12px !important;
  padding-right: 12px !important;
  border: 1px solid #d3d3d3;
  height: 40px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  ${(props) => (props.widgetSearchBorderRadius === 'ROUND' ? 'border-radius: 20px;' : '')}}
  ${(props) => (props.widgetSearchBorderRadius === 'SOFT' ? 'border-radius: 6px;' : '')}}
  ${(props) => (props.widgetSearchBorderRadius === 'SHARP' ? 'border-radius: 0px;' : '')}}
  p {
    margin-right: 12px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    color: #425466 !important;
  }
`

const displayNameMap = {
  VIDEOS: 'Videos',
  IMAGES: 'Photos',
  BOTH: 'All media'
}

const SearchableContentDropdown = ({
  campaign,
  searchableContent,
  setSearchableContent,
  setShowMainPopover,
  setSearchParams,
  searchParams,
  contentDropdownRef
}) => {
  const [showPopover, setShowPopover] = useState(false)
  const popover = useRef(null)

  const closeMenu = (event) => {
    if (popover && popover.current && !popover.current.contains(event.target)) {
      document.removeEventListener('click', closeMenu)
      setShowPopover(false)
    }
  }

  const showPopoverFunc = (event) => {
    setShowMainPopover(false)
    event.preventDefault()
    event.stopPropagation()
    setShowPopover(true)
    document.addEventListener('click', closeMenu)
  }

  if (campaign.widgetSearchSearchableContent !== 'BOTH') {
    return null
  }

  const options = [
    {
      label: 'All media',
      onClick: () => {
        setSearchableContent('BOTH')
        setSearchParams({
          ...searchParams
        })
      }
    },
    {
      label: 'Videos',
      onClick: () => {
        setSearchableContent('VIDEOS')
        setSearchParams({
          ...searchParams
        })
      }
    },
    {
      label: 'Photos',
      onClick: () => {
        setSearchableContent('IMAGES')
        setSearchParams({
          ...searchParams
        })
      }
    }
  ]

  return (
    <Box position="relative" ref={contentDropdownRef}>
      <Box cursor="pointer" onClick={showPopover ? closeMenu : showPopoverFunc}>
        <Launcher widgetSearchBorderRadius={campaign.widgetSearchBorderRadius}>
          <p>{displayNameMap[searchableContent]}</p>
          <img alt="chevron-down" src={`${baseUrl}/__clipara-chevron-down-grey.svg`} width={16} height={16} />
        </Launcher>
      </Box>
      {showPopover ? (
        <Popover widgetSearchBorderRadius={campaign.widgetSearchBorderRadius} width={120} ref={popover}>
          <Flex>
            <Flex padding="8px" flexDirection="column">
              {options.map((option) => {
                return (
                  <Option
                    key={option.label}
                    onClick={() => {
                      option.onClick()
                      setShowPopover(false)
                    }}
                  >
                    {option.label}
                  </Option>
                )
              })}
            </Flex>
          </Flex>
        </Popover>
      ) : null}
    </Box>
  )
}

export default SearchableContentDropdown
