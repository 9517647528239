import Chart from 'chart.js/auto'
import 'chartjs-adapter-moment'
import { backgroundColor } from 'styled-system'
import { colors } from '../ui/helpers'
Chart.defaults.plugins.legend.position = 'bottom'

export const options = {
  interaction: {
    intersect: false,
    mode: 'index'
  },
  plugins: {
    legend: {
      display: false
    }
  },
  maintainAspectRatio: false,
  responsive: true,
  elements: {
    point: {
      borderWidth: 0,
      radius: 2,
      backgroundColor: 'rgba(0,0,0,0)'
    }
  },
  scales: {
    x: {
      border: {
        width: 0
      },
      type: 'time',
      time: {
        unit: 'day'
      },
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        maxTicksLimit: 8
      }
    },
    y: {
      grid: {
        drawBorder: false
      },
      ticks: {
        maxTicksLimit: 8
      },
      beginAtZero: true
    }
  }
}

export const black = {
  default: 'rgba(0, 0, 0, 1)',
  half: 'rgba(0, 0, 0, 0.9)',
  quarter: 'rgba(0, 0, 0, 0.6)',
  zero: 'rgba(0, 0, 0, 0.3)'
}
export const pink = {
  default: 'rgba(255, 146, 174, 1)',
  half: 'rgba(255, 146, 174, 0.9)',
  quarter: 'rgba(255, 146, 174, 0.6)',
  zero: 'rgba(255, 146, 174, 0.3)'
}

let playsChart, playingTimeChart, loadChart, salesChart, clicksChart, conversionChart, engagementRateChart, deviceTypeChart

export const setupCharts = ({ data, deviceData, campaignData, videoData }) => {
  playsChart && playsChart.destroy()
  playingTimeChart && playingTimeChart.destroy()
  loadChart && loadChart.destroy()
  salesChart && salesChart.destroy()
  clicksChart && clicksChart.destroy()
  conversionChart && conversionChart.destroy()
  engagementRateChart && engagementRateChart.destroy()
  deviceTypeChart && deviceTypeChart.destroy()

  const playCtx = document.getElementById('plays-graph')
  var ctx = playCtx.getContext('2d')
  let gradient = ctx.createLinearGradient(0, 25, 0, 300)
  gradient.addColorStop(0, black.half)
  gradient.addColorStop(0.25, black.quarter)
  gradient.addColorStop(1, black.zero)

  var ctx2 = playCtx.getContext('2d')
  let gradient2 = ctx2.createLinearGradient(0, 25, 0, 300)
  gradient2.addColorStop(0, pink.half)
  gradient2.addColorStop(0.25, pink.quarter)
  gradient2.addColorStop(1, pink.zero)
  playsChart = new Chart(playCtx, {
    type: 'line',
    data: {
      labels: data.map((d) => d.day),
      datasets: [
        {
          fill: true,
          backgroundColor: gradient,
          pointStyle: 'rect',
          label: 'Video Plays',
          data: data.map((d) => d.PLAY),
          // backgroundColor: ['#111111'],
          borderColor: ['#111111'],
          tension: 0.5
        }
      ]
    },
    options
  })

  const playingTimeCtx = document.getElementById('playing-time-graph')
  playingTimeChart = new Chart(playingTimeCtx, {
    type: 'line',
    data: {
      labels: data.map((d) => d.day),
      datasets: [
        {
          fill: true,
          backgroundColor: gradient,
          pointStyle: 'rect',
          label: 'Viewing Time',
          data: data.map((d) => d.VIEWING_TIME),
          borderColor: ['#111111'],
          tension: 0.5
        }
      ]
    },
    options
  })

  const loadsCtx = document.getElementById('load-graph')

  loadChart = new Chart(loadsCtx, {
    type: 'line',
    data: {
      labels: data.map((d) => d.day),
      datasets: [
        {
          fill: true,
          backgroundColor: gradient,
          pointStyle: 'rect',
          label: 'Impressions',
          data: data.map((d) => d.DISTINCT_LOADS),
          borderColor: ['#111111'],
          tension: 0.5
        }
      ]
    },
    options
  })

  const salesCtx = document.getElementById('sales-graph')
  if (salesCtx) {
    salesChart = new Chart(salesCtx, {
      type: 'line',
      data: {
        labels: data.map((d) => d.day),
        datasets: [
          {
            fill: true,
            backgroundColor: gradient,
            pointStyle: 'rect',
            data: data.map((d) => d.FULL_PURCHASES),
            borderColor: ['#111111'],
            tension: 0.5
          },
          {
            fill: true,
            backgroundColor: gradient2,
            pointStyle: 'rect',
            data: data.map((d) => d.ASSISTED_PURCHASES),
            borderColor: ['#FF92AE'],
            tension: 0.5
          }
        ]
      },
      options
    })
  }

  const clicksCtx = document.getElementById('clicks-graph')
  if (clicksCtx) {
    clicksChart = new Chart(clicksCtx, {
      type: 'line',
      data: {
        labels: data.map((d) => d.day),
        datasets: [
          {
            fill: true,
            backgroundColor: gradient,
            pointStyle: 'rect',
            data: data.map((d) => d.CLICK),
            borderColor: ['#111111'],
            tension: 0.5
          }
        ]
      },
      options
    })
  }

  const conversionCtx = document.getElementById('conversion-graph')
  if (conversionCtx) {
    conversionChart = new Chart(conversionCtx, {
      type: 'line',
      data: {
        labels: data.map((d) => d.day),
        datasets: [
          {
            fill: true,
            backgroundColor: gradient,
            pointStyle: 'rect',
            label: 'Purchase',
            data: data.map((d) => d.CONVERSION),
            borderColor: ['#111111'],
            tension: 0.5
          },
          {
            fill: true,
            backgroundColor: gradient2,
            pointStyle: 'rect',
            data: data.map((d) => d.ADD_TO_CART_CONVERSION),
            label: 'Add to basket',
            borderColor: ['#FF92AE'],
            tension: 0.5
          }
        ]
      },
      options: {
        ...options,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return `${context.raw}%`
              }
            }
          }
        }
      }
    })
  }

  const engagementRateCtx = document.getElementById('engagement-rate-graph')
  engagementRateChart = new Chart(engagementRateCtx, {
    type: 'line',
    data: {
      labels: data.map((d) => d.day),
      datasets: [
        {
          fill: true,
          backgroundColor: gradient,
          pointStyle: 'rect',
          label: 'Engagement Rate',
          data: data.map((d) => d.ENGAGEMENT_RATE),
          borderColor: ['#111111'],
          tension: 0.5
        }
      ]
    },
    options: {
      ...options,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return `${context.raw}%`
            }
          }
        }
      }
    }
  })

  const deviceTypeCtx = document.getElementById('device-type-donut')
  deviceTypeChart = new Chart(deviceTypeCtx, {
    type: 'doughnut',
    data: {
      labels: ['Mobile', 'Tablet', 'Desktop'],
      datasets: [
        {
          data: [deviceData.MOBILE, deviceData.TABLET, deviceData.DESKTOP],
          backgroundColor: ['#111111', '#A6B7D4', '#FF92AE']
        }
      ],
      tension: 0.5
    },

    options: {
      cutout: '60%',
      plugins: {
        legend: {
          display: false,
          position: 'top'
        }
      },
      maintainAspectRatio: false,
      responsive: true,
      elements: {
        point: {
          borderWidth: 0,
          radius: 2,
          backgroundColor: 'rgba(0,0,0,0)'
        }
      }
    }
  })

  //   const averageViewLengthCtx = document.getElementById('average-view-length-graph')
  //   const averageViewLengthChart = new Chart(averageViewLengthCtx, {
  //     type: 'line',
  //     data: {
  //       labels: data.map((d) => d.day),
  //       datasets: [
  //         {
  //           pointStyle: 'rect',
  //           label: 'Average View Length (S)',
  //           data: data.map((d) => d.AVERAGE_VIEW_LENGTH),
  //           backgroundColor: ['#111111'],
  //           borderColor: ['#111111'],
  //           tension: 0.5
  //         }
  //       ]
  //     },
  //     options
  //   })
}
