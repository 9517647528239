import React from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { space } from 'styled-system'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box } from '../ui/Layout.js'
import { useAuth } from '../Auth.js'
import Icon from '../ui/Icon.js'
import api from '../api.js'
import { useModal } from '../Modal.js'

const Container = styled(Flex)`
  background: linear-gradient(225deg, #bb65ff 0%, #4c6fff 100%);
  border-radius: 10px;
  padding: 16px;
  align-items: center;
  cursor: pointer;
`

const InstallBanner = ({ isSchlong }) => {
  let { user, setUser } = useAuth()
  let navigate = useNavigate()

  const setUserConfirmation = async (e) => {
    e.stopPropagation()
    const res = await api.post('/auth/user/update-user-confirmed-install')
    console.log(res)
    if (res.data.success) {
      setUser({ ...user, userConfirmedInstall: true })
    }
  }

  if (user.userConfirmedInstall || !user.shopifyShopUrl) {
    return null
  }

  if (isSchlong) {
    return (
      <Container onClick={() => navigate('/shopify-install')} justifyContent="space-between" mb="55px">
        <Flex width="auto" alignItems="center">
          <Flex mr="16px" width="48px" height="48px" bg="black" borderRadius="48px" alignItems="center" justifyContent="center">
            <Icon icon="tools-white" width={16} height={16} />
          </Flex>
          <Text color="white">Click here to complete your first step - installing Clipara on your site.</Text>
        </Flex>
        <Flex width="auto" alignItems="flex-end" height="100%">
          <Text mb={0} onClick={setUserConfirmation} color="white" underline cursor="pointer">
            I’ve completed this step
          </Text>
        </Flex>
      </Container>
    )
  }
  return (
    <Container onClick={() => navigate('/shopify-install')} flexDirection="row" justifyContent="space-between" mb="55px">
      <Flex width="auto" height="100%">
        <Flex mr="16px" width="48px" height="48px" bg="black" borderRadius="48px" alignItems="center" justifyContent="center">
          <Icon icon="tools-white" width={16} height={16} />
        </Flex>
      </Flex>
      <Flex flexDirection="column" width="auto" height="100%">
        <Text color="white" mb="48px">
          Click here to install Clipara on your site. This is required for all on page videos.
        </Text>
        <Text mb={0} onClick={setUserConfirmation} color="white" underline cursor="pointer">
          I’ve completed this step
        </Text>
      </Flex>
    </Container>
  )
}

export default InstallBanner
