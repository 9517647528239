import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import hexToRgba from 'hex-to-rgba'

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field, setNestedObjectValues } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize } from '../ui/helpers.js'
import { H1, H2, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input, TextArea } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import CodeCopy from '../ui/CodeCopy.js'
import VideoPreview from '../ui/VideoPreview.js'
import WidgetPreview from '../Components/WidgetPreview.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import Toggle from '../ui/Toggle.js'
import Select from '../ui/Select.js'
import ColorPicker from '../ui/ColorPicker.js'
import FullScreenPreview from '../Components/FullScreenPreview.js'
import MobilePreview from '../Components/MobilePreview.js'
import ModalContainer from '../ui/ModalContainer.js'

import SelectVideoFullPage from './Create/SelectVideoFullPage.js'
import VideoAppearance from './Create/VideoAppearance.js'
import CampaignType from './Create/CampaignType.js'
import EditWidget from './Create/EditWidget.js'
import DisplayType from './Create/DisplayType.js'
import OnSiteOffSite from './Create/OnSiteOffSite.js'
import WidgetType from './Create/WidgetType.js'
import WidgetSelect from './Create/WidgetSelect.js'
import ShareModal from './Create/ShareModal.js'
import ShoppableSelect from './Create/ShoppableSelect.js'
import UltimateInstallModal from './Create/UltimateInstallModal.js'

import formikFormWrapper from '../formikFormWrapper.js'
import { toast } from 'react-toastify'
import { useModal } from '../Modal.js'
import { useVideos } from '../VideoProvider.js'
import { uniqBy } from 'ramda'

const FormInput = formikFormWrapper(Input)

import api from '../api.js'
import { useAuth } from '../Auth.js'

import {
  STEPS,
  CAMPAIGN_RULE_TYPES,
  CAMPAIGN_STATUS,
  campaignRuleTypesOptions,
  WIDGET_POSITIONS,
  widgetPositionOptions,
  WIDGET_RULE_TYPES,
  WIDGET_TYPES,
  RULE_TYPES,
  SINGLE_EMBED_DIMENSIONS,
  MOBILE_DIMENSIONS,
  VIDEO_OBJECT_TYPE,
  WIDGET_TITLE_ALIGNMENT,
  TITLE_POSITION,
  TITLE_ALIGNMENT,
  WIDGET_THUMBNAIL_DIMENSIONS,
  WIDGET_ARROW_POSITION,
  WIDGET_ALIGN,
  WIDGET_THUMBNAIL_SIZE,
  WIDGET_PLAY_BUTTON_POSITION,
  BUTTON_STYLES,
  PLAY_BUTTON_STYLES,
  widgetRuleTypeOptions,
  productSelectOptions,
  ruleTypeOptionsShoppable,
  ruleTypeOptions,
  SEARCH_THUMBNAIL_SIZES,
  SEARCH_SHARPNESS,
  SEARCH_SEARCHABLE_CONTENT
} from './types'
import DownloadType from './Create/DownloadType.js'
import applyTemplate from './templates.js'

export const BackButton = ({ step, setStep }) => (
  <Flex onClick={() => setStep(step)} cursor="pointer" position="absolute" left="62" top="40px" width="auto">
    <Icon mt="-2px" mr="13px" width="24px" height="24px" icon="arrow-left-black" />
    <Text fontSize="14px">Back</Text>
  </Flex>
)

const Stepper = (props) => {
  const { values, step } = props
  console.log(values)
  console.log('STEP', step)
  if (step === STEPS.SELECT_VIDEO) {
    return <SelectVideoFullPage isSubmit {...props} />
  }
  if (step === STEPS.VIDEO_APPEARANCE) {
    return <VideoAppearance isSubmit {...props} />
  }
  if (step === STEPS.EDIT_FLOATING) {
    return <EditWidget editType={WIDGET_TYPES.FLOATING} {...props} />
  }

  if (step === STEPS.EDIT_EMBED) {
    return <EditWidget editType={(values.widget && values.widget.type) || WIDGET_TYPES.CAROUSEL} {...props} />
  }

  if (step === STEPS.EDIT_SEARCH) {
    return <EditWidget editType={(values.widget && values.widget.type) || WIDGET_TYPES.SEARCH} {...props} />
  }

  if (step === STEPS.DISPLAY_TYPE) {
    return <DisplayType {...props} />
  }

  if (step === STEPS.ON_SITE_OFF_SITE) {
    return <OnSiteOffSite {...props} />
  }

  if (step === STEPS.WIDGET_TYPE) {
    return <WidgetType {...props} />
  }

  if (step === STEPS.DOWNLOAD_TYPE) {
    return <DownloadType {...props} />
  }

  if (step === STEPS.WIDGET_SELECT) {
    return <WidgetSelect {...props} />
  }

  if (step === STEPS.SHOPPABLE_SELECT) {
    return <ShoppableSelect {...props} />
  }

  return <CampaignType {...props} />
}

const CampaignCreate = (props) => {
  let { user, setUser } = useAuth()
  const [search, setSearch] = useSearchParams()
  const { videos, images, setVideos, refresh } = useVideos()

  const navigate = useNavigate()
  const { campaignId } = useParams()
  const { setShowInstallEmbedModal } = useModal()
  const [isLoading, setIsLoading] = useState(true)
  const [isCreate, setIsCreate] = useState(!campaignId)
  const [showFullScreenMobilePreview, setShowFullScreenMobilePreview] = useState(false)
  const [showFullScreenDesktopPreview, setShowFullScreenDesktopPreview] = useState(false)
  const [showUltimateInstallModal, setShowUltimateInstallModal] = useState(false)
  const [showCampaignNameModal, setShowCampaignNameModal] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [campaign, setCampaign] = useState()
  const [widgets, setWidgets] = useState()
  const [step, setStepState] = useState(
    isCreate ? search.get('step') || STEPS.ON_SITE_OFF_SITE : search.get('step') || STEPS.CAMPAIGN_TYPE
  )
  const setStep = (newStep, otherParams) => {
    const newSearch = new URLSearchParams()
    newSearch.set('step', newStep)
    if (otherParams) {
      Object.keys(otherParams).forEach((key) => {
        newSearch.set(key, otherParams[key])
      })
    }
    setSearch(newSearch)
  }

  useEffect(() => {
    if (step !== search.get('step')) {
      setStepState(search.get('step'))
    }
  }, [search.get('step')])

  useEffect(() => {
    const fetchEntities = async () => {
      await refresh()
      const campaignRes = await api.get(`/campaign/get`, {
        params: {
          id: campaignId,
          limit: 1
        }
      })
      if (!campaignRes.data.success && campaignId) {
        toast('Campaign does not exist')
        return navigate('/campaigns')
      }
      setCampaign(campaignRes.data.payload[0])
      const widgetsRes = await api.get(`/campaign/widget/get`)
      if (widgetsRes) {
        setWidgets(widgetsRes.data.payload)
      }
    }
    fetchEntities()

    if (!campaignId) {
      document.getElementById('campaign-name') && document.getElementById('campaign-name').focus()
      return
    }
  }, [])

  const widgetRuleType0 = campaign && campaign.widgetUrlRules[0] && campaign.widgetUrlRules[0].ruleType
  const widgetRuleUrl0 = campaign && campaign.widgetUrlRules[0] && campaign.widgetUrlRules[0].urlMatch
  const matchType = campaign && campaign.widgetUrlRules[0] && campaign.widgetUrlRules[0].matchType
  const widgetActive = Boolean(campaign && campaign.widgetUrlRules[0] && campaign.widgetUrlRules[0])
  let buttonLinks = {}
  let buttonTexts = {}
  let titles = {}
  let subtitles = {}
  if (campaign && campaign.videoObjects) {
    campaign.videoObjects.forEach((vo) => {
      if (vo.type === VIDEO_OBJECT_TYPE.BUTTON) {
        buttonLinks[vo.videoId] = vo.buttonLink
        buttonTexts[vo.videoId] = vo.buttonText
      } else if (vo.type === VIDEO_OBJECT_TYPE.TITLE) {
        titles[vo.videoId] = vo.title
        subtitles[vo.videoId] = vo.subtitle
      }
    })
  }

  const handleSubmit =
    (newRoute, noNavigation = false) =>
    async (values, formik) => {
      formik.setSubmitting(true)
      if (isCreate) {
        if (!values.name) {
          values.name = `Campaign - ${dayjs().format('YYYY-MM-DD')}`
        }
        const res = await api.post('/campaign/create', values)
        console.log(res, '<<<<<')
        if (res.data.success) {
          formik.setFieldValue('campaignId', res.data.payload.id)
          toast('Campaign created!')
          console.log(values.templatePassThrough, '<<<<<<<<<<<<<<<<<<<<<<<')
          if (values.templatePassThrough) {
            return navigate(
              `/campaigns/edit/${res.data.payload.id}?template=${values.templatePassThrough}&step=${STEPS.EDIT_EMBED}${
                values.isShareCampaign ? '&showShareModal=true' : ''
              }`
            )
          }
          return navigate(
            `/campaigns/edit/${res.data.payload.id}?step=${newRoute || STEPS.DISPLAY_TYPE}${
              values.isShareCampaign ? '&showShareModal=true' : ''
            }`
          )
        } else {
          toast('Error creating campaign')
          return
        }
      }
      const res = await api.post('/campaign/update', values)
      if (res.data.success) {
        setCampaign({ ...campaign, name: values.name })
        if (newRoute) {
          setStep(newRoute)
          return
        }
        if (noNavigation) {
          return
        }
        setStep(STEPS.CAMPAIGN_TYPE)
      } else {
        toast('Error creating campaign')
      }
      formik.setSubmitting(false)
    }
  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center" height="100vh" bg="white">
      {campaign || isCreate ? (
        <Flex flexDirection="column">
          <Formik
            initialValues={
              !isCreate
                ? {
                    widgetRuleType0,
                    widgetRuleUrl0,
                    widgetActive,
                    matchType,
                    videoId: campaign?.video?.id,
                    videoIds: campaign.videos.map((video) => video.id),
                    buttonLinks,
                    buttonTexts,
                    titles,
                    subtitles,
                    fullscreenBackgroundColor: '#333333',
                    openButtonLinksNewTab: false,
                    searchPromotedTags: [],
                    searchFilterTags: [],
                    searchUseFilterTags: false,
                    buttonFullWidth: true,
                    mobileVerticalScroll: true,
                    randomContentOrder: false,
                    widget: {
                      mobileFontSize: 32,
                      fontWeight: 700,
                      fontSize: 32,
                      playButtonColor: '#333333',
                      borderRadius: 4,
                      desktopVideos: 6,
                      mobileVideos: 2,
                      titleColor: '#000000',
                      playButtonAnimation: true,
                      defaultOpenFullscreen: false,
                      border: true,
                      width: 500,
                      height: 300,
                      responsive: true,
                      borderColor: '#000000',
                      dimensions: SINGLE_EMBED_DIMENSIONS.LANDSCAPE,
                      mobileDimensions: MOBILE_DIMENSIONS.FOLLOW,
                      durationDisplay: false,
                      widgetTitleActive: false,
                      widgetDesktopTitleFontSize: 18,
                      widgetDesktopSubtitleFontSize: 18,
                      widgetMobileTitleFontSize: 14,
                      widgetMobileSubtitleFontSize: 14,
                      widgetTitleFontWeight: 700,
                      widgetSubtitleFontWeight: 500,
                      widgetTitleFontColor: '#000000',
                      widgetTitleAlignment: WIDGET_TITLE_ALIGNMENT.LEFT,
                      widgetTitlePosition: TITLE_POSITION.BELOW,
                      widgetAutoplay: true,
                      widgetSubtitleFontColor: '#000000',
                      widgetThumbnailDimensions: WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT,
                      widgetGapWidth: 16,
                      widgetArrowPosition: WIDGET_ARROW_POSITION.OVERLAP,
                      widgetMobileThumbnailDimensions: WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT,
                      widgetMobileThumbnailSize: WIDGET_THUMBNAIL_SIZE.LARGE,
                      widgetThumbnailBorder: false,
                      widgetThumbnailBorderSize: 2,
                      widgetThumbnailBorderColor: '#000000',
                      position: WIDGET_ALIGN.CENTER,
                      widgetPlayButtonPosition: WIDGET_PLAY_BUTTON_POSITION.CENTER,
                      widgetButtonStyle: BUTTON_STYLES.CIRCLE_CHEVRON,
                      widgetChevronPrimaryColor: '#000000',
                      widgetChevronSecondaryColor: '#ffffff',
                      widgetIsPartialStart: false,
                      widgetPlayButtonStyle: PLAY_BUTTON_STYLES.FILLED,
                      widgetFixedThumbnail: false,
                      widgetFixedThumbnailWidth: 200,
                      widgetFixedThumbnailHeight: 300,
                      widgetHideDesktop: false,
                      widgetMobileChevrons: false,
                      widgetMaxThumbnailWidth: 400,
                      widgetSearchThumbnailSize: SEARCH_THUMBNAIL_SIZES.MEDIUM,
                      widgetSearchBorderRadius: SEARCH_SHARPNESS.SOFT,
                      widgetSearchShowSearchBar: true,
                      widgetSearchSearchVideoNames: false,
                      widgetSearchShowPopular: false,
                      widgetSearchPopularText: 'Popular',
                      widgetSearchPopularButtonColor: '#9F1F1F',
                      widgetSearchPopularTextColor: '#FFFFFF',
                      widgetSearchGridOffset: false,
                      widgetSearchOverlayBackgroundColor: '#FFFFFF',
                      widgetSearchSearchImages: false,
                      widgetSearchSearchableContent: SEARCH_SEARCHABLE_CONTENT.VIDEOS,
                      widgetShowProgressBar: true
                    },
                    ...campaign
                  }
                : {
                    button: false,
                    buttonColor: '#000000',
                    buttonBorderColor: '#000000',
                    buttonFontColor: '#FFFFFF',
                    buttonBorderRadius: 8,
                    buttonOpacity: 100,
                    widgetPosition: WIDGET_POSITIONS.BOTTOM_LEFT,
                    fullscreenBackgroundColor: '#333333',
                    addToCartText: 'Add to cart',
                    titleActive: false,
                    subtitleActive: false,
                    titleFontSize: 16,
                    subtitleFontSize: 14,
                    titleAlignment: TITLE_ALIGNMENT.LEFT,
                    openButtonLinksNewTab: false,
                    searchFilterTags: [],
                    searchUseFilterTags: false,
                    buttonFullWidth: true,
                    mobileVerticalScroll: true,
                    randomContentOrder: false,
                    widget: {
                      mobileFontSize: 32,
                      fontWeight: 700,
                      fontSize: 32,
                      playButtonColor: '#333333',
                      borderRadius: 4,
                      desktopVideos: 6,
                      mobileVideos: 2,
                      titleColor: '#000000',
                      width: 500,
                      height: 300,
                      playButtonAnimation: true,
                      border: true,
                      responsive: true,
                      borderColor: '#000000',
                      dimensions: SINGLE_EMBED_DIMENSIONS.LANDSCAPE,
                      defaultOpenFullscreen: false,
                      mobileDimensions: MOBILE_DIMENSIONS.FOLLOW,
                      durationDisplay: false,
                      widgetTitleActive: false,
                      widgetDesktopTitleFontSize: 18,
                      widgetDesktopSubtitleFontSize: 18,
                      widgetMobileTitleFontSize: 14,
                      widgetMobileSubtitleFontSize: 14,
                      widgetTitleFontWeight: 700,
                      widgetSubtitleFontWeight: 500,
                      widgetTitleFontColor: '#000000',
                      widgetTitleAlignment: WIDGET_TITLE_ALIGNMENT.LEFT,
                      widgetTitlePosition: TITLE_POSITION.BELOW,
                      widgetAutoplay: true,
                      widgetSubtitleFontColor: '#000000',
                      widgetThumbnailDimensions: WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT,
                      widgetGapWidth: 16,
                      widgetArrowPosition: WIDGET_ARROW_POSITION.OVERLAP,
                      widgetMobileThumbnailDimensions: WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT,
                      widgetMobileThumbnailSize: WIDGET_THUMBNAIL_SIZE.LARGE,
                      widgetThumbnailBorder: false,
                      widgetThumbnailBorderSize: 2,
                      widgetThumbnailBorderColor: '#000000',
                      position: WIDGET_ALIGN.CENTER,
                      widgetPlayButtonPosition: WIDGET_PLAY_BUTTON_POSITION.CENTER,
                      widgetButtonStyle: BUTTON_STYLES.CIRCLE_CHEVRON,
                      widgetChevronPrimaryColor: '#000000',
                      widgetChevronSecondaryColor: '#ffffff',
                      widgetIsPartialStart: false,
                      widgetPlayButtonStyle: PLAY_BUTTON_STYLES.FILLED,
                      widgetHideDesktop: false,
                      widgetMobileChevrons: false,
                      widgetMaxThumbnailWidth: 400,
                      widgetSearchThumbnailSize: SEARCH_THUMBNAIL_SIZES.MEDIUM,
                      widgetSearchBorderRadius: SEARCH_SHARPNESS.SOFT,
                      widgetSearchShowSearchBar: true,
                      widgetSearchSearchVideoNames: false,
                      widgetSearchShowPopular: false,
                      widgetSearchPopularText: 'Popular',
                      widgetSearchPopularButtonColor: '#9F1F1F',
                      widgetSearchPopularTextColor: '#FFFFFF',
                      widgetSearchGridOffset: false,
                      widgetSearchOverlayBackgroundColor: '#FFFFFF',
                      widgetSearchSearchableContent: SEARCH_SEARCHABLE_CONTENT.VIDEOS,
                      widgetShowProgressBar: true
                    },
                    searchPromotedTags: [],
                    isShoppable: search.get('isShoppable'),
                    isCustomLauncher: false,
                    videoIds: []
                  }
            }
            validate={(values) => {
              const errors = {}
              if (step === STEPS.VIDEO_APPEARANCE) {
                if (values.button && !values.buttonText) {
                  errors.buttonText = 'Required with active button'
                }
                if (values.button && !values.buttonLink) {
                  errors.buttonLink = 'Required with active button'
                }
              }
              if (step === STEPS.CAMPAIGN_TYPE) {
                if (values.callOut && !values.callOutText) {
                  errors.callOutText = 'Required with active speech bubble'
                }
                console.log(errors)
              }
              return errors
            }}
            onSubmit={handleSubmit()}
          >
            {({
              values,
              dirty,
              validateForm,
              setTouched,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
              setFieldValue,
              setSubmitting
            }) => {
              useEffect(() => {
                const template = search.get('template')
                if (template) {
                  applyTemplate({ template, setFieldValue, setStep, user, submit, values })
                }
              }, [])
              const selectedVideos = values.videoIds
                .map((id) => {
                  if (videos.find((video) => id === video.id)) {
                    return {
                      ...videos.find((video) => id === video.id),
                      type: 'VIDEO'
                    }
                  }
                  if (images.find((image) => id === image.id)) {
                    return {
                      ...images.find((image) => id === image.id),
                      type: 'IMAGE'
                    }
                  }
                })
                .filter((el) => el)
              const activeWidget = widgets && values.widgetId && widgets.find((w) => w.id === values.widgetId)
              const videoObjects =
                (values.buttonLinks && values.buttonTexts) || values.titles
                  ? Object.keys(values.buttonLinks || {})
                      .map((videoId) => {
                        return {
                          id: true,
                          type: 'BUTTON',
                          videoId,
                          buttonLink: values.buttonLinks && values.buttonLinks[videoId],
                          buttonText: values.buttonTexts && values.buttonTexts[videoId]
                        }
                      })
                      .concat(
                        Object.keys(values.titles || values.subtitles || {}).map((videoId) => {
                          return {
                            id: true,
                            type: 'TITLE',
                            videoId,
                            title: values.titles && values.titles[videoId],
                            subtitle: values.subtitles && values.subtitles[videoId]
                          }
                        })
                      )
                  : []

              const submit = async (newRoute, noNavigation) => {
                await validateForm()
                if (Object.keys(errors).length > 0) {
                  setTouched(setNestedObjectValues(errors, true))
                  console.log({ errors })
                  return
                }
                if (values.buttonOpacity !== undefined) {
                  setFieldValue('buttonRgba', hexToRgba(values.buttonColor, values.buttonOpacity / 100))
                  values.buttonRgba = hexToRgba(values.buttonColor, values.buttonOpacity / 100)
                }
                return handleSubmit(newRoute, noNavigation)(values, { setSubmitting, setFieldValue })
              }

              // prefill old campaign
              useEffect(() => {
                if (isCreate && campaign && campaign.id) {
                  setFieldValue('buttonColor', campaign.buttonColor)
                  setFieldValue('buttonBorderColor', campaign.buttonBorderColor)
                  setFieldValue('buttonFontColor', campaign.buttonFontColor)
                  setFieldValue('fullscreenBackgroundColor', campaign.fullscreenBackgroundColor)
                  setFieldValue('buttonOpacity', campaign.buttonOpacity)
                  setFieldValue('fontFamily', campaign.fontFamily)
                  setFieldValue('buttonBorderRadius', campaign.buttonBorderRadius)
                  setFieldValue('addToCartText', campaign.addToCartText)
                  setFieldValue('deliveryMessage', campaign.deliveryMessage)
                  setFieldValue('titleActive', campaign.titleActive)
                  setFieldValue('subtitleActive', campaign.subtitleActive)
                  setFieldValue('subtitleFontSize', campaign.subtitleFontSize)
                  setFieldValue('titleFontSize', campaign.titleFontSize)
                  setFieldValue('titleFontFamily', campaign.titleFontFamily)
                  setFieldValue('titleAlignment', campaign.titleAlignment)
                }
              }, [campaign && campaign.id])

              return (
                <>
                  <Stepper
                    submit={submit}
                    isSubmitting={isSubmitting}
                    values={values}
                    step={step}
                    setStep={setStep}
                    videos={videos}
                    setFieldValue={setFieldValue}
                    isCreate={isCreate}
                    selectedVideos={selectedVideos}
                    openFullscreenDesktopPreview={async () => {
                      await submit(undefined, true)
                      setTimeout(() => window.open(`https://player.getclipara.com/share/${values.id}`), 300)
                    }}
                    openFullscreenMobilePreview={async () => {
                      await submit(undefined, true)
                      setShowFullScreenMobilePreview(true)
                    }}
                    videoObjects={videoObjects}
                    widgets={widgets}
                    setWidgets={setWidgets}
                    activeWidget={activeWidget}
                    validateForm={validateForm}
                    showUltimateInstallModal={showUltimateInstallModal}
                    setShowUltimateInstallModal={setShowUltimateInstallModal}
                    showShareModal={showShareModal}
                    setShowShareModal={setShowShareModal}
                    showCampaignNameModal={showCampaignNameModal}
                    setShowCampaignNameModal={setShowCampaignNameModal}
                  />
                  {showFullScreenDesktopPreview && (
                    <FullScreenPreview
                      campaign={{ ...values, videoObjects }}
                      videos={selectedVideos}
                      close={() => setShowFullScreenDesktopPreview(false)}
                    />
                  )}
                  {showFullScreenMobilePreview && (
                    <MobilePreview
                      campaign={{ ...values, videoObjects }}
                      videos={selectedVideos}
                      close={() => setShowFullScreenMobilePreview(false)}
                    />
                  )}
                  <UltimateInstallModal
                    selectedVideos={selectedVideos}
                    showUltimateInstallModal={showUltimateInstallModal}
                    setShowUltimateInstallModal={setShowUltimateInstallModal}
                    videos={videos}
                    submit={submit}
                    setFieldValue={setFieldValue}
                    values={values}
                    activeWidget={activeWidget}
                  />
                  <ModalContainer
                    interiorClose
                    autoHeight
                    p="24px"
                    width="400px"
                    isOpen={showCampaignNameModal}
                    setIsOpen={setShowCampaignNameModal}
                  >
                    <H3 width="100%">Give your campaign a name</H3>
                    <Field mb="24px" maxLength={65} name="name" label="Campaign Name" dark component={FormInput} />
                    <Flex justifyContent="flex-end">
                      <Button
                        isDisabled={values.name && values.name.length < 5}
                        onClick={() => {
                          setShowCampaignNameModal(false)
                          submit(undefined, true)
                        }}
                        variant="black"
                        label="Next"
                      />
                    </Flex>
                  </ModalContainer>

                  <ShareModal show={showShareModal} setShow={setShowShareModal} campaignId={values.id} />
                </>
              )
            }}
          </Formik>
        </Flex>
      ) : (
        <BigDarkSpinner />
      )}
    </Flex>
  )
}

export default CampaignCreate
