import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../../ui/Layout.js'

const PopularButtonComp = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer !important;
  background-color: ${(props) => props.backgroundColor} !important;
  border: none !important;
  height: 29px !important;
  border-radius: 27px !important;
  font-size: 14px !important;
  text-align: center;
  transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
  background: none;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  z-index: 1;
  p {
    color: ${(props) => props.color} !important;
    text-decoration: none;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 500 !important;
    line-height: 20px;
    font-size: 14px !important;
    padding: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`

export const PopularButton = ({ campaign, isPopular }) => {
  if (!isPopular) {
    return null
  }
  console.log('renders')
  return (
    <PopularButtonComp backgroundColor={campaign.widgetSearchPopularButtonColor} color={campaign.widgetSearchPopularTextColor}>
      <p>{campaign.widgetSearchPopularText}</p>
    </PopularButtonComp>
  )
}
