import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, shorten } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import SideNav, { TABS } from '../Components/SideNav.js'
import VideoPlayer from '../Components/VideoPlayer'
import StatusPill from '../Components/StatusPill'
import formikFormWrapper from '../formikFormWrapper.js'
import { toast } from 'react-toastify'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import CodeCopy from '../ui/CodeCopy.js'
import ModalContainer from '../ui/ModalContainer'
import { openChat } from '../Components/ChatButton'

const UnderlineA = styled.a`
  text-decoration: underline !important;
`

const Install = ({ showInstallWidgetModal, setShowInstallWidgetModal }) => {
  if (!showInstallWidgetModal) {
    return null
  }
  let auth = useAuth()
  const close = () => setShowInstallWidgetModal(false)
  return (
    <ModalContainer
      width="600px"
      autoHeight
      p={'24px 32px 24px 32px'}
      isOpen={showInstallWidgetModal}
      setIsOpen={setShowInstallWidgetModal}
      onClick={close}
    >
      <Flex justifyContent="flex-end" position="absolute" top="24px" right="32px">
        <Text cursor="pointer" onClick={close}>
          I’ll do this later
        </Text>
      </Flex>
      <H1 textAlign="center" mb="24px" width="100%">
        Install Clipara
      </H1>

      <Flex>
        {/* {isShopify ? (
          <Flex flexDirection="column" pr="24px" borderRight={`1px solid ${colors.softBlue}`}>
            <Text fontWeight="700" mb="8px">
              Activate Theme Extension
            </Text>
            <Text mb="16px">Available if you have installed the app via Shopify app store and use a Shopify 2.0 theme.</Text>
            <img width="334px" src="/welcome/themeExtensionInstall.jpg" />
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://${auth.user.shopifyShopUrl}/admin/themes/current/editor?context=apps&activateAppId=7b58bb0c-6a86-4ef5-83de-23ad165cc561/app-embed`}
            >
              <Button mt="32px" width="89px" mb="8px" variant="grey" label="Activate" />
            </a>
          </Flex>
        ) : null} */}
        <Flex flexDirection="column" ml="24px" alignItems={'center'}>
          <Text fontWeight="700" mb="8px">
            Manually Install Code Snippet
          </Text>
          <Text mb="16px">Copy and paste the code snippet into your website builder. Use the guides linked below.</Text>
          <CodeCopy
            width="500px"
            id="install-modal-code-snippet"
            code={`<script>
        window.__clipara = {}
        window.__clipara.organisationId = ${auth.user.organisationId}
        </script>
        <script defer src="https://widget.getclipara.com/widget.js" type="text/javascript"></script>`}
          />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://clipara.notion.site/How-to-install-Clipara-1b86a50e544a418d867a2d447851693e"
          >
            <Text underline maxWidth="600px" mb="16px" light>
              Use these guides for inserting code snippets to site builders and platforms like Squarespace.
            </Text>
          </a>
        </Flex>
      </Flex>

      <Flex justifyContent="flex-end" mt="40px" zIndex={1}>
        <Button variant="black" label="Done" onClick={close} />
      </Flex>
      <Flex position="absolute" justifyContent="center" bottom="24px" left="0px">
        <Text cursor="pointer" underline onClick={openChat}>
          Need help? Contact Support.
        </Text>
      </Flex>
    </ModalContainer>
  )
}

export default Install
