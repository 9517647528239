import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { colors, w } from '../ui/helpers.js'
import { H1, H2, Text, Span, GradientFont, Error } from '../ui/Typography.js'
import { Flex } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Button from '../ui/Button.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import { toast } from 'react-toastify'
import { PrivacyTermsComponent } from './Login.js'

const SignInText = styled(Text)`
  position: absolute;
  bottom: 24px;
  right: 76px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`

const FormInput = formikFormWrapper(Input)

const ResetPassword = (props) => {
  let auth = useAuth()
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  let navigate = useNavigate()

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validate={(values) => {
        const errors = {}
        if (!values.email) {
          errors.email = 'Required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = 'Invalid email address'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setError('')
        setSubmitting(true)
        const res = await api.post('/auth/send-password-reset', values)
        if (res.data.success) {
          setSuccess(true)
          toast('Email sent, check your inbox!')
          return
        } else {
          setError(res.data.message)
        }
        setSubmitting(false)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
        /* and other goodies */
      }) => (
        <Flex>
          <Flex
            display={w < 981 ? 'none' : 'flex'}
            flexDirection="column"
            p="76px"
            width="586px"
            minWidth="586px"
            height="100vh"
            bg={colors.softBlue}
          >
            <Image mb="160px" src="/logo.svg" width={186} />
            <GradientFont mb="25px">Happens to the best of us.</GradientFont>
            <Text light>You’ll be back where you belong soon.</Text>
          </Flex>
          <Flex position="relative" flexDirection="column" alignItems="center" width="100%" p="76px">
            <Flex flexDirection="column" maxWidth="535px">
              <Image src="/lockEmoji.png" height="49px" width="49px" mb="30px" />
              <H1 mb="8px">Password Reset </H1>
              <H2 mb="30px">Enter your email and we will send you a reset link</H2>

              <Field
                name="email"
                large
                label="Email"
                mb="55px"
                placeholder="Type your email address"
                type="email"
                component={FormInput}
              />
              <Button large mb="8px" variant="black" label="Send me the link" onClick={handleSubmit} />
              {error && <Error>{error}</Error>}
              {success && <Text>Email sent, check your inbox!</Text>}
            </Flex>
            <SignInText light>
              Don't have an account?{' '}
              <Link to="/signup">
                <Span link>Get started</Span>
              </Link>
            </SignInText>
            <PrivacyTermsComponent />
          </Flex>
        </Flex>
      )}
    </Formik>
  )
}

export default ResetPassword
