import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../../ui/Layout.js'
import axios from 'axios'
import { baseUrl, formatSeconds } from '../helpers.js'
import { Button } from './SearchComponents.js'
import SearchableContentDropdown from './SearchableContentDropdown.js'

const Input = styled.input`
  border: none;
  outline: none;
  -webkit-appearance: none;
  box-shadow: none !important;
  &&:-webkit-autofill {
    color: #fff !important;
  }
  &&:focus {
    outline: none;
    border: mnone;
  }
  font-size: 16px;
  font-weight: 400 !important;
  margin-left: 16px;
  width: 100% !important;
  cursor: pointer !important;
  color: #27272e !important;
  ::placeholder {
    color: #7a7a9d;
    opacity: 1; /* Firefox */
  }
`

const Container = styled(Flex)`
  position: relative;
  max-width: 700px;
  margin-bottom: 16px;
  height: 50px;
  align-items: center;
  padding-left: 4px;
  padding-right: 16px;
  box-sizing: border-box;
  cursor: pointer !important;
  border: 1px solid #D3D3D3;
  ${(props) => (props.widgetSearchBorderRadius === 'ROUND' ? 'border-radius: 25px;' : '')}}
  ${(props) => (props.widgetSearchBorderRadius === 'SOFT' ? 'border-radius: 8px;' : '')}}
  ${(props) => (props.widgetSearchBorderRadius === 'SHARP' ? 'border-radius: 0px;' : '')}}
  background-color: white !important;

`

const Popover = styled(Flex)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 58px;
  left: 0px;
  width: 700px;
  z-index: 100;
  padding: 16px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 16px;
  overflow-y: scroll;
  border: 1px solid #d3d3d3;
  ${(props) => (props.widgetSearchBorderRadius === 'ROUND' ? 'border-radius: 16px;' : '')}}
  ${(props) => (props.widgetSearchBorderRadius === 'SOFT' ? 'border-radius: 8px;' : '')}}
  ${(props) => (props.widgetSearchBorderRadius === 'SHARP' ? 'border-radius: 0px;' : '')}}


  @media(max-width: 700px) {
    width: calc(100% + 2px);
    left: -1px;
  }
  ${(props) =>
    props.isMobile
      ? `width: calc(100% + 2px);
    left: -1px;`
      : ''}
`
// specific above

const Title = styled.p`
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  color: #27272e !important;
`

const getTags = (searchValue, tags) => {
  if (!searchValue) {
    return tags
  }
  return tags.filter((tag) => {
    return tag?.toLowerCase().includes(searchValue?.toLowerCase())
  })
}

const FullWidthForm = styled.form`
  width: calc(100% - 24px);
`

export const SearchInput = ({
  campaign,
  tags,
  setSearchParams,
  searchParams,
  updateLocalStorage,
  popularTags,
  searchValue,
  setSearchValue,
  isMobile,
  searchableContent,
  setSearchableContent
}) => {
  const [showPopover, setShowPopover] = useState(false)
  const popover = useRef(null)
  const inputRef = useRef(null)
  const contentDropdownRef = useRef(null)
  const sliceFactor = isMobile ? 5 : 8
  const tagsToShow = getTags(searchValue, tags)
  const searchedTags = JSON.parse(localStorage.getItem('searched_tags'))

  const closeMenu = (event, overide) => {
    if ((popover && popover.current && !popover.current.contains(event.target)) || overide) {
      document.removeEventListener('click', closeMenu)
      setShowPopover(false)
      inputRef.current.blur()
    } else {
      if (showPopover) {
        inputRef.current.focus()
      }
    }
  }

  const showPopoverFunc = (event) => {
    event.preventDefault()
    event.stopPropagation()
    inputRef.current.focus()
    if (contentDropdownRef && contentDropdownRef.current && contentDropdownRef.current.contains(event.target)) {
      return
    }
    setShowPopover(true)
    document.addEventListener('click', closeMenu)
  }

  const formSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (campaign.widgetSearchSearchVideoNames) {
      setSearchParams({
        ...searchParams,
        tags: [],
        page: 1,
        query: searchValue || ''
      })
      closeMenu(e, true)
    } else {
      if (searchValue && tagsToShow && tagsToShow[0]) {
        tagOnClick(e, tagsToShow[0])
      }
    }
  }

  const tagOnClick = (e, tag) => {
    e.stopPropagation()
    e.preventDefault()
    const isSelected = searchParams.tags.includes(tag)
    setSearchParams({
      ...searchParams,
      page: 1,
      query: '',
      tags: isSelected ? [] : [tag]
    })
    if (isSelected) {
      setSearchValue('')
    } else {
      setSearchValue(tag)
      updateLocalStorage(tag)
      closeMenu(e, true)
    }
  }

  return (
    <Container onClick={showPopover ? closeMenu : showPopoverFunc} widgetSearchBorderRadius={campaign.widgetSearchBorderRadius}>
      <Flex alignItems="center">
        <SearchableContentDropdown
          contentDropdownRef={contentDropdownRef}
          campaign={campaign}
          searchableContent={searchableContent}
          setSearchableContent={setSearchableContent}
          setShowMainPopover={setShowPopover}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        />
        <FullWidthForm onSubmit={formSubmit}>
          <Input placeholder="Search" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} ref={inputRef} />
        </FullWidthForm>
        {searchParams?.query || searchValue ? (
          <Flex
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setSearchValue('')
              setSearchParams({
                ...searchParams,
                tags: [],
                page: 1,
                query: ''
              })
              closeMenu(e, true)
            }}
            minWidth="24px"
            width="24px"
            height="24px"
            justifyContent="center"
            alignItems="center"
            mr="8px"
          >
            <img width="9px" height="9px" src={`${baseUrl}/__clipara-cross-black.svg`} alt="search clear" />
          </Flex>
        ) : null}
      </Flex>
      <img onClick={formSubmit} width={20} height={20} src={`${baseUrl}/__clipara-search-grey.svg`} alt="search icon" />

      {showPopover ? (
        <Popover ref={popover} widgetSearchBorderRadius={campaign.widgetSearchBorderRadius} isMobile={isMobile}>
          <Flex flexWrap="wrap">
            {tagsToShow.slice(0, sliceFactor).map((tag) => {
              const isSelected = searchParams.tags.includes(tag)
              if (tag === 'High Quality Download') {
                return null
              }
              return (
                <Button
                  label={tag}
                  onClick={(e) => tagOnClick(e, tag)}
                  widgetSearchBorderRadius={campaign.widgetSearchBorderRadius}
                  backgroundColor={isSelected ? campaign.buttonFontColor : campaign.buttonColor}
                  color={isSelected ? campaign.buttonColor : campaign.buttonFontColor}
                  border={`2px solid ${isSelected ? campaign.buttonColor : campaign.buttonFontColor}`}
                  key={tag}
                />
              )
            })}
          </Flex>
          {searchedTags?.length ? (
            <>
              <Title>Recent Searches</Title>
              <Flex flexWrap="wrap">
                {searchedTags.slice(0, sliceFactor).map((tag) => {
                  const isSelected = searchParams.tags.includes(tag)
                  if (tag === 'High Quality Download') {
                    return null
                  }
                  return (
                    <Button
                      label={tag}
                      onClick={(e) => tagOnClick(e, tag)}
                      widgetSearchBorderRadius={campaign.widgetSearchBorderRadius}
                      backgroundColor={isSelected ? campaign.buttonFontColor : campaign.buttonColor}
                      color={isSelected ? campaign.buttonColor : campaign.buttonFontColor}
                      border={`2px solid ${isSelected ? campaign.buttonColor : campaign.buttonFontColor}`}
                      key={tag}
                    />
                  )
                })}
              </Flex>
            </>
          ) : null}
          {popularTags?.length ? (
            <>
              <Title>Trending</Title>
              <Flex flexWrap="wrap">
                {popularTags.slice(0, sliceFactor).map((tag) => {
                  const isSelected = searchParams.tags.includes(tag)
                  if (tag === 'High Quality Download') {
                    return null
                  }
                  return (
                    <Button
                      label={tag}
                      onClick={(e) => tagOnClick(e, tag)}
                      widgetSearchBorderRadius={campaign.widgetSearchBorderRadius}
                      backgroundColor={isSelected ? campaign.buttonFontColor : campaign.buttonColor}
                      color={isSelected ? campaign.buttonColor : campaign.buttonFontColor}
                      border={`2px solid ${isSelected ? campaign.buttonColor : campaign.buttonFontColor}`}
                      key={tag}
                    />
                  )
                })}
              </Flex>
            </>
          ) : null}
          {campaign.searchPromotedTags?.length ? (
            <>
              <Title>Popular</Title>
              <Flex flexWrap="wrap">
                {campaign.searchPromotedTags.slice(0, sliceFactor).map((tag) => {
                  const isSelected = searchParams.tags.includes(tag)
                  return (
                    <Button
                      label={tag}
                      onClick={(e) => tagOnClick(e, tag)}
                      widgetSearchBorderRadius={campaign.widgetSearchBorderRadius}
                      backgroundColor={isSelected ? campaign.buttonFontColor : campaign.buttonColor}
                      color={isSelected ? campaign.buttonColor : campaign.buttonFontColor}
                      border={`2px solid ${isSelected ? campaign.buttonColor : campaign.buttonFontColor}`}
                      key={tag}
                    />
                  )
                })}
              </Flex>
            </>
          ) : null}
        </Popover>
      ) : null}
    </Container>
  )
}
