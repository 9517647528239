import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { colors, numberWithCommas } from '../ui/helpers.js'
import { H1, H2, H3, Text, Span, GradientFont, Error } from '../ui/Typography.js'
import { Container, Flex, Box } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import PopoverDropdown from '../ui/PopoverDropdown.js'
import Icon from '../ui/Icon.js'
import { BigDarkSpinner } from '../ui/Spinner'
import Button from '../ui/Button.js'
import Select from '../ui/Select.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import { PLAYER_TYPES, playerTypeOptions } from '../Campaigns/types.js'
import { setupCharts } from './helpers'
import Chart from 'chart.js/auto'
import 'chartjs-adapter-moment'
import ChatButton from '../Components/ChatButton'
import { stripHtml } from 'string-strip-html'
import { DATE_PERIODS } from './types'
import DatePopover from './DatePopover'
import Campaigns from './Campaigns'
import Videos from './Videos'
import Images from './Images'

const SignInText = styled(Text)`
  position: absolute;
  bottom: 24px;
  right: 76px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`

const StatTile = ({ icon, title, value, iconBg }) => {
  return (
    <Container p={0} pl="24px" width="100%" height="100px" justifyContent="center" mr="30px" mb="16px">
      <Flex flexDirection="row">
        <Flex mr="16px" borderRadius="8px" width="46px" height="46px" bg={iconBg} alignItems="center" justifyContent="center">
          <Icon icon={icon} width={18} height={18} />
        </Flex>
        <Flex flexDirection="column" width="auto">
          <Text fontWeight="700" lightLight width="auto">
            {title}
          </Text>
          <Text fontSize="20px" fontWeight="500" width="auto">
            {value}
          </Text>
        </Flex>
      </Flex>
    </Container>
  )
}

const Grid = styled(Flex)`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
`

const Center = styled(Box)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const ChartRow = ({ title, subtitle, chartId, subComponents }) => {
  return (
    <Container mb="32px" position="relative">
      <Flex>
        <Flex flexDirection="column">
          <H3 fontSize="20px" mb="16px">
            {title}
          </H3>
          <H3 fontSize="28px" mb="24px">
            {subtitle}
          </H3>
          {subComponents}
        </Flex>
        <Box minWidth="70%" width="70%" height={350}>
          <canvas id={chartId} />
        </Box>
      </Flex>
    </Container>
  )
}

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && 'border-bottom: 1px solid black;'}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`
const Tab = ({ onClick, title, isActive }) => {
  return (
    <TabText onClick={onClick} isActive={isActive}>
      {title}
    </TabText>
  )
}

const TABS = {
  OVERVIEW: 'OVERVIEW',
  CAMPAIGN: 'CAMPAIGN',
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE'
}

const Analytics = (props) => {
  let { user } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [deviceData, setDeviceData] = useState([])
  const [response, setResponse] = useState([])
  const [campaigns, setCampaign] = useState([])
  const [videos, setVideo] = useState([])
  const [images, setImage] = useState([])
  const [campaignIds, setCampaignIds] = useState([])
  const [videoIds, setVideoIds] = useState([])
  const [playerTypes, setPlayerTypes] = useState([PLAYER_TYPES.share, PLAYER_TYPES.widget])
  const [startDate, setStartDate] = useState(dayjs().subtract(30, 'day').toDate())
  const [endDate, setEndDate] = useState(dayjs().toDate())
  const [datesChanged, setDatesChanged] = useState(false)
  const [search, setSearch] = useSearchParams()
  const [activeTab, setActiveTabState] = useState(search.get('tab') || TABS.OVERVIEW)
  const [searchParams, setSearchParams] = useSearchParams()
  const isContentCreator = user.isContentCreator
  const setActiveTab = (tab) => {
    setActiveTabState(tab)
    setSearchParams({ tab })
  }

  const navigate = useNavigate()
  const refresh = async (firstLoad, { inputCampaignIds, inputVideoIds, inputPlayerTypes }) => {
    setIsLoading(true)
    const res = await api.post('/analytics/get-v2', {
      playerTypes: inputPlayerTypes,
      videoIds: inputVideoIds,
      campaignIds: inputCampaignIds,
      startDate,
      endDate,
      canUseCache: firstLoad || (campaignIds.length === campaigns.length && videoIds.length === videos.length && !datesChanged)
    })
    setIsLoading(false)

    if (res.data.success) {
      setData(res.data.payload.data)
      setDeviceData(res.data.payload.deviceData)
      setResponse(res.data.payload)
      if (firstLoad) {
        setCampaign(res.data.payload.campaigns)
        setVideo(res.data.payload.videos)
        setImage(res.data.payload.images)
      }
      const data = res.data.payload.data
      const deviceData = res.data.payload.deviceData
      setupCharts({ data, deviceData })
    }
  }

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => setupCharts({ data, deviceData }), 1)
    }
  }, [activeTab])

  useEffect(() => {
    refresh(true, { inputCampaignIds: campaignIds, inputVideoIds: videoIds, inputPlayerTypes: playerTypes })
  }, [])

  useEffect(() => {
    console.log('rEFRESH')
    if (startDate && endDate && campaigns && campaigns.length && videos && videos.length) {
      refresh(false, { inputCampaignIds: campaignIds, inputVideoIds: videoIds, inputPlayerTypes: playerTypes })
    }
  }, [endDate, campaignIds, videoIds])

  const moneyFormat = (input) =>
    response.moneyFormat
      ? stripHtml(
          response.moneyFormat
            .replace('{{amount}}', input)
            .replace('{{amount_no_decimals}}', input)
            .replace('{{amount_with_comma_separator}}', input)
            .replace('{{amount_no_decimals_with_comma_separator}}', input)
            .replace('{{amount_with_apostrophe_separator}}', input)
        ).result
      : input
  return (
    <Flex>
      <Flex position="relative" flexDirection="column" alignItems="center" width="100%" p="30px">
        <Flex>
          <H1 mb="32px" width="100%">
            Analytics
          </H1>
          <Flex width="auto">
            <ChatButton showCallButton noMargin />
          </Flex>
        </Flex>
        <Flex mb="16px" borderBottom="1px solid #EDF2F7;">
          <Tab
            onClick={() => {
              setActiveTab(TABS.OVERVIEW)
              if (campaignIds.length !== campaigns.length) {
                setCampaignIds(campaigns.map((el) => el.id))
              }
              if (videoIds.length !== [...videos, ...images].length) {
                setVideoIds([...videos, ...images].map((el) => el.id))
              }
            }}
            title="Overview"
            isActive={activeTab === TABS.OVERVIEW}
          />
          <Tab
            onClick={() => {
              setActiveTab(TABS.CAMPAIGN)
              if (videoIds.length !== [...videos, ...images].length) {
                setVideoIds([...videos, ...images].map((el) => el.id))
              }
              if (!campaignIds.length) {
                setCampaignIds(campaigns.map((el) => el.id))
              }
            }}
            title="Campaigns"
            isActive={activeTab === TABS.CAMPAIGN}
          />
          <Tab
            onClick={() => {
              setActiveTab(TABS.VIDEO)
            }}
            title="Videos"
            isActive={activeTab === TABS.VIDEO}
          />
          <Tab
            onClick={() => {
              setActiveTab(TABS.IMAGE)
            }}
            title="Images"
            isActive={activeTab === TABS.IMAGE}
          />
        </Flex>
        <Flex mb="16px" justifyContent="space-between">
          <Flex>
            {activeTab === TABS.CAMPAIGN ? (
              <PopoverDropdown
                buttonLabel={
                  campaignIds.length === 1 ? campaigns.find((el) => el.id === campaignIds[0]).name : 'Filter by campaign'
                }
                options={[
                  {
                    label: 'All Campaigns',
                    onClick: () => {
                      setCampaignIds(campaigns.map((el) => el.id))
                    }
                  }
                ].concat(
                  campaigns
                    ? campaigns
                        .map((campaign) => {
                          return {
                            label: campaign.name,
                            onClick: () => {
                              setCampaignIds([campaign.id])
                            }
                          }
                        })
                        .sort((a, b) => (a.label > b.label ? 1 : -1))
                    : []
                )}
              />
            ) : null}
            {activeTab === TABS.VIDEO ? (
              <PopoverDropdown
                buttonLabel={videoIds.length === 1 ? videos.find((el) => el.id === videoIds[0]).name : 'Filter by video'}
                options={[
                  {
                    label: 'All Videos',
                    onClick: () => {
                      setVideoIds(videos.map((el) => el.id))
                    }
                  }
                ].concat(
                  videos
                    ? videos
                        .map((video) => {
                          return {
                            label: video.name,
                            onClick: () => {
                              setVideoIds([video.id])
                            }
                          }
                        })
                        .sort((a, b) => (a.label > b.label ? 1 : -1))
                    : []
                )}
              />
            ) : null}
            {activeTab === TABS.IMAGE ? (
              <PopoverDropdown
                buttonLabel={videoIds.length === 1 ? images.find((el) => el.id === videoIds[0]).name : 'Filter by image'}
                options={[
                  {
                    label: 'All Images',
                    onClick: () => {
                      setVideoIds(images.map((el) => el.id))
                    }
                  }
                ].concat(
                  images
                    ? images
                        .map((video) => {
                          return {
                            label: video.name,
                            onClick: () => {
                              setVideoIds([video.id])
                            }
                          }
                        })
                        .sort((a, b) => (a.label > b.label ? 1 : -1))
                    : []
                )}
              />
            ) : null}
          </Flex>
          <Flex width="300px" justifyContent="flex-end">
            <DatePopover
              startDate={startDate}
              setStartDate={(date) => {
                setDatesChanged(true)
                setStartDate(date)
              }}
              endDate={endDate}
              setEndDate={(date) => {
                setDatesChanged(true)
                setEndDate(date)
              }}
              refresh={() => refresh(false, {})}
            />
          </Flex>
        </Flex>
        {isLoading ? (
          <Flex width="100%" height="70vh" justifyContent="center" alignItems="center" flexDirection="column">
            <GradientFont mb="25px">Gathering your analytics</GradientFont>
            <BigDarkSpinner width="50px" height="50px" />
          </Flex>
        ) : null}
        {!isLoading && response && !response.totalPlays ? (
          <Flex width="100%" height="70vh" justifyContent="center" alignItems="center" flexDirection="column">
            <GradientFont mb="25px">No activity yet!</GradientFont>
          </Flex>
        ) : null}
        {activeTab === TABS.OVERVIEW && response && response.totalPlays && !isLoading ? (
          <>
            <Grid mb="16px">
              <StatTile iconBg="#FFEEF1" icon="file-play-red" title="Total Plays" value={numberWithCommas(response.totalPlays)} />
              <StatTile
                iconBg="#E1E8FF"
                icon="clock-black"
                title="Total Playing Time"
                value={`${numberWithCommas(Math.round(response.totalViewingTime * 10) / 10)} minutes`}
              />
              {user.shopifyShopUrl ? (
                <StatTile
                  iconBg="#FFEDE3"
                  icon="bar-graph-orange"
                  title="Sales"
                  value={`${moneyFormat(numberWithCommas(Math.round(response.totalPurchases)))}`}
                />
              ) : (
                <StatTile
                  iconBg="#FFEDE3"
                  icon="bar-graph-orange"
                  title={isContentCreator ? 'Total Downloads' : 'Total Clicks'}
                  value={numberWithCommas(response.totalClicks)}
                />
              )}
              <StatTile
                iconBg="#DEFFEE"
                icon="bar-graph-green"
                title="Engagement Rate"
                value={`${response.totalEngagementRate}%`}
              />
              {/* <StatTile iconBg="#DEFFEE" icon="plus-circle-black" title="Clicks Through" value={`${response.totalClicks}`} /> */}
            </Grid>
            <ChartRow
              title="Video Plays"
              subtitle={numberWithCommas(response.totalPlays)}
              chartId="plays-graph"
              subComponents={<Text light>Plays per user: {response.totalPlaysPerUser}</Text>}
            />
            <ChartRow
              title="Campaign Impressions"
              subtitle={numberWithCommas(response.totalImpressions)}
              chartId="load-graph"
              // subComponents={<Text light>Plays per user: {response.totalPlaysPerUser}</Text>}
            />
            <ChartRow
              title="Viewing Time"
              subtitle={`${numberWithCommas(Math.round(response.totalViewingTime))} minutes`}
              chartId="playing-time-graph"
              subComponents={<Text light>Time per visitor: {Math.round(response.totalViewingTimePerUser)} seconds</Text>}
            />
            {user.shopifyShopUrl ? (
              <>
                <ChartRow
                  title="Sales"
                  subtitle={`${moneyFormat(numberWithCommas(Math.round(response.totalPurchases)))}`}
                  chartId="sales-graph"
                  subComponents={
                    <>
                      <Text light>Direct: {moneyFormat(numberWithCommas(Math.round(response.totalFullPurchases)))}</Text>
                      <Text light mb="16px">
                        Assisted: {moneyFormat(numberWithCommas(Math.round(response.totalAssistedPurchases)))}
                      </Text>
                      <Text light>Avg per user: {moneyFormat(numberWithCommas(Math.round(response.averageBasketSize)))}</Text>
                    </>
                  }
                />
                <ChartRow
                  title="Conversion"
                  subtitle={`${response.totalConversionRate}%`}
                  chartId="conversion-graph"
                  subComponents={<Text light>Add to basket: {Math.round(response.totalAddToCartConversionRate)}%</Text>}
                />
              </>
            ) : (
              <>
                <ChartRow
                  title={isContentCreator ? 'Downloads' : 'Clicks'}
                  subtitle={numberWithCommas(response.totalClicks)}
                  chartId="clicks-graph"
                />
              </>
            )}
            <ChartRow title="Engagement" subtitle={`${response.totalEngagementRate}%`} chartId="engagement-rate-graph" />
            <Flex>
              <Container width="510px" mb="32px" position="relative" alignItems="center">
                <Flex alignItems="center">
                  <Flex flexDirection="column">
                    <H3 fontSize="20px" mb="32px">
                      Views by Device
                    </H3>
                    <Flex flexDirection="column" mb="24px">
                      <Flex alignItems="center">
                        <Box mr="8px" width="8px" height="8px" borderRadius="8px" bg="#111111" />
                        <Text fontSize="16px">{numberWithCommas(deviceData.MOBILE)}</Text>
                      </Flex>
                      <Text light>Mobile</Text>
                    </Flex>
                    <Flex flexDirection="column" mb="24px">
                      <Flex alignItems="center">
                        <Box mr="8px" width="8px" height="8px" borderRadius="8px" bg="#A6B7D4" />
                        <Text fontSize="16px">{numberWithCommas(deviceData.TABLET)}</Text>
                      </Flex>
                      <Text light>Tablet</Text>
                    </Flex>
                    <Flex flexDirection="column" mb="24px">
                      <Flex alignItems="center">
                        <Box mr="8px" width="8px" height="8px" borderRadius="8px" bg="#FF92AE" />
                        <Text fontSize="16px">{numberWithCommas(deviceData.DESKTOP)}</Text>
                      </Flex>
                      <Text light>Desktop</Text>
                    </Flex>
                  </Flex>
                  <Box minWidth="250px" width="250px" height={200} position="relative">
                    <Center>
                      <Text fontSize="28px" fontWeight="700">
                        {numberWithCommas(Number(deviceData.MOBILE) + Number(deviceData.TABLET) + Number(deviceData.DESKTOP))}
                      </Text>
                    </Center>
                    <canvas id="device-type-donut" />
                  </Box>
                </Flex>
              </Container>
            </Flex>
          </>
        ) : null}
        {activeTab === TABS.CAMPAIGN && response && response.totalPlays && !isLoading ? <Campaigns response={response} /> : null}
        {activeTab === TABS.VIDEO && response && response.totalPlays && !isLoading ? <Videos response={response} /> : null}
        {activeTab === TABS.IMAGE && response && response.totalPlays && !isLoading ? <Images response={response} /> : null}
      </Flex>
    </Flex>
  )
}

export default Analytics
