import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize } from '../../ui/helpers.js'
import { H1, H2, H3, H6, Label, Text, Span, GradientFont, Error, SmallTitle } from '../../ui/Typography.js'
import { Flex, Box, Container } from '../../ui/Layout.js'
import Image from '../../ui/Image.js'
import { Input, SideInput } from '../../ui/Input.js'
import Icon from '../../ui/Icon.js'
import CodeCopy from '../../ui/CodeCopy.js'
import VideoPreview from '../../ui/VideoPreview.js'
import WidgetPreview from '../../Components/WidgetPreview.js'
import CollapsibleSection from '../../Components/CollapsibleSection.js'
import Button from '../../ui/Button.js'
import { BigDarkSpinner } from '../../ui/Spinner.js'
import Toggle from '../../ui/Toggle.js'
import Select, { SideSelect } from '../../ui/Select.js'
import ColorPicker, { ColorPickerRow } from '../../ui/ColorPicker.js'
import formikFormWrapper from '../../formikFormWrapper.js'
import { Tabs } from '../CampaignEdit.js'
import ButtonStyle from './ButtonStyle.js'
import PlayButtonStyle from './PlayButtonStyle.js'
import PartialStart from './PartialStart.js'
import { toast } from 'react-toastify'
import { useModal } from '../../Modal.js'
import ToggleRedGreen from '../../ui/ToggleRedGreen.js'

const FormInput = formikFormWrapper(Input)
const FormSideInput = formikFormWrapper(SideInput)

import api from '../../api.js'
import { useAuth } from '../../Auth.js'

import {
  STEPS,
  CAMPAIGN_RULE_TYPES,
  CAMPAIGN_STATUS,
  campaignRuleTypesOptions,
  WIDGET_POSITIONS,
  widgetPositionOptions,
  widgetRuleTypeOptions,
  WIDGET_TYPES,
  widgetAlignOptions,
  landscapePortraitOptions,
  widgetTextAlignOptions,
  embedWidgetSelectOptions,
  singleEmbedDimensionsOptions,
  SINGLE_EMBED_HEIGHT_TO_WIDTH,
  SINGLE_EMBED_WIDTH_TO_HEIGHT,
  MOBILE_DIMENSIONS,
  mobileDimensionsOptions,
  widgetTitleAlignmentOptions,
  titlePositionOptions,
  widgetThumbnailDimensionsOptions,
  widgetArrowPositionOptions,
  widgetThumbnailSizeOptions,
  widgetPlayButtonPositionOptions,
  WIDGET_THUMBNAIL_DIMENSIONS,
  TITLE_POSITION,
  widgetThumbnailDimensionsStoriesOptions,
  getSelectWidgetOptions,
  getSelectNavigationWidgetOptions,
  getSelectInlineWidgetOptions,
  navigationTitlePositionOptions,
  WIDGET_ALIGN,
  TITLE_ALIGNMENT
} from '../types.js'
import PopoverDropdown from '../../ui/PopoverDropdown.js'

export const ChangeWidget = ({ setFieldValue, values, widget, openCollapsible, setOpenCollapsible, widgets, setStep }) => {
  let widgetModalOptions = getSelectWidgetOptions(widgets)

  if (values.isNavigationCampaign) {
    widgetModalOptions = getSelectNavigationWidgetOptions(widgets)
  } else if (values.isInlineCampaign) {
    widgetModalOptions = getSelectInlineWidgetOptions(widgets)
  }
  const [search, setSearch] = useSearchParams()
  const changeWidget = async (widgetId) => {
    const getWidgetsRes = await api.get(`/campaign/widget/get`, { params: { id: widgetId } })

    if (getWidgetsRes) {
      setFieldValue('widget', getWidgetsRes.data.payload[0])
    }
  }

  return (
    <CollapsibleSection
      label="Widget"
      openCollapsible={openCollapsible}
      setOpenCollapsible={setOpenCollapsible}
      icon="collection-play-white"
    >
      <Select
        mb="16px"
        fullWidth
        value={values.widgetId}
        onChange={async (option) => {
          if (option.value === 'CREATE_NEW') {
            return setStep(STEPS.DISPLAY_TYPE)
          }
          setFieldValue(`widgetId`, option.value)
          setSearch(`step=EDIT_EMBED&widgetId=${option.value}`)
          await changeWidget(option.value)
        }}
        options={[{ value: 'CREATE_NEW', label: 'Create new widget' }].concat(widgetModalOptions)}
        placeholder="Select"
      />
    </CollapsibleSection>
  )
}

export const WidgetTitles = ({ setFieldValue, values, isMobile, setIsMobile }) => {
  const isStories = values.widget.type === WIDGET_TYPES.STORIES
  return (
    <>
      <Flex
        justifyContent="space-between"
        borderTop="1px solid rgba(237, 242, 247, 1)"
        borderBottom="1px solid rgba(237, 242, 247, 1)"
        pt="24px"
        pb="16px"
        px="24px"
      >
        <Label mt="2px" mr="16px">
          Show Titles
        </Label>
        <ToggleRedGreen
          setSelected={(selected) => {
            setFieldValue('widget.widgetTitleActive', selected)
          }}
          value1={false}
          value2={true}
          selected={values.widget.widgetTitleActive}
        />
      </Flex>
      {values.widget.widgetTitleActive ? (
        <>
          <TitleAlignment
            label="Title Alignment"
            setFieldValue={setFieldValue}
            value={values.widget && values.widget.widgetTitleAlignment}
            name="widget.widgetTitleAlignment"
          />
          {!isStories && !values.isInlineCampaign ? (
            <SideSelect
              label="Position"
              value={values.widget.widgetTitlePosition}
              onChange={(option) => {
                setFieldValue(`widget.widgetTitlePosition`, option.value)
              }}
              options={values.isNavigationCampaign ? navigationTitlePositionOptions : titlePositionOptions}
              placeholder="Select"
            />
          ) : null}
          <Flex py="16px" justifyContent="center">
            <Button
              renderCenterIcon={() => <Icon width={12} height={12} icon="desktop-black" />}
              variant={isMobile ? 'grey' : 'white'}
              shadow
              onClick={() => {
                setIsMobile(false)
              }}
              width="52px"
              minWidth="52px"
              mr="8px"
            />
            <Button
              renderCenterIcon={() => <Icon width={12} height={12} icon="phone-black" />}
              variant={!isMobile ? 'grey' : 'white'}
              shadow
              onClick={() => {
                setIsMobile(true)
              }}
              width="52px"
              minWidth="52px"
              mr="16px"
            />
          </Flex>
          {!isMobile ? (
            <>
              <Field
                textPostFix="px"
                name="widget.widgetDesktopTitleFontSize"
                type="number"
                min="0"
                max="200"
                label="Title Size"
                dark
                component={FormSideInput}
              />
              {!isStories ? (
                <Field
                  textPostFix="px"
                  name="widget.widgetDesktopSubtitleFontSize"
                  type="number"
                  min="0"
                  max="200"
                  label="Subtitle Size"
                  dark
                  component={FormSideInput}
                />
              ) : null}
            </>
          ) : (
            <>
              <Field
                textPostFix="px"
                name="widget.widgetMobileTitleFontSize"
                type="number"
                min="0"
                max="200"
                label="Title Size"
                dark
                component={FormSideInput}
              />
              {!isStories ? (
                <Field
                  textPostFix="px"
                  name="widget.widgetMobileSubtitleFontSize"
                  type="number"
                  min="0"
                  max="200"
                  label="Subtitle Size"
                  dark
                  component={FormSideInput}
                />
              ) : null}
            </>
          )}
          <Flex
            flexDirection="column"
            borderTop="1px solid rgba(237, 242, 247, 1)"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
            pt="16px"
            mb="16px"
          >
            <Field
              textPostFix="px"
              name="widget.widgetTitleFontWeight"
              type="number"
              min="0"
              max="200"
              label="Title Weight"
              dark
              component={FormSideInput}
            />
            {!isStories ? (
              <Field
                textPostFix="px"
                name="widget.widgetSubtitleFontWeight"
                type="number"
                min="0"
                max="200"
                label="Subtitle Weight"
                dark
                component={FormSideInput}
              />
            ) : null}
          </Flex>
          <ColorPickerRow
            label="Title color"
            setFieldValue={setFieldValue}
            name="widget.widgetTitleFontColor"
            value={values.widget.widgetTitleFontColor}
          />
          {!isStories ? (
            <ColorPickerRow
              label="Subtitle color"
              setFieldValue={setFieldValue}
              name="widget.widgetSubtitleFontColor"
              value={values.widget.widgetSubtitleFontColor}
            />
          ) : null}
          <Flex
            pt="16px"
            flexDirection="column"
            borderTop="1px solid rgba(237, 242, 247, 1)"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
          >
            <Field
              tooltip="Font family may not display correctly in preview.<br>No value will attempt to inherit your websites default font."
              name="widget.widgetTitleFontFamily"
              label="Title Font Family"
              dark
              component={FormSideInput}
            />
            {!isStories ? (
              <Field
                tooltip="Font family may not display correctly in preview.<br>No value will attempt to inherit your websites default font."
                name="widget.widgetSubtitleFontFamily"
                label="Subtitle Font Family"
                dark
                component={FormSideInput}
              />
            ) : null}
          </Flex>
        </>
      ) : null}
    </>
  )
}

export const WidgetEmbedTitles = ({ setFieldValue, values }) => {
  return (
    <Flex flexDirection="column">
      <Flex borderTop="1px solid rgba(237, 242, 247, 1)" borderBottom="1px solid rgba(237, 242, 247, 1)" p="24px" py="16px">
        <Field name="widget.text" label="Title" dark component={FormInput} />
      </Flex>
      <TitleAlignment
        label="Title Alignment"
        setFieldValue={setFieldValue}
        value={values.widget && values.widget.textAlign}
        name="widget.textAlign"
      />
      <Flex pt="16px" flexDirection="column">
        <Field
          textPostFix="px"
          name="widget.fontSize"
          type="number"
          min="8"
          max="100"
          label="Desktop Size"
          dark
          component={FormSideInput}
        />
        <Field
          textPostFix="px"
          name="widget.mobileFontSize"
          type="number"
          min="8"
          max="100"
          label="Mobile Size"
          dark
          component={FormSideInput}
        />
        <Field
          tooltip="Light text is 300; medium 500, bold 700+"
          name="widget.fontWeight"
          min="0"
          max="32"
          label="Font Weight"
          placeholder="700"
          dark
          component={FormSideInput}
        />
        <Field
          tooltip="Font family may not display correctly in preview.<br>No value will attempt to inherit your websites default font."
          name="widget.fontFamily"
          label="Font Family"
          dark
          component={FormSideInput}
        />
        <Text px="24px" mb="16px" mt="-16px" textAlign="right" fontSize="12px" lightLight underline>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://clipara.notion.site/Customising-Buttons-CTAs-6d693e3fcd2042b0939c798f681e0d77"
          >
            How to find your font family
          </a>
        </Text>
      </Flex>
      <Flex borderTop="1px solid rgba(237, 242, 247, 1)" pt="16px">
        <ColorPickerRow
          label="Title color"
          setFieldValue={setFieldValue}
          name="widget.titleColor"
          value={values.widget.titleColor}
        />
      </Flex>
    </Flex>
  )
}

export const WidgetPadding = ({ setFieldValue, values }) => {
  return (
    <Flex
      flexDirection="column"
      borderTop="1px solid rgba(237, 242, 247, 1)"
      borderBottom="1px solid rgba(237, 242, 247, 1)"
      pt="16px"
    >
      <Field
        textPostFix="px"
        name="widget.marginY"
        type="number"
        min="0"
        max="200"
        label="Padding Top/Bottom"
        placeholder="0"
        dark
        component={FormSideInput}
      />
      <Field
        textPostFix="px"
        name="widget.marginX"
        type="number"
        min="0"
        max="200"
        label="Padding Left/Right"
        placeholder="0"
        dark
        component={FormSideInput}
      />
    </Flex>
  )
}

export const WidgetThumbnailBorder = ({ setFieldValue, values }) => {
  return (
    <>
      <Flex pt="24px" pb="16px" justifyContent="space-between" px="24px">
        <Label mt="2px" mr="16px">
          Border
        </Label>
        <ToggleRedGreen
          setSelected={(selected) => {
            setFieldValue('widget.widgetThumbnailBorder', selected)
          }}
          value1={false}
          value2={true}
          selected={values.widget.widgetThumbnailBorder}
        />
      </Flex>
      {values.widget.widgetThumbnailBorder ? (
        <Flex flexDirection="column">
          <ColorPickerRow
            label="Color"
            setFieldValue={setFieldValue}
            name="widget.widgetThumbnailBorderColor"
            value={values.widget.widgetThumbnailBorderColor}
          />
          <Field
            name="widget.widgetThumbnailBorderSize"
            label="Weight"
            type="number"
            dark
            textPostFix="px"
            component={FormSideInput}
          />
        </Flex>
      ) : null}
    </>
  )
}

export const WidgetAlignment = ({ setFieldValue, values, value, name }) => {
  return (
    <>
      <Flex
        py="16px"
        justifyContent="space-between"
        px="24px"
        alignItems="center"
        borderBottom="1px solid rgba(237, 242, 247, 1)"
      >
        <Label mb="0px" mr="16px">
          Alignment
        </Label>
        <Flex width="auto">
          <Button
            renderCenterIcon={() => <Icon width={20} height={20} icon="alignment-left-black" />}
            variant={values.widget.position === WIDGET_ALIGN.LEFT ? 'white' : 'grey'}
            shadow
            onClick={() => {
              setFieldValue('widget.position', WIDGET_ALIGN.LEFT)
            }}
            width="52px"
            minWidth="52px"
            mr="8px"
          />
          <Button
            renderCenterIcon={() => <Icon width={20} height={20} icon="alignment-center-black" />}
            variant={values.widget.position === WIDGET_ALIGN.CENTER ? 'white' : 'grey'}
            shadow
            onClick={() => {
              setFieldValue('widget.position', WIDGET_ALIGN.CENTER)
            }}
            width="52px"
            minWidth="52px"
            mr="8px"
          />
          <Button
            renderCenterIcon={() => <Icon width={20} height={20} icon="alignment-right-black" />}
            variant={values.widget.position === WIDGET_ALIGN.RIGHT ? 'white' : 'grey'}
            shadow
            onClick={() => {
              setFieldValue('widget.position', WIDGET_ALIGN.RIGHT)
            }}
            width="52px"
            minWidth="52px"
          />
        </Flex>
      </Flex>
    </>
  )
}

export const TitleAlignment = ({ setFieldValue, value, name }) => {
  return (
    <>
      <Flex
        py="16px"
        justifyContent="space-between"
        px="24px"
        alignItems="center"
        borderBottom="1px solid rgba(237, 242, 247, 1)"
      >
        <Label mb="0px" mr="16px">
          Title Alignment
        </Label>
        <Flex width="auto">
          <Button
            renderCenterIcon={() => <Icon width={20} height={20} icon="alignment-left-black" />}
            variant={value === TITLE_ALIGNMENT.LEFT ? 'white' : 'grey'}
            shadow
            onClick={() => {
              setFieldValue(name, TITLE_ALIGNMENT.LEFT)
            }}
            width="52px"
            minWidth="52px"
            mr="8px"
          />
          <Button
            renderCenterIcon={() => <Icon width={20} height={20} icon="alignment-center-black" />}
            variant={value === TITLE_ALIGNMENT.CENTER ? 'white' : 'grey'}
            shadow
            onClick={() => {
              setFieldValue(name, TITLE_ALIGNMENT.CENTER)
            }}
            width="52px"
            minWidth="52px"
            mr="8px"
          />
          <Button
            renderCenterIcon={() => <Icon width={20} height={20} icon="alignment-right-black" />}
            variant={value === TITLE_ALIGNMENT.RIGHT ? 'white' : 'grey'}
            shadow
            onClick={() => {
              setFieldValue(name, TITLE_ALIGNMENT.RIGHT)
            }}
            width="52px"
            minWidth="52px"
          />
        </Flex>
      </Flex>
    </>
  )
}
