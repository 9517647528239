export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const stringToUrl = (x) => {
  return x.replace(/ /g, '-').toLowerCase()
}

const toTitleCase = (phrase) => {
  if (phrase.length === 3) {
    return phrase.toUpperCase()
  }
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const urlToString = (x) => {
  return toTitleCase(x.replace(/-/g, ' '))
}

export function msToTime(duration) {
  var milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

  return (hours > 0 ? hours + 'h ' : '') + minutes + 'm'
}
