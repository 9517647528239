import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../../ui/Layout.js'

const Component = styled(Flex)`
  height: 6px !important;
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  z-index: 1;
  width: 0;
  border-radius: 0px 8px 8px 8px;
  ${(props) => (props.isMobile ? `height: 4px !important;` : '')}
`

const Container = styled(Flex)`
  border-radius: 0px 0px 8px 8px;
  z-index: 1;
  position: absolute;
  bottom: -17px;
  left: 0px;
  height: 40px;
  align-items: center;
  ${(props) =>
    props.isMobile
      ? `top: -18px;
    border-radius: 0px;`
      : ''}
`

const InnerContainer = styled(Flex)`
  border-radius: 0px 0px 8px 8px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  align-items: center;
  ${(props) => (props.isMobile ? `border-radius: 0px;` : '')}
`

const DragEnd = styled(Flex)`
  margin: 10px;
  border-radius: 24px;
  width: 10px !important;
  min-width: 10px !important;
  height: 10px !important;
  background-color: white !important;
  position: absolute;
  right: -12px;
  top: -10px;
  z-index: 2;
`
let animationFrames = []
export const ProgressBar = ({ campaign, videoRef, forceDesktop }) => {
  const [isDragging, setIsDragging] = useState(false)
  const isMobile = !forceDesktop && (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 700
  const containerRef = useRef()
  const progressBarRef = useRef()
  const pauseRef = useRef()

  useEffect(() => {
    videoRef.current.addEventListener('playing', () => {
      pauseRef.current = false
      requestAnimationFrame(updateProgress)
    })
    videoRef.current.addEventListener('pause', () => {
      pauseRef.current = true
    })
    videoRef.current.addEventListener('waiting', () => {
      pauseRef.current = true
    })
  }, [])

  const updateProgress = (e, noAnimation) => {
    if (!videoRef.current) {
      return
    }
    const current = videoRef.current.currentTime
    const total = videoRef.current.duration
    if (progressBarRef.current) {
      progressBarRef.current.style.width = `${(current / total) * 100}% `
    }
    if (pauseRef.current === false && !noAnimation) {
      requestAnimationFrame(updateProgress)
    }
  }

  const seek = (e) => {
    e.stopPropagation()
    const rect = containerRef.current.getBoundingClientRect()
    const offsetX = e.clientX - rect.left
    const width = rect.width
    const percent = offsetX / width
    const duration = videoRef.current.duration
    videoRef.current.currentTime = percent * duration
    updateProgress(null, true)
  }

  const handleMouseDown = (e) => {
    e.stopPropagation()
    setIsDragging(true)
  }

  const handleMouseMove = (e) => {
    e.stopPropagation()
    if (isDragging) {
      const rect = containerRef.current.getBoundingClientRect()
      let offsetX = e.clientX - rect.left

      if (!e.clientX) {
        offsetX = event.touches[0].clientX - rect.left
      }
      const width = rect.width
      const percent = offsetX / width
      const duration = videoRef.current.duration
      videoRef.current.currentTime = percent * duration
      updateProgress(null, true)
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  return (
    <Container
      ref={containerRef}
      isMobile={isMobile}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseUp}
      onTouchMove={handleMouseMove}
      onTouchLeave={handleMouseUp}
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
      // onTouchStart={handleMouseDown}
      // onTouchEnd={handleMouseUp}
      onClick={!isMobile ? seek : () => null}
    >
      <InnerContainer isMobile={isMobile}>
        <Component ref={progressBarRef} isDragging={isDragging} isMobile={isMobile}>
          {/* {w > 700 ? (
            <DragEnd
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onTouchStart={handleMouseDown}
              onTouchEnd={handleMouseUp}
            />
          ) : null} */}
        </Component>
      </InnerContainer>
    </Container>
  )
}
