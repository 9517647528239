import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout'
import Spinner from '../ui/Spinner'
import ProcessingPreview from './ProcessingPreview'
import Hls from 'hls.js'
import mux from 'mux-embed'
import hexToRgba from 'hex-to-rgba'

export const loadScript = (url) => {
  return new Promise(function (resolve, reject) {
    let script = document.createElement('script')
    script.src = url
    script.async = false
    script.onload = function () {
      resolve(url)
    }
    script.onerror = function () {
      reject(url)
    }
    document.body.appendChild(script)
  })
}

export const playPause = (playerId) => {
  if (
    !document.getElementById(playerId || '__clipara-player').paused &&
    !document.getElementById(playerId || '__clipara-player').ended
  ) {
    document.getElementById(playerId || '__clipara-player').pause()
  } else if (document.getElementById(playerId || '__clipara-player').paused) {
    console.log('playing')
    document.getElementById(playerId || '__clipara-player').play()
  }
}

export const loadSpecificVideo = async ({ campaignId, video, setHasEnded, setHasPlayed, playerId, autoplay, inputPlayer }) => {
  const player = inputPlayer || document.getElementById(playerId)

  player.addEventListener('ended', () => {
    console.log('ended')
    setHasEnded(true)
  })
  player.addEventListener('play', () => {
    console.log('play')

    setHasEnded(false)
  })
  var src = `https://stream.mux.com/${video.playbackId}.m3u8#t=0.1`

  if (player.canPlayType('application/vnd.apple.mpegurl')) {
    // Some browsers (safari and ie edge) support HLS natively
    player.src = src
  } else if (Hls.isSupported()) {
    var hls = new Hls()
    hls.loadSource(src)
    hls.attachMedia(player)
  } else {
    console.error("This is a legacy browser that doesn't support MSE")
  }
  if (autoplay) {
    console.log('AUTOPLAY')
    player
      .play()
      .then(() => setHasPlayed(true))
      .catch(() => setHasPlayed(false))
  }
}

const End = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100001;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
`

const Poster = styled.img`
  ${(props) => (props.isLight ? 'opacity: 0.4;' : 'filter: brightness(40%);')}
  object-fit: cover;
  border-radius: 8px;
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
`

const Player = styled.video`
  width: 100%;
  border-radius: 8px;
  ${(props) => `${props.hasEnded && !props.isLight ? 'filter: brightness(40%);' : ''}`}
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
`

const Button = styled.button`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border: ${(props) => props.border};
  font-family: 'Inter';
  height: 42px;
  border-radius: ${(props) => (typeof props.borderRadius === 'number' ? props.borderRadius : 8)}px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
  z-index: 1;
  position: absolute;
  bottom: 16px;
  left: 0px;
  z-index: 110001;
  overflow: hidden;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  outline: inherit;
  width: ${(props) => `calc(${props.videoWidth}px - 32px)`} !important;
  ${(props) =>
    !props.buttonFullWidth
      ? `width: fit-content !important; padding-left: 16px !important; padding-right: 16px !important;`
      : null}
  p {
    text-decoration: none;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 600;
    line-height: 20px;
    padding: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`

const PlayButton = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  cursor: pointer;
`

const FullscreenPlayer = ({
  playerId,
  campaign,
  video,
  isActive,
  isLight,
  videoObject,
  autoplay,
  fillPortrait,
  useFixedRatio,
  videoWidth
}) => {
  const [hasEnded, setHasEnded] = useState(false)
  const [hasPlayed, setHasPlayed] = useState(false)
  const player = useRef()

  useEffect(() => {
    if (isActive) {
      loadSpecificVideo({
        campaignId: campaign.id,
        video,
        playerId,
        setHasEnded,
        setHasPlayed,
        autoplay,
        inputPlayer: player.current
      })
      player.current.addEventListener('play', () => {
        setHasPlayed(true)
      })
    }
  }, [isActive])
  const isLandscape = video.aspectRatioCalc > 1
  const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

  const posterHeight = Math.round(h * 0.8)
  const posterWidth = Math.round(h * 0.8 * video.aspectRatioCalc)
  const poster = video.customThumbnail
    ? video.customThumbnail + `-/scale_crop/${posterWidth}x${posterHeight}/center/`
    : `https://image.mux.com/${video.playbackId}/thumbnail.png?time=${video.thumbnailTime}&width=${posterWidth}&height=${posterHeight}`
  let buttonUrl = ''
  if (videoObject) {
    buttonUrl =
      process.env.NODE_ENV !== 'production'
        ? `http://localhost:8080/campaign/click?url=${encodeURIComponent(videoObject.buttonLink)}&campaignId=${
            campaign.id
          }&type=WIDGET`
        : `https://api.getclipara.com/campaign/click?url=${encodeURIComponent(videoObject.buttonLink)}&campaignId=${
            campaign.id
          }&type=WIDGET`
  }
  let width = 'auto'
  let height = 'auto'
  if (isLandscape) {
    width = `100%`
  } else {
    height = `100%`
  }
  if (!isActive) {
    return (
      <Poster
        src={poster}
        width={width}
        height={height}
        isLight={isLight}
        useFixedRatio={useFixedRatio}
        isLandscape={isLandscape}
      />
    )
  }
  return (
    <>
      <Player
        ref={player}
        fillPortrait={fillPortrait && !isLandscape}
        width={width}
        height={height}
        onClick={() => playPause(playerId)}
        id={playerId}
        preload="metadata"
        playsinline
        poster={poster}
        isLight={isLight}
        hasEnded={hasEnded}
        useFixedRatio={useFixedRatio}
        isLandscape={isLandscape}
      />
      {!hasPlayed && (
        <PlayButton
          onClick={() => {
            playPause(playerId)
            setHasPlayed(true)
          }}
          src="/__clipara-play-button.svg"
        />
      )}
      {/* {videoObject && videoObject.buttonText && videoObject.buttonLink ? (
        <a target="_blank" rel="noreferrer" href={buttonUrl}>
          <Button
            videoWidth={videoWidth}
            borderRadius={campaign.buttonBorderRadius}
            backgroundColor={hexToRgba(campaign.buttonColor, campaign.buttonOpacity / 100) || campaign.buttonRgba}
            color={campaign.buttonFontColor}
            border={`2px solid ${campaign.buttonBorderColor}`}
          >
            <p>{videoObject.buttonText}</p>
          </Button>
        </a>
      ) : null} */}
      {hasEnded ? (
        <End
          onClick={() => playPause(playerId)}
          alt="restart icon"
          src={isLight ? '/__clipara-restart-black.svg' : '/__clipara-restart-white.svg'}
        />
      ) : null}
    </>
  )
}

export default FullscreenPlayer
