import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize } from '../../ui/helpers.js'
import { H1, H2, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../../ui/Typography.js'
import { Flex, Box, Container } from '../../ui/Layout.js'
import Image from '../../ui/Image.js'
import { Input, TextArea } from '../../ui/Input.js'
import Icon from '../../ui/Icon.js'
import CodeCopy, { copyOnClick } from '../../ui/CodeCopy.js'
import VideoPreview from '../../ui/VideoPreview.js'
import WidgetPreview from '../../Components/WidgetPreview.js'
import Button from '../../ui/Button.js'
import { BigDarkSpinner } from '../../ui/Spinner.js'
import Toggle from '../../ui/Toggle.js'
import Select from '../../ui/Select.js'
import ColorPicker from '../../ui/ColorPicker.js'
import formikFormWrapper from '../../formikFormWrapper.js'
import { toast } from 'react-toastify'
import { useModal } from '../../Modal.js'
import ModalContainer from '../../ui/ModalContainer.js'

const FormInput = formikFormWrapper(Input)

import api from '../../api.js'
import { useAuth } from '../../Auth.js'

import {
  STEPS,
  CAMPAIGN_RULE_TYPES,
  CAMPAIGN_STATUS,
  campaignRuleTypesOptions,
  WIDGET_POSITIONS,
  widgetPositionOptions
} from '../types.js'

const ImageContainer = styled(Flex)`
  background-image: url('${(props) => props.src}');
  background-size: cover;
  background-position: center;
  border-radius: 16px;
`

const BigButton = styled(Flex)`
  box-shadow: 0px 1px 14px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
  margin-bottom: 16px;
  padding: 24px;
  border-radius: 10px;
  height: 82px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &&:hover {
    box-shadow: 0px 1px 14px rgba(50, 50, 71, 0.2), 0px 0px 1px rgba(50, 50, 71, 0.2);
  }
`

const ShareModal = ({ campaignId, show, setShow }) => {
  let auth = useAuth()

  return (
    <ModalContainer interiorClose p="24px" width="700px" height="300px" isOpen={show} setIsOpen={setShow}>
      <Flex flexDirection="column" height="100%">
        <Flex>
          <H3 mr="16px">Share your campaign</H3>
          <Icon mt="4px" icon="share-gradient" width="16px" height="16px" />
        </Flex>

        <Flex flexDirection="column" justifyContent="space-between" alignItems="flex-end" height="100%">
          <Flex flexDirection="column">
            <Text mb="24px" light>
              Share the link anywhere on the internet and in your email and SMS campaigns.
            </Text>
            <CodeCopy
              width="100%"
              small
              id="share-code-snippet"
              code={`https://${auth.user.customShareDomain || 'player'}.getclipara.com/share/${campaignId}`}
              toastMessage="Link copied!"
            />
          </Flex>
          <Box>
            <Button
              variant="black"
              label="Copy share link"
              onClick={() => {
                copyOnClick({
                  id: 'share-code-snippet',
                  code: `https://${auth.user.customShareDomain || 'player'}.getclipara.com/share/${campaignId}`,
                  toastMessage: 'Link copied!'
                })
              }}
            />
          </Box>
        </Flex>
      </Flex>
    </ModalContainer>
  )
}

export default ShareModal
