import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize } from '../../ui/helpers.js'
import { H1, H2, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../../ui/Typography.js'
import { Flex, Box, Container } from '../../ui/Layout.js'
import Image from '../../ui/Image.js'
import { Input, TextArea } from '../../ui/Input.js'
import Icon from '../../ui/Icon.js'
import CodeCopy from '../../ui/CodeCopy.js'
import VideoPreview from '../../ui/VideoPreview.js'
import CarouselPreview from '../../Components/CarouselPreview.js'

import WidgetPreview from '../../Components/WidgetPreview.js'
import Button from '../../ui/Button.js'
import { BigDarkSpinner } from '../../ui/Spinner.js'
import Toggle from '../../ui/Toggle.js'
import Select from '../../ui/Select.js'
import ColorPicker from '../../ui/ColorPicker.js'
import formikFormWrapper from '../../formikFormWrapper.js'
import { toast } from 'react-toastify'
import { useModal } from '../../Modal.js'
import ModalContainer from '../../ui/ModalContainer.js'
import ChatButton from '../../Components/ChatButton'
import Tooltip from '../../ui/Tooltip.js'

const FormInput = formikFormWrapper(Input)

import api from '../../api.js'
import { useAuth } from '../../Auth.js'

import {
  STEPS,
  WIDGET_TYPES,
  WIDGET_THUMBNAIL_DIMENSIONS,
  TITLE_POSITION,
  TITLE_ALIGNMENT,
  WIDGET_THUMBNAIL_SIZE
} from '../types'
import { isScalePlan } from '../../Plans.js'

const ChoiceContainer = styled(Container)`
  ${(props) => (props.disabled ? 'background: rgba(250, 250, 250, 1);cursor: initial !important;' : '')}
  padding: 8px;
  cursor: pointer;
  width: 30%;
  max-width: 350px;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
  &&:hover {
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.2), 0px 4px 20px -2px rgba(50, 50, 71, 0.2);
  }
`

const InnerContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  height: 135px;
  background: linear-gradient(225deg, #f1f1f5 0%, #e4ecf7 100%);
  margin-bottom: 24px;
  position: relative;
`

const UpgradeContainer = styled(Flex)`
  background: linear-gradient(225deg, #bb65ff 0%, #4c6fff 100%);
  height: 37px;
  box-sizing: border-box;
  align-items: center;
  border-radius: 8px;
  width: 90px;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 8px;
`

const WidgetType = ({
  step,
  setStep,
  values,
  selectedVideos,
  openFullscreenDesktopPreview,
  openFullscreenMobilePreview,
  videoObjects,
  setFieldValue,
  widgets,
  submit
}) => {
  const { user } = useAuth()
  const { setShowInstallModal, setInstallModalCampaign } = useModal()
  const [search, setSearch] = useSearchParams()
  const [showIntroModal, setShowIntroModal] = useState(false)
  useEffect(() => {
    if (search.get('isCreate')) {
      setShowIntroModal(true)
    }
  }, [])
  return (
    <>
      <Flex height="100vh" flexDirection="column" alignItems="center">
        <Flex onClick={() => setStep(STEPS.CAMPAIGN_TYPE)} cursor="pointer" position="fixed" left="24px" top="24px" width="auto">
          <Icon mt="4px" mr="16px" icon="chevron-left-lightlight" height="16px" width="16px" />
          <Text lightLight fontSize="14px" mb="30px" textAlign="left">
            Back to Campaign
          </Text>
        </Flex>
        <ChatButton topRightFixed />

        <H1 fontSize="28px" mt="72px" mb="72px" textAlign="left">
          How do you want to display your campaign?
        </H1>
        {!values.isNavigationCampaign && !values.isInlineCampaign ? (
          <>
            <Flex justifyContent="center" px="50px" mb="32px">
              <ChoiceContainer
                onClick={() => {
                  setFieldValue('widget.type', WIDGET_TYPES.CAROUSEL)
                  setFieldValue(`widget.widgetThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT)
                  setFieldValue(`widget.widgetMobileThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT)
                  setFieldValue(`widget.widgetFixedThumbnailWidth`, 200)
                  setFieldValue(`widget.widgetFixedThumbnail`, false)
                  setFieldValue(`widget.widgetThumbnailBorder`, false)
                  setFieldValue(`widget.widgetMobileThumbnailSize`, WIDGET_THUMBNAIL_SIZE.LARGE)
                  setFieldValue(`widget.desktopVideos`, 4)
                  setFieldValue(`widget.widgetIsFixedCircle`, false)
                  setFieldValue(`widget.widgetDesktopTitleFontSize`, 18)
                  setFieldValue(`widget.widgetMobileTitleFontSize`, 14)
                  setFieldValue(`widget.widgetTitleFontWeight`, 700)
                  setFieldValue(`widget.id`, null)
                  setFieldValue(`widgetId`, undefined)
                  setFieldValue(`widget.name`, null)
                  setFieldValue(`showInstallModal`, true)
                  setStep(STEPS.EDIT_EMBED, { isNew: true })
                }}
              >
                <InnerContainer>
                  <svg width="179" height="100" viewBox="0 0 179 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_6573_77878)">
                      <rect x="92.9521" y="13.1953" width="53.1712" height="73.6098" rx="3" stroke="#505780" stroke-width="2" />
                      <rect x="32.8767" y="13.1953" width="53.1712" height="73.6098" rx="3" stroke="#505780" stroke-width="2" />
                      <rect x="-27.1987" y="13.1953" width="53.1712" height="73.6098" rx="3" stroke="#505780" stroke-width="2" />
                      <rect x="153.027" y="13.1953" width="53.1712" height="73.6098" rx="3" stroke="#505780" stroke-width="2" />
                      <path
                        opacity="0.9"
                        d="M66.3866 48.6608C67.7214 49.4303 67.7214 51.3566 66.3866 52.1261L57.0144 57.5294C55.681 58.298 54.0154 57.3357 54.0154 55.7967V44.9902C54.0154 43.4512 55.681 42.4888 57.0143 43.2575L66.3866 48.6608Z"
                        fill="#505780"
                      />
                      <path
                        opacity="0.9"
                        d="M126.316 48.6608C127.651 49.4303 127.651 51.3566 126.316 52.1261L116.944 57.5294C115.61 58.298 113.945 57.3357 113.945 55.7967V44.9902C113.945 43.4512 115.61 42.4888 116.944 43.2575L126.316 48.6608Z"
                        fill="#505780"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6573_77878">
                        <rect width="179" height="100" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </InnerContainer>
                <H3 fontSize="16px" mb="16px">
                  Carousel
                </H3>
              </ChoiceContainer>
              <ChoiceContainer
                onClick={() => {
                  setFieldValue('widget.type', WIDGET_TYPES.STORIES)
                  setFieldValue(`widget.widgetThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.CIRCLE)
                  setFieldValue(`widget.widgetMobileThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.CIRCLE)
                  setFieldValue(`widget.widgetFixedThumbnailWidth`, 100)
                  setFieldValue(`widget.durationDisplay`, false)
                  setFieldValue(`widget.widgetFixedThumbnailHeight`, 100)
                  setFieldValue(`widget.widgetFixedThumbnail`, true)
                  setFieldValue(`widget.widgetThumbnailBorderSize`, 4)
                  setFieldValue(`widget.widgetThumbnailBorderColor`, '#4c6fff')
                  setFieldValue(`widget.widgetThumbnailBorder`, true)
                  setFieldValue(`widget.widgetMobileThumbnailSize`, WIDGET_THUMBNAIL_SIZE.LARGE)
                  setFieldValue(`widget.desktopVideos`, 4)
                  setFieldValue(`widget.widgetTitlePosition`, TITLE_POSITION.BELOW)
                  setFieldValue(`widget.widgetTitleAlignment`, TITLE_ALIGNMENT.CENTER)
                  setFieldValue(`widget.widgetIsFixedCircle`, true)
                  setFieldValue(`widget.widgetDesktopTitleFontSize`, 14)
                  setFieldValue(`widget.widgetMobileTitleFontSize`, 12)
                  setFieldValue(`widget.widgetTitleFontWeight`, 500)
                  setFieldValue(`widget.id`, null)
                  setFieldValue(`widgetId`, undefined)
                  setFieldValue(`widget.name`, null)
                  setFieldValue(`showInstallModal`, true)
                  setStep(STEPS.EDIT_EMBED, { isNew: true })
                }}
              >
                <InnerContainer>
                  <svg width="174" height="30" viewBox="0 0 174 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="15" cy="15" r="14" stroke="#505780" stroke-width="2" />
                    <circle cx="51" cy="15" r="14" stroke="#505780" stroke-width="2" />
                    <circle cx="87" cy="15" r="14" stroke="#505780" stroke-width="2" />
                    <circle cx="123" cy="15" r="14" stroke="#505780" stroke-width="2" />
                    <circle cx="159" cy="15" r="14" stroke="#505780" stroke-width="2" />
                  </svg>
                </InnerContainer>
                <H3 fontSize="16px" mb="16px">
                  Stories
                </H3>
              </ChoiceContainer>
              <ChoiceContainer
                onClick={() => {
                  setFieldValue('widget.type', WIDGET_TYPES.GRID)
                  setFieldValue(`widget.widgetThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT)
                  setFieldValue(`widget.widgetMobileThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT)
                  setFieldValue(`widget.widgetFixedThumbnailWidth`, 200)
                  setFieldValue(`widget.widgetFixedThumbnail`, false)
                  setFieldValue(`widget.widgetThumbnailBorder`, false)
                  setFieldValue(`widget.widgetMobileThumbnailSize`, WIDGET_THUMBNAIL_SIZE.LARGE)
                  setFieldValue(`widget.desktopVideos`, 4)
                  setFieldValue(`widget.widgetIsFixedCircle`, false)
                  setFieldValue(`widget.widgetDesktopTitleFontSize`, 18)
                  setFieldValue(`widget.widgetMobileTitleFontSize`, 14)
                  setFieldValue(`widget.widgetTitleFontWeight`, 700)
                  setFieldValue(`widget.id`, null)
                  setFieldValue(`widgetId`, undefined)
                  setFieldValue(`widget.name`, null)
                  setFieldValue(`widget.marginX`, 16)
                  setFieldValue(`showInstallModal`, true)
                  setFieldValue(`widget.widgetSearchShowPopular`, false)
                  setStep(STEPS.EDIT_EMBED, { isNew: true })
                }}
              >
                <InnerContainer>
                  <svg width="130" height="110" viewBox="0 0 130 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="40" height="52" rx="3" stroke="#505780" stroke-width="2" />
                    <path
                      opacity="0.9"
                      d="M25.3066 25.5222C26.7046 26.2783 26.7046 28.2844 25.3066 29.0405L19.8048 32.0162C18.4723 32.7369 16.8534 31.772 16.8534 30.257V24.3057C16.8534 22.7907 18.4723 21.8258 19.8048 22.5465L25.3066 25.5222Z"
                      fill="#505780"
                    />
                    <rect x="1" y="57" width="40" height="52" rx="3" stroke="#505780" stroke-width="2" />
                    <path
                      opacity="0.9"
                      d="M25.3066 81.5222C26.7046 82.2783 26.7046 84.2844 25.3066 85.0405L19.8048 88.0162C18.4723 88.7369 16.8534 87.772 16.8534 86.257V80.3057C16.8534 78.7907 18.4723 77.8258 19.8048 78.5465L25.3066 81.5222Z"
                      fill="#505780"
                    />
                    <rect x="45" y="1" width="40" height="52" rx="3" stroke="#505780" stroke-width="2" />
                    <path
                      opacity="0.9"
                      d="M69.3066 25.5222C70.7046 26.2783 70.7046 28.2844 69.3066 29.0405L63.8048 32.0162C62.4723 32.7369 60.8534 31.772 60.8534 30.257V24.3057C60.8534 22.7907 62.4723 21.8258 63.8048 22.5465L69.3066 25.5222Z"
                      fill="#505780"
                    />
                    <rect x="45" y="57" width="40" height="52" rx="3" stroke="#505780" stroke-width="2" />
                    <path
                      opacity="0.9"
                      d="M69.3066 81.5222C70.7046 82.2783 70.7046 84.2844 69.3066 85.0405L63.8048 88.0162C62.4723 88.7369 60.8534 87.772 60.8534 86.257V80.3057C60.8534 78.7907 62.4723 77.8258 63.8048 78.5465L69.3066 81.5222Z"
                      fill="#505780"
                    />
                    <rect x="89" y="1" width="40" height="52" rx="3" stroke="#505780" stroke-width="2" />
                    <path
                      opacity="0.9"
                      d="M113.307 25.5222C114.705 26.2783 114.705 28.2844 113.307 29.0405L107.805 32.0162C106.472 32.7369 104.853 31.772 104.853 30.257V24.3057C104.853 22.7907 106.472 21.8258 107.805 22.5465L113.307 25.5222Z"
                      fill="#505780"
                    />
                    <rect x="89" y="57" width="40" height="52" rx="3" stroke="#505780" stroke-width="2" />
                    <path
                      opacity="0.9"
                      d="M113.307 81.5222C114.705 82.2783 114.705 84.2844 113.307 85.0405L107.805 88.0162C106.472 88.7369 104.853 87.772 104.853 86.257V80.3057C104.853 78.7907 106.472 77.8258 107.805 78.5465L113.307 81.5222Z"
                      fill="#505780"
                    />
                  </svg>
                </InnerContainer>
                <H3 fontSize="16px" mb="16px">
                  Grid
                </H3>
              </ChoiceContainer>
            </Flex>
            <Flex justifyContent="center" px="50px">
              <ChoiceContainer
                onClick={() => {
                  setFieldValue('widget.type', WIDGET_TYPES.SINGLE_EMBED)
                  setFieldValue(`widget.widgetAutoplay`, false)
                  setFieldValue(`widget.id`, null)
                  setFieldValue(`widgetId`, undefined)
                  setFieldValue(`widget.name`, null)
                  setFieldValue(`showInstallModal`, true)
                  setStep(STEPS.EDIT_EMBED, { isNew: true })
                }}
              >
                <InnerContainer>
                  <svg width="120" height="70" viewBox="0 0 120 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="118" height="68" rx="3" stroke="#505780" stroke-width="2" />
                    <path
                      opacity="0.9"
                      d="M70 33.2679C71.3333 34.0377 71.3333 35.9623 70 36.7321L56.5 44.5263C55.1667 45.2961 53.5 44.3338 53.5 42.7942V27.2058C53.5 25.6662 55.1667 24.7039 56.5 25.4737L70 33.2679Z"
                      fill="#505780"
                    />
                  </svg>
                </InnerContainer>
                <H3 fontSize="16px" mb="16px">
                  Single
                </H3>
              </ChoiceContainer>
              <ChoiceContainer
                onClick={() => {
                  setFieldValue('widget.type', WIDGET_TYPES.FLOATING)
                  setFieldValue(`widget.id`, null)
                  setFieldValue(`widgetId`, undefined)
                  setFieldValue(`widget.name`, null)
                  setFieldValue(`showInstallModal`, true)
                  setStep(STEPS.EDIT_EMBED, { isNew: true })
                }}
              >
                <InnerContainer justifyContent="flex-start" alignItems="flex-start">
                  <Flex justifyContent="flex-start" alignItems="flex-end" height="100%" pb="8px" pl="16px">
                    <svg width="44" height="70" viewBox="0 0 44 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        opacity="0.9"
                        d="M32 33.2679C33.3333 34.0377 33.3333 35.9623 32 36.7321L18.5 44.5263C17.1667 45.2961 15.5 44.3338 15.5 42.7942L15.5 27.2058C15.5 25.6662 17.1667 24.7039 18.5 25.4737L32 33.2679Z"
                        fill="#505780"
                      />
                      <rect x="1" y="1" width="42" height="68" rx="3" stroke="#505780" stroke-width="2" />
                    </svg>
                  </Flex>
                </InnerContainer>
                <H3 fontSize="16px" mb="16px">
                  Pop-up
                </H3>
              </ChoiceContainer>
              <ChoiceContainer
                disabled={!isScalePlan(user)}
                onClick={async () => {
                  if (!isScalePlan(user)) {
                    return
                  }
                  setFieldValue('isCustomLauncher', true)
                  values.isCustomLauncher = true
                  await submit(undefined, true)
                  setStep(STEPS.VIDEO_APPEARANCE)
                }}
              >
                <InnerContainer justifyContent="flex-start" alignItems="flex-start">
                  <Flex justifyContent="center" alignItems="center" height="100%" pt="16px">
                    {!isScalePlan(user) ? (
                      <a href="/account?tab=BILLING" target="_blank" onClick={(e) => e.stopPropagation()}>
                        <UpgradeContainer>
                          <Icon mr="8px" icon="lightening-white" height="16px" width="11px" />
                          <Text fontWeight="500" color="white">
                            Scale
                          </Text>
                        </UpgradeContainer>
                      </a>
                    ) : null}
                    <svg width="66" height="31" viewBox="0 0 66 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="7" y="1" width="58" height="22" rx="3" stroke="#505780" stroke-width="2" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.0815 17.1821C14.2278 17.3284 14.2689 17.5496 14.1848 17.7387L8.52798 30.4666C8.44724 30.6483 8.26654 30.7649 8.06774 30.7635C7.86894 30.7622 7.68981 30.6432 7.6115 30.4605L5.56896 25.6946L0.803046 23.652C0.620316 23.5737 0.501342 23.3946 0.500016 23.1958C0.498691 22.997 0.615268 22.8163 0.796936 22.7356L13.5249 17.0787C13.7139 16.9947 13.9352 17.0358 14.0815 17.1821Z"
                        fill="#505780"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M34.8536 8.14645C35.0488 8.34171 35.0488 8.65829 34.8536 8.85355L31.7071 12L34.8536 15.1464C35.0488 15.3417 35.0488 15.6583 34.8536 15.8536C34.6583 16.0488 34.3417 16.0488 34.1464 15.8536L30.6464 12.3536C30.4512 12.1583 30.4512 11.8417 30.6464 11.6464L34.1464 8.14645C34.3417 7.95118 34.6583 7.95118 34.8536 8.14645Z"
                        fill="#505780"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M39.1464 8.14645C38.9512 8.34171 38.9512 8.65829 39.1464 8.85355L42.2929 12L39.1464 15.1464C38.9512 15.3417 38.9512 15.6583 39.1464 15.8536C39.3417 16.0488 39.6583 16.0488 39.8536 15.8536L43.3536 12.3536C43.5488 12.1583 43.5488 11.8417 43.3536 11.6464L39.8536 8.14645C39.6583 7.95118 39.3417 7.95118 39.1464 8.14645Z"
                        fill="#505780"
                      />
                    </svg>
                  </Flex>
                </InnerContainer>
                <Flex justifyContent="center" alignItems="center">
                  <H3 fontSize="16px" mb="0px" mr="8px">
                    Custom
                  </H3>
                  <Tooltip tooltip={'Add a code snippet to your own designs to launch the Clipara player.'} />
                </Flex>
              </ChoiceContainer>
            </Flex>
          </>
        ) : null}

        {values.isNavigationCampaign ? (
          <Flex justifyContent="center" px="50px" mb="32px">
            <ChoiceContainer
              onClick={() => {
                setFieldValue('widget.type', WIDGET_TYPES.NAVIGATION_CAROUSEL)
                setFieldValue(`widget.widgetThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT)
                setFieldValue(`widget.widgetMobileThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT)
                setFieldValue(`widget.widgetFixedThumbnailWidth`, 200)
                setFieldValue(`widget.widgetFixedThumbnail`, false)
                setFieldValue(`widget.widgetThumbnailBorder`, false)
                setFieldValue(`widget.widgetMobileThumbnailSize`, WIDGET_THUMBNAIL_SIZE.LARGE)
                setFieldValue(`widget.desktopVideos`, 4)
                setFieldValue(`widget.widgetIsFixedCircle`, false)
                setFieldValue(`widget.widgetDesktopTitleFontSize`, 18)
                setFieldValue(`widget.widgetMobileTitleFontSize`, 14)
                setFieldValue(`widget.widgetTitleFontWeight`, 700)
                setFieldValue(`widget.id`, null)
                setFieldValue(`widgetId`, undefined)
                setFieldValue(`widget.name`, null)
                setFieldValue(`showInstallModal`, true)
                setStep(STEPS.EDIT_EMBED, { isNew: true })
              }}
            >
              <InnerContainer>
                <svg width="179" height="100" viewBox="0 0 179 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_6573_77878)">
                    <rect x="92.9521" y="13.1953" width="53.1712" height="73.6098" rx="3" stroke="#505780" stroke-width="2" />
                    <rect x="32.8767" y="13.1953" width="53.1712" height="73.6098" rx="3" stroke="#505780" stroke-width="2" />
                    <rect x="-27.1987" y="13.1953" width="53.1712" height="73.6098" rx="3" stroke="#505780" stroke-width="2" />
                    <rect x="153.027" y="13.1953" width="53.1712" height="73.6098" rx="3" stroke="#505780" stroke-width="2" />
                    <path
                      opacity="0.9"
                      d="M66.3866 48.6608C67.7214 49.4303 67.7214 51.3566 66.3866 52.1261L57.0144 57.5294C55.681 58.298 54.0154 57.3357 54.0154 55.7967V44.9902C54.0154 43.4512 55.681 42.4888 57.0143 43.2575L66.3866 48.6608Z"
                      fill="#505780"
                    />
                    <path
                      opacity="0.9"
                      d="M126.316 48.6608C127.651 49.4303 127.651 51.3566 126.316 52.1261L116.944 57.5294C115.61 58.298 113.945 57.3357 113.945 55.7967V44.9902C113.945 43.4512 115.61 42.4888 116.944 43.2575L126.316 48.6608Z"
                      fill="#505780"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6573_77878">
                      <rect width="179" height="100" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </InnerContainer>
              <H3 fontSize="16px" mb="16px">
                Carousel
              </H3>
            </ChoiceContainer>
            <ChoiceContainer
              onClick={() => {
                setFieldValue('widget.type', WIDGET_TYPES.NAVIGATION_GRID)
                setFieldValue(`widget.widgetThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT)
                setFieldValue(`widget.widgetMobileThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT)
                setFieldValue(`widget.widgetFixedThumbnailWidth`, 200)
                setFieldValue(`widget.widgetFixedThumbnail`, false)
                setFieldValue(`widget.widgetThumbnailBorder`, false)
                setFieldValue(`widget.widgetMobileThumbnailSize`, WIDGET_THUMBNAIL_SIZE.LARGE)
                setFieldValue(`widget.desktopVideos`, 4)
                setFieldValue(`widget.widgetIsFixedCircle`, false)
                setFieldValue(`widget.widgetDesktopTitleFontSize`, 18)
                setFieldValue(`widget.widgetMobileTitleFontSize`, 14)
                setFieldValue(`widget.widgetTitleFontWeight`, 700)
                setFieldValue(`widget.id`, null)
                setFieldValue(`widgetId`, undefined)
                setFieldValue(`widget.name`, null)
                setFieldValue(`widget.marginX`, 16)
                setFieldValue(`showInstallModal`, true)
                setStep(STEPS.EDIT_EMBED, { isNew: true })
              }}
            >
              <InnerContainer>
                <svg width="130" height="110" viewBox="0 0 130 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1" y="1" width="40" height="52" rx="3" stroke="#505780" stroke-width="2" />
                  <path
                    opacity="0.9"
                    d="M25.3066 25.5222C26.7046 26.2783 26.7046 28.2844 25.3066 29.0405L19.8048 32.0162C18.4723 32.7369 16.8534 31.772 16.8534 30.257V24.3057C16.8534 22.7907 18.4723 21.8258 19.8048 22.5465L25.3066 25.5222Z"
                    fill="#505780"
                  />
                  <rect x="1" y="57" width="40" height="52" rx="3" stroke="#505780" stroke-width="2" />
                  <path
                    opacity="0.9"
                    d="M25.3066 81.5222C26.7046 82.2783 26.7046 84.2844 25.3066 85.0405L19.8048 88.0162C18.4723 88.7369 16.8534 87.772 16.8534 86.257V80.3057C16.8534 78.7907 18.4723 77.8258 19.8048 78.5465L25.3066 81.5222Z"
                    fill="#505780"
                  />
                  <rect x="45" y="1" width="40" height="52" rx="3" stroke="#505780" stroke-width="2" />
                  <path
                    opacity="0.9"
                    d="M69.3066 25.5222C70.7046 26.2783 70.7046 28.2844 69.3066 29.0405L63.8048 32.0162C62.4723 32.7369 60.8534 31.772 60.8534 30.257V24.3057C60.8534 22.7907 62.4723 21.8258 63.8048 22.5465L69.3066 25.5222Z"
                    fill="#505780"
                  />
                  <rect x="45" y="57" width="40" height="52" rx="3" stroke="#505780" stroke-width="2" />
                  <path
                    opacity="0.9"
                    d="M69.3066 81.5222C70.7046 82.2783 70.7046 84.2844 69.3066 85.0405L63.8048 88.0162C62.4723 88.7369 60.8534 87.772 60.8534 86.257V80.3057C60.8534 78.7907 62.4723 77.8258 63.8048 78.5465L69.3066 81.5222Z"
                    fill="#505780"
                  />
                  <rect x="89" y="1" width="40" height="52" rx="3" stroke="#505780" stroke-width="2" />
                  <path
                    opacity="0.9"
                    d="M113.307 25.5222C114.705 26.2783 114.705 28.2844 113.307 29.0405L107.805 32.0162C106.472 32.7369 104.853 31.772 104.853 30.257V24.3057C104.853 22.7907 106.472 21.8258 107.805 22.5465L113.307 25.5222Z"
                    fill="#505780"
                  />
                  <rect x="89" y="57" width="40" height="52" rx="3" stroke="#505780" stroke-width="2" />
                  <path
                    opacity="0.9"
                    d="M113.307 81.5222C114.705 82.2783 114.705 84.2844 113.307 85.0405L107.805 88.0162C106.472 88.7369 104.853 87.772 104.853 86.257V80.3057C104.853 78.7907 106.472 77.8258 107.805 78.5465L113.307 81.5222Z"
                    fill="#505780"
                  />
                </svg>
              </InnerContainer>
              <H3 fontSize="16px" mb="16px">
                Grid
              </H3>
            </ChoiceContainer>
          </Flex>
        ) : null}
        {values.isInlineCampaign ? (
          <>
            <Flex justifyContent="center" px="50px" mb="32px">
              <ChoiceContainer
                onClick={() => {
                  setFieldValue('widget.type', WIDGET_TYPES.INLINE_CAROUSEL)
                  setFieldValue(`widget.widgetThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT)
                  setFieldValue(`widget.widgetMobileThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT)
                  setFieldValue(`widget.widgetFixedThumbnailWidth`, 200)
                  setFieldValue(`widget.widgetFixedThumbnail`, false)
                  setFieldValue(`widget.widgetThumbnailBorder`, false)
                  setFieldValue(`widget.widgetMobileThumbnailSize`, WIDGET_THUMBNAIL_SIZE.LARGE)
                  setFieldValue(`widget.desktopVideos`, 3)
                  setFieldValue(`widget.widgetIsFixedCircle`, false)
                  setFieldValue(`widget.widgetDesktopTitleFontSize`, 18)
                  setFieldValue(`widget.widgetMobileTitleFontSize`, 14)
                  setFieldValue(`widget.widgetTitleFontWeight`, 700)
                  setFieldValue(`widget.id`, null)
                  setFieldValue(`widgetId`, undefined)
                  setFieldValue(`widget.name`, null)
                  setFieldValue(`showInstallModal`, true)
                  setFieldValue(`widget.widgetTitlePosition`, TITLE_POSITION.BELOW)

                  setStep(STEPS.EDIT_EMBED, { isNew: true })
                }}
              >
                <InnerContainer>
                  <svg width="179" height="100" viewBox="0 0 179 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_6573_77878)">
                      <rect x="92.9521" y="13.1953" width="53.1712" height="73.6098" rx="3" stroke="#505780" stroke-width="2" />
                      <rect x="32.8767" y="13.1953" width="53.1712" height="73.6098" rx="3" stroke="#505780" stroke-width="2" />
                      <rect x="-27.1987" y="13.1953" width="53.1712" height="73.6098" rx="3" stroke="#505780" stroke-width="2" />
                      <rect x="153.027" y="13.1953" width="53.1712" height="73.6098" rx="3" stroke="#505780" stroke-width="2" />
                      <path
                        opacity="0.9"
                        d="M66.3866 48.6608C67.7214 49.4303 67.7214 51.3566 66.3866 52.1261L57.0144 57.5294C55.681 58.298 54.0154 57.3357 54.0154 55.7967V44.9902C54.0154 43.4512 55.681 42.4888 57.0143 43.2575L66.3866 48.6608Z"
                        fill="#505780"
                      />
                      <path
                        opacity="0.9"
                        d="M126.316 48.6608C127.651 49.4303 127.651 51.3566 126.316 52.1261L116.944 57.5294C115.61 58.298 113.945 57.3357 113.945 55.7967V44.9902C113.945 43.4512 115.61 42.4888 116.944 43.2575L126.316 48.6608Z"
                        fill="#505780"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6573_77878">
                        <rect width="179" height="100" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </InnerContainer>
                <H3 fontSize="16px" mb="16px">
                  Carousel
                </H3>
              </ChoiceContainer>
              {selectedVideos && selectedVideos.length === 1 ? (
                <ChoiceContainer
                  onClick={() => {
                    setFieldValue('widget.type', WIDGET_TYPES.INLINE_SINGLE)
                    setFieldValue(`widget.widgetThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT)
                    setFieldValue(`widget.widgetMobileThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT)
                    setFieldValue(`widget.widgetFixedThumbnailWidth`, 200)
                    setFieldValue(`widget.widgetFixedThumbnail`, false)
                    setFieldValue(`widget.widgetThumbnailBorder`, false)
                    setFieldValue(`widget.widgetMobileThumbnailSize`, WIDGET_THUMBNAIL_SIZE.LARGE)
                    setFieldValue(`widget.desktopVideos`, 3)
                    setFieldValue(`widget.widgetIsFixedCircle`, false)
                    setFieldValue(`widget.widgetDesktopTitleFontSize`, 18)
                    setFieldValue(`widget.widgetMobileTitleFontSize`, 14)
                    setFieldValue(`widget.widgetTitleFontWeight`, 700)
                    setFieldValue(`widget.id`, null)
                    setFieldValue(`widgetId`, undefined)
                    setFieldValue(`widget.name`, null)
                    setFieldValue(`showInstallModal`, true)
                    setFieldValue(`widget.widgetTitlePosition`, TITLE_POSITION.BELOW)
                    setStep(STEPS.EDIT_EMBED, { isNew: true })
                  }}
                >
                  <InnerContainer>
                    <svg width="120" height="70" viewBox="0 0 120 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1" y="1" width="118" height="68" rx="3" stroke="#505780" stroke-width="2" />
                      <path
                        opacity="0.9"
                        d="M70 33.2679C71.3333 34.0377 71.3333 35.9623 70 36.7321L56.5 44.5263C55.1667 45.2961 53.5 44.3338 53.5 42.7942V27.2058C53.5 25.6662 55.1667 24.7039 56.5 25.4737L70 33.2679Z"
                        fill="#505780"
                      />
                    </svg>
                  </InnerContainer>
                  <H3 fontSize="16px" mb="16px">
                    Single
                  </H3>
                </ChoiceContainer>
              ) : null}
            </Flex>
          </>
        ) : null}
      </Flex>
      <ModalContainer
        interiorClose
        p="24px"
        width="700px"
        height="500px"
        isOpen={showIntroModal}
        setIsOpen={setShowIntroModal}
      ></ModalContainer>
    </>
  )
}

export default WidgetType
