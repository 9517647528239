import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, shorten } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import SideNav, { TABS } from '../Components/SideNav.js'
import VideoPlayer from '../Components/VideoPlayer'
import StatusPill from '../Components/StatusPill'
import ProcessingPreview from '../Components/ProcessingPreview'

import formikFormWrapper from '../formikFormWrapper.js'
import { CAMPAIGN_STATUS } from './types.js'
import { toast } from 'react-toastify'
import api from '../api.js'
import { useAuth } from '../Auth.js'

const Popover = styled(Flex)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  width: 500px;
  right: 0;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`

const Option = styled(Text)`
  font-size: 16px;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 16px;
  font-weight: 500;
  :hover {
    color: white;
    background-color: black;
  }
`

const Styler = styled(Flex)`
  .react-datepicker {
    border: none;
    font-family: 'Inter', sans-serif !important;
  }
  .react-datepicker__header {
    background-color: white;
  }
  .react-datepicker__day--in-range {
    background-color: black;
  }
  .react-datepicker__day--in-selecting-range {
    background-color: black;
  }
  .react-datepicker__day--selected {
    background-color: black;
  }
  .react-datepicker__header {
    border-bottom: none;
  }
`

const DatePopover = ({ startDate, endDate, setStartDate, setEndDate, refresh }) => {
  const [showPopover, setShowPopover] = useState(false)
  const popover = useRef(null)
  const navigate = useNavigate()

  const closeMenu = (event) => {
    if (popover && popover.current && !popover.current.contains(event.target)) {
      document.removeEventListener('click', closeMenu)
      setShowPopover(false)
    }
  }

  const showPopoverFunc = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setShowPopover(true)
    document.addEventListener('click', closeMenu)
  }

  const buttonLabel =
    (startDate ? dayjs(startDate).format('D MMM') + ' - ' : '') + (endDate ? dayjs(endDate).format('D MMM') : '')
  return (
    <Box position="relative">
      <Box cursor="pointer" onClick={showPopover ? closeMenu : showPopoverFunc}>
        <Button
          pill
          label={buttonLabel}
          variant="white"
          shadow
          renderRightIcon={() => <Icon ml="16px" width="16px" height="16px" icon="filter-circle-black" />}
        />
      </Box>
      {showPopover ? (
        <Popover ref={popover}>
          <Flex>
            <Flex pt="14px" px="12px" flexDirection="column" borderRight="1px solid #EDF2F7;">
              <Option
                onClick={() => {
                  setEndDate(dayjs().toDate())
                  setStartDate(dayjs().subtract(7, 'day').toDate())
                  setShowPopover(false)
                }}
              >
                Last 7 Days
              </Option>
              <Option
                onClick={() => {
                  setEndDate(dayjs().toDate())
                  setStartDate(dayjs().subtract(30, 'day').toDate())
                  setShowPopover(false)
                }}
              >
                Last 30 Days
              </Option>
              <Option
                onClick={() => {
                  setEndDate(dayjs().toDate())
                  setStartDate(dayjs().subtract(60, 'day').toDate())
                  setShowPopover(false)
                }}
              >
                Last 60 Days
              </Option>
              <Option
                onClick={() => {
                  setEndDate(dayjs().toDate())
                  setStartDate(dayjs().subtract(90, 'day').toDate())
                  setShowPopover(false)
                }}
              >
                Last 90 Days
              </Option>
            </Flex>
            <Styler
              p="24px"
              flexDirection="column"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <DatePicker
                selected={startDate}
                onChange={([s, e]) => {
                  setStartDate(s)
                  setEndDate(e)
                  if (e) {
                    setShowPopover(false)
                  }
                }}
                maxDate={dayjs().toDate()}
                minDate={dayjs('2023-03-01').toDate()}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
            </Styler>
          </Flex>
        </Popover>
      ) : null}
    </Box>
  )
}

export default DatePopover
