import React, { useState, useEffect, memo } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, stringPadLeft, formatSeconds } from '../../ui/helpers'
import { H1, H2, H3, H6, Text, Span, GradientFont, Error, Label } from '../../ui/Typography'
import { Flex, Box, Container } from '../../ui/Layout'
import { Input, TextArea } from '../../ui/Input'
import Image from '../../ui/Image'
import Icon from '../../ui/Icon'
import CodeCopy from '../../ui/CodeCopy'
import VideoPreview from '../../ui/VideoPreview'
import WidgetPreview from '../../Components/WidgetPreview'
import Button from '../../ui/Button'
import VideoUpload from '../../Videos/VideoUpload'
import { BigDarkSpinner } from '../../ui/Spinner'
import Toggle from '../../ui/Toggle'
import Select from '../../ui/Select'
import ColorPicker from '../../ui/ColorPicker'
import formikFormWrapper from '../../formikFormWrapper'
import { toast } from 'react-toastify'
import { Tabs } from '../CampaignEdit'
import { useVideos } from '../../VideoProvider'
import ProcessingPreview from '../../Components/ProcessingPreview'
import ModalContainer from '../../ui/ModalContainer'
import { ReactSortable } from 'react-sortablejs'
import ReactTooltip from 'react-tooltip'
import { uniq, difference, intersection } from 'ramda'
import { getAllowedVideoDuration, getNumberOfAllowedVideos, getPlanName, hasHitVideoLimit, isFreePlan } from '../../Plans'

const FormInput = formikFormWrapper(Input)

import api from '../../api'
import { useAuth } from '../../Auth'

import {
  STEPS,
  CAMPAIGN_RULE_TYPES,
  CAMPAIGN_STATUS,
  campaignRuleTypesOptions,
  WIDGET_POSITIONS,
  widgetPositionOptions
} from '../types'
import ImageUpload from '../../Images/ImageUpload'
import ToggleRedGreen from '../../ui/ToggleRedGreen'

const ImageComp = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-position: center;
`

const Grid = styled(Flex)`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (min-width: 1500px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 1800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 2200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 2500px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

const VideoPill = styled(Flex)`
  box-shadow: 0px 1px 14px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
  margin-bottom: 16px;
  padding: 10px;
  border-radius: 4px;
  background-color: white;
  flex-direction: column;
  word-break: break-all;
`

const GrabIcon = styled(Icon)`
  cursor: grab;
`

const VerticalDivider = styled(Box)`
  border-right: 1px solid rgba(166, 183, 212, 1);
  width: 0px;
  margin-right: 16px;
  margin-left: 8px;
  height: 40px;
`

const Gradient = styled(Flex)`
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 80%, rgba(0, 0, 0, 0.3) 100%);
`

const VideoPills = ({ videosOrImages, setFieldValue }) => {
  let { user } = useAuth()

  ReactTooltip.rebuild()

  return (
    <>
      <Flex flexDirection="column" mb="32px">
        <ReactSortable
          list={videosOrImages}
          setList={(newVideos) => {
            setFieldValue('videoIds', uniq(newVideos.map((video) => video.id)))
          }}
        >
          {videosOrImages.map((videoOrImage, index) => {
            const isVideo = videoOrImage.type === 'VIDEO'
            return (
              <VideoPill key={videoOrImage.id} alignItems="center">
                <Flex alignItems="center">
                  <GrabIcon mr="8px" icon="drag-circles-grey" width="24px" height="24px" alt="drag icon" />
                  {isVideo ? (
                    <>
                      {videoOrImage.playbackId ? (
                        <Image
                          height="60px"
                          width="60px"
                          borderRadius="4px"
                          src={
                            videoOrImage.customThumbnail
                              ? videoOrImage.customThumbnail + `-/scale_crop/${60}x${60}/center/`
                              : `https://image.mux.com/${videoOrImage.playbackId}/thumbnail.jpg?width=60&height=60&fit_mode=smartcrop&time=${videoOrImage.thumbnailTime}`
                          }
                        />
                      ) : (
                        <Box minWidth="60px" width="60px" height="60px">
                          <ProcessingPreview small />
                        </Box>
                      )}
                    </>
                  ) : (
                    <Image
                      height="60px"
                      width="60px"
                      minWidth="60px"
                      borderRadius="4px"
                      objectFit="cover"
                      src={`https://clipara.b-cdn.net/${videoOrImage.path}?width=200`}
                    />
                  )}
                  <Flex flexDirection="column" justifyContent="center" px="10px">
                    <Text fontWeight="600">
                      {videoOrImage.name && videoOrImage.name.length > 45
                        ? videoOrImage.name.substring(0, 45) + '...'
                        : videoOrImage.name}
                    </Text>
                    {isVideo ? (
                      <Text>
                        {stringPadLeft(Math.floor(videoOrImage.duration / 60), '0', 2)}:
                        {stringPadLeft(Math.round(videoOrImage.duration - Math.floor(videoOrImage.duration / 60) * 60), '0', 2)}
                      </Text>
                    ) : null}
                  </Flex>
                  {videosOrImages.length > 1 ? (
                    <Icon
                      cursor="pointer"
                      width="16px"
                      height="16px"
                      icon="bin-grey"
                      onClick={() => {
                        setFieldValue('videoIds', uniq(videosOrImages.filter((v) => v.id !== videoOrImage.id).map((v) => v.id)))
                      }}
                    />
                  ) : null}
                </Flex>
              </VideoPill>
            )
          })}
        </ReactSortable>
      </Flex>
    </>
  )
}

const LineClamp = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 600;
  font-size: 14px;
  line-height: 21.78px;
  word-break: break-all;
  padding-left: 8px;
  padding-right: 8px;
`

const VideoDisplay = memo(
  ({ videoIds, videosToShow, setFieldValue, isVideosTab }) => {
    return videosToShow.map((videoOrImage) => {
      const onClick = () => {
        if (videoIds && videoIds.includes(videoOrImage.id)) {
          setFieldValue('videoIds', uniq(videoIds.filter((id) => id !== videoOrImage.id)))
        } else {
          setFieldValue('videoIds', uniq([videoOrImage.id, ...videoIds]))
        }
      }
      return (
        <Container
          border={videoIds.includes(videoOrImage.id) ? `4px solid rgba(120, 149, 255, 1)` : 'transparent'}
          onClick={onClick}
          hover
          p="0px"
          key={videoOrImage.id}
          width="100%"
          height="350px"
          justifyContent="space-between"
          position="relative"
          overflowX="hidden"
        >
          <Flex overflow="hidden" height="100%" position="relative">
            {isVideosTab ? (
              <>
                {videoOrImage.playbackId ? (
                  <ImageComp
                    loading="lazy"
                    width="100%"
                    height="100%"
                    src={
                      videoOrImage.customThumbnail
                        ? videoOrImage.customThumbnail + `-/scale_crop/400x400/center/`
                        : `https://image.mux.com/${videoOrImage.playbackId}/thumbnail.png?time=${videoOrImage.thumbnailTime}&width=400&height=400&fit_mode=crop`
                    }
                  />
                ) : (
                  <ProcessingPreview />
                )}
              </>
            ) : (
              <ImageComp
                loading="lazy"
                width="100%"
                height="100%"
                src={`https://clipara.b-cdn.net/${videoOrImage.path}?width=400`}
              />
            )}
            <Gradient position="absolute" top="0px" left="0px" height="100%" />

            <Box position="absolute" bottom="8px" right="8px">
              <Text color="white" fontSize="12px" fontWeight="600">
                {videoOrImage.duration && formatSeconds(videoOrImage.duration)}
              </Text>
            </Box>
            <Box position="absolute" bottom="8px" left="8px">
              <Text color="white" fontSize="12px" fontWeight="600">
                {dayjs(videoOrImage.createdAt).format('DD MMM')}
              </Text>
            </Box>
          </Flex>
          <Flex flexDirection="column" py="8px" pb="16px" minHeight="100px" height="100px" justifyContent="space-between">
            <LineClamp>{videoOrImage.name}</LineClamp>
            <Flex overflowX="scroll" pl="8px" noScrollBar>
              {(videoOrImage.tagsArray || []).map((tag) => {
                return (
                  <Flex
                    alignItems="center"
                    bg="rgba(225, 232, 255, 1)"
                    width="auto"
                    height="28px"
                    mr="8px"
                    borderRadius="14px"
                    px="16px"
                    key={tag}
                    cursor="pointer"
                  >
                    <Text whiteSpace="nowrap" mb="2px" light fontWeight="500">
                      {tag}
                    </Text>
                  </Flex>
                )
              })}
            </Flex>
          </Flex>
        </Container>
      )
    })
  },
  (prevProps, nextProps) => {
    return (
      prevProps.videoIds === nextProps.videoIds &&
      prevProps.videosToShow === nextProps.videosToShow &&
      prevProps.isVideosTab === nextProps.isVideosTab
    )
  }
)

const TABS = {
  VIDEOS: 'VIDEOS',
  IMAGES: 'IMAGES'
}

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && 'border-bottom: 1px solid black;'}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`

const Tab = ({ onClick, title, isActive }) => {
  return (
    <TabText onClick={onClick} isActive={isActive}>
      {title}
    </TabText>
  )
}

const SelectVideo = ({ setFieldValue, values, selectedVideos, isCreate, submit, setStep }) => {
  const { videos, images, setVideos, refresh } = useVideos()
  let { user } = useAuth()
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const [manuallyAddVideo, setManuallyAddVideo] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedTags, setSelectedTags] = useState([])
  const [videosToShow, setVideosToShow] = useState(videos)
  const [activeTab, setActiveTabState] = useState(TABS.VIDEOS)
  const setActiveTab = (x) => {
    setActiveTabState(x)
    setManuallyAddVideo(false)
    setVideosToShow([])
  }
  const isVideosTab = activeTab === TABS.VIDEOS

  const videosOrImages = isVideosTab ? videos : images

  let inputAllTags = []
  videosOrImages.forEach((v) => (inputAllTags = inputAllTags.concat(v.tagsArray)))
  inputAllTags = uniq(inputAllTags).filter((el) => el)

  const totalExistingDuration =
    videos && videos.length
      ? Math.round(
          videos.reduce((acc, b) => {
            return acc + b.duration
          }, 0)
        )
      : 0
  const overLimit = getAllowedVideoDuration(user) ? getAllowedVideoDuration(user) <= totalExistingDuration : false

  const asyncFunc = async () => {
    refresh()
  }
  useEffect(() => {
    asyncFunc()
  }, [])

  useEffect(() => {
    setVideosToShow(
      search
        ? videosOrImages
            .filter((el) => el.name && el.name.toLowerCase().includes(search.toLowerCase()))
            .filter((v) => {
              if (!selectedTags || !selectedTags.length) {
                return true
              } else {
                return !!intersection(v.tagsArray || [], selectedTags).length
              }
            })
        : videosOrImages.filter((v) => {
            if (!selectedTags || !selectedTags.length) {
              return true
            } else {
              return !!intersection(v.tagsArray || [], selectedTags).length
            }
          })
    )
  }, [search, videos, images, selectedTags, activeTab])

  return (
    <Flex height="100vh">
      <Flex
        minWidth="400px"
        width="400px"
        flexDirection="column"
        pt="40px"
        borderRight="1px solid #A6B7D4;"
        height="100%"
        overflowY="scroll"
      >
        <Flex
          mb="16px"
          onClick={() => {
            if (searchParams.get('homeback')) {
              return navigate('/')
            }
            isCreate
              ? user.shopifyShopUrl
                ? setStep(STEPS.SHOPPABLE_SELECT)
                : setStep(STEPS.ON_SITE_OFF_SITE)
              : setStep(STEPS.VIDEO_APPEARANCE)
          }}
          cursor="pointer"
          width="auto"
          px="32px"
        >
          <Icon mt="4px" mr="16px" icon="chevron-left-lightlight" height="16px" width="16px" />
          <Text lightLight fontSize="14px" mb="16px" textAlign="left">
            Back
          </Text>
        </Flex>

        <Flex
          borderBottom="1px solid rgba(237, 242, 247, 1)"
          borderTop="1px solid rgba(237, 242, 247, 1)"
          pt="16px"
          pb="16px"
          mb="16px"
          px="32px"
          flexDirection="column"
        >
          <Flex alignItems="center" mb="4px" justifyContent="space-between">
            <Label mb="0px" mr="16px">
              Randomise Order
            </Label>
            <ToggleRedGreen
              setSelected={(selected) => {
                setFieldValue('randomContentOrder', selected)
              }}
              value1={false}
              value2={true}
              selected={values.randomContentOrder}
            />
          </Flex>
          <Text light fontWeight="400" fontSize="11px">
            Content will change order every load
          </Text>
        </Flex>
        <Flex px="32px" flexDirection="column">
          <VideoPills isVideosTab={isVideosTab} videosOrImages={selectedVideos} setFieldValue={setFieldValue} values={values} />
        </Flex>
      </Flex>
      <Flex flexDirection="column" height="100%">
        {videos ? (
          <>
            <Flex px="24px" mb="24px" flexDirection="column" borderBottom="1px solid rgba(237, 242, 247, 1)">
              <Flex mb="16px" height="60px" minHeight="60px" alignItems="center" justifyContent="space-between">
                <Text fontSize="20px" fontWeight="600">
                  Select content to add to your campaign
                </Text>
                <Flex width="auto" alignItems="center">
                  {values.videoIds && values.videoIds.length ? (
                    <Text bold mr="16px" light fontWeight="500">
                      ({values.videoIds.length} selected)
                    </Text>
                  ) : null}
                  <Button
                    isDisabled={!values.videoIds || !values.videoIds.length}
                    variant="black"
                    label={isCreate ? 'Create Campaign' : 'Next'}
                    onClick={() =>
                      isCreate
                        ? submit(values.isShareCampaign ? STEPS.VIDEO_APPEARANCE : STEPS.DISPLAY_TYPE)
                        : submit(STEPS.VIDEO_APPEARANCE)
                    }
                  />
                </Flex>
              </Flex>
              <Flex position="relative" width="auto">
                <Tab onClick={() => setActiveTab(TABS.VIDEOS)} title="Videos" isActive={activeTab === TABS.VIDEOS} />
                <Tab onClick={() => setActiveTab(TABS.IMAGES)} title="Images" isActive={activeTab === TABS.IMAGES} />
              </Flex>
            </Flex>
            {manuallyAddVideo ? (
              isVideosTab ? (
                <Flex flexDirection="column" overflowY="scroll">
                  <VideoUpload
                    closeModal={() => {
                      asyncFunc()
                      setManuallyAddVideo(false)
                    }}
                    isModal
                  />
                  <Box pb="100px"></Box>
                </Flex>
              ) : (
                <Flex flexDirection="column" overflowY="scroll">
                  <ImageUpload
                    closeModal={() => {
                      asyncFunc()
                      setManuallyAddVideo(false)
                    }}
                    isModal
                  />
                  <Box pb="100px"></Box>
                </Flex>
              )
            ) : (
              <>
                <Flex mb="24px" alignItems="center" justifyContent="center" px="24px">
                  <Input
                    border="0.5px solid #A6B7D4;"
                    placeholder="Search"
                    width="100%"
                    mr="16px"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Button
                    variant="grey"
                    label="Clear"
                    onClick={() => {
                      setSearch('')
                    }}
                  />
                </Flex>
                <Flex mb="16px" px="24px" flexWrap="wrap">
                  {selectedTags.map((tag) => (
                    <Flex
                      alignItems="center"
                      bg="rgba(166, 183, 212, 1)"
                      width="auto"
                      height="40px"
                      mr="8px"
                      mb="8px"
                      borderRadius="20px"
                      px="16px"
                      key={tag}
                      onClick={() => {
                        setSelectedTags(selectedTags.filter((el) => el !== tag))
                      }}
                      cursor="pointer"
                    >
                      <Text mb="2px" mr="8px" color="white" fontWeight="500">
                        {tag}
                      </Text>
                      <Icon width="14px" height="14px" icon="circle-cross-white" />
                    </Flex>
                  ))}
                  {selectedTags && selectedTags.length ? <VerticalDivider /> : null}
                  {difference(inputAllTags, selectedTags).map((tag) => (
                    <Flex
                      alignItems="center"
                      bg="rgba(225, 232, 255, 1)"
                      width="auto"
                      height="40px"
                      mr="8px"
                      mb="8px"
                      borderRadius="20px"
                      px="16px"
                      key={tag}
                      onClick={() => {
                        setSelectedTags(selectedTags.concat(tag))
                      }}
                      cursor="pointer"
                    >
                      <Text whiteSpace="nowrap" mb="2px" light fontWeight="500">
                        {tag}
                      </Text>
                      {/* <Icon width="14px" height="14px" icon="circle-cross-grey" /> */}
                    </Flex>
                  ))}
                </Flex>
                <Flex height="100%" overflowY="scroll" pt="8px">
                  <Grid height="100%" px="24px" pb="16px">
                    {/* TWO OF THESE  */}
                    {activeTab !== TABS.IMAGES ? (
                      <>
                        {overLimit ? (
                          <Container alignItems="center" justifyContent="space-between" mr="30px" py="30px" px="24px">
                            <Icon mt="32px" width="32px" height="32px" icon="unlock-purple-gradient" />
                            <Flex flexDirection="column">
                              <Text textAlign="center" fontSize="16px" fontWeight="600" mb="16px" light>
                                You’ve reached the {getAllowedVideoDuration(user) / 60} minutes limit
                              </Text>
                              <Text textAlign="center" fontSize="14px" light>
                                The {getPlanName(user)} plan has a limit of {getAllowedVideoDuration(user) / 60} minutes of
                                storage. Upgrade to unlock more videos.{' '}
                              </Text>
                            </Flex>
                            <Link to="/account?tab=BILLING">
                              <Button
                                renderLeftIcon={() => <Icon width={11} height={16} icon="lightening-white" />}
                                variant="black"
                                label="Upgrade"
                              />
                            </Link>
                          </Container>
                        ) : (
                          <Container
                            cursor="pointer"
                            alignItems="center"
                            justifyContent="center"
                            bg="#E4ECF7"
                            mr="30px"
                            py="16px"
                            px="16px"
                            height="350px"
                            onClick={() => setManuallyAddVideo(true)}
                          >
                            <Icon mb="8px" width="20px" height="20px" icon="cross-dark-grey" />
                            <Text fontSize="16px" fontWeight="600" color="#8492A6">
                              Add a video
                            </Text>
                          </Container>
                        )}
                      </>
                    ) : null}
                    {activeTab === TABS.IMAGES ? (
                      <Container
                        cursor="pointer"
                        alignItems="center"
                        justifyContent="center"
                        bg="#E4ECF7"
                        mr="30px"
                        py="16px"
                        px="16px"
                        height="350px"
                        onClick={() => setManuallyAddVideo(true)}
                      >
                        <Icon mb="8px" width="20px" height="20px" icon="cross-dark-grey" />
                        <Text fontSize="16px" fontWeight="600" color="#8492A6">
                          Upload an image
                        </Text>
                      </Container>
                    ) : null}

                    <VideoDisplay
                      isVideosTab={isVideosTab}
                      videoIds={values.videoIds}
                      videosToShow={videosToShow}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </Flex>
              </>
            )}
          </>
        ) : (
          <BigDarkSpinner />
        )}
        {/* {videos && !videos.length ? (
          <Flex flexDirection="row">
            <Flex
              mb="24px"
              justifyContent="center"
              alignItems="center"
              bg={colors.softBlue}
              width="124px"
              height="124px"
              borderRadius="124px"
            >
              <img width="56px" height="56px" src="/wavingEmoji.png" />
            </Flex>
            <H3 textAlign="center">Upload your first video</H3>
            <Text mb="24px" textAlign="center">
              Looks like you don’t have any videos yet. Click here to upload your first video
            </Text>
            <Link to="/videos/upload">
              <Button width="auto" variant="black" label="Create" />
            </Link>
          </Flex>
        ) : null} */}
      </Flex>
    </Flex>
  )
}

export default SelectVideo
