import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize } from '../../ui/helpers.js'
import { H1, H2, H3, H6, Label, Text, Span, GradientFont, Error, SmallTitle } from '../../ui/Typography.js'
import { Flex, Box, Container } from '../../ui/Layout.js'
import Image from '../../ui/Image.js'
import { Input, TextArea } from '../../ui/Input.js'
import Icon from '../../ui/Icon.js'
import WidgetPreview from '../../Components/WidgetPreview.js'
import Button from '../../ui/Button.js'
import { BigDarkSpinner } from '../../ui/Spinner.js'
import Toggle from '../../ui/Toggle.js'
import Select from '../../ui/Select.js'
import WidgetOptions from './WidgetOptions.js'
import ColorPicker from '../../ui/ColorPicker.js'
import formikFormWrapper from '../../formikFormWrapper.js'
import { Tabs } from '../CampaignEdit.js'
import { toast } from 'react-toastify'
import { useModal } from '../../Modal.js'
import ModalContainer from '../../ui/ModalContainer.js'

const FormInput = formikFormWrapper(Input)

import api from '../../api.js'
import { useAuth } from '../../Auth.js'

import {
  STEPS,
  CAMPAIGN_RULE_TYPES,
  CAMPAIGN_STATUS,
  campaignRuleTypesOptions,
  WIDGET_POSITIONS,
  widgetPositionOptions,
  widgetRuleTypeOptions,
  WIDGET_TYPES,
  RULE_TYPES,
  widgetTypeDisplayName
} from '../types'
import { useVideos } from '../../VideoProvider.js'

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && 'border-bottom: 1px solid black;'}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`

const Tab = ({ onClick, title, isActive }) => {
  return (
    <TabText onClick={onClick} isActive={isActive}>
      {title}
    </TabText>
  )
}

const EditWidget = ({
  step,
  setStep,
  selectedVideos,
  setFieldValue,
  values,
  openFullscreenDesktopPreview,
  widgets,
  setWidgets,
  editType,
  submit: campaignEditSubmit,
  validateForm,
  videoObjects,
  showCampaignRulesModal,
  setShowCampaignRulesModal,
  setShowNonShopifyInstallModal,
  showUltimateInstallModal,
  setShowUltimateInstallModal,
  setShowCampaignNameModal
}) => {
  const [search, setSearch] = useSearchParams()
  const { setShowInstallEmbedModal } = useModal()
  const { videos, setVideos, images, refresh } = useVideos()
  const navigate = useNavigate()
  const [isMobile, setIsMobile] = useState(false)
  const [showWidgetNameModal, setShowWidgetNameModal] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [promotedTagsOpen, setPromotedTagsOpen] = useState(false)
  const [filterTagsOpen, setFilterTagsOpen] = useState(false)

  let { user, setUser } = useAuth()
  useEffect(() => {
    setFieldValue('widget.type', editType)
    setFieldValue('type', editType)
  }, [editType])

  useEffect(() => {
    if (!values.widget.name && search.get('isNew')) {
      setShowWidgetNameModal(true)
    }
    if (!values.widget.name && search.get('step') === STEPS.EDIT_SEARCH) {
      setShowWidgetNameModal(true)
    }
    if (search.get('step') === STEPS.EDIT_SEARCH) {
      setFieldValue('showInstallModal', true)
    }
  }, [])

  useEffect(() => {
    if (!search.get('widgetId') && !search.get('isNew') && values.widgetId) {
      setSearch({ step: search.get('step'), widgetId: values.widgetId })
    }
  }, [])

  useEffect(() => {
    if (search.get('widgetId')) {
      const asyncFunc = async () => {
        const getWidgetsRes = await api.get(`/campaign/widget/get`, { params: { id: search.get('widgetId') } })

        if (getWidgetsRes) {
          setFieldValue('widget', getWidgetsRes.data.payload[0])
        }
      }
      asyncFunc()
    }
  }, [search.get('widgetId')])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const submit = async (newStep, noNavigation) => {
    if (isSubmitting) {
      return
    }
    try {
      setIsSubmitting(true)
      if (!values.widget.name) {
        values.widget.name = `Widget - ${widgets.length + 1}`
      }
      const createWidgetsRes = await api.post(`/campaign/widget/create`, values.widget)
      setFieldValue('widgetId', createWidgetsRes.data.payload.id)
      values.widgetId = createWidgetsRes.data.payload.id
      if (searchParams.has('isNew')) {
        setSearch(`step=EDIT_EMBED&widgetId=${values.widgetId}`)
      }
      const getWidgetsRes = await api.get(`/campaign/widget/get`)
      setWidgets(getWidgetsRes.data.payload)
      await campaignEditSubmit(newStep, noNavigation)
      if (newStep) {
        setStep(newStep)
        return
      }
      if (!noNavigation) {
        setStep(STEPS.CAMPAIGN_TYPE)
      }
    } catch (e) {
      console.log(e)
      toast('Widget updated failed')
    } finally {
      setIsSubmitting(false)
    }
  }
  return (
    <>
      <Flex bg="white">
        <Flex
          flexDirection="column"
          borderRight="1px solid #A6B7D4;"
          minHeight="100vh"
          maxHeight="100vh"
          height="100%"
          width="400px"
          minWidth="400px"
          py="24px"
          overflowY="scroll"
        >
          <Flex
            onClick={() => {
              submit(undefined, true)
              setStep(STEPS.CAMPAIGN_TYPE)
            }}
            cursor="pointer"
            width="auto"
            px="24px"
          >
            <Icon mt="4px" mr="16px" icon="chevron-left-lightlight" height="16px" width="16px" />
            <Text lightLight fontSize="14px" mb="16px" textAlign="left">
              Back to Campaign
            </Text>
          </Flex>

          <Flex borderBottom="1px solid #EDF2F7" px="24px" pt="16px">
            {values.type !== WIDGET_TYPES.SEARCH ? (
              <>
                <Tab
                  title="Content & Interactions"
                  isActive={false}
                  onClick={() => {
                    submit(STEPS.VIDEO_APPEARANCE)
                  }}
                />
                <Tab title="Designer" isActive={true} />
              </>
            ) : (
              <Tab title="Settings" isActive={true} />
            )}
          </Flex>
          <WidgetOptions
            isMobile={isMobile}
            setIsMobile={setIsMobile}
            setStep={setStep}
            widgets={widgets}
            selectedVideos={selectedVideos}
            setFieldValue={setFieldValue}
            values={values}
            widget={values.widget}
            promotedTagsOpen={promotedTagsOpen}
            setPromotedTagsOpen={setPromotedTagsOpen}
            filterTagsOpen={filterTagsOpen}
            setFilterTagsOpen={setFilterTagsOpen}
            videos={videos}
            images={images}
            refresh={refresh}
          />
        </Flex>
        <Flex
          justifyContent="center"
          alignItems="center"
          height="100vh"
          flexDirection="column"
          position="relative"
          width="calc(100vw - 400px);"
        >
          <Flex
            height="60px"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
            alignItems="center"
            justifyContent="flex-end"
            pr="24px"
          >
            <Flex pl="24px">
              <Flex
                width="auto"
                mr="8px"
                alignItems="center"
                onClick={() => {
                  setShowCampaignNameModal(true)
                }}
                cursor="pointer"
                borderRight="1.9px solid #425466"
              >
                <Text light fontSize="16px" fontWeight="500" mr="8px" maxWidth="300px">
                  {values.name || 'Add campaign name'}
                </Text>
              </Flex>
              {values.widget.name ? (
                <Flex width="auto" alignItems="center" onClick={() => setShowWidgetNameModal(true)} cursor="pointer" mr="24px">
                  <Text lightLight fontSize="16px" fontWeight="500" mr="16px">
                    {values.widget.name}
                  </Text>
                </Flex>
              ) : null}
            </Flex>

            {values.widget &&
            values.widget.type !== WIDGET_TYPES.FLOATING &&
            values.widget.type !== WIDGET_TYPES.SINGLE_EMBED &&
            values.widget.type !== WIDGET_TYPES.INLINE_SINGLE ? (
              <>
                <Button
                  renderCenterIcon={() => <Icon width={12} height={12} icon="desktop-black" />}
                  variant={isMobile ? 'grey' : 'white'}
                  shadow
                  onClick={() => {
                    setIsMobile(false)
                  }}
                  zIndex={1}
                  width="52px"
                  minWidth="52px"
                  mr="8px"
                />
                <Button
                  renderCenterIcon={() => <Icon width={12} height={12} icon="phone-black" />}
                  variant={!isMobile ? 'grey' : 'white'}
                  shadow
                  onClick={() => {
                    setIsMobile(true)
                  }}
                  zIndex={1}
                  width="52px"
                  minWidth="52px"
                  mr="16px"
                />
              </>
            ) : null}
            <Button
              mr="16px"
              onClick={() => {
                submit(undefined, true)
                setShowUltimateInstallModal(true)
              }}
              variant="grey"
              label="Install"
              zIndex={1}
            />
            <Button
              zIndex={2}
              isLoading={isSubmitting}
              onClick={async () => {
                await submit(undefined, true)
                if (values.showInstallModal) {
                  setShowUltimateInstallModal(true)
                  setFieldValue('showInstallModal', false)
                }
                toast('Campaign saved')
              }}
              variant="black"
              label="Save"
            />
          </Flex>

          <WidgetPreview
            isMobile={isMobile}
            setIsMobile={setIsMobile}
            openFullscreenDesktopPreview={openFullscreenDesktopPreview}
            widget={values.widget}
            videos={selectedVideos}
            campaign={{ ...values, videoObjects }}
          />
        </Flex>
      </Flex>
      <ModalContainer
        interiorClose
        autoHeight
        p="24px"
        width="400px"
        isOpen={showWidgetNameModal}
        setIsOpen={setShowWidgetNameModal}
      >
        <H3 width="100%">Give your widget a name</H3>
        <Field mb="24px" maxLength={65} name="widget.name" label="Widget Name" dark component={FormInput} />
        <Flex justifyContent="flex-end">
          <Button
            onClick={() => {
              submit(undefined, true)
              setShowWidgetNameModal(false)
            }}
            variant="black"
            label="Next"
          />
        </Flex>
      </ModalContainer>
    </>
  )
}

export default EditWidget
