import React, { useState } from 'react'
import styled from 'styled-components'
import { colors, fontFamily } from './helpers'
import { Box, Flex } from './Layout.js'
import { Text, Label } from './Typography'
import Spinner from './Spinner'
import Select from 'react-select'

import {
  space,
  width,
  color,
  flex,
  order,
  fontSize,
  alignSelf,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  borders,
  minWidth,
  minHeight,
  borderRadius,
  size,
  height,
  maxWidth,
  textAlign,
  boxShadow,
  borderColor,
  position,
  display,
  top,
  bottom,
  left,
  right,
  zIndex
} from 'styled-system'

const Styler = styled(Flex)`
  .react-select {
    width: 100%;
    font-family: ${fontFamily};
    box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
    border: none;
    font-size: 14px;
  }
  div[id*='listbox'] {
    width: 99.6%;
    margin-left: 0.2%;
  }
  cursor: pointer !important;
`

const SelectComponent = ({ options, value, onChange, placeholder, isMulti, clearable, label, fullWidth, ...rest }) => {
  return (
    <Flex height="auto" width={fullWidth ? '100%' : 'auto'} flexDirection="column">
      {label ? <Label>{label}</Label> : null}
      <Styler {...rest}>
        <Select
          isMulti={isMulti}
          placeholder={placeholder}
          className="react-select"
          value={options.find((el) => el.value === value)}
          onChange={onChange}
          clearable={clearable}
          options={options}
        />
      </Styler>
    </Flex>
  )
}

export default SelectComponent

export const SideSelect = (props) => {
  return (
    <Flex
      py="16px"
      borderBottom={props.noBorder ? '' : '1px solid rgba(237, 242, 247, 1)'}
      px="24px"
      alignItems="center"
      justifyContent="space-between"
    >
      <Label mb="0">{props.label}</Label>
      <SelectComponent {...props} width="150px" label={undefined} />
    </Flex>
  )
}
