import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, formatSeconds } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import SideNav, { TABS } from '../Components/SideNav.js'
import VideoPlayer from '../Components/VideoPlayer'
import ProcessingPreview from '../Components/ProcessingPreview'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import ChatButton from '../Components/ChatButton'
import { useAuth } from '../Auth.js'
import { useVideos } from '../VideoProvider.js'
import OptionsPopover from './OptionsPopover'
import TagsModal from './TagsModal'
import ModalContainer from '../ui/ModalContainer.js'
import { uniq, difference, intersection } from 'ramda'
import { getAllowedVideoDuration, getNumberOfAllowedVideos, getPlanName, hasHitVideoLimit, isFreePlan } from '../Plans.js'
import Tooltip from '../ui/Tooltip.js'
import ReactTooltip from 'react-tooltip'
import VideoSearch from './VideoSearch.js'
import { useModal } from '../Modal.js'

const ImageContainer = styled(Flex)`
  border-radius: 8px 8px 0px 0px;
`

const VideoImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: 100%;
`

const Grid = styled(Flex)`
  display: grid;
  grid-gap: 22px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  @media (min-width: 1800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 2200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

const Gradient = styled(Flex)`
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 80%, rgba(0, 0, 0, 0.3) 100%);
`

const GradientReverse = styled(Flex)`
  border-radius: 8px 8px 0px 0px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 80%, rgba(0, 0, 0, 0.3) 100%);
`

const ContainerWithHover = styled(Container)`
  box-shadow: none;
  background-color: transparent;
  border: ${(props) => (props.isSelected ? `4px solid rgba(120, 149, 255, 1)` : '4px solid #F5F8FA')};
  a {
    height: 100%;
  }
  &&:hover {
    .hover {
      visibility: visible;
    }
  }
  .hover {
    visibility: hidden;
  }
`

const SelectedContainer = styled(Flex)`
  z-index: 2;
  background-color: #111111;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 700px;
  position: fixed;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  ${(props) => (props.overLimits ? `background: linear-gradient(225deg, #BB65FF 0%, #4C6FFF 100%);` : '')}
`

export const EmptyState = (props) => {
  return (
    <Flex justifyContent="center" pt="24px">
      <Container alignItems="center" width="380px">
        <Flex
          mb="24px"
          justifyContent="center"
          alignItems="center"
          bg={colors.softBlue}
          width="124px"
          height="124px"
          borderRadius="124px"
        >
          <Icon width="60px" height="60px" icon="editor-purple" />
        </Flex>
        <H3 textAlign="center">Upload a video</H3>
        <Text mb="24px" textAlign="center">
          All the videos you add in your campaigns can be found here.
        </Text>
        <Link to="/videos/upload">
          <Button width="auto" variant="black" label="Upload" />
        </Link>
      </Container>
    </Flex>
  )
}

const VIDEO_TABS = {
  INDEX: 'INDEX',
  UNPUBLISHED: 'UNPUBLISHED'
}

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && 'border-bottom: 1px solid black;'}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`

const Tab = ({ onClick, title, isActive }) => {
  return (
    <TabText onClick={onClick} isActive={isActive}>
      {title}
    </TabText>
  )
}

const PAGESIZE = 200

const Videos = (props) => {
  let { user } = useAuth()
  let navigate = useNavigate()
  ReactTooltip.rebuild()

  const { videos, setVideos, refresh, images } = useVideos()
  const [activeTab, setActiveTabState] = useState(VIDEO_TABS.INDEX)

  const [page, setPage] = useState(1)

  const [hasLoaded, setHasLoaded] = useState(false)
  const [showTagsModal, setShowTagsModal] = useState(false)
  const [tagsModalId, setTagsModalId] = useState()
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedVideoIds, setSelectedVideoIds] = useState([])
  const [searchTerm, setSearchTerm] = useState()
  const { setVideoPopoverId, setShowVideoPopover } = useModal()

  const setActiveTab = (x) => {
    setPage(1)
    setSelectedTags([])
    setSelectedVideoIds([])
    setActiveTabState(x)
    setSearchTerm('')
  }

  const totalExistingDuration =
    videos && videos.length
      ? Math.round(
          videos.reduce((acc, b) => {
            return acc + b.duration
          }, 0)
        )
      : 0
  const overLimit = getAllowedVideoDuration(user) ? getAllowedVideoDuration(user) <= totalExistingDuration : false

  let inputAllTags = []
  videos.forEach((v) => (inputAllTags = inputAllTags.concat(v.tagsArray)))
  images.forEach((v) => (inputAllTags = inputAllTags.concat(v.tagsArray)))
  inputAllTags = uniq(inputAllTags.filter((el) => el))

  useEffect(() => {
    const func = async () => {
      await refresh()
      setHasLoaded(true)
    }
    func()
  }, [])

  const videosToShow = videos
    .filter((v) => {
      if (searchTerm) {
        try {
          return v.name && v.name.toLowerCase().includes(searchTerm.toLowerCase())
        } catch (e) {
          console.log(e)
        }
      }
      return true
    })
    .filter((v) => {
      if (activeTab === VIDEO_TABS.UNPUBLISHED) {
        return v.unpublished
      } else {
        return !v.unpublished
      }
    })
    .filter((v) => {
      if (!selectedTags || !selectedTags.length) {
        return true
      } else {
        return !!intersection(v.tagsArray || [], selectedTags).length
      }
    })

  const unpublish = async () => {
    const res = await api.post('/video/unpublish', {
      ids: selectedVideoIds,
      unpublish: activeTab !== VIDEO_TABS.UNPUBLISHED
    })
    refresh()
    setSelectedVideoIds([])
  }

  const selectAll = () => {
    setSelectedVideoIds(videosToShow.map((el) => el.id))
  }

  return (
    <Flex>
      <Flex position="relative" flexDirection="column" width="100%" p="30px">
        <Flex justifyContent="space-between">
          <H1 mb="32px" width="auto">
            Video
          </H1>
          <Flex width="auto">
            <ChatButton showCallButton />
            <Link to="/campaigns/create?step=ON_SITE_OFF_SITE">
              <Button
                renderLeftIcon={() => <Icon width={12} height={12} icon="plus-white" />}
                mb="8px"
                variant="black"
                label="New campaign"
              />
            </Link>
          </Flex>
        </Flex>
        <Flex borderBottom="1px solid #EEF2F7" mb="32px">
          <Tab onClick={() => setActiveTab(VIDEO_TABS.INDEX)} title="All videos" isActive={activeTab === VIDEO_TABS.INDEX} />
          {user.isContentCreator ? (
            <Tab
              onClick={() => setActiveTab(VIDEO_TABS.UNPUBLISHED)}
              title="Unpublished"
              isActive={activeTab === VIDEO_TABS.UNPUBLISHED}
            />
          ) : null}
        </Flex>
        <VideoSearch
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          selectedTags={selectedTags}
          setPage={setPage}
          setSelectedTags={setSelectedTags}
          selectAll={selectAll}
        />
        <Flex justifyContent="space-between" alignItems="flex-end" mb="16px">
          <Flex width="auto" alignItems="center">
            <Text light fontWeight="600" mb="0px" mr="8px" cursor="pointer" onClick={selectAll}>
              Select all
            </Text>
            {activeTab === VIDEO_TABS.UNPUBLISHED ? (
              <Tooltip tooltip={`Unpublished videos won't appear in search widgets but can be used in all other widget types.`} />
            ) : null}
          </Flex>
          {getAllowedVideoDuration(user) ? (
            <Flex width="auto" alignItems="center">
              <Text light fontWeight="500">
                {Math.round(totalExistingDuration / 60)}/{getAllowedVideoDuration(user) / 60} minutes
              </Text>
              {isFreePlan(user) || totalExistingDuration / getAllowedVideoDuration(user) > 0.8 ? (
                <Link to="/account?tab=BILLING">
                  <Button
                    ml="16px"
                    width="111px"
                    renderLeftIcon={() => <Icon width={11} height={16} icon="lightening-white" />}
                    variant="black"
                    label="Upgrade"
                  />
                </Link>
              ) : null}
            </Flex>
          ) : null}
        </Flex>
        {!hasLoaded && !videos.length ? (
          <Flex justifyContent="center" pt="200px">
            <BigDarkSpinner />
          </Flex>
        ) : null}
        {hasLoaded && !videos.length ? <EmptyState /> : null}
        <Grid>
          {/* TWO OF THESE  */}
          {videos.length ? (
            !overLimit ? (
              <Link to={`/videos/upload${activeTab === VIDEO_TABS.UNPUBLISHED ? '?unpublished=true' : ''}`}>
                <Container alignItems="center" justifyContent="center" bg="#E4ECF7" mr="30px" py="30px" px="24px" height="400px">
                  <Icon mb="8px" width="20px" height="20px" icon="cross-dark-grey" />
                  <Text fontSize="16px" fontWeight="600" color="#8492A6">
                    Upload Video
                  </Text>
                </Container>
              </Link>
            ) : (
              <Container alignItems="center" justifyContent="space-between" mr="30px" py="30px" px="24px" height="400px">
                <Icon mt="32px" width="32px" height="32px" icon="unlock-purple-gradient" />
                <Flex flexDirection="column">
                  <Text textAlign="center" fontSize="16px" fontWeight="600" mb="16px" light>
                    You’ve reached the {getAllowedVideoDuration(user) / 60} minutes limit
                  </Text>
                  <Text textAlign="center" fontSize="14px" light>
                    The {getPlanName(user)} plan has a limit of {getAllowedVideoDuration(user) / 60} minutes of storage. Upgrade
                    to unlock more videos.
                  </Text>
                </Flex>
                <Link to="/account?tab=BILLING">
                  <Button
                    renderLeftIcon={() => <Icon width={11} height={16} icon="lightening-white" />}
                    variant="black"
                    label="Upgrade"
                  />
                </Link>
              </Container>
            )
          ) : null}
          {videosToShow.slice((page - 1) * PAGESIZE, page * PAGESIZE - 1).map((video) => {
            const isSelected = selectedVideoIds.includes(video.id)
            const select = (e) => {
              e.stopPropagation()
              if (isSelected) {
                setSelectedVideoIds(selectedVideoIds.filter((id) => id !== video.id))
              } else {
                setSelectedVideoIds([video.id, ...selectedVideoIds])
              }
            }
            return (
              <ContainerWithHover
                key={video.id}
                mr="30px"
                p="0px"
                height="400px"
                justifyContent="space-between"
                position="relative"
                overflowX="hidden"
                isSelected={isSelected}
              >
                {!isSelected || (selectedVideoIds && selectedVideoIds.length) ? (
                  <Flex
                    className={selectedVideoIds && selectedVideoIds.length ? '' : 'hover'}
                    cursor="pointer"
                    onClick={select}
                    position="absolute"
                    left="0px"
                    top="0px"
                    width="52px"
                    height="52px"
                    zIndex={1}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Icon icon="checkbox-unticked-white" width={20} height={20} />
                  </Flex>
                ) : null}
                {isSelected ? (
                  <Flex
                    cursor="pointer"
                    onClick={select}
                    position="absolute"
                    left="0px"
                    top="0px"
                    width="52px"
                    height="52px"
                    zIndex={1}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Icon icon="checkbox-ticked-blue" width={20} height={20} />
                  </Flex>
                ) : null}
                <Flex
                  flexDirection="column"
                  height="100%"
                  boxShadow={'0px 0px 1px rgba(12, 26, 75, 0.3), 0px 4px 20px -2px rgba(50, 50, 71, 0.24)'}
                >
                  <Flex
                    cursor="pointer"
                    onClick={() => {
                      if (selectedVideoIds && selectedVideoIds.length) {
                        if (isSelected) {
                          setSelectedVideoIds(selectedVideoIds.filter((id) => id !== video.id))
                        } else {
                          setSelectedVideoIds([video.id, ...selectedVideoIds])
                        }
                        return
                      }
                      setVideoPopoverId(video.id)
                      setShowVideoPopover(true)
                    }}
                    noverflow="hidden"
                    height="calc(100% - 100px)"
                    position="relative"
                  >
                    {video.playbackId ? (
                      <ImageContainer>
                        <VideoImage
                          loading="lazy"
                          src={
                            video.customThumbnail
                              ? video.customThumbnail + `-/scale_crop/${500}x${500}/center/`
                              : `https://image.mux.com/${video.playbackId}/thumbnail.jpg?time=${video.thumbnailTime}&width=700&height=700`
                          }
                        />
                      </ImageContainer>
                    ) : (
                      <ProcessingPreview />
                    )}
                    {video.unpublished && activeTab !== VIDEO_TABS.UNPUBLISHED ? (
                      <Box position="absolute" top="8px" right={'8px'} bg="white" borderRadius="20px" px="8px">
                        <Text color="black" fontSize="12px" fontWeight="600">
                          Unpublished
                        </Text>
                      </Box>
                    ) : null}
                    <Gradient position="absolute" top="0px" left="0px" height="100%" />
                    <Box position="absolute" bottom="8px" right={'8px'}>
                      <Text color="white" fontSize="12px" fontWeight="600">
                        {video.duration && formatSeconds(video.duration)}
                      </Text>
                    </Box>
                    <Box position="absolute" bottom="8px" left={'8px'}>
                      <Text color="white" fontSize="12px" fontWeight="600">
                        {dayjs(video.createdAt).format('DD MMM')}
                      </Text>
                    </Box>
                    <GradientReverse className="hover" position="absolute" top="0px" left="0px" height="100%" />
                  </Flex>
                  <Flex
                    backgroundColor="white"
                    flexDirection="column"
                    py="8px"
                    pb={'16px'}
                    minHeight={'100px'}
                    height={'100px'}
                    justifyContent="space-between"
                  >
                    <H6 wordBreak="break-all" mb="0px" px={'8px'}>
                      {video.name && video.name.length > 50 ? video.name.slice(0, 50) + '...' : video.name}
                    </H6>
                    <Flex overflowX="scroll" pl={'8px'} noScrollBar>
                      {(video.tagsArray || []).map((tag) => {
                        return (
                          <Flex
                            alignItems="center"
                            bg="rgba(225, 232, 255, 1)"
                            width="auto"
                            height="28px"
                            mr="8px"
                            borderRadius="14px"
                            px="16px"
                            key={tag}
                            cursor="pointer"
                            onClick={(e) => {
                              e.stopPropagation()
                              setTagsModalId(video.id)
                              setShowTagsModal(true)
                            }}
                          >
                            <Text whiteSpace="nowrap" mb="2px" light fontWeight="500">
                              {tag}
                            </Text>
                          </Flex>
                        )
                      })}
                    </Flex>
                    {!video.tagsArray || !video.tagsArray.length ? (
                      <Flex cursor="pointer">
                        <Text
                          onClick={(e) => {
                            e.stopPropagation()
                            setTagsModalId(video.id)
                            setShowTagsModal(true)
                          }}
                          fontWeight="500"
                          light
                          ml="8px"
                        >
                          + Tag
                        </Text>
                      </Flex>
                    ) : null}
                  </Flex>
                </Flex>
                <Flex
                  className="hover"
                  position="absolute"
                  top="8px"
                  right="8px"
                  width="32px"
                  height="32px"
                  bg="white"
                  borderRadius="16px"
                  alignItems="center"
                  justifyContent="center"
                  onClick={(e) => e.stopPropagation()}
                >
                  <OptionsPopover
                    id={video.id}
                    refresh={refresh}
                    openTagsModal={() => {
                      setTagsModalId(video.id)
                      setShowTagsModal(true)
                    }}
                  />
                </Flex>
              </ContainerWithHover>
            )
          })}
        </Grid>
        {videosToShow?.length && videosToShow.length > PAGESIZE ? (
          <Flex mt="32px" mb="100px" justifyContent="space-between">
            <Text fontWeight="500" width="300px" light>
              Showing {(page - 1) * PAGESIZE + 1} -{' '}
              {page * PAGESIZE > videosToShow.length ? videosToShow.length : page * PAGESIZE} of {videosToShow.length}
            </Text>
            <Flex cursor="pointer" justifyContent="center">
              {page - 1 !== 0 ? (
                <Text
                  onClick={() => {
                    setPage(page - 1)
                  }}
                  light
                  fontWeight="500"
                  px="4px"
                >
                  {'<'}
                </Text>
              ) : null}
              {Array.from({ length: Math.ceil(videosToShow.length / PAGESIZE) }, (v, i) => i).map((i) => {
                return (
                  <Text
                    onClick={() => {
                      setPage(i + 1)
                    }}
                    light
                    key={i}
                    fontWeight={i === page - 1 ? '700' : '500'}
                    px="4px"
                  >
                    {i + 1}
                  </Text>
                )
              })}
              {page !== Math.ceil(videosToShow.length / PAGESIZE) ? (
                <Text
                  onClick={() => {
                    setPage(page + 1)
                  }}
                  light
                  fontWeight="500"
                  px="4px"
                >
                  {'>'}
                </Text>
              ) : null}
            </Flex>
            <Box width="300px" />
          </Flex>
        ) : null}
      </Flex>

      <TagsModal
        id={tagsModalId}
        ids={selectedVideoIds}
        inputTags={(videos.find((v) => v.id === tagsModalId) && videos.find((v) => v.id === tagsModalId).tagsArray) || []}
        inputAllTags={inputAllTags || []}
        isOpen={showTagsModal}
        setIsOpen={setShowTagsModal}
        refresh={refresh}
        setSelectedIds={setSelectedVideoIds}
      />
      {selectedVideoIds.length > 0 ? (
        <SelectedContainer>
          <Flex ml="24px">
            <Text fontWeight="500" color="white" mr="24px">
              {`${selectedVideoIds.length} selected`}
            </Text>
          </Flex>
          <Flex alignItems="center" width="auto">
            <Button
              mr="16px"
              renderLeftIcon={() => <Icon icon="plus-white" width="12px" height="12px" />}
              borderColor="white"
              variant="black"
              label="Add Tags"
              width="133px"
              small
              onClick={() => {
                setTagsModalId()
                setShowTagsModal(true)
              }}
              background="transparent"
            />
            {user.isContentCreator ? (
              <Button
                mr="16px"
                borderColor="white"
                variant="black"
                label={activeTab === VIDEO_TABS.UNPUBLISHED ? 'Publish' : 'Unpublish'}
                width="90px"
                small
                onClick={unpublish}
                background="transparent"
              />
            ) : null}
            <Text onClick={() => setSelectedVideoIds([])} cursor="pointer" mr="16px" fontWeight="500" color="white">
              Cancel
            </Text>
          </Flex>
        </SelectedContainer>
      ) : null}
    </Flex>
  )
}

export default Videos
