import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { colors, w } from '../ui/helpers.js'
import { H1, H2, Text, Span, GradientFont, Error } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Icon from '../ui/Icon.js'

import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Button from '../ui/Button.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import ChatButton from '../Components/ChatButton.js'
import ModalContainer from '../ui/ModalContainer.js'
import ShopifyConnectModal from '../Integrations/ShopifyConnectModal.js'
import CircleToggle from '../ui/CircleToggle.js'

const OPTIONS = {
  SHOPIFY: 'SHOPIFY',
  CUSTOM: 'CUSTOM',
  DOWNLOAD: 'DOWNLOAD'
}

const AccountType = (props) => {
  const { user, setUser } = useAuth()
  const [error, setError] = useState('')
  const [shopifyInstallOpen, setShopifyInstallOpen] = useState(false)
  const [optionToggled, setOptionToggled] = useState()

  let navigate = useNavigate()

  const goToBilling = async () => {
    if (optionToggled === OPTIONS.SHOPIFY) {
      if (user.shopifyShopUrl) {
        navigate('/billing')
      } else {
        setShopifyInstallOpen(true)
      }
      return
    }
    if (optionToggled === OPTIONS.CUSTOM) {
      return navigate('/billing')
    }
    if (optionToggled === OPTIONS.DOWNLOAD) {
      const res = await api.post('/auth/organisation/update', { isContentCreator: true })
      if (res.data.success) {
        const authRes = await api.get('/auth')
        setUser(authRes.data.payload)
        return navigate('/billing')
      }
    }
  }

  return (
    <Flex>
      <ChatButton topRightFixed />

      <Flex flexDirection="column" pt="76px" alignItems="center" width="350px" height="100vh" bg={colors.softBlue}>
        <Image mb="160px" src="/logo.svg" minWidth="186px" width={186} />
      </Flex>
      <Flex position="relative" flexDirection="column" alignItems="center" width="100%" py="76px">
        <H1 mb="16px" textAlign="center" width="100%" px="60px">
          How are you going to use Clipara?
        </H1>
        <Text fontSize="16px" fontWeight="400" mb="86px" light>
          We’ll change the experience to best suit your needs.
        </Text>
        <Flex
          mb="32px"
          width="auto"
          cursor="pointer"
          onClick={() => {
            setOptionToggled(OPTIONS.SHOPIFY)
          }}
        >
          <Box mt="4px" mr="32px">
            <CircleToggle width={20} toggled={optionToggled === OPTIONS.SHOPIFY} />
          </Box>
          <Flex flexDirection="column" width="350px" minWidth="350px">
            <Text fontWeight="500" fontSize="18px">
              For my Shopify store
            </Text>
            <Text fontWeight="400" light>
              Add video experiences and shoppable video to your Shopify store.
            </Text>
          </Flex>
          <Icon mt="18px" ml="42px" icon="shopify-black" width={32} height={32} />
        </Flex>
        <Flex
          mb="32px"
          width="auto"
          cursor="pointer"
          onClick={() => {
            setOptionToggled(OPTIONS.CUSTOM)
          }}
        >
          <Box mt="4px" mr="32px">
            <CircleToggle width={20} toggled={optionToggled === OPTIONS.CUSTOM} />
          </Box>
          <Flex flexDirection="column" width="350px" minWidth="350px">
            <Text fontWeight="500" fontSize="18px">
              Add videos to my website
            </Text>
            <Text fontWeight="400" light>
              Create and host video experiences on a no-code or custom website.
            </Text>
          </Flex>
          <Icon mt="18px" ml="42px" icon="code-slash-black" width={31} height={31} />
        </Flex>
        <Flex
          mb="48px"
          width="auto"
          cursor="pointer"
          onClick={() => {
            setOptionToggled(OPTIONS.DOWNLOAD)
          }}
        >
          <Box mt="4px" mr="32px">
            <CircleToggle width={20} toggled={optionToggled === OPTIONS.DOWNLOAD} />
          </Box>
          <Flex flexDirection="column" width="350px" minWidth="350px">
            <Text fontWeight="500" fontSize="18px">
              For downloadable content collections
            </Text>
            <Text fontWeight="400" light>
              Create and display collections of videos and images for your customers to download and use.
            </Text>
          </Flex>
          <Icon mt="18px" ml="42px" icon="download-black" width={32} height={32} />
        </Flex>
        <Button large isDisabled={!optionToggled} variant="black" label="Next" width="218px" mr="155px" onClick={goToBilling} />
        {/* <Flex mb="24px" justifyContent="center">
          <Link to="/billing">
            <ChoiceContainer>
              <ColorBox>
                <Icon icon="code-slash-white" width={31} height={31} />
              </ColorBox>
              <Text textAlign="center" fontSize="16px" fontWeight="600" mb="16px">
                I’m using Clipara with a website builder or custom site.
              </Text>
              <Text textAlign="center" fontSize="14px" fontWeight="400">
                We’ll show you relevant guides and tips for adding Clipara to your website.
              </Text>
            </ChoiceContainer>
          </Link>
          <ChoiceContainer onClick={goToBilling}>
            <ColorBox>
              <Icon icon="shopify-logo-white" width={76} height={22} />
            </ColorBox>
            <Text textAlign="center" fontSize="16px" fontWeight="600" mb="16px">
              I’m using Clipara with my Shopify store.
            </Text>
            <Text textAlign="center" fontSize="14px" fontWeight="400">
              You’ll be able to connect your Clipara directly with your Shopify store and products.
            </Text>
          </ChoiceContainer>
        </Flex> */}
      </Flex>
      <ShopifyConnectModal isOpen={shopifyInstallOpen} setIsOpen={setShopifyInstallOpen} />
    </Flex>
  )
}

export default AccountType
