import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout.js'
import { loadVideoLite, formatSeconds, loadVideoMP4, baseUrl } from './helpers.js'
import { useModal } from '../Modal.js'
import Icon from '../ui/Icon.js'
import { getAspectRatio } from './EmbedCarousel.js'

// SPECIFIC STUFF FOR THUMBNAILS
const ThumbnailContainer = styled(Flex)`
  .hover-text {
    display: none;
  }
  &&:hover {
    .hover-text {
      display: block;
    }
  }
`

const SlideItem = styled(Box)`
  @keyframes loading-gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: -100% 50%;
    }
    100% {
      background-position: -200% 50%;
    }
  }
  cursor: pointer;
  border-radius: ${(props) => `${props.borderRadius}px`};
  box-sizing: border-box;
`

const ImageIconContainer = styled(Flex)`
  position: absolute;
  right: 16px;
  bottom: 16px;
  width: 40px !important;
  height: 40px !important;
  border-radius: 40px !important;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  background-color: rgba(78, 78, 78, 0.4) !important;
`

const VideoImage = styled.img`
  box-sizing: border-box;
  border-radius: ${(props) => `${props.borderRadius}px`};
  object-fit: cover;
  width: 100% !important;
  height: 100% !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  ${(props) =>
    props.widgetThumbnailBorder
      ? `border: ${props.widgetThumbnailBorderSize || 3}px solid ${props.widgetThumbnailBorderColor || 'black'};`
      : ''}
  background: linear-gradient(to right, #e9e9e9, #f9f9f9, #e9e9e9);
  background: -webkit-linear-gradient(to right, #e9e9e9, #f9f9f9, #e9e9e9);
  animation: loading-gradient 4s linear infinite;
  background-size: 200%;
`

const VideoContainer = styled(Flex)`
  transition: opacity 0.5s;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`

const Video = styled.video`
  cursor: pointer !important;
  transition: opacity 0.1s ease-in-out;
  -moz-transition: opacity 0.1s ease-in-out;
  -webkit-transition: opacity 0.1s ease-in-out;
  box-sizing: border-box;
  border-radius: ${(props) => `${props.borderRadius}px`};
  width: ${(props) => props.videoWidth}px !important;
  height: ${(props) => props.videoHeight}px !important;
  object-fit: cover;
  z-index: 1;
  ${(props) =>
    props.isCircle && props.widgetThumbnailBorder && `border: 2.5px solid white; box-sizing: border-box; border-radius: 100%;`}
  &&::-webkit-media-controls {
    display: none !important;
  }
  /* Could Use thise as well for Individual Controls */
  &&::-webkit-media-controls-play-button {
    display: none !important;
  }
  &&::-webkit-media-controls-volume-slider {
    display: none !important;
  }
  &&::-webkit-media-controls-mute-button {
    display: none !important;
  }
  &&::-webkit-media-controls-timeline {
    display: none !important;
  }
  &&::-webkit-media-controls-current-time-display {
    display: none !important;
  }
  ${(props) =>
    props.widgetThumbnailBorder
      ? `border: ${props.widgetThumbnailBorderSize || 3}px solid ${props.widgetThumbnailBorderColor || 'black'};`
      : ''}
`

const VideoTitle = styled.p`
  font-family: ${(props) => (props.widgetTitleFontFamily ? props.widgetTitleFontFamily.replace(';') : 'inherit')} !important;
  font-weight: ${(props) => props.widgetTitleFontWeight || '700'} !important;
  font-size: ${(props) => props.widgetDesktopTitleFontSize || '18'}px !important;
  line-height: ${(props) => props.widgetDesktopTitleFontSize || '18'}px !important;
  text-align: ${(props) => (props.widgetTitleAlignment ? props.widgetTitleAlignment.toLowerCase() : 'center')} !important;
  color: ${(props) => props.widgetTitleFontColor || 'black'};
  text-decoration: none !important;
  margin-bottom: 16px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
`

const VideoSubtitle = styled.p`
  font-family: ${(props) =>
    props.widgetSubtitleFontFamily ? props.widgetSubtitleFontFamily.replace(';') : 'inherit'} !important;
  font-weight: ${(props) => props.widgetSubtitleFontWeight || '500'} !important;
  font-size: ${(props) => props.widgetDesktopSubtitleFontSize || '18'}px !important;
  line-height: ${(props) => props.widgetDesktopSubtitleFontSize || '18'}px !important;
  text-align: ${(props) => (props.widgetTitleAlignment ? props.widgetTitleAlignment.toLowerCase() : 'center')} !important;
  color: ${(props) => props.widgetSubtitleFontColor || 'black'};
  text-decoration: none !important;
  margin-bottom: 16px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
`

const TitleContainer = styled(Flex)`
  flex-direction: column;
`

const MuteContainer = styled(Flex)`
  width: 40px;
  height: 40px;
  position: absolute;
  right: 12px;
  bottom: 16px;
  z-index: 110001;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  background-color: rgba(78, 78, 78, 0.4) !important;
`

const PlayContainer = styled(MuteContainer)`
  right: 60px;
`

const Volume = styled.img`
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  object-fit: none;
  margin-left: ${(props) => props.ml || 0}px;
`

const Titles = ({
  isStories,
  videoWidth,
  gapWidth,
  titleVideoObject,
  widgetTitleAlignment,
  widgetDesktopTitleFontSize,
  widgetTitleFontColor,
  widgetTitleFontFamily,
  widgetTitleFontWeight,
  widgetSubtitleFontWeight,
  widgetSubtitleFontFamily,
  widgetSubtitleFontColor,
  widgetDesktopSubtitleFontSize,
  hideMarginLeft,
  hideMarginRight,
  isMobile,
  widgetMobileTitleFontSize,
  widgetMobileSubtitleFontSize
}) => {
  return (
    <TitleContainer
      maxWidth={videoWidth}
      mr={hideMarginRight ? 0 : `${gapWidth / 2}px`}
      ml={hideMarginLeft ? 0 : `${gapWidth / 2}px`}
      width="auto"
    >
      {titleVideoObject.title && (
        <VideoTitle
          widgetTitleAlignment={widgetTitleAlignment}
          widgetDesktopTitleFontSize={isMobile ? widgetMobileTitleFontSize : widgetDesktopTitleFontSize}
          widgetTitleFontColor={widgetTitleFontColor}
          widgetTitleFontFamily={widgetTitleFontFamily}
          widgetTitleFontWeight={widgetTitleFontWeight}
        >
          {titleVideoObject.title}
        </VideoTitle>
      )}
      {titleVideoObject.subtitle && !isStories && (
        <VideoSubtitle
          widgetTitleAlignment={widgetTitleAlignment}
          widgetDesktopSubtitleFontSize={isMobile ? widgetMobileSubtitleFontSize : widgetDesktopSubtitleFontSize}
          widgetSubtitleFontColor={widgetSubtitleFontColor}
          widgetSubtitleFontFamily={widgetSubtitleFontFamily}
          widgetSubtitleFontWeight={widgetSubtitleFontWeight}
        >
          {titleVideoObject.subtitle}
        </VideoSubtitle>
      )}
    </TitleContainer>
  )
}

const StyledPicture = styled.picture`
  display: block;
  height: 100% !important;
`

const StyledNextPicture = styled.picture`
  z-index: -1;
  height: 100% !important;
  position: absolute;
  left: 0;
  top: 0;
`
const PlayButton = styled.svg`
  display: block;
  -webkit-transform: translateZ(0);
  fill: none !important;
  width: ${(props) => props.width}px !important;
  height: ${(props) => props.height}px !important;
  position: relative;
  z-index: 2;

  border-radius: 60px;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const PlaybuttonContainer = styled.div`
  border-radius: 60px;
  @keyframes cliparapulse4 {
    0% {
      box-shadow: 0 0 0 0 ${(props) => props.playButtonColor};
    }
    100% {
      box-shadow: 0 0 0 8px #fbb32f01;
    }
  }
  display: block;
  -webkit-transform: translateZ(0);
  fill: none !important;
  position: absolute;
  width: ${(props) => props.width}px !important;
  height: ${(props) => props.height}px !important;
  ${(props) => props.animate && `animation: cliparapulse4 1500ms infinite;`}
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`

// SPECIFIC STUFF FOR DASH
const CursorDiv = styled.div`
  cursor: pointer !important;
  position: relative;
  &&:hover {
    .thumbnail-button {
      display: block;
    }
  }
`
const PlayButtonComponent = ({ campaign, pbWidth }) => {
  return (
    <PlaybuttonContainer
      animate={campaign.playButtonAnimation}
      playButtonColor={campaign.playButtonColor || '#333333'}
      width={pbWidth}
      height={pbWidth}
    >
      <PlayButton
        animate={campaign.playButtonAnimation}
        playButtonColor={campaign.playButtonColor || '#333333'}
        width={pbWidth}
        height={pbWidth}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {campaign.widgetPlayButtonStyle === 'FILLED' ? (
          <>
            <circle cx="30" cy="30" r="30" fill={campaign.playButtonColor || '#333333'} />
            <path
              d="M45 28.268C46.3333 29.0378 46.3333 30.9623 45 31.7321L24 43.8564C22.6667 44.6262 21 43.664 21 42.1244L21 17.8756C21 16.336 22.6667 15.3738 24 16.1436L45 28.268Z"
              fill="white"
            />
          </>
        ) : (
          <>
            <circle cx="30" cy="30" r="28.5" stroke={campaign.playButtonColor || '#333333'} stroke-width="3" />
            <path
              d="M46.1097 29.2683C47.4431 30.0381 47.4431 31.9626 46.1097 32.7324L24.9451 44.9518C23.6118 45.7216 21.9451 44.7594 21.9451 43.2198L21.9451 18.781C21.9451 17.2414 23.6118 16.2791 24.9451 17.0489L46.1097 29.2683Z"
              fill={campaign.playButtonColor || '#333333'}
            />
          </>
        )}
      </PlayButton>
    </PlaybuttonContainer>
  )
}

const InlineSlideItem = ({
  campaign,
  videoHeight,
  videoWidth,
  activeVideoIndex,
  videosToShow,
  i,
  video,
  videoObjects,
  borderRadius,
  isStories,
  isCircle,
  isMobile,
  isNavigation,
  noNextImage,
  isMuted,
  setIsMuted,
  isActive,
  isPaused,
  setIsPaused,
  setActive,
  hasStarted,
  setHasStarted
}) => {
  const {
    widgetTitleActive,
    widgetTitlePosition,
    widgetGapWidth,
    widgetThumbnailBorderSize,
    widgetThumbnailBorder,
    widgetThumbnailBorderColor,
    widgetAutoplay,
    videos
  } = campaign
  const [videoPlaying, setVideoPlaying] = useState(false)
  const isVideo = video.type === 'VIDEO'
  const showNextImage = i === Math.ceil(videosToShow + activeVideoIndex - 1) && !noNextImage
  const imageBoostFactor = 1.5
  const imageUrl = video.customThumbnail
    ? video.customThumbnail +
      `-/scale_crop/${Math.round(videoWidth * imageBoostFactor)}x${Math.round(videoHeight * imageBoostFactor)}/center/`
    : `https://thumbnails.getclipara.com/${video.playbackId}/thumbnail.png?time=${video.thumbnailTime || 0.0}&width=${Math.round(
        videoWidth * imageBoostFactor
      )}&height=${Math.round(videoHeight * imageBoostFactor)}&fit_mode=crop`
  const webpUrl = video.customThumbnail
    ? video.customThumbnail +
      `-/scale_crop/${Math.round(videoWidth * imageBoostFactor)}x${Math.round(
        videoHeight * imageBoostFactor
      )}/center/-/format/webp/`
    : imageUrl.replace('.png', '.webp')

  // get next image url for lazy load
  let nextImageUrl, nextWebpUrl, nextImageIsVideo, nextImageImageUrl
  if (videos[i + 1]) {
    nextImageIsVideo = videos[i + 1].type === 'VIDEO'
    if (!nextImageIsVideo) {
      nextImageImageUrl = `https://clipara.b-cdn.net/${videos[i + 1].path}?aspect_ratio=${getAspectRatio(
        campaign.widgetThumbnailDimensions
      )}&width=${Math.round(videoWidth * 2)}`
    }
    nextImageUrl = videos[i + 1].customThumbnail
      ? videos[i + 1].customThumbnail +
        `-/scale_crop/${Math.round(videoWidth * imageBoostFactor)}x${Math.round(videoHeight * imageBoostFactor)}/center/`
      : `https://thumbnails.getclipara.com/${videos[i + 1].playbackId}/thumbnail.png?time=${
          videos[i + 1].thumbnailTime || 0.0
        }&width=${Math.round(videoWidth * imageBoostFactor)}&height=${Math.round(videoHeight * imageBoostFactor)}&fit_mode=crop`
    nextWebpUrl = videos[i + 1].customThumbnail
      ? videos[i + 1].customThumbnail +
        `-/scale_crop/${Math.round(videoWidth * imageBoostFactor)}x${Math.round(
          videoHeight * imageBoostFactor
        )}/center/-/format/webp/`
      : nextImageUrl.replace('.png', '.webp')
  }

  const playerId = `__clipara-inline-carousel-${video.id}-${campaign.id}`

  const playPause = () => {
    const element = document.getElementById(playerId)
    if (!element || !isVideo) {
      return
    }
    if (!element.paused) {
      setIsPaused(true)

      element.pause()
      // element.style.opacity = '0'
      return
    } else if (element.paused) {
      setIsPaused(false)
      if (element?.src) {
        element.play()
        element.style.opacity = '1'
        return
      } else {
        loadVideoLite({
          id: playerId,
          video,
          campaign,
          playerName: 'inline-carousel',
          useClip: false
        })
        element.style.opacity = '1'
        return
      }
    }
  }

  const titleVideoObject = videoObjects.find((vo) => vo.videoId.toString() === video.id.toString() && vo.type === 'TITLE') || {}
  const buttonVideoObject = videoObjects.find((vo) => vo.videoId.toString() === video.id.toString() && vo.type === 'BUTTON') || {}

  // Specific Stuff for thumbnails
  const { setAddThumbnailVideo, setShowAddThumbnailModal } = useModal()

  return (
    <SlideItem key={video.id} className="keen-slider__slide" borderRadius={borderRadius}>
      <CursorDiv
        href={isNavigation ? buttonVideoObject.buttonLink : undefined}
        onClick={() => {
          setHasStarted(true)
          isMobile && setIsMuted(false)
          if (isActive) {
            playPause()
          } else {
            setActive()
          }
        }}
      >
        <Box position="relative" height={videoHeight}>
          {/* DASHBOARD ONLY */}
          <Box className="thumbnail-button" display="none" position="absolute" top="24px" right="16px" zIndex="2">
            <ThumbnailContainer
              bg="white"
              borderRadius="20px"
              alignItems="center"
              justifyContent="center"
              width="40px"
              height="40px"
              boxShadow="0px 3px 8px -1px rgba(50, 50, 71, 0.05);filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))"
              cursor="pointer"
              minWidth="40px"
              onClick={() => {
                setAddThumbnailVideo(video)
                setShowAddThumbnailModal(true)
              }}
            >
              <Icon width="16px" height="16px" icon="image-black" />
            </ThumbnailContainer>
          </Box>
          {isActive ? (
            <VideoContainer isMobile={isMobile} isStories={isStories} videoPlaying={videoPlaying}>
              <Video
                widgetThumbnailBorderSize={widgetThumbnailBorderSize}
                widgetThumbnailBorderColor={widgetThumbnailBorderColor}
                widgetThumbnailBorder={widgetThumbnailBorder}
                borderRadius={borderRadius}
                videoWidth={videoWidth}
                videoHeight={videoHeight}
                width={videoWidth}
                height={videoHeight}
                minHeight={videoHeight}
                loop
                isCircle={isCircle}
                muted={true}
                id={`__clipara-inline-carousel-clip-${video.id}-${campaign.id}`}
                preload="auto"
                playsInline
                poster={navigator.userAgent.includes('wv') ? imageUrl : undefined}
                webkit-playsInline
                x-webkit-airplay="allow"
              />
            </VideoContainer>
          ) : null}
          <VideoContainer isMobile={isMobile} isStories={isStories} videoPlaying={videoPlaying}>
            <Video
              widgetThumbnailBorderSize={widgetThumbnailBorderSize}
              widgetThumbnailBorderColor={widgetThumbnailBorderColor}
              widgetThumbnailBorder={widgetThumbnailBorder}
              borderRadius={borderRadius}
              videoWidth={videoWidth}
              videoHeight={videoHeight}
              loop
              isCircle={isCircle}
              muted={isMuted}
              id={`__clipara-inline-carousel-${video.id}-${campaign.id}`}
              preload="metadata"
              playsInline
              poster={navigator.userAgent.includes('wv') ? imageUrl : undefined}
              webkit-playsInline
              x-webkit-airplay="allow"
            />
          </VideoContainer>
          {isVideo ? (
            <StyledPicture>
              <source srcset={webpUrl} type="image/webp" />
              <source srcset={imageUrl} type="image/png" />
              <VideoImage
                widgetThumbnailBorderSize={widgetThumbnailBorderSize}
                widgetThumbnailBorderColor={widgetThumbnailBorderColor}
                widgetThumbnailBorder={widgetThumbnailBorder}
                borderRadius={borderRadius}
                isCircle={isCircle}
                loading="lazy"
                src={imageUrl}
                alt={video.name}
              />
            </StyledPicture>
          ) : (
            <StyledPicture>
              <VideoImage
                widgetThumbnailBorderSize={widgetThumbnailBorderSize}
                widgetThumbnailBorderColor={widgetThumbnailBorderColor}
                widgetThumbnailBorder={widgetThumbnailBorder}
                borderRadius={borderRadius}
                isCircle={isCircle}
                loading="lazy"
                src={`https://clipara.b-cdn.net/${video.path}?aspect_ratio=${getAspectRatio(
                  campaign.widgetThumbnailDimensions
                )}&width=${Math.round(videoWidth * 2)}`}
                alt={video.name}
              />
            </StyledPicture>
          )}
          {showNextImage && nextImageUrl && nextWebpUrl ? (
            <StyledNextPicture>
              <source srcset={nextWebpUrl} type="image/webp" />
              <source srcset={nextImageUrl} type="image/png" />
              <VideoImage
                widgetThumbnailBorderSize={widgetThumbnailBorderSize}
                widgetThumbnailBorderColor={widgetThumbnailBorderColor}
                widgetThumbnailBorder={widgetThumbnailBorder}
                borderRadius={borderRadius}
                isCircle={isCircle}
                loading="lazy"
                src={nextImageUrl}
                alt={video.name}
              />
            </StyledNextPicture>
          ) : null}
          {isVideo ? isActive && hasStarted ? null : <PlayButtonComponent pbWidth={60} campaign={campaign} /> : null}
          {isActive && hasStarted && isVideo ? (
            <>
              <PlayContainer autoOpen={campaign.autoOpen}>
                {!isPaused ? (
                  <Volume width="12px" height="18px" alt="volume" src={`${baseUrl}/__clipara-pause-white.svg`} />
                ) : (
                  <Volume ml="4px" width="22px" height="22px" alt="volume" src={`${baseUrl}/__clipara-play-white.svg`} />
                )}
              </PlayContainer>
              <MuteContainer
                onClick={(e) => {
                  e.stopPropagation()
                  setIsMuted(!isMuted)
                }}
              >
                <Volume
                  width="28px"
                  height="28px"
                  alt="volume"
                  src={!isMuted ? `${baseUrl}/__clipara-mute-white-large.svg` : `${baseUrl}/__clipara-volume-white-large.svg`}
                />
              </MuteContainer>
            </>
          ) : null}
          {!isVideo ? (
            <ImageIconContainer>
              <img width="16px" height="16px" alt="close preview" src={`${baseUrl}/__clipara-image-white.svg`} />
            </ImageIconContainer>
          ) : null}
        </Box>

        {titleVideoObject && widgetTitleActive ? (
          <Box mt="16px">
            <Titles
              {...campaign}
              isMobile={isMobile}
              isStories={isStories}
              videoWidth={videoWidth}
              gapWidth={widgetGapWidth}
              titleVideoObject={titleVideoObject}
              hideMarginLeft={i === 0}
              hideMarginRight={i === videos.length - 1}
            />
          </Box>
        ) : null}
      </CursorDiv>
    </SlideItem>
  )
}

export default InlineSlideItem
