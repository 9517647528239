import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout'
import ProcessingPreview from './ProcessingPreview'
import { formatSeconds } from '../ui/helpers'
import { getChevronStyling, getChevronImg, getChevronImgSize } from '../Campaigns/chevronStyles.js'

// SPECIFIC
import AddThumbnailModal from '../Videos/AddThumbnailModal'
import { useModal } from '../Modal.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { Text } from '../ui/Typography'
import { ImageExpandButton } from './ui/ImageExpandButton'

const baseUrl = 'https://widget-v2.getclipara.com'

const CarouselItem = styled(Box)`
  position: relative;
  cursor: pointer;
  width: ${(props) => props.videoWidth}px;
  min-width: ${(props) => props.videoWidth}px;
  height: ${(props) => props.videoHeight}px;
  margin-left: ${(props) => (props.hideMarginLeft ? '0' : props.gapWidth / 2)}px;
  margin-right: ${(props) => (props.hideMarginRight ? '0' : props.gapWidth / 2)}px;
  border-radius: ${(props) => `${props.borderRadius}px`};
  overflow: hidden;
  background-color: black;
  margin-bottom: 16px;
  margin-top: 16px;
  box-sizing: border-box;
  ${(props) =>
    props.widgetThumbnailBorder
      ? `border: ${props.widgetThumbnailBorderSize || 3}px solid ${props.widgetThumbnailBorderColor || 'black'};`
      : ''}

  &&:hover {
    .hover {
      display: flex;
    }
  }
`

// SPECIFIC STUFF FOR THUMBNAILS
const ThumbnailHover = styled(Flex)`
  position: relative;
  &&:hover {
    .thumbnail-button {
      display: block;
    }
  }
`

const ThumbnailContainer = styled(Flex)`
  .hover-text {
    display: none;
  }
  &&:hover {
    .hover-text {
      display: block;
    }
  }
`

const CarouselContainer = styled(Flex)`
  overflow-x: hidden;
`

const PlayButton = styled.svg`
  fill: none !important;
  width: ${(props) => props.width}px !important;
  height: ${(props) => props.height}px !important;
  position: absolute;
  z-index: 2;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 ${(props) => props.playButtonColor};
    }
    100% {
      box-shadow: 0 0 0 8px #fbb32f01;
    }
  }
  border-radius: 60px;
  ${(props) => props.animate && `animation: pulse 1500ms infinite;`}
  @media(max-width: 1000px) {
    width: 40px !important;
    height: 40px !important;
  }
  ${(props) => {
    if (props.widgetPlayButtonPosition === 'BOTTOM_LEFT') {
      return `bottom: 16px; left: 16px;`
    }
    if (props.widgetPlayButtonPosition === 'BOTTOM_RIGHT') {
      return `bottom: 16px; right: 16px;`
    }
    return ` top: 50%; left: 50%; transform: translate(-50%, -50%);`
  }}
`

const VideoImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  ${(props) =>
    props.isCircle && props.widgetThumbnailBorder && `border: 2.5px solid white; box-sizing: border-box; border-radius: 100%;`}
`

const Slider = styled(Flex)`
  transition: transform ease-in-out 0.3s;
  transform: translate(-${(props) => props.activeVideoIndex * props.slideWidth + props.slideAdjust}px);
`

const Alignment = styled(Flex)`
  flex-direction: column;
  align-items: ${(props) => {
    if (props.position === 'LEFT') {
      return 'flex-start'
    }
    if (props.position === 'RIGHT') {
      return 'flex-end'
    }
    return 'center'
  }};
  position: relative;
  margin-bottom: 24px;
`

const Chevron = styled(Flex)`
  z-index: 1;
  width: 40px;
  min-width: 40px;
  height: 40px;
  ${(props) => getChevronStyling(props.widgetButtonStyle, props.widgetChevronSecondaryColor, props.isStories)}
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${(props) => (props.isDisabled ? `cursor: auto; opacity: 0.5;` : '')}
  ${(props) => (props.isSmallControls ? `width: 32px; height: 32px; min-width: 32px;` : '')}
`

const ChevronImage = styled(Flex)`
  ${(props) =>
    props.isStories
      ? `svg {
    fill: ${(props) => props.widgetChevronPrimaryColor || 'black'} !important;
    stroke: ${(props) => props.widgetChevronPrimaryColor || 'black'} !important;
  }`
      : ''}
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  align-items: center;
  justify-content: center;
  width: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  height: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  svg {
    width: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
    height: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  }

  ${(props) => (props.isSmallControls ? `width: 16px !important; height: 16px !important; min-width: 16px !important;` : '')}
`

const LeftChevron = styled(Chevron)`
  ${(props) => props.widgetArrowPosition === 'INSIDE' && 'margin-left: 8px;'}
  ${(props) => props.hide && 'display:none;'}
`

const RightChevron = styled(Chevron)`
  ${(props) => props.widgetArrowPosition === 'INSIDE' && 'margin-right: 8px;'}
  ${(props) => props.hide && 'display:none;'}
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
`

const CirclesContainer = styled(Flex)`
  position: absolute;
  bottom: -16px;
  left: 0;
  justify-content: center;
`

const Circle = styled.div`
  display: block !important;
  background-color: ${(props) => (props.isActive ? 'black' : '#CCCCCC')};
  width: 10px;
  min-width: 10px;
  height: 10px;
  min-height: 10px;
  border-radius: 10px;
  margin-left: 3px;
  margin-right: 3px;
`

const Circles = ({ activeVideoIndex, videosToShow, numberVideos, isSmallControls }) => {
  if (numberVideos - videosToShow + 1 <= 1) {
    return null
  }
  if (numberVideos > 10) {
    return null
  }
  return (
    <CirclesContainer isSmallControls={isSmallControls}>
      {Array.apply(null, Array(numberVideos - videosToShow + 1) || [0])
        .map(function () {})
        .map((_, i) => {
          return <Circle isSmallControls={isSmallControls} key={i} isActive={i === activeVideoIndex} />
        })}
    </CirclesContainer>
  )
}

const Title = styled.p`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily.replace(';') : 'inherit')} !important;
  font-weight: ${(props) => props.fontWeight || '700'} !important;
  font-size: ${(props) => props.fontSize || '50'}px !important;
  line-height: ${(props) => props.fontSize || '50'}px !important;
  text-align: ${(props) => props.textAlign || 'center'} !important;
  color: ${(props) => props.color};
  text-decoration: none;
  margin-bottom: ${(props) => (props.isStories ? 0 : 16)}px !important;
  margin-top: 0px;
  padding: 0px !important;
  padding-top: 0px !important;
  width: ${(props) => props.width};
  padding-bottom: 0px !important;
  word-break: break-word;
`

const VideoDuration = styled.p`
  z-index: 2;
  position: absolute;
  bottom: ${(props) => props.durationPadding || 16}px;
  right: ${(props) => props.durationPadding || 16}px;
  color: white !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  margin: 0px !important;
`

const VideoTitle = styled.p`
  font-family: ${(props) => (props.widgetTitleFontFamily ? props.widgetTitleFontFamily.replace(';') : 'inherit')} !important;
  font-weight: ${(props) => props.widgetTitleFontWeight || '700'} !important;
  font-size: ${(props) => props.widgetDesktopTitleFontSize || '18'}px !important;
  line-height: ${(props) => props.widgetDesktopTitleFontSize || '18'}px !important;
  text-align: ${(props) => (props.widgetTitleAlignment ? props.widgetTitleAlignment.toLowerCase() : 'center')} !important;
  color: ${(props) => props.widgetTitleFontColor || 'black'};
  text-decoration: none !important;
  margin-bottom: ${(props) => (props.isStories ? 0 : 16)}px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
  a,
  a:visited,
  a:active,
  a:hover,
  a:focus {
    color: ${(props) => props.widgetTitleFontColor || 'black'};
  }
`

const VideoSubtitle = styled.p`
  font-family: ${(props) =>
    props.widgetSubtitleFontFamily ? props.widgetSubtitleFontFamily.replace(';') : 'inherit'} !important;
  font-weight: ${(props) => props.widgetSubtitleFontWeight || '500'} !important;
  font-size: ${(props) => props.widgetDesktopSubtitleFontSize || '18'}px !important;
  line-height: ${(props) => props.widgetDesktopSubtitleFontSize || '18'}px !important;
  text-align: ${(props) => (props.widgetTitleAlignment ? props.widgetTitleAlignment.toLowerCase() : 'center')} !important;
  color: ${(props) => props.widgetSubtitleFontColor || 'black'};
  text-decoration: none !important;
  margin-bottom: 16px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
  a,
  a:visited,
  a:active,
  a:hover,
  a:focus {
    color: ${(props) => props.widgetSubtitleFontColor || 'black'};
  }
`

const TitleContainer = styled(Flex)`
  flex-direction: column;
`

const Titles = ({
  isStories,
  videoWidth,
  gapWidth,
  titleVideoObject,
  widgetTitleAlignment,
  widgetDesktopTitleFontSize,
  widgetTitleFontColor,
  widgetTitleFontFamily,
  widgetTitleFontWeight,
  widgetSubtitleFontWeight,
  widgetSubtitleFontFamily,
  widgetSubtitleFontColor,
  widgetDesktopSubtitleFontSize,
  hideMarginLeft,
  hideMarginRight
}) => {
  return (
    <TitleContainer
      maxWidth={videoWidth}
      mr={hideMarginRight ? 0 : `${gapWidth / 2}px`}
      ml={hideMarginLeft ? 0 : `${gapWidth / 2}px`}
      width="auto"
    >
      {titleVideoObject.title && (
        <VideoTitle
          isStories={isStories}
          widgetTitleAlignment={widgetTitleAlignment}
          widgetDesktopTitleFontSize={widgetDesktopTitleFontSize}
          widgetTitleFontColor={widgetTitleFontColor}
          widgetTitleFontFamily={widgetTitleFontFamily}
          widgetTitleFontWeight={widgetTitleFontWeight}
          dangerouslySetInnerHTML={{ __html: titleVideoObject.title }}
        ></VideoTitle>
      )}
      {titleVideoObject.subtitle && !isStories && (
        <VideoSubtitle
          widgetTitleAlignment={widgetTitleAlignment}
          widgetDesktopSubtitleFontSize={widgetDesktopSubtitleFontSize}
          widgetSubtitleFontColor={widgetSubtitleFontColor}
          widgetSubtitleFontFamily={widgetSubtitleFontFamily}
          widgetSubtitleFontWeight={widgetSubtitleFontWeight}
          dangerouslySetInnerHTML={{ __html: titleVideoObject.subtitle }}
        ></VideoSubtitle>
      )}
    </TitleContainer>
  )
}

const InnerTitleContainer = styled(Flex)`
  box-sizing: border-box !important;
  z-index: 1;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding-left: 16px;
  padding-right: 16px;
  height: 150px;
  flex-direction: column-reverse;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
`

const StyledPicture = styled.picture`
  height: 100%;
`

const PlayButtonComponent = ({ campaign, pbWidth }) => {
  if (campaign.type === 'STORIES') {
    return null
  }
  return (
    <PlayButton
      widgetPlayButtonPosition={campaign.widgetPlayButtonPosition}
      animate={campaign.playButtonAnimation}
      playButtonColor={campaign.playButtonColor || '#333333'}
      width={pbWidth}
      height={pbWidth}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {campaign.widgetPlayButtonStyle === 'FILLED' ? (
        <>
          <circle cx="30" cy="30" r="30" fill={campaign.playButtonColor || '#333333'} />
          <path
            d="M45 28.268C46.3333 29.0378 46.3333 30.9623 45 31.7321L24 43.8564C22.6667 44.6262 21 43.664 21 42.1244L21 17.8756C21 16.336 22.6667 15.3738 24 16.1436L45 28.268Z"
            fill="white"
          />
        </>
      ) : (
        <>
          <circle cx="30" cy="30" r="28.5" stroke={campaign.playButtonColor || '#333333'} stroke-width="3" />
          <path
            d="M46.1097 29.2683C47.4431 30.0381 47.4431 31.9626 46.1097 32.7324L24.9451 44.9518C23.6118 45.7216 21.9451 44.7594 21.9451 43.2198L21.9451 18.781C21.9451 17.2414 23.6118 16.2791 24.9451 17.0489L46.1097 29.2683Z"
            fill={campaign.playButtonColor || '#333333'}
          />
        </>
      )}
    </PlayButton>
  )
}

const getMaxVideoWidth = (dimensions) => {
  if (dimensions === 'LANDSCAPE') {
    return 640
  }
  if (dimensions === 'SQUARE') {
    return 489
  }
  if (dimensions === 'SQUISHED_PORTRAIT') {
    return 338
  }
  if (dimensions === 'CIRCLE') {
    return 489
  }
  // PORTRAIT
  return 338
}

const getVideoHeight = (dimensions, videoWidth) => {
  if (dimensions === 'LANDSCAPE') {
    return (videoWidth / 640) * 370
  }
  if (dimensions === 'SQUARE') {
    return videoWidth
  }
  if (dimensions === 'SQUASHED_PORTRAIT') {
    return (videoWidth / 338) * 500
  }
  if (dimensions === 'CIRCLE') {
    return videoWidth
  }
  // PORTRAIT
  return (videoWidth / 338) * 600
}

export const getAspectRatio = (dimensions) => {
  if (dimensions === 'LANDSCAPE') {
    return '16:9'
  }
  if (dimensions === 'SQUARE') {
    return '1:1'
  }
  if (dimensions === 'SQUASHED_PORTRAIT') {
    return '2:3'
  }
  if (dimensions === 'CIRCLE') {
    return '1:1'
  }
  // PORTRAIT
  return '9:16'
}

const getArrowWidth = (position, noArrows, isStories) => {
  if (noArrows || isStories) {
    return 0
  }
  if (position === 'BELOW') {
    return 0
  }
  if (position === 'INSIDE') {
    return 0
  }
  if (position === 'OUTSIDE') {
    return 56
  }
  // OVERLAP
  return 20
}

const getDimensionsForResponsive = (campaign, { activeVideoIndex, setActiveVideoIndex }) => {
  const { videos, desktopVideos, widgetThumbnailDimensions, marginX, widgetGapWidth, widgetArrowPosition, widgetIsPartialStart } =
    campaign
  const isStories = campaign.type === 'STORIES'
  const videosToShow = isStories ? desktopVideos : videos.length < desktopVideos ? videos.length : desktopVideos || 3
  const noArrows = videos.length <= desktopVideos
  const maxVideoWidth = getMaxVideoWidth(widgetThumbnailDimensions)
  const arrowsWidth = getArrowWidth(widgetArrowPosition, noArrows, isStories)
  const containerWidth =
    document.getElementById('__clipara-embed') &&
    document.getElementById('__clipara-embed').clientWidth - (marginX ? marginX * 2 : 0)
  const gapWidth = widgetGapWidth
  const maxContainerWidth = (maxVideoWidth + gapWidth) * (isStories ? desktopVideos : videosToShow) - gapWidth + arrowsWidth * 2 // Minus one gap width for start/end
  const containerHeight = document.getElementById('__clipara-embed') && document.getElementById('__clipara-embed').clientHeight
  const containerWidthWithoutArrows = containerWidth - arrowsWidth * 2
  const isSmallControls = containerWidth < 900
  const containerCanFitFullWidth = containerWidthWithoutArrows - maxContainerWidth > 0
  const videoWidth = containerCanFitFullWidth
    ? maxVideoWidth
    : (containerWidthWithoutArrows - widgetGapWidth * videosToShow + gapWidth) / videosToShow

  const carouselWidth =
    (videoWidth + gapWidth) * (isStories ? (videos.length < desktopVideos ? videos.length : desktopVideos) : videosToShow) -
    gapWidth +
    arrowsWidth * 2

  const videoHeight = getVideoHeight(widgetThumbnailDimensions, videoWidth)
  const maxContainerWidthFactoringNoArrows = maxContainerWidth - arrowsWidth

  const chevronContainerHeight = videoHeight + 30
  const carouselContainerWidth = containerCanFitFullWidth ? `${maxContainerWidth}px` : `calc(100% - ${arrowsWidth}px)`
  const slideAdjust = widgetIsPartialStart ? (noArrows ? 0 : videoWidth / 2) : 0
  const leftChevronDisabled = widgetIsPartialStart ? activeVideoIndex === -1 : activeVideoIndex === 0
  const rightChevronDisabled = activeVideoIndex > videos.length - videosToShow - 1
  const leftChevronOnClick = () => {
    if (leftChevronDisabled) {
      return
    }
    setActiveVideoIndex(activeVideoIndex - 1)
  }
  const rightChevronOnClick = () => {
    if (rightChevronDisabled) {
      return
    }
    setActiveVideoIndex(activeVideoIndex + 1)
  }
  const isCircle = campaign.widgetThumbnailDimensions === 'CIRCLE'
  const borderRadius = isCircle ? '100%' : campaign.borderRadius
  return {
    videosToShow,
    noArrows,
    maxVideoWidth,
    arrowsWidth,
    containerWidth,
    gapWidth,
    maxContainerWidth,
    containerHeight,
    containerWidthWithoutArrows,
    isSmallControls,
    containerCanFitFullWidth,
    videoWidth,
    videoHeight,
    maxContainerWidthFactoringNoArrows,
    chevronContainerHeight,
    carouselContainerWidth,
    slideAdjust,
    leftChevronDisabled,
    rightChevronDisabled,
    leftChevronOnClick,
    rightChevronOnClick,
    borderRadius,
    carouselWidth,
    isCircle: campaign.widgetThumbnailDimensions === 'CIRCLE',
    isStories
  }
}

const getDimensionsForFixed = (campaign, { activeVideoIndex, setActiveVideoIndex }) => {
  const {
    videos,
    marginX,
    widgetGapWidth,
    widgetIsPartialStart,
    widgetFixedThumbnailHeight,
    widgetFixedThumbnailWidth,
    widgetThumbnailDimensions,
    desktopVideos
  } = campaign
  const isStories = campaign.type === 'STORIES'
  const numberVideos = videos.length
  const maxVideoWidth = widgetFixedThumbnailWidth
  const maxVideoHeight = getVideoHeight(widgetThumbnailDimensions, maxVideoWidth)
  const arrowsWidth = 0
  const containerWidth =
    document.getElementById('__clipara-embed') &&
    document.getElementById('__clipara-embed').clientWidth - (marginX ? marginX * 2 : 0)
  const gapWidth = widgetGapWidth
  const maxContainerWidth = (maxVideoWidth + gapWidth) * numberVideos - gapWidth // Minus one gap width for start/end
  const videosThatCanBeShown = Math.floor((containerWidth - gapWidth) / (maxVideoWidth + gapWidth))
  const videosToShow = videosThatCanBeShown
  const containerHeight = document.getElementById('__clipara-embed') && document.getElementById('__clipara-embed').clientHeight
  const noArrows = videosThatCanBeShown >= numberVideos
  const containerWidthWithoutArrows = containerWidth - arrowsWidth * 2
  const isSmallControls = false
  const containerCanFitFullWidth = containerWidthWithoutArrows - maxContainerWidth > 0
  const videoWidth = maxVideoWidth
  const carouselWidth =
    (videoWidth + gapWidth) * (isStories ? (videos.length < desktopVideos ? videos.length : desktopVideos) : videosToShow) -
    gapWidth +
    arrowsWidth * 2
  const videoHeight = maxVideoHeight
  const maxContainerWidthFactoringNoArrows = maxContainerWidth - arrowsWidth
  const chevronContainerHeight = videoHeight + 30
  const carouselContainerWidth = `auto`
  const slideAdjust = 0
  const leftChevronDisabled = widgetIsPartialStart ? activeVideoIndex === -1 : activeVideoIndex === 0
  const rightChevronDisabled = activeVideoIndex > videos.length - videosThatCanBeShown - 1
  const leftChevronOnClick = () => {
    if (leftChevronDisabled) {
      return
    }
    setActiveVideoIndex(activeVideoIndex - 1)
  }
  const rightChevronOnClick = () => {
    if (rightChevronDisabled) {
      return
    }
    setActiveVideoIndex(activeVideoIndex + 1)
  }
  const isCircle = campaign.widgetThumbnailDimensions === 'CIRCLE'
  const borderRadius = isCircle ? '100%' : campaign.borderRadius

  return {
    videosToShow,
    noArrows,
    maxVideoWidth,
    arrowsWidth,
    containerWidth,
    gapWidth,
    maxContainerWidth,
    containerHeight,
    containerWidthWithoutArrows,
    isSmallControls,
    containerCanFitFullWidth,
    videoWidth,
    videoHeight,
    maxContainerWidthFactoringNoArrows,
    chevronContainerHeight,
    carouselContainerWidth,
    slideAdjust,
    leftChevronDisabled,
    rightChevronDisabled,
    leftChevronOnClick,
    rightChevronOnClick,
    borderRadius,
    isCircle,
    isStories,
    carouselWidth
  }
}

const EmbedCarousel = ({ campaign, setShowFullscreenPlayer, setActiveFullScreenVideo }) => {
  const [activeVideoIndex, setActiveVideoIndex] = useState(0)
  const [resize, setResize] = useState()
  const carousel = useRef()

  const {
    landscape,
    videos,
    playButtonColor,
    position,
    fontSize,
    fontWeight,
    widgetFontFamily,
    textAlign,
    text,
    titleColor,
    durationDisplay,
    widgetTitleActive,
    videoObjects,
    widgetTitleAlignment,
    widgetDesktopTitleFontSize,
    widgetMobileTitleFontSize,
    widgetTitleFontColor,
    widgetTitleFontFamily,
    widgetTitleFontWeight,
    widgetSubtitleFontWeight,
    widgetTitlePosition,
    widgetSubtitleFontColor,
    widgetSubtitleFontFamily,
    widgetDesktopSubtitleFontSize,
    widgetArrowPosition,
    widgetThumbnailBorderSize,
    widgetThumbnailBorderColor,
    widgetThumbnailBorder,
    widgetPlayButtonPosition,
    widgetButtonStyle,
    widgetChevronPrimaryColor,
    widgetChevronSecondaryColor,
    widgetIsPartialStart,
    widgetPlayButtonStyle,
    type,
    widgetFixedThumbnail
  } = campaign

  const {
    videosToShow,
    noArrows,
    arrowsWidth,
    gapWidth,
    maxContainerWidth,
    isSmallControls,
    containerCanFitFullWidth,
    videoWidth,
    videoHeight,
    maxContainerWidthFactoringNoArrows,
    chevronContainerHeight,
    carouselContainerWidth,
    slideAdjust,
    leftChevronDisabled,
    rightChevronDisabled,
    leftChevronOnClick,
    rightChevronOnClick,
    borderRadius,
    isCircle,
    isStories,
    containerWidth,
    carouselWidth
  } = widgetFixedThumbnail
    ? getDimensionsForFixed(campaign, { activeVideoIndex, setActiveVideoIndex })
    : getDimensionsForResponsive(campaign, { activeVideoIndex, setActiveVideoIndex })

  useEffect(() => {
    function handleResize() {
      setResize(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  //
  //
  // NEW FOR DASH
  useEffect(() => {
    setResize(window.innerWidth)
  }, [containerWidth])
  useEffect(() => {
    setActiveVideoIndex(0)
  }, [widgetFixedThumbnail])
  //
  //
  //
  return (
    <Alignment position={position} id="align">
      {text ? (
        <Title
          isStories={isStories}
          color={titleColor}
          fontSize={fontSize}
          fontWeight={fontWeight}
          fontFamily={widgetFontFamily}
          textAlign={textAlign}
          width="100%"
        >
          {text}
        </Title>
      ) : null}
      <Flex
        width={
          isStories && noArrows && !widgetFixedThumbnail
            ? carouselWidth
            : containerCanFitFullWidth
            ? `${maxContainerWidthFactoringNoArrows + arrowsWidth}px`
            : '100%'
        }
      >
        {!noArrows ? (
          <Flex
            height={chevronContainerHeight}
            alignItems="center"
            minWidth={arrowsWidth}
            width={arrowsWidth}
            display="flex !important"
          >
            <LeftChevron
              widgetChevronSecondaryColor={widgetChevronSecondaryColor}
              isStories={isStories}
              widgetButtonStyle={widgetButtonStyle}
              hide={widgetArrowPosition === 'BELOW' || widgetArrowPosition === 'CENTER_BELOW'}
              widgetArrowPosition={widgetArrowPosition}
              isSmallControls={isSmallControls}
              isDisabled={leftChevronDisabled}
              onClick={leftChevronOnClick}
            >
              <ChevronImage
                isStories={isStories}
                widgetChevronPrimaryColor={widgetChevronPrimaryColor}
                widgetButtonStyle={widgetButtonStyle}
                isSmallControls={isSmallControls}
                alt="chevron icon"
                width={getChevronImgSize(widgetButtonStyle, isStories)}
                height={getChevronImgSize(widgetButtonStyle, isStories)}
              >
                {getChevronImg(widgetButtonStyle, widgetChevronPrimaryColor, isStories)}
              </ChevronImage>
            </LeftChevron>
          </Flex>
        ) : null}

        <CarouselContainer
          id="carousel-container"
          ref={carousel}
          width={carouselContainerWidth}
          maxWidth={carouselContainerWidth}
          containerCanFitFullWidth={containerCanFitFullWidth}
        >
          <Slider slideWidth={videoWidth + gapWidth} activeVideoIndex={activeVideoIndex} slideAdjust={slideAdjust}>
            {videos.map((video, i) => {
              const isVideo = video.type === 'VIDEO'
              const [videoPlaying, setVideoPlaying] = useState(false)
              const optimiseLoad = i > videosToShow + activeVideoIndex
              const imageBoostFactor = videoWidth < 150 || videoHeight < 150 ? 1.5 : 1
              const imageUrl = video.customThumbnail
                ? video.customThumbnail + `-/scale_crop/${Math.round(videoWidth)}x${Math.round(videoHeight)}/center/`
                : `https://image.mux.com/${video.playbackId}/thumbnail.png?time=${video.thumbnailTime || 0.0}&width=${Math.round(
                    videoWidth * imageBoostFactor
                  )}&height=${Math.round(videoHeight * imageBoostFactor)}&fit_mode=crop`
              const isSmallPb = videoWidth < 175 || videoHeight < 175
              const pbWidth = isSmallPb ? 36 : 60
              const durationPadding = borderRadius > 32 ? borderRadius / 2 : 16
              const webpUrl = video.customThumbnail ? video.customThumbnail + '-/format/webp/' : imageUrl.replace('.png', '.webp')

              const titleVideoObject =
                videoObjects.find((vo) => vo.videoId.toString() === video.id.toString() && vo.type === 'TITLE') || {}

              // Specific Stuff for thumbnails
              const { setAddThumbnailVideo, setShowAddThumbnailModal } = useModal()

              return (
                <ThumbnailHover flexDirection="column" key={video.id} width="auto">
                  <Box className="thumbnail-button" display="none" position="absolute" top="24px" right="16px" zIndex="2">
                    <ThumbnailContainer
                      bg="white"
                      borderRadius="20px"
                      alignItems="center"
                      justifyContent="center"
                      width="40px"
                      height="40px"
                      boxShadow="0px 3px 8px -1px rgba(50, 50, 71, 0.05);filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))"
                      cursor="pointer"
                      minWidth="40px"
                      onClick={() => {
                        setAddThumbnailVideo(video)
                        setShowAddThumbnailModal(true)
                      }}
                    >
                      <Icon width="16px" height="16px" icon="image-black" />
                    </ThumbnailContainer>
                  </Box>
                  <CarouselItem
                    onClick={() => {
                      setActiveFullScreenVideo(i)
                      setShowFullscreenPlayer(true)
                    }}
                    hideMarginLeft={i === 0}
                    hideMarginRight={i === videos.length - 1}
                    gapWidth={gapWidth}
                    videoWidth={videoWidth}
                    videoHeight={videoHeight}
                    landscape={landscape}
                    key={video.id}
                    borderRadius={borderRadius}
                    widgetThumbnailBorderSize={widgetThumbnailBorderSize}
                    widgetThumbnailBorderColor={widgetThumbnailBorderColor}
                    widgetThumbnailBorder={widgetThumbnailBorder}
                  >
                    {!optimiseLoad ? (
                      isVideo ? (
                        <StyledPicture>
                          <source srcset={webpUrl} type="image/webp" />
                          <source srcset={imageUrl} type="image/png" />
                          <VideoImage
                            isCircle={isCircle}
                            widgetThumbnailBorder={widgetThumbnailBorder}
                            loading="lazy"
                            src={imageUrl}
                            alt={video.name}
                          />
                        </StyledPicture>
                      ) : (
                        <VideoImage
                          isCircle={isCircle}
                          widgetThumbnailBorder={widgetThumbnailBorder}
                          loading="lazy"
                          src={`https://clipara.b-cdn.net/${video.path}?aspect_ratio=${getAspectRatio(
                            campaign.widgetThumbnailDimensions
                          )}&width=${Math.round(videoWidth * 2)}`}
                          alt={video.name}
                        />
                      )
                    ) : null}
                    {isVideo ? <PlayButtonComponent pbWidth={pbWidth} campaign={campaign} /> : <ImageExpandButton />}
                    {titleVideoObject && widgetTitleActive && widgetTitlePosition === 'INSIDE' ? (
                      <InnerTitleContainer>
                        <Titles
                          isStories={isStories}
                          videoWidth={videoWidth}
                          widgetTitleAlignment={widgetTitleAlignment}
                          widgetDesktopTitleFontSize={widgetDesktopTitleFontSize}
                          widgetMobileTitleFontSize={widgetMobileTitleFontSize}
                          widgetTitleFontColor={widgetTitleFontColor}
                          widgetTitleFontFamily={widgetTitleFontFamily}
                          widgetTitleFontWeight={widgetTitleFontWeight}
                          widgetSubtitleFontWeight={widgetSubtitleFontWeight}
                          titleVideoObject={titleVideoObject}
                          widgetSubtitleFontColor={widgetSubtitleFontColor}
                          widgetSubtitleFontFamily={widgetSubtitleFontFamily}
                          widgetDesktopSubtitleFontSize={widgetDesktopSubtitleFontSize}
                        />
                      </InnerTitleContainer>
                    ) : null}
                    {durationDisplay ? (
                      <VideoDuration durationPadding={durationPadding}>
                        {video.duration ? formatSeconds(video.duration) : ''}
                      </VideoDuration>
                    ) : null}
                  </CarouselItem>
                  {titleVideoObject && widgetTitleActive && widgetTitlePosition === 'BELOW' ? (
                    <Titles
                      isStories={isStories}
                      videoWidth={videoWidth}
                      gapWidth={gapWidth}
                      widgetTitleAlignment={widgetTitleAlignment}
                      widgetDesktopTitleFontSize={widgetDesktopTitleFontSize}
                      widgetMobileTitleFontSize={widgetMobileTitleFontSize}
                      widgetTitleFontColor={widgetTitleFontColor}
                      widgetTitleFontFamily={widgetTitleFontFamily}
                      widgetTitleFontWeight={widgetTitleFontWeight}
                      widgetSubtitleFontWeight={widgetSubtitleFontWeight}
                      titleVideoObject={titleVideoObject}
                      widgetSubtitleFontColor={widgetSubtitleFontColor}
                      widgetSubtitleFontFamily={widgetSubtitleFontFamily}
                      widgetDesktopSubtitleFontSize={widgetDesktopSubtitleFontSize}
                      hideMarginLeft={i === 0}
                      hideMarginRight={i === videos.length - 1}
                    />
                  ) : null}
                </ThumbnailHover>
              )
            })}
          </Slider>
        </CarouselContainer>
        {!noArrows ? (
          <Flex
            height={chevronContainerHeight}
            justifyContent="flex-end"
            alignItems="center"
            minWidth={arrowsWidth}
            width={arrowsWidth}
            display="flex !important"
          >
            <RightChevron
              widgetChevronSecondaryColor={widgetChevronSecondaryColor}
              isStories={isStories}
              widgetButtonStyle={widgetButtonStyle}
              hide={widgetArrowPosition === 'BELOW' || widgetArrowPosition === 'CENTER_BELOW'}
              widgetArrowPosition={widgetArrowPosition}
              isSmallControls={isSmallControls}
              isDisabled={rightChevronDisabled}
              onClick={rightChevronOnClick}
            >
              <ChevronImage
                isStories={isStories}
                widgetChevronPrimaryColor={widgetChevronPrimaryColor}
                widgetButtonStyle={widgetButtonStyle}
                isSmallControls={isSmallControls}
                alt="chevron icon"
                width={getChevronImgSize(widgetButtonStyle, isStories)}
                height={getChevronImgSize(widgetButtonStyle, isStories)}
              >
                {getChevronImg(widgetButtonStyle, widgetChevronPrimaryColor, isStories)}
              </ChevronImage>
            </RightChevron>
          </Flex>
        ) : null}

        {isStories || widgetArrowPosition === 'CENTER_BELOW' || widgetArrowPosition === 'BELOW' ? null : (
          <Circles
            isSmallControls={isSmallControls}
            numberVideos={videos.length}
            activeVideoIndex={activeVideoIndex}
            videosToShow={videosToShow}
          />
        )}
      </Flex>
      {/* BELOW ARROWS */}
      {!noArrows && (widgetArrowPosition === 'BELOW' || widgetArrowPosition === 'CENTER_BELOW') ? (
        <Flex
          justifyContent={widgetArrowPosition === 'CENTER_BELOW' ? 'center' : 'flex-end'}
          alignItems="center"
          display="flex !important"
          width={carouselContainerWidth}
          maxWidth={carouselContainerWidth}
          boxSizing="border-box"
        >
          <LeftChevron
            widgetChevronSecondaryColor={widgetChevronSecondaryColor}
            isStories={isStories}
            widgetButtonStyle={widgetButtonStyle}
            widgetArrowPosition={widgetArrowPosition}
            isDisabled={leftChevronDisabled}
            onClick={leftChevronOnClick}
            mr="8px"
          >
            <ChevronImage
              isStories={isStories}
              widgetChevronPrimaryColor={widgetChevronPrimaryColor}
              widgetButtonStyle={widgetButtonStyle}
              isSmallControls={isSmallControls}
              alt="chevron icon"
              width={getChevronImgSize(widgetButtonStyle, isStories)}
              height={getChevronImgSize(widgetButtonStyle, isStories)}
            >
              {getChevronImg(widgetButtonStyle, widgetChevronPrimaryColor, isStories)}
            </ChevronImage>
          </LeftChevron>
          <RightChevron
            widgetChevronSecondaryColor={widgetChevronSecondaryColor}
            isStories={isStories}
            widgetButtonStyle={widgetButtonStyle}
            widgetArrowPosition={widgetArrowPosition}
            isDisabled={rightChevronDisabled}
            onClick={rightChevronOnClick}
          >
            <ChevronImage
              isStories={isStories}
              widgetChevronPrimaryColor={widgetChevronPrimaryColor}
              widgetButtonStyle={widgetButtonStyle}
              isSmallControls={isSmallControls}
              alt="chevron icon"
              width={getChevronImgSize(widgetButtonStyle, isStories)}
              height={getChevronImgSize(widgetButtonStyle, isStories)}
            >
              {getChevronImg(widgetButtonStyle, widgetChevronPrimaryColor, isStories)}
            </ChevronImage>
          </RightChevron>
        </Flex>
      ) : null}
    </Alignment>
  )
}

export default EmbedCarousel
