import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import hexToRgba from 'hex-to-rgba'
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { colors, getDisplaySize } from '../ui/helpers'
import { Label, Error } from '../ui/Typography'
import { Flex, Box } from '../ui/Layout'
import ProcessingPreview from './ProcessingPreview'
import FullScreenPlayer from './FullScreenPlayer'
import Icon from '../ui/Icon.js'
import CarouselPopover from './CarouselPopover'
import { WIDGET_TYPES } from '../Campaigns/types'
import { SearchPreview } from './EmbedPreview'
import Image from '../ui/Image'
import { CarouselDownloadButton } from './ui/CarouselDownloadButton'

const VideoContainer = styled(Flex)`
  // transition: width ease-in-out 0.3s, height ease-in-out 0.3s, left ease-in-out 0.3s, right ease-in-out 0.3s;
  position: relative;
  ${(props) => {
    if (props.isLandscape && props.index === props.activeVideoIndex - 1) {
      return 'transform: translate(-100px, 0px);'
    }
    if (props.isLandscape && props.index === props.activeVideoIndex + 1) {
      return 'transform: translate(100px, 0px);'
    }
    return ''
  }}
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`

const CarouselContainer = styled(Flex)`
  padding-top: 60px;
  padding-bottom: 60px;
  min-width: 100%;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  transition: width ease-in-out 0.3s, height ease-in-out 0.3s, left ease-in-out 0.3s, right ease-in-out 0.3s;
  left: ${(props) => props.activeVideoIndex * -25}%;
`

const Chevron = styled(Flex)`
  width: 40px !important;
  height: 40px !important;
  img {
    width: 20px !important;
    height: 20px !important;
  }
  position: absolute;
  z-index: 110001;
  border-radius: 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  top: calc(50% - 15px);
  background-color: rgba(91, 91, 91, 0.4);
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.15);
`

// SPECIFIC STYLED ON LEFT
const LeftChevron = styled(Chevron)`
  left: 25%;
  ${(props) =>
    props.hasLandscapeVideo &&
    `position:absolute; 
    right: calc(50% + 10px); 
    bottom: 24px; 
    top:auto;
    left:auto;`}
`
// SPECIFIC STYLED ON RIGHT
const RightChevron = styled(Chevron)`
  right: 25%;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  ${(props) =>
    props.hasLandscapeVideo &&
    `position:absolute; 
    right: calc(50% - 50px); 
    bottom: 24px; 
    top:auto; 
    left:auto;`}
`

const VideoTitle = styled.p`
  font-family: ${(props) => (props.titleFontFamily ? props.titleFontFamily.replace(';', '') : 'inherit')} !important;
  font-weight: 700 !important;
  font-size: ${(props) => props.titleFontSize || '18'}px !important;
  line-height: ${(props) => props.titleFontSize || '18'}px !important;
  text-align: ${(props) => (props.titleAlignment ? props.titleAlignment.toLowerCase() : 'center')} !important;
  color: white;
  text-decoration: none !important;
  margin-bottom: 6px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
  a,
  a:visited,
  a:active,
  a:hover,
  a:focus {
    color: white;
  }
`

const VideoSubtitle = styled.p`
  font-family: ${(props) => (props.titleFontFamily ? props.titleFontFamily.replace(';', '') : 'inherit')} !important;
  font-weight: 500 !important;
  font-size: ${(props) => props.subtitleFontSize || '16'}px !important;
  line-height: ${(props) => props.subtitleFontSize || '16'}px !important;
  text-align: ${(props) => (props.titleAlignment ? props.titleAlignment.toLowerCase() : 'center')} !important;
  color: white;
  text-decoration: none !important;
  margin-bottom: 16px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
  a,
  a:visited,
  a:active,
  a:hover,
  a:focus {
    color: white;
  }
`

const Titles = ({
  hasNonShoppableButton,
  hasShoppableButtonSmall,
  hasShoppableButtonLarge,
  titleActive,
  titleFontFamily,
  titleColor,
  titleAlignment,
  titleFontSize,
  subtitleColor,
  subtitleFontSize,
  subtitleActive,
  titleVideoObject,
  isLandscape
}) => {
  let paddingBottom = '0px'
  if (hasNonShoppableButton) {
    paddingBottom = '60px'
  }
  if (hasShoppableButtonSmall) {
    paddingBottom = '100px'
  }
  if (hasShoppableButtonLarge) {
    paddingBottom = '120px'
  }
  if (isLandscape) {
    paddingBottom = '0px'
  }
  return (
    <Flex flexDirection="column" width="auto" pb={paddingBottom}>
      {titleVideoObject.title && titleActive && (
        <VideoTitle
          titleFontSize={titleFontSize}
          titleAlignment={titleAlignment}
          titleColor={titleColor}
          titleFontFamily={titleFontFamily}
          dangerouslySetInnerHTML={{ __html: titleVideoObject.title }}
        ></VideoTitle>
      )}
      {titleVideoObject.subtitle && subtitleActive && (
        <VideoSubtitle
          titleAlignment={titleAlignment}
          subtitleFontSize={subtitleFontSize}
          subtitleColor={subtitleColor}
          titleFontFamily={titleFontFamily}
          dangerouslySetInnerHTML={{ __html: titleVideoObject.subtitle }}
        ></VideoSubtitle>
      )}
    </Flex>
  )
}

const InnerTitleContainer = styled(Flex)`
  box-sizing: border-box !important;
  z-index: 1;
  position: absolute;
  bottom: 30px;
  left: 0px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 50px;
  flex-direction: column-reverse;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  left: 50% !important;
  transform: translate(-50%);
  border-radius: 0px 0px 8px 8px;
`

const InnerContainer = styled(Flex)``

const ImagePreview = styled.img`
  object-fit: cover;
  width: 100%;
  border-radius: 8px;
`

const Button = styled.button`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border: ${(props) => props.border};
  font-family: 'Inter';
  height: 42px;
  border-radius: ${(props) => (typeof props.borderRadius === 'number' ? props.borderRadius : 8)}px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
  z-index: 1;
  position: absolute;
  bottom: 46px;
  left: 0px;
  z-index: 110001;
  overflow: hidden;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  outline: inherit;
  width: ${(props) => `calc(${props.videoWidth}px - 32px)`} !important;
  ${(props) =>
    !props.buttonFullWidth
      ? `width: fit-content !important; padding-left: 16px !important; padding-right: 16px !important;`
      : null}
  p {
    text-decoration: none;
    white-space: nowrap;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 600;
    line-height: 20px;
    padding: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`

const VideoElement = ({
  containerRef,
  index,
  video,
  activeVideoIndex,
  setActiveVideoIndex,
  isLight,
  campaign,
  autoplay,
  idExtension,
  values,
  setFieldValue,
  videos,
  setShowVideoToProductModal,
  noModalInteraction
}) => {
  const isActive = index === activeVideoIndex
  const isLandscape = video.aspectRatioCalc > 1
  const outerWidth = isActive ? '50%' : '25%'
  const outerHeight = isActive ? '100%' : '50%'
  const containerWidth = containerRef.current ? containerRef.current.clientWidth : null
  const containerHeight = containerRef.current ? containerRef.current.clientHeight - 120 - 60 : null // minus the padding
  const elementWidth = isActive ? containerWidth / 2 : containerWidth / 4
  const elementHeight = isActive ? containerHeight : containerHeight / 2
  const innerWidth = isLandscape ? elementWidth : (elementHeight / 16) * 9
  const innerHeight = isLandscape ? (elementWidth / 16) * 9 : elementHeight
  const titleVideoObject = campaign.videoObjects.find((vo) => vo.videoId === video.id.toString() && vo.type === 'TITLE') || {}
  let buttonVideoObject = campaign.videoObjects.find((el) => el.videoId === video.id.toString() && el.type === 'BUTTON')
  console.log(campaign.videoObjects)
  if (campaign.isDownloadCampaign && campaign.type !== WIDGET_TYPES.SEARCH) {
    buttonVideoObject = {
      buttonLink: `https://stream.mux.com/${video.playbackId}/${
        video.maxStoredResolution === 'SD' ? 'medium' : 'high'
      }.mp4?download=${encodeURIComponent(video.name)}`,
      buttonText: 'Download'
    }
  }
  const marginBottom = isLandscape ? elementHeight / 2 - innerHeight / 2 : '0'

  let buttonUrl = ''
  if (buttonVideoObject) {
    buttonUrl =
      process.env.NODE_ENV !== 'production'
        ? `http://localhost:8080/campaign/click?url=${encodeURIComponent(buttonVideoObject.buttonLink)}&campaignId=${
            campaign.id
          }&type=WIDGET`
        : `https://api.getclipara.com/campaign/click?url=${encodeURIComponent(buttonVideoObject.buttonLink)}&campaignId=${
            campaign.id
          }&type=WIDGET`
  }

  return (
    <VideoContainer
      onClick={() => {
        if (isActive) {
          return
        }
        setActiveVideoIndex(index)
      }}
      index={index}
      activeVideoIndex={activeVideoIndex}
      isLandscape={video.aspectRatioCalc > 1}
      isActive={isActive}
      position="relative"
      height={outerHeight}
      minWidth={outerWidth}
      width={outerWidth}
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
    >
      <InnerContainer width={innerWidth} height={innerHeight} videos={videos}>
        {isActive && !noModalInteraction ? (
          <CarouselPopover
            video={video}
            values={values}
            setFieldValue={setFieldValue}
            setShowVideoToProductModal={setShowVideoToProductModal}
            videos={videos}
          />
        ) : null}
        {video.type === 'VIDEO' ? (
          <>
            {video.playbackId ? (
              <FullScreenPlayer
                campaign={campaign}
                isLight={isLight}
                isActive={isActive}
                playerId={`__clipara-player${idExtension}-${video.id}-${index}`}
                video={video}
                autoplay={autoplay}
                videoObject={buttonVideoObject}
                videoWidth={innerWidth}
              />
            ) : (
              <ProcessingPreview />
            )}
          </>
        ) : (
          <ImagePreview src={`https://clipara.b-cdn.net/${video.path}?width=900`} />
        )}
        {isActive &&
        buttonVideoObject &&
        buttonVideoObject.buttonText &&
        buttonVideoObject.buttonLink &&
        !campaign.isDownloadCampaign ? (
          <a target="_blank" rel="noreferrer" href={buttonUrl}>
            <Button
              buttonFullWidth={campaign.buttonFullWidth}
              videoWidth={innerWidth}
              borderRadius={campaign.buttonBorderRadius}
              backgroundColor={hexToRgba(campaign.buttonColor, campaign.buttonOpacity / 100) || campaign.buttonRgba}
              color={campaign.buttonFontColor}
              border={`2px solid ${campaign.buttonBorderColor}`}
            >
              <p>{buttonVideoObject.buttonText}</p>
            </Button>
          </a>
        ) : null}
        {isActive && campaign.isDownloadCampaign ? (
          <Box width={`${innerWidth}px`} position="relative">
            <CarouselDownloadButton activeResult={video} campaign={campaign} />
          </Box>
        ) : null}
        {campaign.titleActive && titleVideoObject && titleVideoObject.id && isActive ? (
          <InnerTitleContainer width={innerWidth} mb={marginBottom}>
            <Titles
              hasNonShoppableButton={!!buttonVideoObject}
              titleFontSize={campaign.titleFontSize}
              titleActive={campaign.titleActive}
              titleFontFamily={campaign.titleFontFamily}
              titleColor={campaign.titleColor}
              titleAlignment={campaign.titleAlignment}
              subtitleColor={campaign.subtitleColor}
              subtitleFontSize={campaign.subtitleFontSize}
              subtitleActive={campaign.subtitleActive}
              titleVideoObject={titleVideoObject}
              isLandscape={isLandscape}
            />
          </InnerTitleContainer>
        ) : null}
      </InnerContainer>
    </VideoContainer>
  )
}

const Carousel = ({
  videos,
  isLight,
  campaign,
  autoplay,
  isFullScreen,
  values,
  setFieldValue,
  setShowVideoToProductModal,
  noModalInteraction,
  setActiveVideoIndex: parentSetActiveVideoIndex
}) => {
  const [activeVideoIndex, setActiveVideoIndex] = useState(0)
  const hasLandscapeVideo = videos.find((video) => video.aspectRatioCalc > 1)
  const containerRef = useRef()
  const [search, setSearch] = useSearchParams()

  const [reload, setReload] = useState()
  useEffect(() => {
    setReload(Math.random())
  }, [search.get('step')])

  useEffect(() => {
    parentSetActiveVideoIndex && parentSetActiveVideoIndex(activeVideoIndex)
  }, [activeVideoIndex])

  return (
    <>
      <CarouselContainer ref={containerRef} id="__clipara-carousel" activeVideoIndex={activeVideoIndex}>
        <Flex minWidth="25%" width="25%" />
        {videos.map((video, index) => {
          return (
            <VideoElement
              noModalInteraction={noModalInteraction}
              containerRef={containerRef}
              key={index}
              setActiveVideoIndex={setActiveVideoIndex}
              activeVideoIndex={activeVideoIndex}
              index={index}
              video={video}
              campaign={campaign}
              isLight={isLight}
              autoplay={autoplay}
              isFullScreen={isFullScreen}
              values={values}
              setFieldValue={setFieldValue}
              videos={videos}
              setShowVideoToProductModal={setShowVideoToProductModal}
            />
          )
        })}
      </CarouselContainer>
      {activeVideoIndex !== 0 ? (
        <LeftChevron
          hasLandscapeVideo={hasLandscapeVideo}
          isLight={isLight}
          onClick={() => {
            setActiveVideoIndex(activeVideoIndex - 1)
            parentSetActiveVideoIndex && parentSetActiveVideoIndex(activeVideoIndex - 1)
          }}
        >
          <img src={`/__clipara-chevron-left-white.svg`} alt="chevron icon" width="20px" height="20px" />
        </LeftChevron>
      ) : null}
      {activeVideoIndex !== videos.length - 1 ? (
        <RightChevron
          hasLandscapeVideo={hasLandscapeVideo}
          isLight={isLight}
          onClick={() => {
            setActiveVideoIndex(activeVideoIndex + 1)
            parentSetActiveVideoIndex && parentSetActiveVideoIndex(activeVideoIndex + 1)
          }}
        >
          <img src={`/__clipara-chevron-left-white.svg`} alt="chevron icon" width="20px" height="20px" />
        </RightChevron>
      ) : null}
    </>
  )
}

export default ({
  videos,
  isLight,
  campaign,
  autoplay,
  values,
  setFieldValue,
  widget,
  setShowVideoToProductModal,
  noModalInteraction,
  setActiveVideoIndex
}) => {
  if (values.type === WIDGET_TYPES.SEARCH) {
    console.log(campaign)
    return (
      <Flex px="24px">
        <SearchPreview isMobile={false} widget={widget || {}} campaign={campaign} videos={videos} />
      </Flex>
    )
  }
  return (
    <Carousel
      noModalInteraction={noModalInteraction}
      isLight={isLight}
      videos={videos}
      campaign={campaign}
      autoplay={autoplay}
      values={values}
      setFieldValue={setFieldValue}
      setShowVideoToProductModal={setShowVideoToProductModal}
      setActiveVideoIndex={setActiveVideoIndex}
    />
  )
}
