import React from 'react'
import styled from 'styled-components'
import { Formik, Field } from 'formik'
import { Flex, Box, Container } from '../../ui/Layout.js'
import { space } from 'styled-system'
import formikFormWrapper from '../../formikFormWrapper.js'
import { Input, TextArea } from '../../ui/Input.js'
import { H1, H2, H3, H6, Label, Text, Span, GradientFont, Error, SmallTitle } from '../../ui/Typography.js'
import { BUTTON_STYLES } from '../types.js'
import ColorPicker from '../../ui/ColorPicker.js'
import { getChevronStyling, getChevronImg, getChevronImgSize } from '../chevronStyles.js'
const baseUrl = 'https://widget-v2.getclipara.com'

const FormInput = formikFormWrapper(Input)

const ButtonStyle = ({ values, setFieldValue }) => {
  return (
    <>
      <Flex flexWrap="wrap" mb="16px">
        {[false, true].map((isPartialStart, i) => {
          const isSelected = values.widget.widgetIsPartialStart === isPartialStart
          return (
            <Flex
              flexDirection="column"
              boxSizing="border-box"
              cursor="pointer"
              justifyContent="center"
              alignItems="center"
              px="8px"
              borderRadius="8px"
              width="167px"
              height="125px"
              overflow="hidden"
              key={isPartialStart}
              mr={i % 2 === 0 ? '16px' : 0}
              bg={isSelected ? 'rgba(235, 242, 250, 1)' : 'white'}
              border={isSelected ? '3px solid rgba(120, 149, 255, 1)' : '1px solid rgba(166, 183, 212, 1)'}
              onClick={() => {
                setFieldValue(`widget.widgetIsPartialStart`, isPartialStart)
              }}
            >
              {isPartialStart ? (
                <svg width="167" height="62" viewBox="0 0 155 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="-11.5" y="0.5" width="40" height="61" rx="3.5" fill="#E1E8FF" stroke="#A6B7D4" />
                  <rect x="35.5" y="0.5" width="40" height="61" rx="3.5" fill="#E1E8FF" stroke="#A6B7D4" />
                  <rect x="82.5" y="0.5" width="40" height="61" rx="3.5" fill="#E1E8FF" stroke="#A6B7D4" />
                  <rect x="129.5" y="0.5" width="40" height="61" rx="3.5" fill="#E1E8FF" stroke="#A6B7D4" />
                </svg>
              ) : (
                <svg width="135" height="62" viewBox="0 0 135 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="40" height="61" rx="3.5" fill="#E1E8FF" stroke="#A6B7D4" />
                  <rect x="47.5" y="0.5" width="40" height="61" rx="3.5" fill="#E1E8FF" stroke="#A6B7D4" />
                  <rect x="94.5" y="0.5" width="40" height="61" rx="3.5" fill="#E1E8FF" stroke="#A6B7D4" />
                </svg>
              )}
              {!isPartialStart ? (
                <Label mt="16px" mb="0px">
                  In view
                </Label>
              ) : (
                <Label mt="16px" mb="0px">
                  Overlap
                </Label>
              )}
            </Flex>
          )
        })}
      </Flex>
    </>
  )
}

export default ButtonStyle
