import React, { useState } from 'react'
import styled from 'styled-components'
import { useCookies } from 'react-cookie'

import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { colors } from '../ui/helpers.js'
import { H1, H2, H3, Text, Span, SmallTitle, GradientFont, Error } from '../ui/Typography.js'
import { Flex, Box } from '../ui/Layout.js'
import Icon from '../ui/Icon.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Button from '../ui/Button.js'
import PopoverDropdown from '../ui/PopoverDropdown.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { openChat } from './ChatButton'
import { useAuth } from '../Auth.js'
import { useModal } from '../Modal.js'
import { isProPlan } from '../Plans.js'

export const TABS = {
  HOME: 'HOME',
  VIDEOS: 'VIDEOS',
  IMAGES: 'IMAGES',
  CAMPAIGNS: 'CAMPAIGNS',
  ANALYTICS: 'ANALYTICS',
  ACCOUNT: 'ACCOUNT',
  INTEGRATIONS: 'INTEGRATIONS'
}

export const pathsToTabs = {
  '/videos': 'VIDEOS',
  '/': 'HOME',
  '/videos/upload': 'VIDEOS',
  '/images': 'IMAGES',
  '/images/upload': 'IMAGES',
  '/campaigns': 'CAMPAIGNS',
  '/campaigns/create': 'NO_NAV',
  '/analytics': 'ANALYTICS',
  '/account': 'ACCOUNT',
  '/integrations': 'INTEGRATIONS',
  '/install': 'INSTALL'
}

export const catchAllRoutes = {
  '/videos/': 'VIDEOS',
  '/campaigns/edit/': 'NO_NAV',
  '/campaigns/': 'CAMPAIGNS'
}

const PopoverContainer = styled(Flex)`
  box-shadow: 0px 0px 1px 0px rgba(50, 50, 71, 0.2);
  box-shadow: 0px 1px 2px 2px rgba(50, 50, 71, 0.08);
  border-radius: 8px;
  width: 218px;
  padding-left: 16px;
  padding-right: 16px;
`

const ChangeOrganisation = () => {
  let { user } = useAuth()
  let navigate = useNavigate()

  if (!user || !user.organisations || user.organisations.length === 1) {
    return null
  }
  const selectedOrg = user.organisations.find((org) => org.organisationId === user.organisationId)

  const changeOrg = async (newOrganisationId) => {
    const res = await api.post('/organisation/change-organisation', { organisationId: newOrganisationId })
    window.location.reload()
  }

  return (
    <Flex px="16px" mt="-24px" mb="16px">
      <PopoverDropdown
        right
        width="218px"
        renderCustomLauncher={() => (
          <PopoverContainer cursor="pointer" py="8px">
            <Flex>
              <Icon mt="3px" mr="19px" icon="house-purple" width="16px" height="16px" />
              <Text fontWeight="500">{selectedOrg.organisationName || selectedOrg.firstName + ' ' + selectedOrg.lastName}</Text>
            </Flex>
            <Icon ml="16px" mt="4px" icon="chevron-down-black" width="16px" height="16px" />
          </PopoverContainer>
        )}
        options={user.organisations.map((u) => {
          return { label: u.organisationName || u.firstName + ' ' + u.lastName, onClick: () => changeOrg(u.organisationId) }
        })}
      />
    </Flex>
  )
}

const PageLink = ({ isActive, label, icon, to, onClick, href }) => {
  if (onClick) {
    return (
      <Flex
        onClick={onClick}
        mt="8px"
        mb="8px"
        cursor={isActive ? 'default' : 'pointer'}
        alignItems="center"
        borderLeft={`3px solid ${isActive ? colors.blue : 'white'}`}
      >
        <Icon ml="24px" mr="22px" icon={icon} />
        <SmallTitle color={isActive ? colors.blue : colors.textLight}>{label}</SmallTitle>
      </Flex>
    )
  }
  if (href) {
    return (
      <a href={href} target="_blank" rel="noreferrer">
        <Flex
          mt="8px"
          mb="8px"
          cursor={isActive ? 'default' : 'pointer'}
          alignItems="center"
          borderLeft={`3px solid ${isActive ? colors.blue : 'white'}`}
        >
          <Icon ml="24px" mr="22px" icon={icon} />
          <SmallTitle color={isActive ? colors.blue : colors.textLight}>{label}</SmallTitle>
        </Flex>
      </a>
    )
  }
  return (
    <Link to={to}>
      <Flex
        mt="8px"
        mb="8px"
        cursor={isActive ? 'default' : 'pointer'}
        alignItems="center"
        borderLeft={`3px solid ${isActive ? colors.blue : 'white'}`}
      >
        <Icon ml="24px" mr="22px" icon={icon} />
        <SmallTitle color={isActive ? colors.blue : colors.textLight}>{label}</SmallTitle>
      </Flex>
    </Link>
  )
}

const UpgradeContainer = styled(Flex)`
  background: linear-gradient(225deg, #bb65ff 0%, #4c6fff 100%);
  height: 37px;
  box-sizing: border-box;
  align-items: center;
  border-radius: 8px;
  width: calc(100% - 40px);
  margin-left: 20px;
  padding-left: 12px;
`

const BetaBox = styled(Box)`
  position: absolute;
  right: 82px;
  top: 10px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 30px;
  background: linear-gradient(225deg, #bb65ff 0%, #4c6fff 100%);
`

const SideNav = () => {
  let auth = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies(['russwar_auth'])
  const { setShowInstallWidgetModal, setShowReferModal } = useModal()

  const exactMatch = pathsToTabs[location.pathname]
  const catchAllPath =
    !exactMatch &&
    Object.keys(catchAllRoutes).find((route) => {
      return location.pathname.substring(0, route.length) === route
    })
  const activeTab = exactMatch || catchAllRoutes[catchAllPath]
  if (!activeTab || activeTab === 'NO_NAV') {
    return null
  }
  return (
    <>
      <Box width="250px" minWidth="250px" />
      <Flex
        flexDirection="column"
        width="250px"
        minWidth="250px"
        height="100vh"
        bg="white"
        position="fixed"
        left="0px"
        top="0px"
        borderRight={`1px solid ${colors.softBlue}`}
        mr="250px"
        overflowY="scroll"
        noScrollBar
      >
        <Flex flexDirection="column" minHeight="425px" height="425px">
          <Image m="14px" mb="66px" src="/logo.svg" width={130} />
          <ChangeOrganisation />
          <PageLink
            to="/"
            icon={`home-${activeTab === TABS.HOME ? 'blue' : 'grey'}`}
            label="Home"
            isActive={activeTab === TABS.HOME}
          />
          <PageLink
            to="/campaigns"
            icon={`campaigns-icon-${activeTab === TABS.CAMPAIGNS ? 'blue' : 'grey'}`}
            label="Pages & Campaigns"
            isActive={activeTab === TABS.CAMPAIGNS}
          />
          <PageLink
            to="/videos"
            icon={`videos-icon-${activeTab === TABS.VIDEOS ? 'blue' : 'grey'}`}
            label="Videos"
            isActive={activeTab === TABS.VIDEOS}
          />
          <Box position="relative">
            <PageLink
              to="/images"
              icon={`image-${activeTab === TABS.IMAGES ? 'blue' : 'grey'}`}
              label="Images"
              isActive={activeTab === TABS.IMAGES}
            />
          </Box>
          <PageLink
            to="/analytics"
            icon={`analytics-icon-${activeTab === TABS.ANALYTICS ? 'blue' : 'grey'}`}
            label="Analytics"
            isActive={activeTab === TABS.ANALYTICS}
          />
        </Flex>
        <Flex width="210px" mx="20px" mb="8px" borderBottom="1px solid #EDF2F7;" />
        <PageLink
          icon={`tools-grey`}
          label="Install"
          onClick={() => {
            if (auth.user.shopifyShopUrl) {
              navigate('/shopify-install')
            } else {
              setShowInstallWidgetModal(true)
            }
          }}
        />
        <PageLink
          to="/integrations"
          icon={`arrow-repeat-${activeTab === TABS.INTEGRATIONS ? 'blue' : 'grey'}`}
          label="Integrations"
          isActive={activeTab === TABS.INTEGRATIONS}
        />
        <Flex height="100%" flexDirection="column" justifyContent="flex-end">
          {!isProPlan(auth.user) ? (
            <Link to="/account?tab=BILLING">
              <UpgradeContainer>
                <Icon mr="16px" icon="lightening-white" height="16px" width="11px" />
                <Text fontWeight="500" color="white">
                  Upgrade to Pro
                </Text>
              </UpgradeContainer>
            </Link>
          ) : null}
          <PageLink
            icon={`cash-grey`}
            label="Refer & Earn"
            onClick={() => {
              setShowReferModal(true)
            }}
          />
          <PageLink icon={`chat-question-light`} label="Help" onClick={openChat} />
          <PageLink
            icon={`file-grey`}
            label="Guides"
            href="https://clipara.notion.site/clipara/Clipara-Guides-1b7ea70c03d9433699335b05f336dd37"
          />
          <PageLink icon={`tools-grey`} label="Account" to="/account" isActive={activeTab === TABS.ACCOUNT} />
          <Flex
            mt="8px"
            mb="16px"
            cursor="pointer"
            alignItems="center"
            onClick={() => {
              removeCookie('russwar_auth', {
                path: '/',
                domain: process.env.NODE_ENV === 'production' ? '.getclipara.com' : 'localhost'
              })
              auth.setUser()
              navigate('/login')
            }}
          >
            <Icon ml="24px" mr="22px" icon="logout-grey" />
            <SmallTitle color={colors.textLight}>Logout</SmallTitle>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default SideNav
