import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { colors, getDisplaySize } from '../ui/helpers'
import { Label, Error, Text } from '../ui/Typography'
import { Flex, Box } from '../ui/Layout'
import Toggle from '../ui/Toggle.js'
import Button from '../ui/Button'
import Icon from '../ui/Icon'
import VideoPreview from '../ui/VideoPreview'
import Image from '../ui/Image.js'
import { WIDGET_TYPES, SINGLE_EMBED_DIMENSIONS } from '../Campaigns/types'
import ProcessingPreview from './ProcessingPreview'
import EmbedMobileCarousel from './EmbedMobileCarousel'
import EmbedCarousel from './EmbedCarousel'
import EmbedSingle from './EmbedSingle'
import InlineMobileCarousel from './InlineMobileCarousel'
import InlineCarousel from './InlineCarousel'
import NavigationMobileCarousel from './NavigationMobileCarousel'
import NavigationCarousel from './NavigationCarousel'
import NavigationGrid from './Grid'
import Search from './Search/Search'
import InlineSingle from './InlineSingle'

const DesktopContainer = styled(Flex)`
  ${(props) => !props.isStories && `zoom: 0.6;`}
  height: 100%;
  max-height: 100%;
  align-items: center;
  border-radius: 16px;
  @media (min-width: 1600px) {
    zoom: 0.9;
  }
`

const GridDesktopContainer = styled(Flex)`
  zoom: 0.6;
  align-items: center;
  border-radius: 16px;
  @media (min-width: 1600px) {
    zoom: 0.9;
  }
`

const SearchDesktopContainer = styled(Flex)`
  zoom: 0.8;
  align-items: center;
  border-radius: 16px;
  @media (min-width: 1600px) {
    zoom: 0.9;
  }
`

const SearchMobileContainer = styled(DesktopContainer)`
  zoom: 0.7;
  padding-top: 16px;
  background: white;
  border-radius: 0px;
  overflow-y: scroll;
  align-items: flex-start;
`

const MobileContainerContainer = styled(Flex)`
  flex-direction: column;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  max-height: 85vh;
  border-radius: 24px 24px 24px 24px;
  background: white;
  height: 80vh;
  zoom: 1;
  width: 40vh;
  overflow: hidden;
`

const MobileContainer = styled(Flex)`
  flex-direction: column;
  max-height: 100%;
  background: white;
  height: 100%;
`

const DesktopContainerContainer = styled(Flex)`
  flex-direction: column;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  max-height: 85vh;
  height: 100%;
  border-radius: 24px 24px 24px 24px;
  background: white;
  zoom: 1;
  overflow: hidden;
  overflow-y: scroll;
  margin-top: 24px;
`

const GridMobileContainer = styled(DesktopContainer)`
  padding-top: 16px;
  background: white;
  overflow-y: scroll;
  align-items: flex-start;
`

const DesktopBlock = styled(Flex)`
  background-color: rgba(210, 210, 210, 0.15);
  height: 100%;
  border-radius: 16px;
`

const FakeMobile = ({ children }) => {
  return (
    <MobileContainerContainer>
      <Flex height="52px" minHeight="52px" alignItems="center" px="16px" borderBottom="1px solid #e6e6e6">
        <Icon pl="8px" icon="browser-dots-grey" height={12} width={52} />
      </Flex>
      {children}
    </MobileContainerContainer>
  )
}

const FakeDesktop = ({ children }) => {
  return (
    <DesktopContainerContainer>
      <Flex height="52px" minHeight="52px" alignItems="center" px="16px" borderBottom="1px solid #e6e6e6">
        <Icon pl="8px" icon="browser-dots-grey" height={12} width={52} />
      </Flex>
      {children}
    </DesktopContainerContainer>
  )
}

export const SearchPreview = ({ isMobile, widget, campaign, videos }) => {
  if (isMobile) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <FakeMobile>
          <SearchMobileContainer id="__clipara-embed" justifyContent="center" position="relative">
            <Flex width={`calc(100% - ${(widget.marginX || 0) * 2}px)`}>
              <Search isMobile={isMobile} campaign={{ ...campaign, ...widget, videos }} />
            </Flex>
          </SearchMobileContainer>
        </FakeMobile>
      </Flex>
    )
  }
  return (
    <FakeDesktop>
      <SearchDesktopContainer isStories={false} id="__clipara-embed" py="50px" justifyContent="center" position="relative">
        <Flex width={`calc(100% - ${(widget.marginX || 0) * 2}px)`}>
          <Flex my="20px">
            <Search campaign={{ ...campaign, ...widget, videos }} />
          </Flex>
        </Flex>
      </SearchDesktopContainer>
    </FakeDesktop>
  )
}

export default ({ videos, campaign, widget, isMobile, openFullscreenDesktopPreview }) => {
  if (widget.type === WIDGET_TYPES.SEARCH) {
    return <SearchPreview isMobile={isMobile} widget={widget} campaign={campaign} videos={videos} />
  }

  if (widget.type === WIDGET_TYPES.SINGLE_EMBED) {
    return (
      <FakeDesktop>
        <Flex justifyContent="center" height="100%" alignItems="center">
          <Flex
            id="__clipara-embed"
            flexDirection="column"
            width={widget.dimensions === SINGLE_EMBED_DIMENSIONS.PORTRAIT ? '300px' : `500px`}
            my={`${widget.marginY || 20}px`}
            mx={`${widget.marginX}px` || 0}
            heigh
            // my="20px"
          >
            <EmbedSingle
              campaign={{ ...campaign, ...widget, videos }}
              setShowFullscreenPlayer={() => null}
              setActiveFullScreenVideo={() => null}
            />
          </Flex>
        </Flex>
      </FakeDesktop>
    )
  }

  const isInlineCarousel = widget.type === WIDGET_TYPES.INLINE_CAROUSEL

  if (isMobile && isInlineCarousel) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <FakeMobile>
          <MobileContainer id="__clipara-embed" justifyContent="center">
            <Flex my="20px">
              <InlineMobileCarousel campaign={{ ...campaign, ...widget, videos }} />
            </Flex>
          </MobileContainer>
        </FakeMobile>
      </Flex>
    )
  }

  if (isInlineCarousel) {
    return (
      <FakeDesktop>
        <DesktopContainer isStories={false} id="__clipara-embed" py="50px" justifyContent="center">
          <Flex width={`calc(100% - ${(widget.marginX || 0) * 2}px)`}>
            <Flex my="20px">
              <InlineCarousel campaign={{ ...campaign, ...widget, videos }} />
            </Flex>
          </Flex>
        </DesktopContainer>
      </FakeDesktop>
    )
  }
  if (widget.type === WIDGET_TYPES.INLINE_SINGLE) {
    return (
      <FakeDesktop>
        <Flex justifyContent="center" height="100%" alignItems="center">
          <Flex
            id="__clipara-embed"
            flexDirection="column"
            width={
              widget.widgetThumbnailDimensions === SINGLE_EMBED_DIMENSIONS.PORTRAIT ||
              widget.widgetThumbnailDimensions === SINGLE_EMBED_DIMENSIONS.SHORT_PORTRAIT
                ? '300px'
                : `500px`
            }
            my={`${widget.marginY || 20}px`}
            mx={`${widget.marginX}px` || 0}
            heigh
            // my="20px"
          >
            <InlineSingle
              isMobile={isMobile}
              campaign={{ ...campaign, ...widget, videos }}
              setShowFullscreenPlayer={() => null}
              setActiveFullScreenVideo={() => null}
            />
          </Flex>
        </Flex>
      </FakeDesktop>
    )
  }

  const isNavigationCarousel = widget.type === WIDGET_TYPES.NAVIGATION_CAROUSEL

  if (isMobile && isNavigationCarousel) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <FakeMobile>
          <MobileContainer id="__clipara-embed" justifyContent="center">
            <Flex my="20px">
              <NavigationMobileCarousel campaign={{ ...campaign, ...widget, videos }} />
            </Flex>
          </MobileContainer>
        </FakeMobile>
      </Flex>
    )
  }

  if (isNavigationCarousel) {
    return (
      <FakeDesktop>
        <DesktopContainer isStories={false} id="__clipara-embed" py="50px" justifyContent="center">
          <Flex width={`calc(100% - ${(widget.marginX || 0) * 2}px)`}>
            <Flex my="20px">
              <NavigationCarousel campaign={{ ...campaign, ...widget, videos }} />
            </Flex>
          </Flex>
        </DesktopContainer>
      </FakeDesktop>
    )
  }

  const isNavigationGrid = widget.type === WIDGET_TYPES.NAVIGATION_GRID

  if (isNavigationGrid) {
    if (isMobile) {
      return (
        <Flex alignItems="center" justifyContent="center" height="100%">
          <FakeMobile>
            <GridMobileContainer id="__clipara-embed" justifyContent="center">
              <Flex width={`calc(100% - ${(widget.marginX || 0) * 2}px)`}>
                <NavigationGrid isMobile={isMobile} campaign={{ ...campaign, ...widget, videos }} />
              </Flex>
            </GridMobileContainer>
          </FakeMobile>
        </Flex>
      )
    }
    return (
      <FakeDesktop>
        <GridDesktopContainer isStories={false} id="__clipara-embed" py="50px" justifyContent="center">
          <Flex width={`calc(100% - ${(widget.marginX || 0) * 2}px)`}>
            <Flex my="20px">
              <NavigationGrid campaign={{ ...campaign, ...widget, videos }} />
            </Flex>
          </Flex>
        </GridDesktopContainer>
      </FakeDesktop>
    )
  }
  const isGrid = widget.type === WIDGET_TYPES.GRID

  if (isGrid) {
    if (isMobile) {
      return (
        <Flex alignItems="center" justifyContent="center" height="100%">
          <FakeMobile>
            <GridMobileContainer id="__clipara-embed" justifyContent="center">
              <Flex width={`calc(100% - ${(widget.marginX || 0) * 2}px)`}>
                <NavigationGrid isMobile={isMobile} campaign={{ ...campaign, ...widget, videos }} />
              </Flex>
            </GridMobileContainer>
          </FakeMobile>
        </Flex>
      )
    }
    return (
      <FakeDesktop>
        <GridDesktopContainer isStories={false} id="__clipara-embed" py="50px" justifyContent="center">
          <Flex width={`calc(100% - ${(widget.marginX || 0) * 2}px)`}>
            <Flex my="20px">
              <NavigationGrid campaign={{ ...campaign, ...widget, videos }} />
            </Flex>
          </Flex>
        </GridDesktopContainer>
      </FakeDesktop>
    )
  }

  const isStories = widget.type === WIDGET_TYPES.STORIES
  if (isMobile) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <FakeMobile>
          <MobileContainer id="__clipara-embed" justifyContent="center">
            <Flex my="20px">
              <EmbedMobileCarousel
                campaign={{ ...campaign, ...widget, videos }}
                setShowFullscreenPlayer={() => true}
                setActiveFullScreenVideo={() => true}
              />
            </Flex>
          </MobileContainer>
        </FakeMobile>
      </Flex>
    )
  }
  if (isStories) {
    return (
      <FakeDesktop>
        <DesktopContainer isStories={isStories} p="16px" justifyContent="center">
          <DesktopBlock width="100%" mr="16px" />
          <Flex flexDirection="column" width="auto" height="100%">
            <DesktopBlock height="100px" />
            <Flex width={`500px`} px={widget.marginX || 0} py={`${widget.marginY || 0}px`} id="__clipara-embed">
              <EmbedCarousel
                campaign={{ ...campaign, ...widget, videos }}
                setShowFullscreenPlayer={() => null}
                setActiveFullScreenVideo={() => null}
              />
            </Flex>
            <DesktopBlock height="100px" />
          </Flex>
        </DesktopContainer>
      </FakeDesktop>
    )
  }
  return (
    <FakeDesktop>
      <DesktopContainer isStories={isStories} id="__clipara-embed" py="50px" justifyContent="center">
        <Flex width={`calc(100% - ${(widget.marginX || 0) * 2}px)`}>
          <EmbedCarousel
            campaign={{ ...campaign, ...widget, videos }}
            setShowFullscreenPlayer={() => null}
            setActiveFullScreenVideo={() => null}
          />
        </Flex>
      </DesktopContainer>
    </FakeDesktop>
  )
}
