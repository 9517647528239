import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { colors, w } from '../ui/helpers.js'
import { H1, H2, Text, Span, GradientFont, Error } from '../ui/Typography.js'
import { Flex, Box } from '../ui/Layout.js'
import Toggle from '../ui/Toggle'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Button from '../ui/Button.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import CodeCopy from '../ui/CodeCopy.js'
import Icon from '../ui/Icon.js'

const TABS = {
  QUICK: 'QUICK',
  MANUAL: 'MANUAL'
}

const ShopifyInstall = (props) => {
  let { user, setUser } = useAuth()
  const [activeTab, setActiveTab] = useState(TABS.QUICK)
  let navigate = useNavigate()

  const setUserConfirmation = async (e) => {
    e.stopPropagation()
    const res = await api.post('/auth/user/update-user-confirmed-install')
    if (res.data.success) {
      const authRes = await api.get('/auth')
      setUser(authRes.data.payload)
    }
    return navigate('/')
  }

  return (
    <Flex backgroundColor="white" minHeight="100vh">
      <Flex position="relative" flexDirection="column" alignItems="center" width="100%" py="76px">
        <Box width="auto" cursor="pointer" position="absolute" top="24px" right="24px">
          <Link to="/">
            <Text light>I’ll do this later</Text>
          </Link>
        </Box>
        <H1 mb="42px" textAlign="center" width="100%" px="60px">
          Hey 👋, let’s get you started, {user.firstName}.
        </H1>
        {activeTab === TABS.QUICK ? (
          <>
            <Text fontWeight="600" mb="8px" fontSize="16px">
              Activate the Clipara Theme Extension
            </Text>
            <Text mb="16px" light>
              If you are on a 1.0 theme or want to install manually, toggle to manual install
            </Text>
          </>
        ) : null}
        {activeTab === TABS.MANUAL ? (
          <>
            <Text fontWeight="600" mb="8px" fontSize="16px">
              Manually Install Code Snippet
            </Text>
            <Text mb="16px" light>
              If you are not on a 1.0 theme and want to use theme extensions, toggle to quick install
            </Text>
          </>
        ) : null}
        <Toggle
          label1="Quick install"
          label2="Manual"
          value1={TABS.QUICK}
          value2={TABS.MANUAL}
          selected={activeTab}
          setSelected={setActiveTab}
          width="180px"
          mb="40px"
        />

        {activeTab === TABS.QUICK ? (
          <>
            <img width="334px" src="/welcome/themeExtensionInstall.jpg" />
            <Text mt="24px" mb="32px" light>
              Make sure to click ‘Save’ in the Shopify theme editor as soon as you’ve activated
            </Text>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://${user.shopifyShopUrl}/admin/themes/current/editor?context=apps&activateAppId=7b58bb0c-6a86-4ef5-83de-23ad165cc561/app-embed`}
            >
              <Button width="89px" mb="32px" variant="black" label="Activate" />
            </a>
            <Flex width="auto" alignItems="center" cursor="pointer" onClick={setUserConfirmation}>
              <Text mr="8px" fontWeight="600">
                I’ve activated, let’s begin!
              </Text>
              <Icon icon="arrow-right-black" width="16px" height="16px" />
            </Flex>
          </>
        ) : null}

        {activeTab === TABS.MANUAL ? (
          <>
            <Text mb="16px" light>
              {'Copy and paste this between the <head> tags in your Theme.Liquid file'}. Click{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://clipara.notion.site/Enabling-Shopify-Installation-ea35bddbc47147f38e405ebf6f256ae4"
              >
                <Span underline>here</Span>
              </a>{' '}
              for a guide.
            </Text>
            <CodeCopy
              width="700px"
              id="install-modal-code-snippet"
              code={`<script>window.__clipara = {};window.__clipara.organisationId = ${user.organisationId};</script>
<script defer src="https://widget.getclipara.com/widget.js" type="text/javascript"></script>`}
            />

            <Button onClick={setUserConfirmation} width="89px" mb="8px" variant="black" label="Done" />
          </>
        ) : null}
      </Flex>
    </Flex>
  )
}

export default ShopifyInstall
