import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import hexToRgba from 'hex-to-rgba'

import { colors, getDisplaySize } from '../ui/helpers'
import { Label, Error, Text } from '../ui/Typography'
import { Flex, Box } from '../ui/Layout'
import Button from '../ui/Button'
import ProcessingPreview from './ProcessingPreview'
import FullScreenPlayer from './FullScreenPlayer'
import CarouselPreview from './CarouselPreview'
import Icon from '../ui/Icon.js'

const FullscreenContainer = styled(Flex)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.9);
  align-items: center;
  justify-content: center;
`

const TemplateContainer = styled(Flex)`
  width: 45vh;
  height: 80vh;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 1px 14px rgba(50, 50, 71, 0.9), 0px 0px 1px rgba(50, 50, 71, 0.2);
`

const Close = styled.img`
  width: 19px;
  height: 19px;
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 110001;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`

const Logo = styled.img`
  position: absolute;
  top: 11px;
  left: 16px;
  width: 30%;
  max-width: 90px;
  z-index: 110001;
`

const CarouselContainer = styled(Box)`
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  width: 100%;
  height: 100%;
  z-index: 100002;
  &&::-webkit-scrollbar {
    width: 0 !important;
  }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  @keyframes bounce-3 {
    0% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(-50px);
    }
    50% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }
`

const CarouselChild = styled(Box)`
  background-color: black;
  scroll-snap-align: start;
  width: 100%;
  height: 80vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.animate
      ? `animation-name: bounce-3;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-iteration-count: 2;
  @keyframes bounce-3 {
    0% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(-50px);
    }
    50% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }`
      : ''}
`

const NumVids = styled.p`
  background-color: rgba(255, 255, 255, 0.4);
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  padding-left: 7px !important;
  padding-right: 7px !important;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter, sans-serif;
  margin: 0px !important;
  position: absolute;
  top: 16px;
  right: 54px;
  color: white !important;
  z-index: 2;
`

const VideoTitle = styled.p`
  font-family: ${(props) => (props.titleFontFamily ? props.titleFontFamily.replace(';') : 'inherit')} !important;
  font-weight: 700 !important;
  font-size: ${(props) => props.widgetDesktopTitleFontSize || '18'}px !important;
  line-height: ${(props) => props.widgetDesktopTitleFontSize || '18'}px !important;
  text-align: ${(props) => (props.titleAlignment ? props.titleAlignment.toLowerCase() : 'center')} !important;
  color: ${(props) => props.titleColor || 'white'};
  text-decoration: none !important;
  margin-bottom: 16px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
  @media (max-width: 700px) {
    font-size: ${(props) => props.widgetMobileTitleFontSize || '16'}px !important;
    line-height: ${(props) => props.widgetMobileTitleFontSize || '16'}px !important;
  }
`

const VideoSubtitle = styled.p`
  font-family: ${(props) => (props.titleFontFamily ? props.titleFontFamily.replace(';') : 'inherit')} !important;
  font-weight: 500 !important;
  font-size: ${(props) => props.subtitleFontSize || '16'}px !important;
  line-height: ${(props) => props.subtitleFontSize || '16'}px !important;
  text-align: ${(props) => (props.titleAlignment ? props.titleAlignment.toLowerCase() : 'center')} !important;
  color: ${(props) => props.subtitleColor || 'white'};
  text-decoration: none !important;
  margin-bottom: 16px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
`

const Titles = ({
  hasNonShoppableButton,
  hasShoppableButtonSmall,
  hasShoppableButtonLarge,
  titleActive,
  titleFontFamily,
  titleColor,
  titleAlignment,
  subtitleColor,
  subtitleFontSize,
  subtitleActive,
  titleVideoObject
}) => {
  let paddingBottom = '0px'
  if (hasNonShoppableButton) {
    paddingBottom = '60px'
  } else if (hasShoppableButtonSmall) {
    paddingBottom = '72px'
  } else if (hasShoppableButtonLarge) {
    paddingBottom = '100px'
  }
  return (
    <Flex flexDirection="column" width="auto" pb={paddingBottom}>
      {titleVideoObject.title && titleActive && (
        <VideoTitle titleAlignment={titleAlignment} titleColor={titleColor} titleFontFamily={titleFontFamily}>
          {titleVideoObject.title}
        </VideoTitle>
      )}
      {titleVideoObject.subtitle && subtitleActive && (
        <VideoSubtitle
          titleAlignment={titleAlignment}
          subtitleFontSize={subtitleFontSize}
          subtitleColor={subtitleColor}
          titleFontFamily={titleFontFamily}
        >
          {titleVideoObject.subtitle}
        </VideoSubtitle>
      )}
    </Flex>
  )
}

const InnerTitleContainer = styled(Flex)`
  z-index: 1;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 50px;
  flex-direction: column-reverse;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
`

const Carousel = ({ videos, setActiveVideo, activeVideo, campaign, screenHeight }) => {
  useEffect(() => {
    const func = () => {
      const element = document.querySelector('.__clipara-mobile-carousel-child')
      if (!element) {
        return
      }
      const positionYofFirstcard = element.getBoundingClientRect().y - 100
      const framesAcross = Math.round(positionYofFirstcard / (screenHeight * 0.8))
      setActiveVideo(-framesAcross)
    }

    document.getElementById('__clipara-mobile-carousel').addEventListener('scroll', () => {
      func()
    }) // detect current item on scroll
  }, [])
  useEffect(() => {
    setTimeout(() => {
      videos.map((video) => {
        document.getElementById(`__clipara-player-mobile-${video.id}`).currentTime = 0
        if (videos[activeVideo].id === video.id) {
          document.getElementById(`__clipara-player-mobile-${videos[activeVideo].id}`).play()
        } else {
          document.getElementById(`__clipara-player-mobile-${video.id}`).pause()
        }
      })
    }, 0)
  }, [activeVideo])

  const [animate, setAnimate] = useState(false)
  useEffect(() => {
    document.getElementById(`__clipara-player-mobile-${videos[0].id}`).addEventListener('ended', () => {
      if (activeVideo === 0 && videos.length > 1) {
        setAnimate(true)
      }
    })
  }, [])

  if (!videos) {
    return null
  }

  return (
    <CarouselContainer id="__clipara-mobile-carousel">
      {videos.map((video, index) => {
        const buttonVideoObject = campaign.videoObjects
          ? campaign.videoObjects.find((el) => el.videoId === video.id && el.type === 'BUTTON')
          : undefined
        const titleVideoObject = campaign.videoObjects.find((vo) => vo.videoId === video.id && vo.type === 'TITLE') || {}
        return (
          <CarouselChild animate={animate && index === 0} className="__clipara-mobile-carousel-child" key={video.id}>
            <NumVids>
              {index + 1} of {videos.length}
            </NumVids>
            {!video.playbackId && <ProcessingPreview position="absolute" left="0" top="0" />}
            <FullScreenPlayer
              fillPortrait
              videoObject={buttonVideoObject}
              isActive
              playerId={`__clipara-player-mobile-${video.id}`}
              video={video}
              campaign={campaign}
            />
            {campaign.titleActive && titleVideoObject && titleVideoObject.type ? (
              <InnerTitleContainer>
                <Titles
                  hasNonShoppableButton={buttonVideoObject}
                  titleFontSize={campaign.titleFontSize}
                  titleActive={campaign.titleActive}
                  titleFontFamily={campaign.titleFontFamily}
                  titleColor={campaign.titleColor}
                  titleAlignment={campaign.titleAlignment}
                  subtitleColor={campaign.subtitleColor}
                  subtitleFontSize={campaign.subtitleFontSize}
                  subtitleActive={campaign.subtitleActive}
                  titleVideoObject={titleVideoObject}
                />
              </InnerTitleContainer>
            ) : null}
          </CarouselChild>
        )
      })}
    </CarouselContainer>
  )
}

const ScrollText = styled(Text)`
  position: absolute;
  bottom: 24px;
  color: white;
`

const MobileFullscreen = ({ campaign, videos, close }) => {
  const [activeVideo, setActiveVideo] = useState(0)
  return (
    <FullscreenContainer id="__clipara-fullscreen-player" onClick={close}>
      <Close onClick={close} width="20px" height="20px" alt="close preview" src="/__clipara-cross-white.svg" />
      <TemplateContainer
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <Flex alignItems="center" justifyContent="center" height="100%">
          <iframe width="100%" height="100%" frameBorder="0" src={`https://player.getclipara.com/share/${campaign.id}`} />
        </Flex>
      </TemplateContainer>
      <ScrollText>Drag screen to move through videos</ScrollText>
    </FullscreenContainer>
  )
}

export default MobileFullscreen
