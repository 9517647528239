import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { Flex } from './Layout'

const Outer = styled(Flex)`
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
  border: 2px solid ${(props) => (props.toggled ? 'black' : 'rgba(201, 206, 214, 1)')};
  border-radius: ${(props) => props.width}px;
  align-items: center;
  justify-content: center;
`

const Inner = styled(Flex)`
  width: ${(props) => props.width / 2}px;
  height: ${(props) => props.width / 2}px;
  ${(props) => (props.toggled ? 'background-color: black;' : '')}
  transition: background-color 0.3s ease;
  border-radius: ${(props) => props.width}px;
`

const CircleToggle = ({ toggled, width }) => {
  return (
    <Outer toggled={toggled} width={width}>
      <Inner toggled={toggled} width={width} />
    </Outer>
  )
}

export default CircleToggle
