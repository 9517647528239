import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { colors, formatSeconds } from '../ui/helpers'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography'
import { Flex, Box, Container } from '../ui/Layout'
import ModalContainer from '../ui/ModalContainer'
import { uniq, difference } from 'ramda'
import { getModalContent } from './types'
import Button from '../ui/Button'
import { useAuth } from '../Auth'
import Image from '../ui/Image'

const ImageContainer = styled(Box)`
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const WidgetDetailsModal = ({ type, link, setIsOpen, onClose }) => {
  const { user } = useAuth()
  if (!type) {
    return null
  }
  return (
    <ModalContainer
      backgroundColor="rgba(0, 0, 0, 0.6)"
      interiorClose
      p="0px"
      width="900px"
      height="600px"
      isOpen={!!type}
      setIsOpen={setIsOpen}
      onClose={onClose}
    >
      <Flex height="100%">
        <Flex flexDirection="column" width="320px" minWidth="320px" p="24px" pt="40px">
          <Text color="black" fontWeight="900" fontSize="32px" lineHeight="43px" mb="32px">
            {getModalContent(type, !!user.shopifyShopUrl).title}
          </Text>
          <Text mb="24px">{getModalContent(type, !!user.shopifyShopUrl).subtitle}</Text>
          <Link to={link}>
            <Button variant="black" label="Create now" />
          </Link>
        </Flex>
        <Flex
          height="100%"
          flexDirection="column"
          backgroundColor="rgba(225, 232, 255, 1)"
          p="24px"
          justifyContent="center"
          alignItems="center"
        >
          <ImageContainer
            width="280px"
            height="520px"
            border="3px solid rgba(166, 183, 212, 1)"
            boxShadow="0px 0px 5px 3px rgba(153,153,153,0.21)"
            borderRadius="30px"
          >
            <Image src={getModalContent(type, !!user.shopifyShopUrl).image} />
          </ImageContainer>
        </Flex>
      </Flex>
    </ModalContainer>
  )
}

export default WidgetDetailsModal
