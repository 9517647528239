import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import ReactTooltip from 'react-tooltip'

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams, useLocation } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize, stringPadLeft } from '../../ui/helpers.js'
import { H1, Label, H2, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../../ui/Typography.js'
import { Flex, Box, Container } from '../../ui/Layout.js'
import Image from '../../ui/Image.js'
import { Input, SideInput, TextArea } from '../../ui/Input.js'
import Icon from '../../ui/Icon.js'
import CodeCopy from '../../ui/CodeCopy.js'
import VideoPreview from '../../ui/VideoPreview.js'
import WidgetPreview from '../../Components/WidgetPreview.js'
import Button from '../../ui/Button.js'
import { BigDarkSpinner } from '../../ui/Spinner.js'
import Toggle from '../../ui/Toggle.js'
import Select, { SideSelect } from '../../ui/Select.js'
import ModalContainer from '../../ui/ModalContainer.js'
import ColorPicker, { ColorPickerRow } from '../../ui/ColorPicker.js'
import ProcessingPreview from '../../Components/ProcessingPreview'
import CarouselPreview from '../../Components/CarouselPreview'
import formikFormWrapper from '../../formikFormWrapper.js'
import { Tabs } from '../CampaignEdit.js'
import { ReactSortable } from 'react-sortablejs'
import { toast } from 'react-toastify'
import ChatButton from '../../Components/ChatButton'
import VideoProductModal from '../../Videos/VideoProductModal'
import ToggleRedGreen from '../../ui/ToggleRedGreen.js'
import CollapsibleSection, { IconBox } from '../../Components/CollapsibleSection.js'
import { BrandingUploader } from '../../Account/index.js'
import { useVideos } from '../../VideoProvider.js'
import { useModal } from '../../Modal.js'

const FormInput = formikFormWrapper(Input)
const FormSideInput = formikFormWrapper(SideInput)

import api from '../../api.js'
import { useAuth } from '../../Auth.js'

import {
  STEPS,
  CAMPAIGN_RULE_TYPES,
  CAMPAIGN_STATUS,
  campaignRuleTypesOptions,
  WIDGET_POSITIONS,
  widgetPositionOptions,
  TITLE_POSITION,
  TITLE_ALIGNMENT,
  titlePositionOptions,
  titleAlignmentOptions,
  WIDGET_TYPES
} from '../types'
import { TitleAlignment } from './WidgetOptionsOptions.js'

const ChevronImage = styled(Icon)`
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  width: 20px;
  height: 20px;
`

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && 'border-bottom: 1px solid black;'}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`

const Tab = ({ onClick, title, isActive }) => {
  return (
    <TabText onClick={onClick} isActive={isActive}>
      {title}
    </TabText>
  )
}

const VideoAppearance = ({
  step,
  setStep,
  videos,
  setFieldValue,
  values,
  submit,
  selectedVideos,
  openFullscreenDesktopPreview,
  openFullscreenMobilePreview,
  videoObjects,
  setShowUltimateInstallModal,
  setShowShareModal,
  showCampaignNameModal,
  setShowCampaignNameModal,
  activeWidget
}) => {
  const [showSelectVideo, setShowSelectVideo] = useState(false)
  const [showVideoToProductModal, setShowVideoToProductModal] = useState(false)
  const [openCollapsible, setOpenCollapsible] = useState()
  const [activeVideoIndex, setActiveVideoIndex] = useState(0)
  const activeVideo = selectedVideos[activeVideoIndex]
  let { user } = useAuth()
  const [search, setSearch] = useSearchParams()
  console.log({ activeWidget })
  useEffect(() => {
    if (!values.name) {
      setShowCampaignNameModal(true)
    }
    if (search.get('showShareModal')) {
      setFieldValue('showShareModal', true)
    }
  }, [])

  return (
    <>
      <Flex>
        <Flex
          flexDirection="column"
          borderRight="1px solid #A6B7D4;"
          minHeight="100vh"
          maxHeight="100vh"
          height="100%"
          width="400px"
          minWidth="400px"
          py="24px"
          bg="white"
          overflowY="scroll"
        >
          <Flex flexDirection="column" px="24px">
            <Flex
              onClick={() => {
                submit(undefined, true)
                setStep(STEPS.CAMPAIGN_TYPE)
              }}
              cursor="pointer"
              width="auto"
            >
              <Icon mt="4px" mr="16px" icon="chevron-left-lightlight" height="16px" width="16px" />
              <Text lightLight fontSize="14px" mb="16px" textAlign="left">
                Back to Campaign
              </Text>
            </Flex>

            <Flex pt="16px">
              <Tab title="Content & Interactions" isActive={true} />
              {!values.isShareCampaign && !values.isCustomLauncher ? (
                <Tab
                  title="Designer"
                  onClick={() => {
                    if (!values.widgetId) {
                      return setStep(STEPS.DISPLAY_TYPE)
                    }
                    submit(STEPS.EDIT_EMBED)
                  }}
                  isActive={false}
                />
              ) : null}
            </Flex>
          </Flex>
          <Flex
            flexDirection="column"
            cursor={'pointer'}
            onClick={async () => {
              await submit(STEPS.SELECT_VIDEO)
            }}
            borderTop="1px solid #EDF2F7"
          >
            <Flex justifyContent="space-between" alignItems="center" mb="16px" mt="16px" px="24px">
              <Flex alignItems="center">
                <IconBox iconBgColor="purple">
                  <Icon width={16} height={16} icon="video-white" />
                </IconBox>
                <H3 lightLight mb="0" fontWeight={500}>
                  Media{selectedVideos.length ? ` (${selectedVideos.length})` : ''}
                </H3>
              </Flex>

              <ChevronImage icon="chevron-right-grey" alt="chevron icon" width="20px" height="20px" />
            </Flex>
          </Flex>
          {/* BUTTONS */}
          <Flex flexDirection="column">
            {!values.isNavigationCampaign ? (
              <CollapsibleSection
                topBorder
                label="Buttons"
                openCollapsible={openCollapsible}
                setOpenCollapsible={setOpenCollapsible}
                icon="hand-pointing-white"
                iconBgColor="black"
                noContentPadding
              >
                <Flex borderTop="1px solid rgba(237, 242, 247, 1)" pt="16px">
                  <ColorPickerRow
                    label="Button Color"
                    setFieldValue={setFieldValue}
                    name="buttonColor"
                    value={values.buttonColor}
                  />
                </Flex>
                <Flex flexDirection="column" borderBottom="1px solid rgba(237, 242, 247, 1)">
                  <ColorPickerRow
                    label="Font Color"
                    setFieldValue={setFieldValue}
                    name="buttonFontColor"
                    value={values.buttonFontColor}
                  />
                  <Field
                    tooltip="Font family may not display correctly in preview.<br>No value will attempt to inherit your websites default font."
                    name="fontFamily"
                    label="Font Family"
                    dark
                    component={FormSideInput}
                  />
                  <Text px="24px" mb="16px" mt="-16px" textAlign="right" fontSize="12px" lightLight underline>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://clipara.notion.site/Customising-Buttons-CTAs-6d693e3fcd2042b0939c798f681e0d77"
                    >
                      How to find your font family
                    </a>
                  </Text>
                </Flex>
                <Flex borderBottom="1px solid rgba(237, 242, 247, 1)" pt="16px">
                  <Field
                    name="buttonOpacity"
                    label="Opacity"
                    textPostFix="%"
                    type="number"
                    placeholder="100%"
                    min="0"
                    max="100"
                    dark
                    component={FormSideInput}
                  />
                </Flex>

                <Flex flexDirection="column" borderBottom="1px solid rgba(237, 242, 247, 1)" pt="16px">
                  <ColorPickerRow
                    label="Button Border"
                    setFieldValue={setFieldValue}
                    name="buttonBorderColor"
                    value={values.buttonBorderColor}
                  />
                  <Field
                    name="buttonBorderRadius"
                    label="Round Corners"
                    textPostFix="px"
                    type="number"
                    min="0"
                    max="50"
                    dark
                    component={FormSideInput}
                  />
                </Flex>

                {!values.isShoppable ? (
                  <>
                    <SideSelect
                      label="Button width"
                      width="100%"
                      value={values.buttonFullWidth}
                      onChange={(option) => setFieldValue('buttonFullWidth', option.value)}
                      options={[
                        { value: true, label: 'Full width' },
                        { value: false, label: 'Hug content' }
                      ]}
                      placeholder="Select option"
                    />
                    <Flex my="24px" px="24px" justifyContent="space-between" alignItems="center">
                      <Label mb="0px">Open links in new tab:</Label>
                      <ToggleRedGreen
                        setSelected={(selected) => {
                          setFieldValue('openButtonLinksNewTab', selected)
                        }}
                        value1={false}
                        value2={true}
                        selected={values.openButtonLinksNewTab}
                      />
                    </Flex>
                  </>
                ) : null}
              </CollapsibleSection>
            ) : null}
            {/* TITLES */}
            {!values.isInlineCampaign ? (
              <CollapsibleSection
                topBorder={values.isNavigationCampaign}
                label="Titles"
                openCollapsible={openCollapsible}
                setOpenCollapsible={setOpenCollapsible}
                icon="text-white"
                iconBgColor="black"
                noContentPadding
              >
                <Flex flexDirection="column" borderBottom="1px solid rgba(237, 242, 247, 1)" pt="16px" px="24px" mb="16px">
                  <Flex mb="16px" justifyContent="space-between">
                    <Label mt="2px" mr="16px">
                      Display title:
                    </Label>
                    <ToggleRedGreen
                      setSelected={(selected) => {
                        setFieldValue('titleActive', selected)
                      }}
                      value1={false}
                      value2={true}
                      selected={values.titleActive}
                    />
                  </Flex>
                  <Flex mb="16px" justifyContent="space-between">
                    <Label mt="2px" mr="16px">
                      Display subtitle:
                    </Label>
                    <ToggleRedGreen
                      setSelected={(selected) => {
                        setFieldValue('subtitleActive', selected)
                      }}
                      value1={false}
                      value2={true}
                      selected={values.subtitleActive}
                    />
                  </Flex>
                </Flex>

                {values.titleActive || values.subtitleActive ? (
                  <Flex flexDirection="column" borderBottom="1px solid rgba(237, 242, 247, 1)" mb="16px">
                    <Field
                      name="titleFontSize"
                      label="Title Font Size"
                      textPostFix="px"
                      type="number"
                      min="0"
                      dark
                      component={FormSideInput}
                    />
                    <Field
                      name="subtitleFontSize"
                      label="Subtitle Font Size"
                      textPostFix="px"
                      type="number"
                      min="0"
                      dark
                      component={FormSideInput}
                    />
                  </Flex>
                ) : null}

                <Flex borderBottom="1px solid rgba(237, 242, 247, 1)">
                  <Field
                    tooltip="Font family may not display correctly in preview.<br>No value will attempt to inherit your websites default font."
                    name="titleFontFamily"
                    label="Font Family"
                    dark
                    component={FormSideInput}
                  />
                </Flex>
                <TitleAlignment setFieldValue={setFieldValue} name="titleAlignment" value={values.titleAlignment} />
              </CollapsibleSection>
            ) : null}
            {/* BACKGROUND COLOR */}
            <CollapsibleSection
              label="Background Color"
              openCollapsible={openCollapsible}
              setOpenCollapsible={setOpenCollapsible}
              icon="brush-white"
              iconBgColor="black"
              noContentPadding
            >
              <Flex flexDirection="column" borderTop="1px solid rgba(237, 242, 247, 1)" pt="16px">
                <ColorPickerRow
                  label="Fullscreen Background Color"
                  setFieldValue={setFieldValue}
                  name="fullscreenBackgroundColor"
                  value={values.fullscreenBackgroundColor}
                />
              </Flex>
            </CollapsibleSection>

            {/* SETTINGS*/}
            {!values.isInlineCampaign && !values.isNavigationCampaign ? (
              <CollapsibleSection
                label="Player Settings"
                openCollapsible={openCollapsible}
                setOpenCollapsible={setOpenCollapsible}
                icon="cog-white"
                iconBgColor="black"
                noContentPadding
              >
                <Flex flexDirection="column" borderTop="1px solid rgba(237, 242, 247, 1)">
                  <SideSelect
                    noBorder
                    label="Mobile Swipe Direction"
                    width="100%"
                    value={values.mobileVerticalScroll}
                    onChange={(option) => setFieldValue('mobileVerticalScroll', option.value)}
                    options={[
                      { value: true, label: 'Vertical' },
                      { value: false, label: 'Horizontal' }
                    ]}
                    placeholder="Select option"
                  />
                </Flex>
                {selectedVideos && selectedVideos.length && selectedVideos.length > 50 ? (
                  <Text px="24px" mb="16px" fontSize="12px" lineHeight="16px" light>
                    For performance reasons we recommend larger campaigns use horizontal swiping.
                  </Text>
                ) : null}
              </CollapsibleSection>
            ) : null}

            {/* SHOPPABLE */}
            {values.isShoppable ? (
              <CollapsibleSection
                label="Shoppable Settings"
                openCollapsible={openCollapsible}
                setOpenCollapsible={setOpenCollapsible}
                icon="cart-white"
                iconBgColor="black"
                noContentPadding
              >
                <Flex flexDirection="column" borderTop="1px solid rgba(237, 242, 247, 1)" pt="16px" px="24px">
                  <Field mb="24px" name="addToCartText" label="Add To Cart Button Label" dark component={FormInput} />
                </Flex>

                <Flex flexDirection="column" borderTop="1px solid rgba(237, 242, 247, 1)" pt="16px" px="24px">
                  <Field mb="24px" name="deliveryMessage" label="Delivery Message" dark component={FormInput} />
                </Flex>
              </CollapsibleSection>
            ) : null}

            {/* SHARING */}
            <CollapsibleSection
              label="Sharing Label"
              openCollapsible={openCollapsible}
              setOpenCollapsible={setOpenCollapsible}
              icon="share-white"
              iconBgColor="black"
              noContentPadding
            >
              <Flex flexDirection="column" borderTop="1px solid rgba(237, 242, 247, 1)" pt="16px" px="24px">
                <Field mb="24px" name="sharingTitle" label="Sharing Meta Title" dark component={FormInput} />
              </Flex>
            </CollapsibleSection>

            {/* BRANDING */}
            <CollapsibleSection
              label="Branding"
              openCollapsible={openCollapsible}
              setOpenCollapsible={setOpenCollapsible}
              icon="lightening-hollow-white"
              iconBgColor="black"
            >
              <BrandingUploader />
            </CollapsibleSection>
          </Flex>
        </Flex>
        <Flex flexDirection="column" height="100vh">
          <Flex
            height="60px"
            alignItems="center"
            justifyContent="flex-end"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
            pr="24px"
          >
            <Flex pl="24px" alignItems="center" onClick={() => setShowCampaignNameModal(true)} cursor="pointer">
              <Text light fontSize="16px" maxWidth="300px" fontWeight="500" mr="16px">
                {values.name || 'Add campaign name'}
              </Text>
              <Icon icon="pencil-square-grey" width="16px" height="16px" />
            </Flex>
            <>
              <Text fontWeight="500" mr="16px">
                Preview
              </Text>
              <Button
                renderCenterIcon={() => <Icon width={12} height={12} icon="desktop-black" />}
                variant="white"
                shadow
                onClick={openFullscreenDesktopPreview}
                zIndex={1}
                width="52px"
                minWidth="52px"
                mr="8px"
              />
              <Button
                renderCenterIcon={() => <Icon width={12} height={12} icon="phone-black" />}
                variant="white"
                shadow
                onClick={openFullscreenMobilePreview}
                zIndex={1}
                width="52px"
                minWidth="52px"
                mr="16px"
              />
            </>
            {values.widgetId || activeWidget || values.isCustomLauncher ? (
              <Button
                mr="16px"
                onClick={() => {
                  submit(undefined, true)
                  setShowUltimateInstallModal(true)
                }}
                variant="grey"
                label="Install"
                zIndex={1}
              />
            ) : null}
            {selectedVideos && selectedVideos.length ? (
              <Button
                variant="black"
                label={'Save'}
                onClick={async () => {
                  await submit(undefined, true)
                  if (values.showInstallModal) {
                    setShowUltimateInstallModal(true)
                    setFieldValue('showInstallModal', false)
                  }
                  if (values.showShareModal) {
                    setShowShareModal(true)
                    setFieldValue('showShareModal', false)
                  }
                  toast('Campaign saved')
                }}
              />
            ) : null}
          </Flex>
          <Flex
            position="relative"
            alignItems="center"
            flexDirection="column"
            overflowX="hidden"
            height="100%"
            backgroundColor="#F7FAFC"
          >
            {!selectedVideos || !selectedVideos.length ? (
              <Flex height="100%" flexDirection="column" alignItems="center" justifyContent="center">
                <Icon icon="lightning-blue-black" width="60px" height="94px" mb="24px" />
                <Text maxWidth="330px" textAlign="center" fontSize="16px">
                  Start by giving your campaign a name and adding a video
                </Text>
              </Flex>
            ) : null}

            {selectedVideos && selectedVideos.length ? (
              <CarouselPreview
                py="60px"
                values={values}
                setFieldValue={setFieldValue}
                isLight
                campaign={{ ...values, videoObjects }}
                videos={selectedVideos || []}
                setShowVideoToProductModal={setShowVideoToProductModal}
                setActiveVideoIndex={setActiveVideoIndex}
              />
            ) : null}
          </Flex>
        </Flex>
      </Flex>
      <ModalContainer p="0px" width="90%" height="90%" isOpen={showVideoToProductModal} setIsOpen={setShowVideoToProductModal}>
        <VideoProductModal close={() => setShowVideoToProductModal(false)} video={activeVideo} refresh={() => {}} />
      </ModalContainer>
      <ModalContainer
        interiorClose
        autoHeight
        p="24px"
        width="400px"
        isOpen={showCampaignNameModal}
        setIsOpen={setShowCampaignNameModal}
      >
        <H3 width="100%">Give your campaign a name</H3>
        <Field mb="24px" maxLength={65} name="name" label="Campaign Name" dark component={FormInput} />
        <Flex justifyContent="flex-end">
          <Button
            isDisabled={values.name.length < 5}
            onClick={() => {
              setShowCampaignNameModal(false)
            }}
            variant="black"
            label="Next"
          />
        </Flex>
      </ModalContainer>
    </>
  )
}

export default VideoAppearance
