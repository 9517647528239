import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize } from '../ui/helpers.js'
import { H1, H2, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import SideNav from '../Components/SideNav.js'
import VideoPlayer from '../Components/VideoPlayer'
import formikFormWrapper from '../formikFormWrapper.js'
import { toast } from 'react-toastify'
import { useVideos } from '../VideoProvider.js'
import InstagramUpload from './InstagramUpload'
import bluebird from 'bluebird'
import * as Sentry from '@sentry/react'

import api from '../api.js'
import { useAuth } from '../Auth.js'
import { getAllowedVideoDuration, getNumberOfAllowedVideos, isFreePlan } from '../Plans.js'
window.URL = window.URL || window.webkitURL

const UpgradeContainer = styled(Flex)`
  background: linear-gradient(225deg, #bb65ff 0%, #4c6fff 100%);
  height: 37px;
  box-sizing: border-box;
  align-items: center;
  border-radius: 8px;
  width: calc(100% - 40px);
  width: 191px;
  margin-top: 50px;
  justify-content: center;
`

const LargeUpgradeContainer = styled(UpgradeContainer)`
  width: 500px;
  height: 42px;
`

const STEPS = {
  SELECT_FILE: 'SELECT_FILE',
  CONFIRM_FILE: 'CONFIRM_FILE',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS'
}

export const SpinningIcon = styled(Icon)`
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  ${(props) =>
    !props.finished &&
    `-webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;`}
`

export const ProgressBar = styled(Box)`
  background-color: ${colors.backgroundGrey};
  height: 16px;
  border-radius: 16px;
  -webkit-transition: width 1s;
  -moz-transition: width 1s;
  -o-transition: width 1s;
  transition: width 1s;
`

export const ProgressBarProgress = styled(ProgressBar)`
  background: linear-gradient(225deg, #bb65ff 0%, #4c6fff 100%);
  ${(props) => (props.finished ? 'background: linear-gradient(225deg, #67E9F1 0%, #24E795 100%);' : null)}
`

const StyledInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  background-color: red;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const DragDropContainer = styled(Flex)`
  width: 500px;
  height: 300px;
  border-radius: 16px;
  overflow: hidden;
  background-color: #f7fafc;
  cursor: pointer;
  border: 2px dashed #e1e8ff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

const SaveText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`

const TABS = {
  UPLOAD: 'UPLOAD',
  INSTAGRAM: 'INSTAGRAM',
  TIKTOK: 'TIKTOK',
  YOUTUBE: 'YOUTUBE'
}

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && 'border-bottom: 1px solid black;'}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`

const errorCopy = 'Upgrade now or reduce the amount you are trying to upload.'

const Tab = ({ onClick, title, isActive }) => {
  return (
    <TabText onClick={onClick} isActive={isActive}>
      {title}
    </TabText>
  )
}

const Stepper = ({
  video,
  uploadFromUrl,
  setUrl,
  url,
  isLoading,
  uploadUrl,
  files,
  step,
  startUpload,
  percentProgress,
  closeModal,
  setName,
  name,
  percentProgressObj,
  isModal,
  error,
  setFiles
}) => {
  const { refresh } = useVideos()
  const { user } = useAuth()

  const [searchParams, setSearchParams] = useSearchParams()
  const [activeTab, setActiveTabState] = useState(searchParams.get('tab') || TABS.UPLOAD)
  const setActiveTab = (tab) => {
    setActiveTabState(tab)
    if (!isModal) {
      setSearchParams({ tab })
    }
  }

  const UpgradeComponent =
    error && error.includes(errorCopy) ? (
      <Link to="/account?tab=BILLING">
        <UpgradeContainer>
          <Icon mr="16px" icon="lightening-white" height="16px" width="11px" />
          <Text fontWeight="500" color="white">
            Upgrade Plan
          </Text>
        </UpgradeContainer>
      </Link>
    ) : null
  if (step === STEPS.SELECT_FILE) {
    return (
      <>
        <H1 mb="32px">Upload a new video</H1>
        <Flex mb="32px" justifyContent="center">
          <Tab onClick={(e) => setActiveTab(TABS.UPLOAD)} title="From File" isActive={activeTab === TABS.UPLOAD} />
          <Tab onClick={() => setActiveTab(TABS.INSTAGRAM)} title="Instagram" isActive={activeTab === TABS.INSTAGRAM} />
          <Tab onClick={() => setActiveTab(TABS.TIKTOK)} title="Tiktok" isActive={activeTab === TABS.TIKTOK} />
          <Tab onClick={() => setActiveTab(TABS.YOUTUBE)} title="Youtube" isActive={activeTab === TABS.YOUTUBE} />
        </Flex>
        {activeTab === TABS.UPLOAD && (
          <Flex flexDirection="column" alignItems="center" pt="20px">
            <DragDropContainer>
              <Icon icon="videos-icon-purple" width="50px" height="50px" />
              <Text fontSize="14px" fontWeight="500" mt="16px">
                Drag & Drop
              </Text>
              <Text fontSize="14px" fontWeight="500" mb="8px">
                or
              </Text>
              <Button mb="8px" variant="black" label="Select files" />
              <StyledInput
                type="file"
                id="clipara-file-picker"
                name="file-picker"
                accept="video/mp4,video/x-m4v,video/*"
                multiple="multiple"
                onChange={(e) => {
                  try {
                    const filePicker = document.getElementById('clipara-file-picker')
                    console.log('file picker files', filePicker.files)
                    if (filePicker.files && filePicker.files[0]) {
                      setFiles(Array.from(filePicker.files))
                    } else {
                      console.log('No files selected 1', filePicker.files)
                      Sentry.captureException(`No files selected 1`, {
                        extra: user
                      })
                    }
                  } catch (e) {
                    console.log('No files error', e)
                    Sentry.captureException('Failed to set files', { extra: user })
                    Sentry.captureException(e, { extra: user })
                  }
                }}
              />
            </DragDropContainer>
            <Error>{error}</Error>
            {isFreePlan(user) && user.isContentCreator ? null : UpgradeComponent}
            {isFreePlan(user) && user.isContentCreator ? (
              <a href="/account?tab=BILLING" target="_blank">
                <LargeUpgradeContainer mb="32px">
                  <Icon mr="16px" icon="lightening-white" height="16px" width="11px" />
                  <Text mr="20px" fontWeight="500" color="white">
                    Upgrade your plan to store and offer downloads above 1080p
                  </Text>
                </LargeUpgradeContainer>
              </a>
            ) : null}
          </Flex>
        )}
        {activeTab === TABS.INSTAGRAM && (
          <InstagramUpload
            uploadFromUrl={uploadFromUrl}
            setUrl={setUrl}
            isLoading={isLoading}
            error={error}
            closeModal={closeModal}
            isModal={isModal}
          />
        )}
        {activeTab === TABS.TIKTOK && (
          // https://www.tiktok.com/@wigaholic_official/video/7187329416761068806
          <Flex flexDirection="column" alignItems="center" maxWidth="500px">
            <Input
              mb="32px"
              width="100%"
              label="Post Url"
              placeholder="e.g. https://www.tiktok.com/@username/video/718732941761068806L/"
              dark
              onChange={(e) => setUrl(e.target.value)}
            />
            <Button mb="8px" isLoading={isLoading} variant="black" label="Upload" onClick={uploadFromUrl} />
            <Error>{error}</Error>
            {UpgradeComponent}
          </Flex>
        )}
        {activeTab === TABS.YOUTUBE && (
          // https://www.tiktok.com/@wigaholic_official/video/7187329416761068806
          <Flex flexDirection="column" alignItems="center" maxWidth="500px">
            <Input
              mb="32px"
              width="100%"
              label="Post Url"
              placeholder="e.g. https://www.youtube.com/shorts/HAFDlXkFUMcIjY"
              dark
              onChange={(e) => setUrl(e.target.value)}
            />
            <Button mb="8px" isLoading={isLoading} variant="black" label="Upload" onClick={uploadFromUrl} />
            <Error>{error}</Error>
            {UpgradeComponent}
          </Flex>
        )}
      </>
    )
  }
  if (step === STEPS.IN_PROGRESS) {
    const numberUploaded = Object.values(percentProgressObj).reduce((acc, b) => {
      return b === 100 ? acc + 1 : acc
    }, 0)
    const finished = numberUploaded === files.length
    return (
      <>
        <H1 mb="12px">{`${finished ? 'Uploaded' : 'Uploading'} ${files.length} video${files.length > 1 ? 's' : ''}`}</H1>
        <SpinningIcon finished={finished} mb="32px" width="60px" height="60px" icon="logo-no-text" />
        {files.map((file) => {
          return (
            <Flex key={file.name} flexDirection="column" alignItems="center" pt="32px">
              <Text width="100%" textAlign="left" mb="8px" fontWeight="500">
                {file.name}
              </Text>
              <ProgressBar mb="8px" width="100%">
                <ProgressBarProgress
                  finished={percentProgressObj[file.name] === 100}
                  width={`${percentProgressObj[file.name]}%`}
                />
              </ProgressBar>
              <Flex justifyContent="space-between">
                <Text lightLight>{Math.round(file.size / 10000) / 100} MB</Text>
                <Text lightLight>{Math.round(percentProgressObj[file.name])}%</Text>
              </Flex>
            </Flex>
          )
        })}
        <Flex justifyContent="flex-end" mt="24px">
          <Box width="auto" borderBottom="1px solid rgba(228, 236, 247, 1)" mb="8px">
            <Text textAlign="right" mb="8px" width="100%" fontWeight="600" fontSize="15px">
              {numberUploaded} uploaded
            </Text>
          </Box>
        </Flex>
        <Text textAlign="right" light width="100%" fontWeight="600" mb="48px">
          of {files.length} videos
        </Text>
        {closeModal ? (
          <Flex justifyContent="flex-end">
            <Button
              onClick={numberUploaded !== files.length ? () => null : closeModal}
              isDisabled={numberUploaded !== files.length}
              width="200px"
              mb="8px"
              variant="black"
              label="Continue"
            />
          </Flex>
        ) : (
          <Flex justifyContent="flex-end">
            <a href="/videos/upload">
              <Button isDisabled={numberUploaded !== files.length} mb="8px" variant="black" label="Add another video" />
            </a>
            <Box width="32px" />
            <Link to="/campaigns/create?step=ON_SITE_OFF_SITE">
              <Button isDisabled={numberUploaded !== files.length} mb="8px" variant="black" label="Create campaign" />
            </Link>
          </Flex>
        )}
      </>
    )
  }
  if (step === STEPS.SUCCESS) {
    // const [name, setName] = useState(file ? file.name : url)
    // const [isSubmitting, setIsSubmitting] = useState(false)
    const updateName = async () => {
      const res = await api.post('/video/update', { name, id: video.id })
      if (res.data.success) {
        refresh()
        toast('Video name saved')
      } else {
        toast(res.data.message)
      }
    }
    return (
      <>
        <H1 mb="12px">Success</H1>
        <Flex
          width="60px"
          height="60px"
          alignItems="center"
          mb="40px"
          justifyContent="center"
          bg={colors.green}
          borderRadius="30px"
        >
          <Icon width={24} height={24} icon="tick-white" />
        </Flex>
        <Text fontSize="16px" mb="16px">
          Name your video:
        </Text>
        <Flex alignItems="center" width="400px" mb="48px">
          <Input large value={name} width="400px" mr="16px" dark onChange={(e) => setName(e.target.value)} />
          <SaveText onClick={updateName}>Save</SaveText>
        </Flex>
        {closeModal ? (
          <Flex justifyContent="center">
            <Button onClick={closeModal} width="200px" mb="8px" variant="black" label="Continue" />
          </Flex>
        ) : (
          <Flex flexDirection="column" alignItems="center" justifyContent="center">
            <Link to="/campaigns/create?step=ON_SITE_OFF_SITE">
              <Button large width="200px" mb="16px" variant="black" label="Create Campaign" />
            </Link>
            <a href="/videos/upload">
              <Button large width="200px" mb="8px" variant="grey" label="Add another video" />
            </a>
          </Flex>
        )}
      </>
    )
  }
}

const VideoUpload = (props) => {
  const { videos: existingVideos } = useVideos()
  const { user } = useAuth()
  const [files, setFiles] = useState()
  const [url, setUrl] = useState()
  const [search, setSearch] = useSearchParams()
  const [isLoading, setIsLoading] = useState()
  const [name, setName] = useState()
  const [percentProgress, setPercentProgress] = useState(0)
  const [percentProgressObj, setPercentProgressObj] = useState({})
  const [step, setStep] = useState(STEPS.SELECT_FILE)
  const [video, setVideo] = useState()
  const [error, setError] = useState('')

  const getUploadUrlId = async () => {
    try {
      const res = await api.get('/video/get-upload-url')
      if (res.data.success) {
        return { url: decodeURIComponent(res.data.payload.url), id: decodeURIComponent(res.data.payload.id) }
      }
    } catch (e) {
      Sentry.captureException(e, {
        extra: user
      })
    }
  }

  const startUpload = async () => {
    console.log('starting upload', files)

    try {
      let temp = {}
      files.forEach((file) => {
        temp = { ...temp, [file.name]: '0' }
      })
      setPercentProgressObj(temp)
      setStep(STEPS.IN_PROGRESS)
    } catch (e) {
      Sentry.captureException(e, {
        extra: user
      })
    }
    await bluebird.each(files, async (file) => {
      const uploadObj = await getUploadUrlId()
      await new Promise((resolve, reject) => {
        const upload = UpChunk.createUpload({
          endpoint: uploadObj.url,
          file,
          chunkSize: 5120
        })
        upload.on('error', (err) => {
          console.error('💥 🙀', err.detail)
          return resolve()
        })
        upload.on('progress', (progress) => {
          console.log('Uploaded', progress.detail, 'percent of this file.')
          setPercentProgressObj((prevState) => {
            return { ...prevState, [file.name]: progress.detail === 100 ? 90 : progress.detail }
          })
        })
        // subscribe to events
        upload.on('success', async (err) => {
          console.log(err, 'success')
          console.log("Wrap it up, we're done here. 👋")
          console.log(uploadObj)
          const res = await api.post('/video/create', {
            uploadId: uploadObj.id,
            name: file.name,
            unpublished: !!search.get('unpublished')
          })
          setPercentProgressObj((prevState) => {
            return { ...prevState, [file.name]: 100 }
          })
          console.log(res.data.payload)
          return resolve()
        })
      })
      console.log('PROMISE DONE')
    })
  }

  const checkFileLength = async (file) => {
    try {
      return new Promise((resolve, reject) => {
        try {
          var video = document.createElement('video')
          video.preload = 'metadata'
          video.onloadedmetadata = function () {
            try {
              console.log('video onloadedmetadata')
              window.URL.revokeObjectURL(video.src)
              resolve(video.duration)
            } catch (e) {
              Sentry.captureException(e, {
                extra: user
              })
            }
          }
          video.onerror = function () {
            console.log('video onerror')
            Sentry.captureException('Video failed to load 1.', {
              extra: user
            })
            resolve(0)
          }
          video.src = URL.createObjectURL(file)
        } catch (e) {
          console.log(e, '<<<')

          Sentry.captureException(e, {
            extra: user
          })
        }
      })
    } catch (e) {
      console.log(e, '<<<')
      Sentry.captureException(e, {
        extra: user
      })
    }
  }

  const uploadFromUrl = async () => {
    console.log('starting url upload')
    setIsLoading(true)
    try {
      const res = await api.post('/video/create-from-url', { url })
      console.log(res)
      if (res.data.success) {
        setVideo(res.data.payload.upload)
        setName(res.data.payload.name)
        setStep(STEPS.SUCCESS)
      } else {
        setError(res.data.message)
      }
      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const asyncFunc = async () => {
      try {
        if (!files || !files.length) {
          console.log('NO FILE', files && files.length)

          Sentry.captureException(`No files selected 2`, {
            extra: user
          })
          return
        }

        console.log('VALID 1')

        let valid = true
        await bluebird.each(files, async (file) => {
          const duration = await checkFileLength(file)
          console.log({ duration })
          if (duration > 60 * 20) {
            valid = false
          }
        })
        console.log('VALID', valid)

        if (!valid) {
          Sentry.captureException(`Video duration greater than 20 minutes. Contact us via live chat for longer video uploads.`, {
            extra: user
          })
          setError('Video duration greater than 20 minutes. Contact us via live chat for longer video uploads.')
          return
        } else {
          setError('')
        }
        console.log('PRE LIMIT 1')

        // Check number of videos
        const limit = getAllowedVideoDuration(user)
        const selectedDuration = await bluebird.reduce(
          files,
          async (acc, file) => {
            const length = await checkFileLength(file)
            return length + acc
          },
          0
        )
        console.log('PRE LIMIT')

        if (limit) {
          const totalExistingDuration =
            existingVideos && existingVideos.length
              ? Math.round(
                  existingVideos.reduce((acc, b) => {
                    return acc + b.duration
                  }, 0)
                )
              : 0
          const remainingDuration =
            totalExistingDuration + selectedDuration > limit ? 0 : limit - totalExistingDuration + selectedDuration
          if (totalExistingDuration + selectedDuration > limit) {
            Sentry.captureException(`You only have ${remainingDuration} minutes of storage remaining. ${errorCopy}`, {
              extra: user
            })
            setError(`You only have ${remainingDuration} minutes of storage remaining. ${errorCopy}`)
            return
          }
        }

        console.log('FILE', files && files.length)
        if (files && files.length) {
          setStep(STEPS.IN_PROGRESS)
          startUpload()
        }
      } catch (e) {
        Sentry.captureException(e, { extra: user })
      }
    }
    setTimeout(() => asyncFunc(), 100)
  }, [files])

  const Innards = (
    <>
      <Stepper
        video={video}
        uploadFromUrl={uploadFromUrl}
        files={files}
        step={step}
        startUpload={startUpload}
        percentProgress={percentProgress}
        percentProgressObj={percentProgressObj}
        closeModal={props.closeModal}
        isLoading={isLoading}
        url={url}
        setUrl={setUrl}
        setName={setName}
        name={name}
        isModal={props.isModal}
        error={error}
        setFiles={setFiles}
      />
    </>
  )

  if (props.isModal) {
    return (
      <Flex position="relative" flexDirection="column" alignItems="center" width="100%" p="76px">
        <Flex
          width="auto"
          justifyContent="center"
          position="absolute"
          top="0px"
          left="24px"
          zIndex="1"
          cursor="pointer"
          onClick={props.closeModal}
        >
          <Icon mt="4px" mr="16px" icon="chevron-left-lightlight" height="16px" width="16px" />
          <Text lightLight>Back</Text>
        </Flex>
        {Innards}
      </Flex>
    )
  }

  return (
    <Flex>
      <Flex position="relative" flexDirection="column" alignItems="center" width="100%" p="76px">
        <Container alignItems="center">{Innards}</Container>
      </Flex>
    </Flex>
  )
}

export default VideoUpload
