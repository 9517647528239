import React from 'react'
import styled from 'styled-components'
import { Formik, Field } from 'formik'
import { Flex, Box, Container } from '../../ui/Layout.js'
import { space } from 'styled-system'
import formikFormWrapper from '../../formikFormWrapper.js'
import { Input, TextArea } from '../../ui/Input.js'
import { H1, H2, H3, H6, Label, Text, Span, GradientFont, Error, SmallTitle } from '../../ui/Typography.js'
import { BUTTON_STYLES, PLAY_BUTTON_STYLES, widgetPlayButtonPositionOptions } from '../types.js'
import ColorPicker, { ColorPickerRow } from '../../ui/ColorPicker.js'
import { getChevronStyling, getChevronImg, getChevronImgSize } from '../chevronStyles.js'
import { SideSelect } from '../../ui/Select.js'
import ToggleRedGreen from '../../ui/ToggleRedGreen.js'
const baseUrl = 'https://widget-v2.getclipara.com'

const FormInput = formikFormWrapper(Input)

const PlayButton = styled.svg``

const PlayButtonStyle = ({ values, setFieldValue, hidePosition }) => {
  return (
    <Flex flexDirection="column" borderTop="1px solid rgba(237, 242, 247, 1)" pt="16px">
      <Label px="24px" mb="0px">
        Play Button Style
      </Label>
      <Flex flexWrap="wrap" mb="16px" px="24px">
        {Object.keys(PLAY_BUTTON_STYLES).map((style, i) => {
          const isSelected = values.widget.widgetPlayButtonStyle === style
          return (
            <Flex
              boxSizing="border-box"
              cursor="pointer"
              justifyContent="center"
              alignItems="center"
              mt="8px"
              borderRadius="8px"
              width="167px"
              height="125px"
              key={style}
              mr={i % 2 === 0 ? '16px' : 0}
              bg={isSelected ? 'rgba(235, 242, 250, 1)' : 'white'}
              border={isSelected ? '3px solid rgba(120, 149, 255, 1)' : '1px solid rgba(166, 183, 212, 1)'}
              onClick={() => {
                setFieldValue(`widget.widgetPlayButtonStyle`, style)
              }}
            >
              {style === PLAY_BUTTON_STYLES.FILLED ? (
                <Flex flexDirection="column" width="auto">
                  <PlayButton
                    animate={values.widget.playButtonAnimation}
                    playButtonColor={'#A6B7D4'}
                    width={60}
                    height={60}
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="30" cy="30" r="30" fill={'#A6B7D4'} />
                    <path
                      d="M45 28.268C46.3333 29.0378 46.3333 30.9623 45 31.7321L24 43.8564C22.6667 44.6262 21 43.664 21 42.1244L21 17.8756C21 16.336 22.6667 15.3738 24 16.1436L45 28.268Z"
                      fill="white"
                    />
                  </PlayButton>
                  <Label textAlign="center" mt="16px" mb="0px">
                    Filled
                  </Label>
                </Flex>
              ) : (
                <Flex flexDirection="column" width="auto">
                  <PlayButton
                    animate={values.widget.playButtonAnimation}
                    playButtonColor={'#A6B7D4'}
                    width={60}
                    height={60}
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="30" cy="30" r="28.5" stroke={'#A6B7D4'} stroke-width="3" />
                    <path
                      d="M46.1097 29.2683C47.4431 30.0381 47.4431 31.9626 46.1097 32.7324L24.9451 44.9518C23.6118 45.7216 21.9451 44.7594 21.9451 43.2198L21.9451 18.781C21.9451 17.2414 23.6118 16.2791 24.9451 17.0489L46.1097 29.2683Z"
                      fill={'#A6B7D4'}
                    />
                  </PlayButton>
                  <Label textAlign="center" mt="16px" mb="0px">
                    Light
                  </Label>
                </Flex>
              )}
            </Flex>
          )
        })}
      </Flex>
      <Flex borderTop="1px solid rgba(237, 242, 247, 1)" borderBottom="1px solid rgba(237, 242, 247, 1)" pt="16px">
        <ColorPickerRow
          label="Color"
          setFieldValue={setFieldValue}
          name="widget.playButtonColor"
          value={values.widget.playButtonColor}
        />
      </Flex>
      {!hidePosition ? (
        <SideSelect
          label="Play Button Position"
          value={values.widget.widgetPlayButtonPosition}
          onChange={(option) => {
            setFieldValue(`widget.widgetPlayButtonPosition`, option.value)
          }}
          options={widgetPlayButtonPositionOptions}
          placeholder="Select"
        />
      ) : null}
      <Flex
        px="24px"
        py="24px"
        justifyContent="space-between"
        borderBottom="1px solid rgba(237, 242, 247, 1)"
        alignItems="center"
      >
        <Label mb="0px">Play Button Animation</Label>
        <ToggleRedGreen
          setSelected={(selected) => {
            setFieldValue('widget.playButtonAnimation', selected)
          }}
          value1={false}
          value2={true}
          selected={values.widget.playButtonAnimation}
        />
      </Flex>
    </Flex>
  )
}

export default PlayButtonStyle
