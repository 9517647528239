import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../../ui/Layout.js'
import axios from 'axios'
import {
  getGridResults,
  getNumberOfColumns,
  searchUrl,
  tagsFetchUrl,
  Button,
  DurationButton,
  PlayButtonComponent,
  updateQueryString,
  getStateFromQuery,
  PopularButton
} from './SearchComponents.js'
import { baseUrl, loadVideo, loadVideoLite, loadVideoMP4 } from '../helpers.js'
import { SearchInput } from './Input.js'

const VideoImage = styled.img`
  box-sizing: border-box !important;
  border-radius: ${(props) => `${props.borderRadius}px`};
  object-fit: cover;
  width: ${(props) => props.width}px !important;
  height: ${(props) => props.height}px !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  border-radius: ${(props) => `${props.borderRadius}px`};
  ${(props) =>
    props.widgetThumbnailBorder
      ? `border: ${props.widgetThumbnailBorderSize || 3}px solid ${props.widgetThumbnailBorderColor || 'black'};`
      : ''}
  background: linear-gradient(to right, #e9e9e9, #f9f9f9, #e9e9e9);
  background: -webkit-linear-gradient(to right, #e9e9e9, #f9f9f9, #e9e9e9);
  animation: loading-gradient 4s linear infinite;
  background-size: 200%;
`

const StyledPicture = styled.picture`
  display: block;
  height: 100% !important;
`

const VideoContainer = styled(Flex)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: ${(props) => `${props.borderRadius}px`};
`

const Video = styled.video`
  width: ${(props) => props.width}px !important;
  height: ${(props) => props.height}px !important;
  object-fit: cover;
  z-index: 1;
  box-sizing: border-box !important;
  border-radius: ${(props) => `${props.borderRadius}px`};
  ${(props) =>
    props.widgetThumbnailBorder
      ? `border: ${props.widgetThumbnailBorderSize || 3}px solid ${props.widgetThumbnailBorderColor || 'black'};`
      : ''}
  &&::-webkit-media-controls {
    display: none !important;
  }
  /* Could Use thise as well for Individual Controls */
  &&::-webkit-media-controls-play-button {
    display: none !important;
  }
  &&::-webkit-media-controls-volume-slider {
    display: none !important;
  }
  &&::-webkit-media-controls-mute-button {
    display: none !important;
  }
  &&::-webkit-media-controls-timeline {
    display: none !important;
  }
  &&::-webkit-media-controls-current-time-display {
    display: none !important;
  }
  transition: opacity 0.15 ease-in-out;
  -moz-transition: opacity 0.15 ease-in-out;
  -webkit-transition: opacity 0.15 ease-in-out;
`

const VideoResult = ({ video, isMobile, campaign, onClick, columnWidth, columnGap }) => {
  const imageBoostFactor = 1.5
  const customThumbnailUrl =
    video.customThumbnail || video.customMobileThumbnail
      ? (isMobile && video.customMobileThumbnail ? video.customMobileThumbnail : video.customThumbnail).replace(
          'https://ucarecdn.com/',
          'https://customthumbnail.getclipara.com/'
        )
      : undefined
  const imageHeight = columnWidth / video.aspectRatioCalc
  const imageUrl = customThumbnailUrl
    ? customThumbnailUrl +
      `-/scale_crop/${Math.round(columnWidth * imageBoostFactor)}x${Math.round(imageHeight * imageBoostFactor)}/center/`
    : `https://thumbnails.getclipara.com/${video.playbackId}/thumbnail.png?time=${video.thumbnailTime || 0.0}&width=${Math.round(
        columnWidth * imageBoostFactor
      )}&height=${Math.round(imageHeight * imageBoostFactor)}&fit_mode=crop`
  const webpUrl = customThumbnailUrl
    ? customThumbnailUrl +
      `-/scale_crop/${Math.round(columnWidth * imageBoostFactor)}x${Math.round(
        imageHeight * imageBoostFactor
      )}/center/-/format/webp/`
    : imageUrl.replace('.png', '.webp')

  return (
    <Flex
      onClick={onClick}
      cursor="pointer"
      key={video.id}
      mb={columnGap}
      bg="#dcdedc"
      width={columnWidth}
      height={imageHeight}
      position="relative"
      borderRadius={campaign.borderRadius}
      onMouseEnter={async () => {
        const element = document.getElementById(`__clipara-search-${video.id}-${campaign.id}`)
        if (element?.src || element.innerHTML.includes('source')) {
          try {
            element.play()
          } catch (e) {}
        } else {
          await loadVideoLite({
            id: `__clipara-search-${video.id}-${campaign.id}`,
            playerName: 'search-autoplay',
            video,
            campaign,
            useClip: true,
            columnWidth
          })
        }
        element.style.visibility = 'visible'
      }}
      onMouseLeave={() => {
        try {
          const element = document.getElementById(`__clipara-search-${video.id}-${campaign.id}`)
          element.pause()
          element.currentTime = 0
          element.style.visibility = 'hidden'
        } catch (e) {}
      }}
    >
      <DurationButton duration={video.duration} />
      <PopularButton campaign={campaign} rank={video.rank} />
      <StyledPicture>
        <source srcSet={webpUrl} type="image/webp" />
        <source srcSet={imageUrl} type="image/png" />
        <VideoImage
          width={columnWidth}
          height={imageHeight}
          borderRadius={campaign.borderRadius}
          widgetThumbnailBorder={campaign.widgetThumbnailBorder}
          widgetThumbnailBorderSize={campaign.widgetThumbnailBorderSize}
          widgetThumbnailBorderColor={campaign.widgetThumbnailBorderColor}
          loading="lazy"
          src={imageUrl}
          alt={video.name}
        />
      </StyledPicture>
      <VideoContainer>
        <Video
          loop
          borderRadius={campaign.borderRadius}
          widgetThumbnailBorder={campaign.widgetThumbnailBorder}
          widgetThumbnailBorderSize={campaign.widgetThumbnailBorderSize}
          widgetThumbnailBorderColor={campaign.widgetThumbnailBorderColor}
          muted
          width={columnWidth}
          height={imageHeight}
          id={`__clipara-search-${video.id}-${campaign.id}`}
          preload="metadata"
          playsInline
          webkit-playsInline
          x-webkit-airplay="allow"
        />
      </VideoContainer>
    </Flex>
  )
}

export default VideoResult
