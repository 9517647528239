import React from 'react'
import styled from 'styled-components'
import { Formik, Field } from 'formik'
import { Flex, Box, Container } from '../../ui/Layout.js'
import { space } from 'styled-system'
import formikFormWrapper from '../../formikFormWrapper.js'
import { Input, TextArea } from '../../ui/Input.js'
import { H1, H2, H3, H6, Label, Text, Span, GradientFont, Error, SmallTitle } from '../../ui/Typography.js'
import { BUTTON_STYLES, widgetArrowPositionOptions } from '../types.js'
import ColorPicker, { ColorPickerRow } from '../../ui/ColorPicker.js'
import { getChevronStyling, getChevronImg, getChevronImgSize } from '../chevronStyles.js'
import { SideSelect } from '../../ui/Select.js'
import ToggleRedGreen from '../../ui/ToggleRedGreen.js'
const baseUrl = 'https://widget-v2.getclipara.com'

const FormInput = formikFormWrapper(Input)

const Chevron = styled(Flex)`
  z-index: 1;
  width: 40px;
  min-width: 40px;
  height: 40px;
  ${(props) => getChevronStyling(props.widgetButtonStyle, props.widgetChevronSecondaryColor)}
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${(props) => (props.isDisabled ? `cursor: auto; opacity: 0.5;` : '')}
  ${(props) => (props.isSmallControls ? `width: 32px; height: 32px; min-width: 32px;` : '')}
`

const ChevronImage = styled(Flex)`
  svg {
    fill: ${(props) => props.widgetChevronPrimaryColor || 'black'} !important;
    stroke: ${(props) => props.widgetChevronPrimaryColor || 'black'} !important;
  }
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  align-items: center;
  justify-content: center;
  width: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  height: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  svg {
    width: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
    height: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  }
  ${(props) => (props.isSmallControls ? `width: 16px !important; height: 16px !important; min-width: 16px !important;` : '')}
`

const LeftChevron = styled(Chevron)``

const RightChevron = styled(Chevron)`
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
`

const ButtonStyle = ({ values, setFieldValue }) => {
  return (
    <Flex flexDirection="column" borderTop="1px solid rgba(237, 242, 247, 1)" pt="16px">
      <Label px="24px" mb="0px">
        Button Style
      </Label>
      <Flex flexWrap="wrap" mb="16px" px="24px">
        {Object.keys(BUTTON_STYLES).map((style, i) => {
          const isSelected = values.widget.widgetButtonStyle === style
          return (
            <Flex
              boxSizing="border-box"
              cursor="pointer"
              justifyContent="center"
              alignItems="center"
              mt="8px"
              borderRadius="8px"
              width="167px"
              height="74px"
              key={style}
              mr={i % 2 === 0 ? '16px' : 0}
              bg={isSelected ? 'rgba(235, 242, 250, 1)' : 'white'}
              border={isSelected ? '3px solid rgba(120, 149, 255, 1)' : '1px solid rgba(166, 183, 212, 1)'}
              onClick={() => {
                setFieldValue(`widget.widgetButtonStyle`, style)
              }}
            >
              <LeftChevron
                widgetButtonStyle={style}
                mr="8px"
                widgetChevronSecondaryColor={values.widget.widgetChevronSecondaryColor}
              >
                <ChevronImage
                  widgetChevronPrimaryColor={values.widget.widgetChevronPrimaryColor}
                  widgetButtonStyle={style}
                  src={`${baseUrl}/${getChevronImg(style)}`}
                  alt="chevron icon"
                  width={getChevronImgSize(style)}
                  height={getChevronImgSize(style)}
                >
                  {getChevronImg(style)}
                </ChevronImage>
              </LeftChevron>
              <RightChevron widgetButtonStyle={style} widgetChevronSecondaryColor={values.widget.widgetChevronSecondaryColor}>
                <ChevronImage
                  widgetChevronPrimaryColor={values.widget.widgetChevronPrimaryColor}
                  widgetButtonStyle={style}
                  src={`${baseUrl}/${getChevronImg(style)}`}
                  alt="chevron icon"
                  width={getChevronImgSize(style)}
                  height={getChevronImgSize(style)}
                >
                  {getChevronImg(style)}
                </ChevronImage>
              </RightChevron>
            </Flex>
          )
        })}
      </Flex>
      <Flex borderTop="1px solid rgba(237, 242, 247, 1)" borderBottom="1px solid rgba(237, 242, 247, 1)" pt="16px">
        <ColorPickerRow
          label="Primary Color"
          setFieldValue={setFieldValue}
          name="widget.widgetChevronPrimaryColor"
          value={values.widget.widgetChevronPrimaryColor}
        />
      </Flex>
      <Flex borderBottom="1px solid rgba(237, 242, 247, 1)" pt="16px">
        <ColorPickerRow
          label="Secondary Color"
          setFieldValue={setFieldValue}
          name="widget.widgetChevronSecondaryColor"
          value={values.widget.widgetChevronSecondaryColor}
        />
      </Flex>

      <SideSelect
        label="Desktop Arrow Position"
        value={values.widget.widgetArrowPosition}
        onChange={(option) => {
          setFieldValue(`widget.widgetArrowPosition`, option.value)
        }}
        options={widgetArrowPositionOptions}
        placeholder="Select"
      />

      <Flex
        px="24px"
        py="24px"
        justifyContent="space-between"
        borderBottom="1px solid rgba(237, 242, 247, 1)"
        alignItems="center"
      >
        <Label mb="0px">Show Mobile Buttons</Label>
        <ToggleRedGreen
          setSelected={(selected) => {
            setFieldValue('widget.widgetMobileChevrons', selected)
          }}
          value1={false}
          value2={true}
          selected={values.widget.widgetMobileChevrons}
        />
      </Flex>
    </Flex>
  )
}

export default ButtonStyle
