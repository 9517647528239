import React, { useCallback, useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize, getDisplaySizeForceLandscapePortrait } from '../ui/helpers.js'
import { H1, H2, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input, TextArea } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import CodeCopy, { copyOnClick } from '../ui/CodeCopy.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import Toggle from '../ui/Toggle.js'
import Select from '../ui/Select.js'
import ColorPicker from '../ui/ColorPicker.js'
import formikFormWrapper from '../formikFormWrapper.js'
import { toast } from 'react-toastify'
import { useModal } from '../Modal.js'
import ModalContainer from '../ui/ModalContainer.js'
import { useAuth } from '../Auth.js'
import api from '../api.js'
import * as LR from '@uploadcare/blocks'
import '@uploadcare/blocks/web/lr-basic.min.css'
import st from '../uploadcare.module.css'
import { useVideos } from '../VideoProvider.js'
import VideoPlayer from '../Components/VideoPlayer'

const FormInput = formikFormWrapper(Input)

const ImageBox = styled(Flex)`
  height: 80px;
  width: 200px;
  max-width: 200px;
  border: 1px dashed #a6b7d4;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

LR.registerBlocks(LR)

const PreviewImage = styled(Image)`
  width: 600px;
  height: 300px;
  object-fit: contain;
`
const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && 'border-bottom: 1px solid black;'}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`
const Tab = ({ onClick, title, isActive }) => {
  return (
    <TabText onClick={onClick} isActive={isActive}>
      {title}
    </TabText>
  )
}

const TABS = {
  DEFAULT: 'DEFAULT',
  MOBILE: 'MOBILE'
}

const AddThumbnailModal = ({ video, refresh, show, setShow }) => {
  if (!show) {
    return null
  }
  const { refresh: allRefresh } = useVideos()
  const [activeTab, setActiveTab] = useState(TABS.DEFAULT)
  let dataOutputRef = useRef()
  let uploaderButton = useRef()
  const [file, setFile] = useState([])

  const classNames = ['lr-wgt-common', st.uploaderCfg].join(' ')
  const handleUploaderEvent = useCallback((e) => {
    const { data } = e.detail
    setFile(data[0])
  }, [])

  const saveImageToUser = async (customThumbnail) => {
    const payload =
      activeTab !== TABS.DEFAULT ? { id: video.id, customMobileThumbnail: customThumbnail } : { id: video.id, customThumbnail }
    const res = await api.post('/video/update', payload)
    if (res.data.success) {
      refresh()
      allRefresh()
      toast('Thumbnail saved')
      setShow(false)
    } else {
      toast('Error updating Thumbnail')
    }
  }

  useEffect(() => {
    let el = dataOutputRef.current
    el.addEventListener('lr-data-output', handleUploaderEvent)
    return () => {
      el.removeEventListener('lr-data-output', handleUploaderEvent)
    }
  }, [handleUploaderEvent])

  const thumbnailToUse = activeTab !== TABS.DEFAULT ? video.customMobileThumbnail : video.customThumbnail

  return (
    <ModalContainer p="24px" interiorClose width="990px" height="550px" isOpen={true} setIsOpen={setShow}>
      <Flex height="100%" justifyContent="space-between">
        <Flex flexDirection="column" maxWidth="211px" borderRight="1px solid #EDF2F7;" pr="16px">
          <H3 fontSize="20px" lineHeight="32px" mb="16px">
            Custom thumbnails
          </H3>
          <Text light mb="16px">
            Upload an image to be used as the video cover image..
          </Text>
        </Flex>
        <Flex flexDirection="column" justifyContent="center" alignItems="center" pt="32px">
          <Flex justifyContent="center" mb="16px">
            <Tab onClick={() => setActiveTab(TABS.DEFAULT)} title="Default" isActive={activeTab === TABS.DEFAULT} />
            <Tab onClick={() => setActiveTab(TABS.MOBILE)} title="Mobile" isActive={activeTab === TABS.MOBILE} />
          </Flex>
          {activeTab === TABS.MOBILE ? (
            <Text textAlign="center" maxWidth="580px" mb="-48px">
              You can use this when using different embed dimensions on desktop and mobile. Without a mobile thumbnail, the
              default will be used on all screen sizes.
            </Text>
          ) : null}
          <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%">
            {!thumbnailToUse && !file.uuid ? (
              <ImageBox
                onClick={() => {
                  document.querySelector('#uploadcare-uploader button').click()
                }}
              >
                <Icon width={16} height={16} icon="plus-grey" />
              </ImageBox>
            ) : (
              <>
                <PreviewImage src={file.uuid ? `https://ucarecdn.com/${file.uuid}/` : thumbnailToUse} alt="Preview" />
              </>
            )}
          </Flex>
          <Flex justifyContent="flex-end" height="auto">
            <Flex>
              <Button
                ml="40px"
                variant="white"
                shadow
                onClick={() => {
                  saveImageToUser('')
                }}
                label="Remove"
              />
            </Flex>
            <Button
              mr="16px"
              variant="grey"
              onClick={() => {
                document.querySelector('#uploadcare-uploader button').click()
              }}
              label="Change"
            />
            <Button variant="black" label="Save" onClick={() => saveImageToUser(`https://ucarecdn.com/${file.uuid}/`)} />
          </Flex>
        </Flex>
        <Flex flexDirection="column" justifyContent="center" alignItems="center" width="0px" height="0px" overflow="hidden">
          <lr-file-uploader-regular id="uploadcare-uploader" ref={uploaderButton} class={classNames}></lr-file-uploader-regular>

          <lr-data-output ref={dataOutputRef} use-event class={classNames} onEvent={handleUploaderEvent}></lr-data-output>
        </Flex>
      </Flex>
    </ModalContainer>
  )
}

const STEPS = {
  INDEX: 'INDEX',
  CUSTOM: 'CUSTOM',
  VIDEO: 'VIDEO'
}

const Stepper = ({ video: inputVideo, show, setShow }) => {
  const [step, setStep] = useState(STEPS.index)
  const [isSubmitting, setIsSubmitting] = useState(STEPS.index)
  const { refresh, videos } = useVideos()

  useEffect(() => {
    refresh()
  }, [])

  if (!inputVideo || !show) {
    return null
  }

  const video = videos.find((v) => v.id === inputVideo.id)

  if (!videos || !videos.length) {
    return null
  }
  const close = (x) => {
    setStep(STEPS.INDEX)
    setShow(x)
  }

  const updateThumbnail = async () => {
    setIsSubmitting(true)

    const time = document.getElementById('video-player-thumbnail').currentTime
    console.log({ time })
    const res = await api.post('/video/update', {
      thumbnailTime: time,
      id: video.id,
      customMobileThumbnail: null,
      customThumbnail: null
    })
    if (res.data.success) {
      close(false)
      refresh()
      toast('Video thumbnail saved')
    } else {
      toast(res.data.message)
    }
    setIsSubmitting(false)
  }

  if (step === STEPS.CUSTOM) {
    return <AddThumbnailModal video={video} refresh={refresh} show={show} setShow={close} />
  }

  const { displayWidth, displayHeight } = getDisplaySizeForceLandscapePortrait(400, 500, video.aspectRatioCalc)
  if (step === STEPS.VIDEO) {
    return (
      <ModalContainer p="24px" interiorClose width={500} autoHeight isOpen={true} setIsOpen={close}>
        <Flex height="100%" flexDirection="column" justifyContent="space-between" alignItems="center">
          <H3 fontSize="20px" lineHeight="32px" mb="16px" textAlign="left" width="100%">
            Thumbnail
          </H3>
          <VideoPlayer
            id="video-player-thumbnail"
            videoStyle={{ width: `${displayWidth}px`, height: `${displayHeight}px`, borderRadius: '16px' }}
            src={`https://stream.mux.com/${video.playbackId}.m3u8`}
          />
          <Flex mt="24px" justifyContent="center">
            <Button mr="8px" variant="grey" label="Cancel" onClick={() => setStep(STEPS.INDEX)} />
            <Button isLoading={isSubmitting} variant="black" label="Select this frame" onClick={updateThumbnail} />
          </Flex>
        </Flex>
      </ModalContainer>
    )
  }

  const ThumbnailSrc = video.customThumbnail
    ? video.customThumbnail + `-/scale_crop/${Math.round(displayWidth)}x${Math.round(displayHeight)}/center/`
    : `https://image.mux.com/${video.playbackId}/thumbnail.png?time=${video.thumbnailTime || 0.0}&width=${Math.round(
        displayWidth
      )}&height=${Math.round(displayHeight)}&fit_mode=crop`
  return (
    <ModalContainer p="24px" interiorClose width={500} autoHeight isOpen={true} setIsOpen={close}>
      <Flex height="100%" flexDirection="column" justifyContent="space-between" alignItems="center">
        <H3 fontSize="20px" lineHeight="32px" mb="16px" textAlign="left" width="100%">
          Thumbnail
        </H3>
        <Image src={ThumbnailSrc} width={displayWidth} height={displayHeight} />
        <Flex mt="24px" justifyContent="center">
          <Button mr="8px" variant="grey" label="Select from video" onClick={() => setStep(STEPS.VIDEO)} />
          <Button variant="grey" label="Custom thumbnail" onClick={() => setStep(STEPS.CUSTOM)} />
        </Flex>
      </Flex>
    </ModalContainer>
  )
}

export default Stepper
