import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import VideoPlayer from '../Components/VideoPlayer'
import ProcessingPreview from '../Components/ProcessingPreview'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { toast } from 'react-toastify'
import { useAuth } from '../Auth.js'
import baseUrl from '../baseUrl.js'
import { useVideos } from '../VideoProvider.js'
import ChatButton from '../Components/ChatButton'
import { useModal } from '../Modal.js'
import Billing from '../Billing/Billing.js'
import { isFreePlan, isProPlan } from '../Plans'
import Users from './Users'
import DeliveredMinutes from './DeliveredMinutes'
import { USER_ROLES } from '../Auth/types.js'
import ToggleRedGreen from '../ui/ToggleRedGreen.js'

const FormInput = formikFormWrapper(Input)

const ProfileImageFallback = styled(Flex)`
  width: 80px;
  height: 80px;
  background: linear-gradient(225deg, #bb65ff 0%, #4c6fff 100%);
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  margin-bottom: 32px;
`

const ImageBox = styled(Flex)`
  height: 80px;
  width: 200px;
  max-width: 200px;
  border: 1px dashed #a6b7d4;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const PreviewImageBox = styled(Flex)`
  width: auto;
  border: 1px solid #a6b7d4;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding: 4px;
`

const PreviewImage = styled(Image)`
  height: 150px;
  object-fit: contain;
`

const TABS = {
  BILLING: 'BILLING',
  ACCOUNT: 'ACCOUNT',
  USERS: 'USERS',
  DELIVERY: 'DELIVERY'
}

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && 'border-bottom: 1px solid black;'}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`

const Tab = ({ onClick, title, isActive }) => {
  return (
    <TabText onClick={onClick} isActive={isActive}>
      {title}
    </TabText>
  )
}

const Account = (props) => {
  let { user, setUser } = useAuth()
  const [search, setSearch] = useSearchParams()
  const [activeTab, setActiveTabState] = useState(search.get('tab') || TABS.ACCOUNT)
  const [searchParams, setSearchParams] = useSearchParams()

  const setActiveTab = (tab) => {
    setActiveTabState(tab)
    setSearchParams({ tab })
  }

  const goToStripePortal = async () => {
    const res = await api.get('/stripe/create-portal-session')
    if (res.data.success) {
      window.location.href = res.data.payload
    }
  }

  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex
        position="relative"
        flexDirection="column"
        width="100%"
        p="30px"
        pb="0px"
        bg="white"
        borderBottom="1px solid #EDF2F7;"
      >
        <Flex>
          <H1 mb="32px" width="100%">
            Account settings
          </H1>
        </Flex>
        <Flex>
          <Tab onClick={() => setActiveTab(TABS.ACCOUNT)} title="Your Account" isActive={activeTab === TABS.ACCOUNT} />
          <Tab onClick={() => setActiveTab(TABS.BILLING)} title="Billing" isActive={activeTab === TABS.BILLING} />
          <Tab onClick={() => setActiveTab(TABS.USERS)} title="Users" isActive={activeTab === TABS.USERS} />
          <Tab onClick={() => setActiveTab(TABS.DELIVERY)} title="Delivery" isActive={activeTab === TABS.DELIVERY} />
        </Flex>
        {user.stripeCustomerId ? (
          <Button
            position="absolute"
            top="72px"
            right="36px"
            shadow
            large
            variant="white"
            label="Manage Payments"
            onClick={goToStripePortal}
          />
        ) : null}{' '}
      </Flex>
      <Flex bg={colors.backgroundGrey} pt="30px" flexDirection="column" alignItems="center">
        {activeTab === TABS.ACCOUNT ? <AccountTab /> : null}
        {activeTab === TABS.BILLING ? <Billing redirectUrl={`${baseUrl}/account?tab=BILLING`} /> : null}
        {activeTab === TABS.USERS ? <Users /> : null}
        {activeTab === TABS.DELIVERY ? <DeliveredMinutes /> : null}
      </Flex>
    </Flex>
  )
}

const UpgradeContainer = styled(Flex)`
  background: linear-gradient(225deg, #bb65ff 0%, #4c6fff 100%);
  height: 37px;
  box-sizing: border-box;
  align-items: center;
  border-radius: 8px;
  width: 212px;
  justify-content: center;
`

const GreyedLabel = styled(Label)`
  ${(props) => (props.disabled ? 'color: rgba(160, 174, 192, 1);' : '')}
`

// USED ELSEWHERE
export const BrandingUploader = ({ hideBrandingToggle }) => {
  let { showAddLogoModal, setShowAddLogoModal } = useModal()
  let { user, setUser } = useAuth()

  const updateNoCliparaLogo = async (bool) => {
    const res = await api.post('/auth/user/update-no-clipara-logo', { noCliparaLogo: bool })
    if (res.data.success) {
      const authRes = await api.get('/auth')
      setUser(authRes.data.payload)
      toast('Changes saved')
      return
    } else {
      toast('Error updating user')
    }
  }

  const ToggleComp = () => (
    <Flex flexDirection="column">
      <Flex alignItems="center" mt="24px" mb="8px">
        <GreyedLabel disabled={!isProPlan(user)} lightLight mr="16px">
          Remove 'Powered by Clipara' in fullscreen footer.
        </GreyedLabel>
        <ToggleRedGreen
          mb="8px"
          setSelected={(selected) => {
            if (!isProPlan(user)) {
              return
            }
            updateNoCliparaLogo(selected)
          }}
          value1={false}
          value2={true}
          selected={user.noCliparaLogo}
        />
      </Flex>
      {isProPlan(user) ? null : (
        <a href="/account?tab=BILLING" target="_blank">
          <UpgradeContainer mb="32px">
            <Icon mr="16px" icon="lightening-white" height="16px" width="11px" />
            <Text mr="20px" fontWeight="500" color="white">
              Upgrade
            </Text>
          </UpgradeContainer>
        </a>
      )}
    </Flex>
  )

  if (isFreePlan(user)) {
    return (
      <>
        <GreyedLabel disabled mb="16px" lightLight>
          Player Branding Logo
        </GreyedLabel>
        <a href="/account?tab=BILLING" target="_blank">
          <UpgradeContainer mb={hideBrandingToggle ? '24px' : '0px'}>
            <Icon mr="16px" icon="lightening-white" height="16px" width="11px" />
            <Text mr="20px" fontWeight="500" color="white">
              Upgrade
            </Text>
          </UpgradeContainer>
        </a>
        {!hideBrandingToggle ? <ToggleComp /> : null}
      </>
    )
  }

  if (user.logoUrl) {
    return (
      <>
        <Label mb="16px">Player Branding Logo</Label>
        <Flex alignItems="flex-end">
          <PreviewImageBox mr="16px" onClick={() => setShowAddLogoModal(true)}>
            <PreviewImage src={user.logoUrl} />
          </PreviewImageBox>

          <Button
            renderLeftIcon={() => <Icon width={16} height={16} icon="upload-black" />}
            variant="white"
            label="Upload"
            shadow
            onClick={() => setShowAddLogoModal(true)}
          />
        </Flex>
        <Flex mb="50px">{!hideBrandingToggle ? <ToggleComp /> : null}</Flex>
      </>
    )
  }
  return (
    <>
      <Label mb="16px">Player Branding Logo</Label>
      <ImageBox mb="8px" onClick={() => setShowAddLogoModal(true)}>
        <Icon width={16} height={16} icon="plus-grey" />
      </ImageBox>
      {!hideBrandingToggle ? <ToggleComp /> : null}
    </>
  )
}

const AccountTab = (props) => {
  let { user, setUser } = useAuth()
  return (
    <Flex flexDirection="column" maxWidth="600px">
      <H3 textAlign="left" width="100%">
        Your Details
      </H3>
      <Formik
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          organisationName: user.organisationName
        }}
        validate={(values) => {
          const errors = {}
          if (!values.firstName) {
            errors.firstName = 'Required'
          }
          if (!values.lastName) {
            errors.lastName = 'Required'
          }
          return errors
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          const res = await api.post('/auth/user/update', values)
          if (res.data.success) {
            setUser({ ...user, ...res.data.payload })
            toast('Changes saved')
            return
          } else {
            toast('Error updating user')
          }
          setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
          /* and other goodies */
        }) => (
          <>
            <ProfileImageFallback>
              <Text fontSize="36px" color="white" fontWeight="700">
                {values.firstName && values.firstName[0]}
              </Text>
            </ProfileImageFallback>
            <Flex mb="24px">
              <Field
                name="firstName"
                placeholder="Enter your name"
                label="First Name"
                large
                type="text"
                component={FormInput}
                mr="28px"
              />
              <Field
                name="lastName"
                large
                placeholder="Enter your last name"
                label="Last Name"
                type="text"
                component={FormInput}
              />
            </Flex>
            <Field
              name="email"
              label="Email"
              disabled
              large
              mb="40px"
              placeholder="Type your email address"
              type="email"
              component={FormInput}
            />
            <Field
              disabled={user.role !== USER_ROLES.ADMIN}
              name="organisationName"
              label="Organisation Name"
              large
              mb="40px"
              placeholder=""
              component={FormInput}
            />

            <BrandingUploader hideBrandingToggle />
            <Box>
              <Button
                mb="8px"
                disabled={isSubmitting}
                isLoading={isSubmitting}
                variant="black"
                label="Save changes"
                onClick={handleSubmit}
              />
            </Box>
          </>
        )}
      </Formik>
    </Flex>
  )
}

export default Account
