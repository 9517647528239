import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import ModalContainer from '../ui/ModalContainer.js'
import SideNav, { TABS } from '../Components/SideNav.js'
import VideoPlayer from '../Components/VideoPlayer'
import ProcessingPreview from '../Components/ProcessingPreview'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { toast } from 'react-toastify'
import { useAuth } from '../Auth.js'
import { useVideos } from '../VideoProvider.js'
import ShopifyConnectModal from './ShopifyConnectModal'
import ChatButton from '../Components/ChatButton'
import ImageUploadModal from '../ui/ImageUploadModal.js'

const Grid = styled(Flex)`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  padding: 30px;
`

const Integrations = (props) => {
  let { user, setUser } = useAuth()
  const [search, setSearch] = useSearchParams()
  const [shopifyInstallOpen, setShopifyInstallOpen] = useState(false)
  const [shopifyInstallConfirmationOpen, setShopifyInstallConfirmationOpen] = useState(!!search.get('showShopifyConfirmation'))
  const [shopifyShopName, setShopifyShopName] = useState('')
  const [error, setError] = useState('')
  const submitShopifyConnect = async () => {
    const res = await api.post('/auth/shopify/install', { shop: shopifyShopName })
    if (res.data.success) {
      return (window.location.href = res.data.payload.url)
    }
  }

  return (
    <>
      <Flex flexDirection="column" alignItems="center">
        <Flex position="relative" flexDirection="column" width="100%" p="30px" bg="white" borderBottom="1px solid #EDF2F7;">
          <Flex>
            <H1 mb="32px" width="100%">
              Integrations
            </H1>
            <Flex width="auto">
              <ChatButton showCallButton />
            </Flex>
          </Flex>
        </Flex>
        <Grid>
          <Container p="24px" justifyContent="space-between">
            <Icon icon="shopify-logo" width="150px" height="41px" mb="22px" />
            <Text light mb="22px">
              Connect your Shopify store for shoppable video.
            </Text>
            <Flex flexDirection="row-reverse" alignItems="center">
              {user.shopifyShopUrl ? (
                <>
                  <Icon width={8} height={8} icon="tick-green" />
                  <Text mr="8px" light fontSize="12px" fontWeight="600">
                    {user.shopifyShopUrl}
                  </Text>
                </>
              ) : (
                <Button small width="auto" variant="black" label="Install" onClick={() => setShopifyInstallOpen(true)} />
              )}
            </Flex>
          </Container>
        </Grid>
      </Flex>
      <ShopifyConnectModal isOpen={shopifyInstallOpen} setIsOpen={setShopifyInstallOpen} />
      <ModalContainer
        interiorClose
        p="24px"
        width="650px"
        height="350px"
        overflowY="unset"
        isOpen={shopifyInstallConfirmationOpen}
        setIsOpen={setShopifyInstallConfirmationOpen}
      >
        <Flex flexDirection="column" alignItems="left">
          <Icon icon="shopify-logo" width="150px" height="41px" mb="22px" />
          <Text fontSize="16px" mb="16px" light fontWeight="700">
            You’ve successfully linked your Shopify store 🙌.
          </Text>
          <Text fontSize="16px" mb="16px" light>
            You can now create fully Shoppable video experiences by linking products to your campaigns and videos.
          </Text>
          <Link to="/campaigns/create?step=ON_SITE_OFF_SITE">
            <Button
              mr="16px"
              zIndex={1}
              position="absolute"
              bottom="24px"
              right="8px"
              variant="black"
              label="Make a campaign"
              renderRightIcon={() => <Icon ml="16px" width={16} height={16} icon="chevron-right-white" />}
            />
          </Link>
        </Flex>
      </ModalContainer>
    </>
  )
}

export default Integrations
