import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize } from '../../ui/helpers'
import { H1, H2, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../../ui/Typography'
import { Flex, Box, Container } from '../../ui/Layout'
import Image from '../../ui/Image'
import { Input, TextArea } from '../../ui/Input'
import Icon from '../../ui/Icon'
import CodeCopy from '../../ui/CodeCopy'
import VideoPreview from '../../ui/VideoPreview'
import CarouselPreview from '../../Components/CarouselPreview'

import WidgetPreview from '../../Components/WidgetPreview'
import Button from '../../ui/Button'
import { BigDarkSpinner } from '../../ui/Spinner'
import Toggle from '../../ui/Toggle'
import Select from '../../ui/Select'
import ColorPicker from '../../ui/ColorPicker'
import formikFormWrapper from '../../formikFormWrapper'
import { toast } from 'react-toastify'
import { useModal } from '../../Modal'
import ModalContainer from '../../ui/ModalContainer'
import CreateModal from './ShareModal'
import ChatButton from '../../Components/ChatButton'

const FormInput = formikFormWrapper(Input)

import api from '../../api'
import { useAuth } from '../../Auth'

import {
  STEPS,
  CAMPAIGN_RULE_TYPES,
  CAMPAIGN_STATUS,
  campaignRuleTypesOptions,
  WIDGET_POSITIONS,
  widgetPositionOptions,
  widgetRuleTypeOptions,
  widgetTypeDisplayName,
  getSelectWidgetOptions
} from '../types'

const ChoiceContainer = styled(Container)`
  border: 1px solid #e1e8ff;
  padding: 8px;
  cursor: pointer;
  width: 30%;
  max-width: 350px;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
  &&:hover {
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.2), 0px 4px 20px -2px rgba(50, 50, 71, 0.2);
    border: 2px solid #7895ff;
    margin-top: -1px;
  }
`

const InnerContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  height: 200px;
  background: linear-gradient(225deg, #f1f1f5 0%, #e4ecf7 100%);
  margin-bottom: 24px;
  position: relative;
`

const BottomRightIcon = styled(Icon)`
  position: absolute;
  bottom: 8px;
  right: 8px;
`

const OnSiteOffSite = ({
  step,
  setStep,
  values,
  selectedVideos,
  openFullscreenDesktopPreview,
  openFullscreenMobilePreview,
  videoObjects,
  setFieldValue,
  submit,
  widgets,
  setWidgets
}) => {
  const { user } = useAuth()
  console.log(user)
  return (
    <>
      <Flex height="100vh" flexDirection="column" alignItems="center">
        <Link to="/campaigns">
          <Flex cursor="pointer" position="fixed" left="24px" top="24px" width="auto">
            <Icon mt="4px" mr="16px" icon="chevron-left-lightlight" height="16px" width="16px" />
            <Text lightLight fontSize="14px" mb="30px" textAlign="left">
              Back to Campaigns
            </Text>
          </Flex>
        </Link>

        <H1 fontSize="28px" mt="72px" mb="72px" textAlign="left">
          Where is your campaign going?
        </H1>
        <Flex mb="50px" justifyContent="center" px="50px">
          <ChoiceContainer
            onClick={() => {
              setFieldValue(`isShareCampaign`, false)
              setStep(STEPS.SHOPPABLE_SELECT)
            }}
          >
            <InnerContainer>
              <Icon icon="code-slash-purple" width={63} height={63} />
            </InnerContainer>
            <H3 fontSize="16px" mb="16px">
              On-site
            </H3>
            <Text light textAlign="center" mx="24px" mb="32px">
              Add content to a page on your website
            </Text>
          </ChoiceContainer>
          <ChoiceContainer
            onClick={() => {
              setFieldValue(`isShareCampaign`, true)
              if (user.shopifyShopUrl) {
                setStep(STEPS.SHOPPABLE_SELECT)
              } else if (user.isContentCreator) {
                setStep(STEPS.SHOPPABLE_SELECT)
              } else {
                setStep(STEPS.SELECT_VIDEO)
                setFieldValue('isShoppable', false)
                setStep(STEPS.SELECT_VIDEO, { isShoppable: false })
              }
            }}
          >
            <InnerContainer>
              <Icon icon="share-purple" width={63} height={63} />
            </InnerContainer>
            <H3 fontSize="16px" mb="16px">
              Off-site
            </H3>
            <Text light textAlign="center" mx="24px" mb="32px">
              Share a link to your campaign anywhere you like, such as email & SMS.
            </Text>
          </ChoiceContainer>
        </Flex>
      </Flex>
    </>
  )
}

export default OnSiteOffSite
