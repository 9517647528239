import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../ui/Layout'
import ProcessingPreview from './ProcessingPreview'
import { formatSeconds } from '../ui/helpers'
import { getChevronStyling, getChevronImg, getChevronImgSize } from '../Campaigns/chevronStyles.js'
import { getAspectRatio } from './EmbedCarousel.js'
import { ImageExpandButton } from './ui/ImageExpandButton.js'

const baseUrl = 'https://widget-v2.getclipara.com'

const CarouselItem = styled(Box)`
  position: relative;
  cursor: pointer;
  width: ${(props) => props.videoWidth}px;
  min-width: ${(props) => props.videoWidth}px;
  height: ${(props) => props.videoHeight}px;
  margin-left: ${(props) => props.gapWidth / 2}px;
  margin-right: ${(props) => props.gapWidth / 2}px;
  border-radius: ${(props) => `${props.borderRadius}px`};
  overflow: hidden;
  background-color: black;
  box-sizing: border-box;
  ${(props) =>
    props.widgetThumbnailBorder
      ? `border: ${props.widgetThumbnailBorderSize || 3}px solid ${props.widgetThumbnailBorderColor || 'black'};`
      : ''}
`

const pbSizeFunc = (props) => {
  if (!props.widgetMobileThumbnailSize || props.widgetMobileThumbnailSize === 'LARGE') {
    return `width: 60px;
      height: 60px;`
  }
  if (props.widgetMobileThumbnailSize === 'MEDIUM') {
    return `width: 40px;
      height: 40px;`
  }
  if (props.widgetMobileThumbnailSize === 'SMALL') {
    return `width: 20px;
      height: 20px;`
  }
}
const PlayButton = styled.svg`
  fill: none !important;
  ${pbSizeFunc}
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const PBContainer = styled(Box)`
  ${pbSizeFunc}
  position: absolute;
  z-index: 1;
  @keyframes cliparapulse {
    0% {
      box-shadow: 0 0 0 0 ${(props) => props.playButtonColor};
    }
    100% {
      box-shadow: 0 0 0 8px #fbb32f01;
    }
  }
  border-radius: 60px;
  ${(props) => props.animate && `animation: cliparapulse 1500ms infinite;`}
  ${(props) => {
    if (props.widgetPlayButtonPosition === 'BOTTOM_LEFT') {
      return `bottom: 16px; left: 16px;`
    }
    if (props.widgetPlayButtonPosition === 'BOTTOM_RIGHT') {
      return `bottom: 16px; right: 16px;`
    }
    return ` top: 50%; left: 50%; transform: translate(-50%, -50%);`
  }}
`

const VideoImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  ${(props) =>
    props.isCircle && props.widgetThumbnailBorder && `border: 2.5px solid white; box-sizing: border-box; border-radius: 100%;`}
`

const Picture = styled.picture`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const Slider = styled(Flex)`
  scroll-behavior: smooth;
  max-width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &&::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  justify-content: ${(props) => {
    if (props.shouldShowArrows) {
      return 'flex-start'
    }
    if (props.position === 'LEFT') {
      return 'flex-start'
    }
    if (props.position === 'RIGHT') {
      return 'flex-end'
    }
    return 'center'
  }};
`

const Title = styled.p`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily.replace(';') : 'inherit')} !important;
  font-weight: ${(props) => props.fontWeight || '700'} !important;
  font-size: ${(props) => props.mobileFontSize || props.fontSize || '50'}px !important;
  line-height: ${(props) => props.mobileFontSize || props.fontSize || '50'}px !important;
  text-align: ${(props) => props.textAlign || 'center'} !important;
  color: ${(props) => props.color};
  text-decoration: none;
  margin-bottom: 16px;
  margin-top: 0px;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-left: 8px !important;
  width: ${(props) => props.width};
  padding-bottom: 0px !important;
  word-break: break-word;
`

const VideoDuration = styled.p`
  z-index: 2;
  position: absolute;
  bottom: ${(props) => props.durationPadding || 16}px;
  right: ${(props) => props.durationPadding || 16}px;
  color: white !important;
  font-weight: 600 !important;
  margin: 0px !important;
  ${(props) => {
    if (props.widgetMobileThumbnailSize === 'MEDIUM') {
      return 'font-size: 14px !important; line-height: 14px !important;'
    }
    if (props.widgetMobileThumbnailSize === 'SMALL') {
      return 'font-size: 12px !important; line-height: 12px !important;'
    }
    return 'font-size: 16px !important; line-height: 16px !important;'
  }}
`

const Chevron = styled(Flex)`
  z-index: 2;
  width: 48px;
  min-width: 48px;
  height: 48px;
  ${(props) => getChevronStyling(props.widgetButtonStyle, props.widgetChevronSecondaryColor)}
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${(props) => {
    if (!props.widgetMobileThumbnailSize || props.widgetMobileThumbnailSize === 'LARGE') {
      return `width: 48px;
  min-width: 48px;
  height: 48px;`
    }
    if (props.widgetMobileThumbnailSize === 'MEDIUM' || props.widgetMobileThumbnailSize === 'SMALL') {
      return `width: 40px;
  min-width: 40px;
  height: 40px;`
    }
  }}
  ${(props) => (props.isDisabled ? `cursor: auto; opacity: 0.5;` : '')}
`

const ChevronImage = styled(Flex)`
  svg {
    fill: ${(props) => props.widgetChevronPrimaryColor || 'black'} !important;
    stroke: ${(props) => props.widgetChevronPrimaryColor || 'black'} !important;
  }
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  align-items: center;
  justify-content: center;
  width: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  height: ${(props) => getChevronImgSize(props.widgetButtonStyle)} !important;
  ${(props) => {
    if (!props.widgetMobileThumbnailSize || props.widgetMobileThumbnailSize === 'LARGE') {
      return `width: 20px !important;
  height: 20px !important;`
    }
    if (props.widgetMobileThumbnailSize === 'MEDIUM' || props.widgetMobileThumbnailSize === 'SMALL') {
      return `width: 16px !important;
  height: 16px !important;`
    }
  }}
`

const LeftChevron = styled(Chevron)``

const RightChevron = styled(Chevron)`
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
`

const VideoTitle = styled.p`
  font-family: ${(props) => (props.widgetTitleFontFamily ? props.widgetTitleFontFamily.replace(';') : 'inherit')} !important;
  font-weight: ${(props) => props.widgetTitleFontWeight || '700'} !important;
  font-size: ${(props) => props.widgetMobileTitleFontSize || '18'}px !important;
  line-height: ${(props) => props.widgetMobileTitleFontSize || '18'}px !important;
  text-align: ${(props) => (props.widgetTitleAlignment ? props.widgetTitleAlignment.toLowerCase() : 'center')} !important;
  color: ${(props) => props.widgetTitleFontColor || 'black'};
  text-decoration: none !important;
  margin-bottom: 16px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
  a:visited,
  a:active,
  a:hover,
  a:focus {
    color: ${(props) => props.widgetTitleFontColor || 'black'};
  }
`

const VideoSubtitle = styled.p`
  font-family: ${(props) =>
    props.widgetSubtitleFontFamily ? props.widgetSubtitleFontFamily.replace(';') : 'inherit'} !important;
  font-weight: ${(props) => props.widgetSubtitleFontWeight || '500'} !important;
  text-align: ${(props) => (props.widgetTitleAlignment ? props.widgetTitleAlignment.toLowerCase() : 'center')} !important;
  color: ${(props) => props.widgetSubtitleFontColor || 'black'};
  text-decoration: none !important;
  margin-bottom: 16px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  word-break: break-word;
  font-size: ${(props) => props.widgetMobileSubtitleFontSize || '16'}px !important;
  line-height: ${(props) => props.widgetMobileSubtitleFontSize || '16'}px !important;
  a:visited,
  a:active,
  a:hover,
  a:focus {
    color: ${(props) => props.widgetSubtitleFontColor || 'black'};
  }
`

const Titles = ({
  isStories,
  gapWidth,
  titleVideoObject,
  widgetTitleAlignment,
  widgetMobileTitleFontSize,
  widgetTitleFontColor,
  widgetTitleFontFamily,
  widgetTitleFontWeight,
  widgetSubtitleFontWeight,
  widgetMobileSubtitleFontSize,
  widgetSubtitleFontColor,
  widgetSubtitleFontFamily
}) => {
  return (
    <Flex flexDirection="column" mx={`${gapWidth / 2}px`} width="100%">
      {titleVideoObject.title && (
        <VideoTitle
          widgetTitleAlignment={widgetTitleAlignment}
          widgetMobileTitleFontSize={widgetMobileTitleFontSize}
          widgetTitleFontColor={widgetTitleFontColor}
          widgetTitleFontFamily={widgetTitleFontFamily}
          widgetTitleFontWeight={widgetTitleFontWeight}
          dangerouslySetInnerHTML={{ __html: titleVideoObject.title }}
        ></VideoTitle>
      )}
      {titleVideoObject.subtitle && !isStories && (
        <VideoSubtitle
          widgetTitleAlignment={widgetTitleAlignment}
          widgetMobileSubtitleFontSize={widgetMobileSubtitleFontSize}
          widgetSubtitleFontColor={widgetSubtitleFontColor}
          widgetSubtitleFontFamily={widgetSubtitleFontFamily}
          widgetSubtitleFontWeight={widgetSubtitleFontWeight}
          dangerouslySetInnerHTML={{ __html: titleVideoObject.subtitle }}
        ></VideoSubtitle>
      )}
    </Flex>
  )
}

const InnerTitleContainer = styled(Flex)`
  z-index: 1;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding-left: 16px;
  padding-right: 16px;
  height: 150px;
  flex-direction: column-reverse;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
`

const applySizeFactor = (x, size, isStories) => {
  if (isStories) {
    if (size === 'MEDIUM') {
      return Math.round(x * 0.75)
    }
    if (size === 'SMALL') {
      return Math.round(x * 0.5833)
    }
    return x
  }
  if (size === 'MEDIUM') {
    return Math.round(x * 0.6)
  }
  if (size === 'SMALL') {
    return Math.round(x * 0.4)
  }
  return x
}

const getGapWidth = (size) => {
  if (size === 'MEDIUM') {
    return 8
  }
  if (size === 'SMALL') {
    return 4
  }
  return 16
}

const getVideoWidth = (dimensions, isStories) => {
  if (isStories) {
    if (dimensions === 'CIRCLE') {
      return 120
    }
    return 120
  }
  if (dimensions === 'LANDSCAPE') {
    return 300
  }
  if (dimensions === 'SQUARE') {
    return 300
  }
  if (dimensions === 'SQUISHED_PORTRAIT') {
    return 250
  }
  if (dimensions === 'CIRCLE') {
    return 300
  }
  // PORTRAIT
  return 250
}

const getVideoHeight = (dimensions, isStories) => {
  if (isStories) {
    if (dimensions === 'CIRCLE') {
      return 120
    }
    return 177.5
  }
  if (dimensions === 'LANDSCAPE') {
    return 175
  }
  if (dimensions === 'SQUARE') {
    return 300
  }
  if (dimensions === 'SQUASHED_PORTRAIT') {
    return 325
  }
  if (dimensions === 'CIRCLE') {
    return 300
  }
  // PORTRAIT
  return 400
}

const getFixedVideoHeight = (dimensions, videoWidth) => {
  if (dimensions === 'LANDSCAPE') {
    return (videoWidth / 640) * 370
  }
  if (dimensions === 'SQUARE') {
    return videoWidth
  }
  if (dimensions === 'SQUASHED_PORTRAIT') {
    return (videoWidth / 338) * 500
  }
  if (dimensions === 'CIRCLE') {
    return videoWidth
  }
  // PORTRAIT
  return (videoWidth / 338) * 600
}

const getDurationPadding = (borderRadius, size) => {
  if (borderRadius > 32) {
    return borderRadius + 8
  }
  if (size === 'MEDIUM') {
    return 10
  }
  if (size === 'SMALL') {
    return 6
  }
  return 12
}

let mouseDown = false
let startX, scrollLeft

const EmbedMobileCarousel = ({ campaign, setShowFullscreenPlayer, setActiveFullScreenVideo }) => {
  const {
    videos,
    landscape,
    playButtonColor,
    borderRadius: inputBorderRadius,
    textAlign,
    fontFamily,
    fontSize,
    fontWeight,
    titleColor,
    durationDisplay,
    mobileFontSize,
    widgetTitleActive,
    videoObjects,
    widgetTitleAlignment,
    widgetDesktopTitleFontSize,
    widgetMobileTitleFontSize,
    widgetTitleFontColor,
    widgetTitleFontFamily,
    widgetTitleFontWeight,
    widgetSubtitleFontWeight,
    widgetTitlePosition,
    widgetSubtitleFontColor,
    widgetSubtitleFontFamily,
    widgetThumbnailDimensions,
    widgetMobileThumbnailDimensions,
    widgetMobileThumbnailSize,
    widgetMobileSubtitleFontSize,
    widgetThumbnailBorderSize,
    widgetThumbnailBorderColor,
    widgetThumbnailBorder,
    marginX,
    widgetPlayButtonPosition,
    widgetButtonStyle,
    widgetChevronPrimaryColor,
    widgetChevronSecondaryColor,
    widgetPlayButtonStyle,
    type,
    widgetFixedThumbnailWidth,
    widgetFixedThumbnailHeight,
    widgetFixedThumbnail,
    position,
    widgetGapWidth,
    widgetMobileChevrons
  } = campaign
  const isStories = type === 'STORIES'
  const dimensions = isStories ? widgetThumbnailDimensions : widgetMobileThumbnailDimensions || widgetThumbnailDimensions
  const videoWidth = widgetFixedThumbnail
    ? widgetFixedThumbnailWidth
    : applySizeFactor(getVideoWidth(dimensions, isStories), widgetMobileThumbnailSize, isStories)
  const videoHeight = widgetFixedThumbnail
    ? getFixedVideoHeight(dimensions, videoWidth)
    : applySizeFactor(getVideoHeight(dimensions, isStories), widgetMobileThumbnailSize, isStories)
  const isCircle = dimensions === 'CIRCLE'
  const borderRadius = isCircle ? videoWidth / 2 : inputBorderRadius
  const gapWidth = isStories ? widgetGapWidth : getGapWidth(widgetMobileThumbnailSize)
  const numberOfVideos = videos.length
  const containerWidth = 400
  const shouldShowArrows = containerWidth - numberOfVideos * videoWidth - gapWidth * (numberOfVideos - 2) < 0

  const slider = useRef()

  // DASH SPECIFIC
  const startDragging = (e) => {
    mouseDown = true
    startX = e.pageX - slider.current.offsetLeft
    scrollLeft = slider.current.scrollLeft
  }
  const stopDragging = (e) => {
    mouseDown = false
  }
  const move = (e) => {
    e.preventDefault()
    if (!mouseDown) {
      return
    }
    const x = e.pageX - slider.current.offsetLeft
    const scroll = x - startX
    slider.current.scrollLeft = scrollLeft - scroll
  }
  useEffect(() => {
    slider.current.addEventListener('mousemove', move, false)
    slider.current.addEventListener('mousedown', startDragging, false)
    slider.current.addEventListener('mouseup', stopDragging, false)
    slider.current.addEventListener('mouseleave', stopDragging, false)
  }, [])

  return (
    <Flex flexDirection="column">
      {campaign.text ? (
        <Title
          color={titleColor}
          fontSize={fontSize}
          mobileFontSize={mobileFontSize}
          fontWeight={fontWeight}
          fontFamily={fontFamily}
          textAlign={textAlign}
          width={'100%'}
        >
          {campaign.text}
        </Title>
      ) : null}
      <Slider
        position={position}
        shouldShowArrows={shouldShowArrows}
        ref={slider}
        mb={!isStories && shouldShowArrows ? '16px' : '0px'}
      >
        {videos.map((video, i) => {
          const isVideo = video.type === 'VIDEO'
          const imageBoostFactor = videoWidth < 150 || videoHeight < 150 ? 1.5 : 1
          const imageUrl =
            video.customMobileThumbnail || video.customThumbnail
              ? (video.customMobileThumbnail || video.customThumbnail) +
                `-/scale_crop/${Math.round(videoWidth)}x${Math.round(videoHeight)}/center/`
              : `https://image.mux.com/${video.playbackId}/thumbnail.png?time=${video.thumbnailTime || 0.0}&width=${Math.round(
                  videoWidth * imageBoostFactor
                )}&height=${Math.round(videoHeight * imageBoostFactor)}&fit_mode=crop`
          const webpUrl =
            video.customMobileThumbnail || video.customThumbnail
              ? (video.customMobileThumbnail || video.customThumbnail) + '-/format/webp/'
              : imageUrl.replace('.png', '.webp')
          const durationPadding = getDurationPadding(borderRadius, widgetMobileThumbnailSize)
          const titleVideoObject =
            videoObjects.find((vo) => vo.videoId.toString() === video.id.toString() && vo.type === 'TITLE') || {}
          return (
            <Flex key={video.id} flexDirection="column" width="auto">
              <CarouselItem
                onClick={() => {
                  setActiveFullScreenVideo(i)
                  setShowFullscreenPlayer(true)
                }}
                gapWidth={gapWidth}
                videoWidth={videoWidth}
                videoHeight={videoHeight}
                landscape={landscape}
                key={video.id}
                borderRadius={borderRadius}
                widgetThumbnailBorderSize={widgetThumbnailBorderSize}
                widgetThumbnailBorderColor={widgetThumbnailBorderColor}
                widgetThumbnailBorder={widgetThumbnailBorder}
              >
                {!video.playbackId && isVideo && <ProcessingPreview position="absolute" left="0" top="0" />}
                {isVideo ? (
                  <Picture>
                    <source srcset={webpUrl} type="image/webp" />
                    <source srcset={imageUrl} type="image/png" />
                    <VideoImage
                      isCircle={isCircle}
                      widgetThumbnailBorder={widgetThumbnailBorder}
                      loading="lazy"
                      src={imageUrl}
                      alt={video.name}
                      borderRadius={borderRadius}
                    />
                  </Picture>
                ) : (
                  <VideoImage
                    isCircle={isCircle}
                    widgetThumbnailBorder={widgetThumbnailBorder}
                    loading="lazy"
                    src={`https://clipara.b-cdn.net/${video.path}?aspect_ratio=${getAspectRatio(
                      campaign.widgetThumbnailDimensions
                    )}&width=${Math.round(videoWidth * 2)}`}
                    alt={video.name}
                    borderRadius={borderRadius}
                  />
                )}

                {isStories ? null : isVideo ? (
                  <PBContainer
                    widgetPlayButtonPosition={widgetPlayButtonPosition}
                    widgetMobileThumbnailSize={widgetMobileThumbnailSize}
                    animate={campaign.playButtonAnimation}
                    playButtonColor={playButtonColor || '#333333'}
                  >
                    <PlayButton
                      widgetMobileThumbnailSize={widgetMobileThumbnailSize}
                      animate={campaign.playButtonAnimation}
                      playButtonColor={playButtonColor || '#333333'}
                      width={36}
                      height={36}
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {widgetPlayButtonStyle === 'FILLED' ? (
                        <>
                          <circle cx="30" cy="30" r="30" fill={playButtonColor || '#333333'} />
                          <path
                            d="M45 28.268C46.3333 29.0378 46.3333 30.9623 45 31.7321L24 43.8564C22.6667 44.6262 21 43.664 21 42.1244L21 17.8756C21 16.336 22.6667 15.3738 24 16.1436L45 28.268Z"
                            fill="white"
                          />
                        </>
                      ) : (
                        <>
                          <circle cx="30" cy="30" r="28.5" stroke={playButtonColor || '#333333'} stroke-width="3" />
                          <path
                            d="M46.1097 29.2683C47.4431 30.0381 47.4431 31.9626 46.1097 32.7324L24.9451 44.9518C23.6118 45.7216 21.9451 44.7594 21.9451 43.2198L21.9451 18.781C21.9451 17.2414 23.6118 16.2791 24.9451 17.0489L46.1097 29.2683Z"
                            fill={playButtonColor || '#333333'}
                          />
                        </>
                      )}
                    </PlayButton>
                  </PBContainer>
                ) : null}
                {titleVideoObject && widgetTitleActive && widgetTitlePosition === 'INSIDE' ? (
                  <InnerTitleContainer>
                    <Titles
                      isStories={isStories}
                      videoWidth={videoWidth}
                      widgetTitleAlignment={widgetTitleAlignment}
                      widgetDesktopTitleFontSize={widgetDesktopTitleFontSize}
                      widgetMobileTitleFontSize={widgetMobileTitleFontSize}
                      widgetTitleFontColor={widgetTitleFontColor}
                      widgetTitleFontFamily={widgetTitleFontFamily}
                      widgetTitleFontWeight={widgetTitleFontWeight}
                      widgetSubtitleFontWeight={widgetSubtitleFontWeight}
                      titleVideoObject={titleVideoObject}
                      widgetSubtitleFontFamily={widgetSubtitleFontFamily}
                      widgetSubtitleFontColor={widgetSubtitleFontColor}
                      widgetMobileSubtitleFontSize={widgetMobileSubtitleFontSize}
                    />
                  </InnerTitleContainer>
                ) : null}
                {durationDisplay ? (
                  <VideoDuration durationPadding={durationPadding} widgetMobileThumbnailSize={widgetMobileThumbnailSize}>
                    {video.duration ? formatSeconds(video.duration) : ''}
                  </VideoDuration>
                ) : null}
              </CarouselItem>
              {titleVideoObject && widgetTitleActive && widgetTitlePosition === 'BELOW' ? (
                <Flex mt="16px" maxWidth={videoWidth + 16} px="8px" boxSizing="border-box">
                  <Titles
                    isStories={isStories}
                    videoWidth={videoWidth}
                    widgetTitleAlignment={widgetTitleAlignment}
                    widgetDesktopTitleFontSize={widgetDesktopTitleFontSize}
                    widgetMobileTitleFontSize={widgetMobileTitleFontSize}
                    widgetTitleFontColor={widgetTitleFontColor}
                    widgetTitleFontFamily={widgetTitleFontFamily}
                    widgetTitleFontWeight={widgetTitleFontWeight}
                    widgetSubtitleFontWeight={widgetSubtitleFontWeight}
                    titleVideoObject={titleVideoObject}
                    widgetSubtitleFontFamily={widgetSubtitleFontFamily}
                    widgetSubtitleFontColor={widgetSubtitleFontColor}
                    widgetMobileSubtitleFontSize={widgetMobileSubtitleFontSize}
                  />
                </Flex>
              ) : null}
            </Flex>
          )
        })}
        {shouldShowArrows && <Box width="8px" minWidth="8px" />}
      </Slider>
      {shouldShowArrows && !isStories && widgetMobileChevrons ? (
        <Flex justifyContent="flex-end" alignItems="center" display="flex !important">
          <LeftChevron
            widgetChevronSecondaryColor={widgetChevronSecondaryColor}
            widgetButtonStyle={widgetButtonStyle}
            widgetMobileThumbnailSize={widgetMobileThumbnailSize}
            mr="12px"
            onClick={() => {
              slider.current.scrollBy(-videoWidth, 0)
            }}
          >
            <ChevronImage
              widgetChevronPrimaryColor={widgetChevronPrimaryColor}
              widgetButtonStyle={widgetButtonStyle}
              widgetMobileThumbnailSize={widgetMobileThumbnailSize}
              width={getChevronImgSize(widgetButtonStyle)}
              height={getChevronImgSize(widgetButtonStyle)}
            >
              {getChevronImg(widgetButtonStyle)}
            </ChevronImage>
          </LeftChevron>
          <RightChevron
            widgetChevronSecondaryColor={widgetChevronSecondaryColor}
            widgetButtonStyle={widgetButtonStyle}
            widgetMobileThumbnailSize={widgetMobileThumbnailSize}
            mr="8px"
            onClick={() => {
              slider.current.scrollBy(videoWidth, 0)
            }}
          >
            <ChevronImage
              widgetChevronPrimaryColor={widgetChevronPrimaryColor}
              widgetButtonStyle={widgetButtonStyle}
              widgetMobileThumbnailSize={widgetMobileThumbnailSize}
              width={getChevronImgSize(widgetButtonStyle)}
              height={getChevronImgSize(widgetButtonStyle)}
            >
              {getChevronImg(widgetButtonStyle)}
            </ChevronImage>
          </RightChevron>
        </Flex>
      ) : null}
    </Flex>
  )
}

export default EmbedMobileCarousel
