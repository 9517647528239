import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import ReactTooltip from 'react-tooltip'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { colors, numberWithCommas, shorten } from '../ui/helpers.js'
import { msToTime } from '../ui/formatters'
import { H1, H2, H3, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Container, Flex, Box } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import { BigDarkSpinner } from '../ui/Spinner'
import Button from '../ui/Button.js'
import Select from '../ui/Select.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import { PLAYER_TYPES, playerTypeOptions } from '../Campaigns/types.js'
import { setupCharts } from './helpers'
import Chart from 'chart.js/auto'
import 'chartjs-adapter-moment'
import ChatButton from '../Components/ChatButton'
import { stripHtml } from 'string-strip-html'
import { DATE_PERIODS } from './types'
import DatePopover from './DatePopover'
import PopoverDropdown from '../ui/PopoverDropdown.js'
import { HTr, Th, HeaderText, Tr, Td, Table } from '../ui/Table.js'
import { options, black, pink } from './helpers.js'

const CHART_TYPES = {
  LOADS: 'LOADS',
  PLAYS: 'PLAYS',
  CLICKS: 'CLICKS',
  VIEWING_TIME: 'VIEWING_TIME',
  AVG_VIEWING_TIME: 'AVG_VIEWING_TIME',
  ADD_TO_CART: 'ADD_TO_CART'
}

const PAGESIZE = 100

const CHART_TYPES_OPTIONS = (isContentCreator) => ({
  LOADS: {
    title: 'Impressions',
    property: 'distinctLoad'
  },
  PLAYS: {
    title: 'Total Plays',
    property: 'play'
  },
  CLICKS: {
    title: isContentCreator ? 'Total Downloads' : 'Total Clicks',
    property: 'click'
  },
  VIEWING_TIME: {
    title: 'Total View Time',
    property: 'viewTime',
    label: 'Minutes'
  },
  AVG_VIEWING_TIME: {
    title: 'Average View Time',
    property: 'averageViewTime',
    label: 'Seconds'
  },
  ADD_TO_CART: {
    title: 'Add to Carts',
    property: 'addToCart'
  }
})

export const Header = ({ title, sortColumn, setSortColumn, setSortDirection, setPage }) => {
  return (
    <Flex justifyContent="flex-end">
      <HeaderText mr="16px">{title}</HeaderText>
      <Flex justifyContent="center" flexDirection="column" width="auto" marginTop="0px" cursor="pointer">
        <Icon
          icon="caret-up-grey"
          width={7}
          height={7}
          minWidth={7}
          minHeight={7}
          mb="2px"
          onClick={() => {
            setSortColumn(sortColumn)
            setSortDirection('asc')
            setPage(1)
          }}
        />
        <Icon
          icon="caret-down-grey"
          width={7}
          height={7}
          minWidth={7}
          minHeight={7}
          onClick={() => {
            setSortColumn(sortColumn)
            setSortDirection('desc')
            setPage(1)
          }}
        />
      </Flex>
    </Flex>
  )
}

const LineClamp = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const ChartRow = ({ title, chartId, setChartToShow, show }) => {
  let { user } = useAuth()
  const shopifyOptions = user.shopifyShopUrl
    ? [{ label: 'Add to Cart', onClick: () => setChartToShow(CHART_TYPES.ADD_TO_CART) }]
    : [{ label: user.isContentCreator ? 'Downloads' : 'Clicks', onClick: () => setChartToShow(CHART_TYPES.CLICKS) }]
  return (
    <Container display={show ? 'block' : 'none'} mb="32px" position="relative">
      <Flex flexDirection="column">
        <PopoverDropdown
          width="300px"
          renderCustomLauncher={() => (
            <Flex cursor="pointer" width="auto">
              <H3 fontSize="24px" mb="24px" display="inline">
                {title}
              </H3>
              <Icon ml="16px" mt="4px" icon="chevron-down-black" width="18px" height="18px" />
            </Flex>
          )}
          options={[
            { label: 'Loads', onClick: () => setChartToShow(CHART_TYPES.LOADS) },
            { label: 'Plays', onClick: () => setChartToShow(CHART_TYPES.PLAYS) },
            { label: 'Viewing Time', onClick: () => setChartToShow(CHART_TYPES.VIEWING_TIME) },
            { label: 'Average Viewing Time', onClick: () => setChartToShow(CHART_TYPES.AVG_VIEWING_TIME) },
            ...shopifyOptions
          ]}
        />
        <Box minWidth="100%" width="100%" height={300}>
          <canvas id={chartId} />
        </Box>
      </Flex>
    </Container>
  )
}

let chart
const CampaignAnalytics = ({ response }) => {
  const allData = response.campaignAnalyticsAll
  let { user } = useAuth()
  const moneyFormat = (input) =>
    response.moneyFormat
      ? stripHtml(
          response.moneyFormat
            .replace('{{amount}}', input)
            .replace('{{amount_no_decimals}}', input)
            .replace('{{amount_with_comma_separator}}', input)
            .replace('{{amount_no_decimals_with_comma_separator}}', input)
            .replace('{{amount_with_apostrophe_separator}}', input)
        ).result
      : input
  useEffect(() => {
    ReactTooltip.rebuild()
    return () => {
      ReactTooltip.hide()
    }
  })

  const [chartToShow, setChartToShow] = useState(CHART_TYPES.PLAYS)
  const [sortColumn, setSortColumn] = useState('distinctPlay')
  const [sortDirection, setSortDirection] = useState('desc')
  const [page, setPage] = useState(1)

  useEffect(() => {
    rebuildChart()
  }, [allData, chartToShow])

  const rebuildChart = async () => {
    const chartCtx = document.getElementById('campaign-graph')
    chart && chart.destroy()

    var ctx = chartCtx.getContext('2d')
    let gradient = ctx.createLinearGradient(0, 25, 0, 300)
    gradient.addColorStop(0, black.half)
    gradient.addColorStop(0.25, black.quarter)
    gradient.addColorStop(1, black.zero)
    chart = new Chart(chartCtx, {
      type: 'line',
      data: {
        labels: allData.map((d) => d.day),
        datasets: [
          {
            fill: true,
            backgroundColor: gradient,
            pointStyle: 'rect',
            label:
              CHART_TYPES_OPTIONS(user.isContentCreator)[chartToShow].label ||
              CHART_TYPES_OPTIONS(user.isContentCreator)[chartToShow].title,
            data: allData.map((d) => d[CHART_TYPES_OPTIONS(user.isContentCreator)[chartToShow].property]),
            borderColor: ['#111111'],
            tension: 0.5
          }
        ]
      },
      options: {
        ...options,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    })
  }

  const rows = response.campaignData.sort((c, c2) => {
    if (sortDirection === 'desc' ? c[sortColumn] - c2[sortColumn] < 0 : c[sortColumn] - c2[sortColumn] > 0) {
      return 1
    } else {
      return -1
    }
  })

  return (
    <>
      <ChartRow
        show={true}
        title={CHART_TYPES_OPTIONS(user.isContentCreator)[chartToShow].title}
        chartId="campaign-graph"
        setChartToShow={setChartToShow}
      />
      <Container p="0px" pt="16px">
        <SmallTitle ml="24px" mb="16px">
          Campaigns
        </SmallTitle>
        <Table>
          <HTr>
            <Th width="220px !important">
              <HeaderText left>NAME</HeaderText>
            </Th>
            <Th>
              <Header
                title="IMPRESSIONS"
                sortColumn="distinctLoad"
                setSortDirection={setSortDirection}
                setSortColumn={setSortColumn}
                setPage={setPage}
              />
            </Th>
            <Th>
              <Header
                title="ENGAGED USERS"
                sortColumn="distinctPlay"
                setSortDirection={setSortDirection}
                setPage={setPage}
                setSortColumn={setSortColumn}
              />
            </Th>
            <Th>
              <Header
                title="ENGAGEMENT RATE"
                sortColumn="engagementRate"
                setSortDirection={setSortDirection}
                setPage={setPage}
                setSortColumn={setSortColumn}
              />
            </Th>
            <Th>
              <Header
                title="VIDEO PLAYS"
                sortColumn="play"
                setSortDirection={setSortDirection}
                setSortColumn={setSortColumn}
                setPage={setPage}
              />
            </Th>
            <Th>
              <Header
                title="PLAYS PER USER"
                sortColumn="playPerUser"
                setSortDirection={setSortDirection}
                setPage={setPage}
                setSortColumn={setSortColumn}
              />
            </Th>
            <Th>
              <Header
                title="VIEWING TIME"
                sortColumn="viewTime"
                setSortDirection={setSortDirection}
                setPage={setPage}
                setSortColumn={setSortColumn}
              />
            </Th>
            {user.shopifyShopUrl ? (
              <>
                <Th>
                  <Header
                    title="SALES"
                    sortColumn="sales"
                    setSortDirection={setSortDirection}
                    setPage={setPage}
                    setSortColumn={setSortColumn}
                  />
                </Th>
                <Th>
                  <Header
                    title="CONVERSION RATE"
                    sortColumn="conversion"
                    setSortDirection={setSortDirection}
                    setPage={setPage}
                    setSortColumn={setSortColumn}
                  />
                </Th>
                <Th>
                  <Header
                    title="AVERAGE ORDER VALUE"
                    sortColumn="aov"
                    setSortDirection={setSortDirection}
                    setPage={setPage}
                    setSortColumn={setSortColumn}
                  />
                </Th>
              </>
            ) : (
              <Th>
                <Header
                  title={user.isContentCreator ? 'DOWNLOADS' : 'CLICKS'}
                  sortColumn="click"
                  setSortDirection={setSortDirection}
                  setPage={setPage}
                  setSortColumn={setSortColumn}
                />
              </Th>
            )}
          </HTr>
          {rows.slice((page - 1) * PAGESIZE, page * PAGESIZE - 1).map((campaign) => {
            return (
              <Tr key={campaign.id} justifyContent="space-between">
                <Td left data-tip={campaign.name && campaign.name.length > 30 ? campaign.name : null}>
                  <LineClamp>{campaign.name}</LineClamp>
                </Td>
                <Td mb={0} lightLight>
                  {numberWithCommas(campaign.distinctLoad) || '-'}
                </Td>
                <Td mb={0} lightLight>
                  {numberWithCommas(campaign.distinctPlay) || '-'}
                </Td>
                <Td mb={0} lightLight>
                  {Math.round(campaign.engagementRate * 10) / 10 + '%' || '-'}
                </Td>
                <Td mb={0} lightLight>
                  {numberWithCommas(campaign.play) || '-'}
                </Td>
                <Td mb={0} lightLight>
                  {campaign.playPerUser ? Math.round(campaign.playPerUser * 10) / 10 : '-'}
                </Td>
                <Td mb={0} lightLight>
                  {campaign.viewTime ? msToTime(campaign.viewTime) : '-'}
                </Td>
                {user.shopifyShopUrl ? (
                  <>
                    <Td mb={0} lightLight>
                      {campaign.sales ? moneyFormat(Math.round(campaign.sales)) : '-'}
                    </Td>
                    <Td mb={0} lightLight>
                      {campaign.conversion ? campaign.conversion + '%' : '-'}
                    </Td>
                    <Td mb={0} lightLight>
                      {campaign.aov ? moneyFormat(campaign.aov) : '-'}
                    </Td>
                  </>
                ) : (
                  <Td mb={0} lightLight>
                    {campaign.click > 0 ? numberWithCommas(campaign.click) : '-'}
                  </Td>
                )}
              </Tr>
            )
          })}
        </Table>
        <Flex>
          {rows?.length && rows.length > PAGESIZE ? (
            <Flex mt="16px" mb="16px" justifyContent="space-between">
              <Text fontWeight="300" width="375px" lightLight px="16px">
                Showing {(page - 1) * PAGESIZE + 1} - {page * PAGESIZE > rows.length ? rows.length : page * PAGESIZE} of{' '}
                {rows.length}
              </Text>
              <Flex cursor="pointer" justifyContent="center">
                {page - 1 !== 0 ? (
                  <Text
                    onClick={() => {
                      setPage(page - 1)
                    }}
                    light
                    fontWeight="500"
                    px="4px"
                  >
                    {'<'}
                  </Text>
                ) : null}
                {Array.from({ length: Math.ceil(rows.length / PAGESIZE) }, (v, i) => i).map((i) => {
                  return (
                    <Text
                      onClick={() => {
                        setPage(i + 1)
                      }}
                      light
                      key={i}
                      fontWeight={i === page - 1 ? '700' : '500'}
                      px="4px"
                    >
                      {i + 1}
                    </Text>
                  )
                })}
                {page !== Math.ceil(rows.length / PAGESIZE) ? (
                  <Text
                    onClick={() => {
                      setPage(page + 1)
                    }}
                    light
                    fontWeight="500"
                    px="4px"
                  >
                    {'>'}
                  </Text>
                ) : null}
              </Flex>
              <Box width="375px" />
            </Flex>
          ) : null}
        </Flex>
      </Container>
    </>
  )
}

export default CampaignAnalytics
