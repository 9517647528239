import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, shorten } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import SideNav, { TABS } from '../Components/SideNav.js'
import VideoPlayer from '../Components/VideoPlayer'
import StatusPill from '../Components/StatusPill'
import formikFormWrapper from '../formikFormWrapper.js'
import { toast } from 'react-toastify'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import CodeCopy from '../ui/CodeCopy.js'
import { useVideos } from '../VideoProvider.js'
import { stripHtml } from 'string-strip-html'

const Grid = styled(Flex)`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (min-width: 1800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 2100px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 2400px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

const VideoPill = styled(Flex)`
  box-shadow: 0px 1px 14px rgba(50, 50, 71, 0.08);
  padding: 10px;
  border-radius: 4px;
  background-color: white;
  flex-direction: row;
  word-break: break-all;
  position: relative;
`

const CloseContainer = styled(Flex)`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: white;
  width: 30px;
  height: 30px;
  box-shadow: 0px 1px 14px rgba(50, 50, 71, 0.2);
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const PillImage = styled(Image)`
  object-fit: cover;
`

const ImageComp = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-position: center;
`

const OneLineTitle = styled(H6)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
`

const Gradient = styled(Flex)`
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 80%, rgba(0, 0, 0, 0.3) 100%);
`

const VideoProductModal = ({ video, close, refresh }) => {
  if (!video) {
    return null
  }
  const [products, setProducts] = useState([])
  const [showSpinner, setShowSpinner] = useState(false)
  const [shop, setShop] = useState()
  const [query, setQuery] = useState()
  const { videos, setVideos, refresh: refreshVideos } = useVideos()
  const [selectedProducts, setSelectedProducts] = useState(
    (video.linkedProducts && video.linkedProducts.map((p) => p.product)) || []
  )

  let { user } = useAuth()

  const submit = async () => {
    const res = await api.post('/shopify/video-product/create', {
      videoId: video.id,
      products: selectedProducts || []
    })
    if (res.data.success) {
      refresh()
      refreshVideos()
      close()
    }
  }

  const search = async (query) => {
    setShowSpinner(true)
    const shopifyRes = await api.get(`/shopify/get-products${query ? `?query=${query}&limit=250` : '?limit=250'}`)
    setProducts(shopifyRes.data.payload.products)
    setShop(shopifyRes.data.payload.shop)
    setTimeout(() => (document.getElementById('product-container').scrollTop = 0), 1)
    setShowSpinner(false)
  }

  useEffect(() => {
    search()
  }, [])
  console.log(products.length)
  return (
    <Flex pb="24px" pt="40px" flexDirection="column" alignItems="center" height="100%">
      <Text mb="24px" fontSize="20px" fontWeight="600">
        Select Products
      </Text>
      {products && shop ? (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              search(query)
            }}
          >
            <Flex mb="24px" alignItems="center" justifyContent="center">
              <Input width="300px" height="36px" value={query} onChange={(e) => setQuery(e.target.value)} dark />
              <Button isLoading={showSpinner} ml="16px" mr="16px" variant="black" label="Search" onClick={() => search(query)} />
              <Button
                variant="grey"
                label="Clear"
                onClick={() => {
                  setQuery('')
                  search()
                }}
              />
            </Flex>
          </form>
          <Flex
            height="100%"
            overflowY="scroll"
            pt="8px"
            bg={colors.backgroundGrey}
            flexDirection="column"
            id="product-container"
          >
            {selectedProducts && selectedProducts.length ? (
              <Label pt="24px" px="24px">
                Selected products:
              </Label>
            ) : null}
            <Flex pb="24px" bg={colors.backgroundGrey}>
              <Grid height="100%" px="24px">
                {selectedProducts.map((product) => {
                  return (
                    <VideoPill key={product.id} alignItems="center">
                      <CloseContainer
                        onClick={() => {
                          setSelectedProducts(selectedProducts.filter((p) => p.id !== product.id))
                        }}
                      >
                        <Icon width="12px" height="12px" icon="close-cross-grey" />
                      </CloseContainer>
                      <PillImage
                        height="60px"
                        width="60px"
                        minWidth="60px"
                        borderRadius="4px"
                        src={product.image ? product.image.src : product.media && product.media[0].src}
                      />
                      <Flex flexDirection="column" ml="16px">
                        <Text fontWeight="600">{product.title}</Text>
                      </Flex>
                    </VideoPill>
                  )
                })}
              </Grid>
            </Flex>
            <Grid height="100%" px="24px">
              {products.map((product) => {
                const price =
                  shop &&
                  stripHtml(
                    shop.moneyFormat
                      .replace('{{amount}}', product.variants[0].price)
                      .replace('{{amount_no_decimals}}', product.variants[0].price)
                      .replace('{{amount_with_comma_separator}}', product.variants[0].price)
                      .replace('{{amount_no_decimals_with_comma_separator}}', product.variants[0].price)
                      .replace('{{amount_with_apostrophe_separator}}', product.variants[0].price)
                  ).result
                const onClick = () => {
                  if (selectedProducts && selectedProducts.find((p) => p.id === product.id)) {
                    setSelectedProducts(selectedProducts.filter((p) => p.id !== product.id))
                  } else {
                    setSelectedProducts([...selectedProducts, product])
                  }
                }
                return (
                  <Container
                    border={
                      selectedProducts.find((p) => p.id === product.id) ? `4px solid rgba(120, 149, 255, 1)` : 'transparent'
                    }
                    onClick={onClick}
                    hover
                    p="0px"
                    key={product.id}
                    width="100%"
                    height="350px"
                    justifyContent="space-between"
                    position="relative"
                    overflowX="hidden"
                  >
                    <Flex overflow="hidden" height="100%" position="relative">
                      {product && product.image && product.image.src ? (
                        <ImageComp
                          loading="lazy"
                          width="100%"
                          height="100%"
                          src={product && product.image && product.image.src}
                        />
                      ) : null}
                    </Flex>
                    <Flex flexDirection="column" py="8px" pb="16px" justifyContent="space-between">
                      <OneLineTitle wordBreak="break-all" mb="0px" px="8px">
                        {product.title && product.title.length > 50 ? product.title.slice(0, 50) + '...' : product.title}
                      </OneLineTitle>
                      <Text light fontSize="12px" fontWeight="600" px="8px">
                        {price}
                      </Text>
                    </Flex>
                  </Container>
                )
              })}
            </Grid>
          </Flex>
          <Flex justifyContent="flex-end" alignItems="center" bg="white" pt="24px" px="24px">
            {selectedProducts && selectedProducts.length ? (
              <Text bold mr="16px" light fontWeight="500">
                ({selectedProducts.length} selected)
              </Text>
            ) : null}
            <Button variant="black" label={'Save'} onClick={submit} />
          </Flex>
        </>
      ) : (
        <BigDarkSpinner />
      )}
    </Flex>
  )
}

export default VideoProductModal
