import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import ReactTooltip from 'react-tooltip'

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, shorten } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import PopoverDropdown from '../ui/PopoverDropdown.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import SideNav, { TABS } from '../Components/SideNav.js'
import VideoPlayer from '../Components/VideoPlayer'
import StatusPill from '../Components/StatusPill'
import ProcessingPreview from '../Components/ProcessingPreview'
import OptionsPopover from './OptionsPopover'
import ChatButton from '../Components/ChatButton'
import InstallBanner from '../Components/InstallBanner'
import formikFormWrapper from '../formikFormWrapper.js'
import { toast } from 'react-toastify'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import { RULE_TYPES, widgetTypeDisplayName } from './types.js'

const HTr = styled.tr`
  height: 52px;
  padding-left: 32px;
  padding-right: ${(props) => (!props.isLast ? '32px' : '0px')};
`

const Th = styled.th`
  min-width: ${(props) => props.minWidth || '0px'};
  height: 52px;
  vertical-align: middle;
  border-bottom: 1px solid #edf2f7;
  background: ${colors.backgroundGrey};
  padding-left: ${(props) => (!props.isLast ? '16px' : '0px')};
  padding-right: ${(props) => (!props.isLast ? '16px' : '0px')};
`

const HeaderText = styled(Text)`
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.06em;
  color: ${colors.textGrey};
  text-align: left;
`

const Tr = styled.tr`
  height: 78px;
`

const Td = styled.td`
  word-break: break-word;
  cursor: pointer;
  vertical-align: middle;
  border-bottom: 1px solid #edf2f7;
  min-width: ${(props) => props.minWidth || '0px'};
  padding-left: ${(props) => (!props.isLast ? '16px' : '0px')};
  padding-right: ${(props) => (!props.isLast ? '16px' : '0px')};
  font-size: 14px;
`

const HideSmallTd = styled(Td)`
  @media (max-width: 1300px) {
    display: none;
  }
`

const HideSmallTh = styled(Th)`
  @media (max-width: 1300px) {
    display: none;
  }
`

const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
`

const VideoPreview = styled(Box)`
  background-image: url('${(props) => props.src}');
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  border: 2.5px solid white;
  width: 34px;
  height: 34px;
  margin-right: -16px;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);
`

const EmptyState = ({ firstName }) => {
  return (
    <Flex justifyContent="center">
      <Container alignItems="center" width="361px" p="0px" overflow="hidden">
        <Box borderBottom="1px solid rgba(237, 242, 247, 1)">
          <img src="/illustrations/getInspiredCarousel.jpg" width="361px" height="184px" />
        </Box>
        <Flex p="24px" flexDirection="column" alignItems="center">
          <H3 textAlign="center">Let’s begin, {firstName}!</H3>
          <Text mb="24px" textAlign="center">
            Create your first video embeds or shareable links.
          </Text>
          <Link to="/campaigns/create?step=ON_SITE_OFF_SITE">
            <Button width="auto" variant="black" label="Get started" />
          </Link>
        </Flex>
      </Container>
    </Flex>
  )
}

const WidgetDisplay = ({ campaign }) => {
  if (campaign.isShareCampaign) {
    return (
      <Text light fontWeight="700" fontSize="12px" lineHeight="20px">
        Off-site
      </Text>
    )
  }
  if (!campaign.widgetId) {
    return '-'
  }
  return (
    <>
      <Text light fontWeight="700" fontSize="12px" lineHeight="20px">
        {widgetTypeDisplayName[campaign.type]}
      </Text>
      <Text fontWeight="400" light lineHeight="20px">
        {shorten(campaign.widgetName, 50)}
      </Text>
    </>
  )
}

const RulesDisplay = ({ campaign }) => {
  const activeRule = campaign.widgetUrlRules ? campaign.widgetUrlRules[0] : null
  const getRuleDisplay = () => {
    if (!activeRule) {
      return '-'
    }
    if (activeRule.matchType === RULE_TYPES.HOMEPAGE) {
      return 'homepage'
    }
    return activeRule.ruleType && activeRule.ruleType.replace('_', ' ').toLowerCase()
  }
  if (!activeRule) {
    return '-'
  }
  if (activeRule.matchType === RULE_TYPES.HOMEPAGE) {
    return (
      <Text light fontWeight="700" fontSize="12px" lineHeight="20px">
        {getRuleDisplay()}
      </Text>
    )
  }
  return (
    <>
      <Text light fontWeight="700" fontSize="12px" lineHeight="20px">
        {getRuleDisplay()}
      </Text>
      <Text fontWeight="400" light lineHeight="20px">
        {activeRule && activeRule.urlMatch && activeRule.matchType !== RULE_TYPES.HOMEPAGE
          ? shorten(activeRule.urlMatch.toLowerCase(), 50)
          : '-'}
      </Text>
    </>
  )
}

const Campaigns = (props) => {
  let { user } = useAuth()
  const [campaigns, setCampaigns] = useState([])
  const [hasLoaded, setHasLoaded] = useState(false)
  const navigate = useNavigate()

  const refresh = async () => {
    const res = await api.get('/campaign/get')
    if (res.data.success) {
      setCampaigns(res.data.payload)
      setHasLoaded(true)
    }
  }

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    ReactTooltip.rebuild()
    return () => {
      ReactTooltip.hide()
    }
  })
  console.log(campaigns)
  return (
    <Flex>
      <Flex position="relative" flexDirection="column" width="100%" p="30px">
        <Flex justifyContent="space-between">
          <H1 mb="32px" width="auto">
            Pages & Campaigns
          </H1>
          <Flex width="auto">
            <ChatButton showCallButton />
            <Link to="/campaigns/create?step=ON_SITE_OFF_SITE">
              <Button
                renderLeftIcon={() => <Icon width={12} height={12} icon="plus-white" />}
                mb="8px"
                variant="black"
                label="New campaign"
              />
            </Link>
          </Flex>
        </Flex>
        <H3 mb="16px">All campaigns</H3>
        <InstallBanner isSchlong />
        {hasLoaded && !campaigns.length ? <EmptyState firstName={user && user.firstName} /> : null}
        {!hasLoaded ? (
          <Flex justifyContent="center" pt="200px">
            <BigDarkSpinner />
          </Flex>
        ) : null}
        {hasLoaded && campaigns.length ? (
          <Container p="0px" pt="16px">
            <SmallTitle ml="24px" mb="16px">
              Campaigns
            </SmallTitle>
            <Table>
              <HTr>
                <Th>
                  <HeaderText>CAMPAIGN</HeaderText>
                </Th>
                <HideSmallTh width="180px">
                  <HeaderText>MEDIA</HeaderText>
                </HideSmallTh>
                <Th>
                  <HeaderText>WIDGET</HeaderText>
                </Th>
                <Th>
                  <HeaderText>PAGE RULE</HeaderText>
                </Th>
                <Th width="110px">
                  <HeaderText>STATUS</HeaderText>
                </Th>
                <Th width="150px">
                  <HeaderText>CREATED</HeaderText>
                </Th>
                <Th isLast minWidth="24px" width="24px"></Th>
              </HTr>
              {campaigns.map((campaign) => {
                return (
                  <Tr
                    key={campaign.id}
                    onClick={() => navigate(`/campaigns/edit/${campaign.id}`)}
                    height="52px"
                    justifyContent="space-between"
                  >
                    <Td data-tip={campaign.name && campaign.name.length > 40 ? campaign.name : null}>
                      <Text fontWeight="400" lineHeight="20px">
                        {shorten(campaign.name, 70)}
                      </Text>
                    </Td>
                    <HideSmallTd mb={0} lightLight width="180px">
                      <Flex alignItems="center">
                        {!campaign.videos?.length ? '-' : null}
                        {campaign.videos.slice(0, 5).map((videoOrImage) => {
                          return (
                            <VideoPreview
                              key={videoOrImage.id}
                              src={
                                videoOrImage.type === 'VIDEO'
                                  ? videoOrImage.customThumbnail
                                    ? videoOrImage.customThumbnail + `-/scale_crop/${100}x${100}/center/`
                                    : `https://image.mux.com/${videoOrImage.playbackId}/thumbnail.jpg?width=100&height=100&time=${videoOrImage.thumbnailTime}`
                                  : `https://clipara.b-cdn.net/${videoOrImage.path}?width=60`
                              }
                            />
                          )
                        })}

                        {campaign.videos && campaign.videos.length > 5 ? (
                          <Text ml="20px" lightLight fontWeight="500" fontSize="12" minWidth="20px">
                            +{campaign.videos.length - 5}
                          </Text>
                        ) : null}
                      </Flex>
                    </HideSmallTd>
                    <Td mb={0} lightLight>
                      <WidgetDisplay campaign={campaign} />
                    </Td>
                    <Td mb={0} lightLight>
                      <RulesDisplay campaign={campaign} />
                    </Td>
                    <Td width="110px">
                      <StatusPill
                        isShareCampaign={campaign.isShareCampaign}
                        widgetActive={campaign.widgetActive}
                        widgetId={campaign.widgetId}
                      />
                    </Td>
                    <Td mb={0} lightLight width="150px">
                      <Text light fontWeight="400">
                        {dayjs(campaign.createdAt).format('MMM DD, HH:mm')}
                      </Text>
                    </Td>
                    <Td mb={0} isLast minWidth="24px" width="24px" lightLight>
                      <OptionsPopover refresh={refresh} id={campaign.id} />
                    </Td>
                  </Tr>
                )
              })}
            </Table>
            <Text fontSize="14px" my="18px" ml="32px" lightLight>
              Showing {campaigns.length} campaign{campaigns.length > 1 ? 's' : ''}
            </Text>
          </Container>
        ) : null}
      </Flex>
    </Flex>
  )
}

export default Campaigns
