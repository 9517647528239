import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import ReactTooltip from 'react-tooltip'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { colors, numberWithCommas, shorten } from '../ui/helpers'
import { msToTime } from '../ui/formatters'
import { H1, H2, H3, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography'
import { Container, Flex, Box } from '../ui/Layout'
import Image from '../ui/Image'
import { Input } from '../ui/Input'
import Icon from '../ui/Icon'
import { BigDarkSpinner } from '../ui/Spinner'
import Button from '../ui/Button'
import Select from '../ui/Select'
import formikFormWrapper from '../formikFormWrapper'
import api from '../api'
import { useAuth } from '../Auth'
import { PLAYER_TYPES, playerTypeOptions } from '../Campaigns/types'
import { setupCharts } from './helpers'
import Chart from 'chart.js/auto'
import 'chartjs-adapter-moment'
import ChatButton from '../Components/ChatButton'
import { stripHtml } from 'string-strip-html'
import { DATE_PERIODS } from './types'
import DatePopover from './DatePopover'
import PopoverDropdown from '../ui/PopoverDropdown'
import { options, black, pink } from './helpers.js'
import { Header } from './Campaigns'
import { useModal } from '../Modal'
import { useVideos } from '../VideoProvider'

const PAGESIZE = 200

const HTr = styled.tr`
  height: 52px;
  padding-left: 32px;
  padding-right: ${(props) => (!props.isLast ? '32px' : '0px')};
`

const Th = styled.th`
  min-width: ${(props) => props.minWidth || '0px'};
  height: 52px;
  vertical-align: middle;
  border-bottom: 1px solid #edf2f7;
  background: ${colors.backgroundGrey};
  padding-left: ${(props) => (!props.isLast ? '12px' : '0px')};
  padding-right: ${(props) => (!props.isLast ? '12px' : '0px')};
`

const HeaderText = styled(Text)`
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.06em;
  color: ${colors.textGrey};
  text-align: right;
  ${(props) => props.left && 'text-align: left;'}
`

const Tr = styled.tr`
  height: 52px;
`

const Td = styled.td`
  word-break: break-word;
  vertical-align: middle;
  border-bottom: 1px solid #edf2f7;
  min-width: ${(props) => props.minWidth || '0px'};
  padding-left: ${(props) => (!props.isLast ? '12px' : '0px')};
  padding-right: ${(props) => (!props.isLast ? '12px' : '0px')};
  font-size: 14px;
  text-align: right;
  ${(props) => props.left && 'text-align: left;'}
  ${(props) => (props.pointer ? 'cursor: pointer;' : '')}
`

const VideoPreview = styled.img`
  object-fit: cover;
  border-radius: 16px;
  border: 2.5px solid white;
  width: 34px;
  max-width: 34px;
  height: 34px;
  max-height: 34px;
  margin-right: -14px;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);
`

const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
`

const CHART_TYPES = {
  PLAYS: 'PLAYS',
  CLICKS: 'CLICKS',
  ADD_TO_CART: 'ADD_TO_CART'
}

const CHART_TYPES_OPTIONS = (isContentCreator) => ({
  PLAYS: {
    title: 'Total Views',
    property: 'play'
  },
  CLICKS: {
    title: isContentCreator ? 'Total Downloads' : 'Total Clicks',
    property: 'click'
  },
  ADD_TO_CART: {
    title: 'Add to Carts',
    property: 'addToCart'
  }
})

const ChartRow = ({ title, chartId, setChartToShow, show }) => {
  let { user } = useAuth()
  const shopifyOptions = user.shopifyShopUrl
    ? [{ label: 'Add to Cart', onClick: () => setChartToShow(CHART_TYPES.ADD_TO_CART) }]
    : [{ label: user.isContentCreator ? 'Downloads' : 'Clicks', onClick: () => setChartToShow(CHART_TYPES.CLICKS) }]
  return (
    <Container display={show ? 'block' : 'none'} mb="32px" position="relative">
      <Flex flexDirection="column">
        <PopoverDropdown
          width="300px"
          renderCustomLauncher={() => (
            <Flex cursor="pointer" width="auto">
              <H3 fontSize="24px" mb="24px" display="inline">
                {title}
              </H3>
              <Icon ml="16px" mt="4px" icon="chevron-down-black" width="18px" height="18px" />
            </Flex>
          )}
          options={[{ label: 'Plays', onClick: () => setChartToShow(CHART_TYPES.PLAYS) }, ...shopifyOptions]}
        />
        <Box minWidth="100%" width="100%" height={300}>
          <canvas id={chartId} />
        </Box>
      </Flex>
    </Container>
  )
}

let chart

const LineClamp = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const VideoAnalytics = ({ response }) => {
  const allData = response.imageAnalyticsAll
  let { user } = useAuth()
  const { setVideoPopoverId, setShowVideoPopover } = useModal()
  const { refresh } = useVideos()

  const isContentCreator = user.isContentCreator

  const moneyFormat = (input) =>
    response.moneyFormat
      ? stripHtml(
          response.moneyFormat
            .replace('{{amount}}', input)
            .replace('{{amount_no_decimals}}', input)
            .replace('{{amount_with_comma_separator}}', input)
            .replace('{{amount_no_decimals_with_comma_separator}}', input)
            .replace('{{amount_with_apostrophe_separator}}', input)
        ).result
      : input
  useEffect(() => {
    ReactTooltip.rebuild()
    return () => {
      ReactTooltip.hide()
    }
  })

  const [chartToShow, setChartToShow] = useState(CHART_TYPES.PLAYS)
  const [sortColumn, setSortColumn] = useState('play')
  const [sortDirection, setSortDirection] = useState('desc')
  const [page, setPage] = useState(1)

  useEffect(() => {
    rebuildChart()
  }, [allData, chartToShow])

  useEffect(() => {
    refresh()
  }, [])

  const rebuildChart = async () => {
    const chartCtx = document.getElementById('video-graph')
    chart && chart.destroy()

    var ctx = chartCtx.getContext('2d')
    let gradient = ctx.createLinearGradient(0, 25, 0, 300)
    gradient.addColorStop(0, black.half)
    gradient.addColorStop(0.25, black.quarter)
    gradient.addColorStop(1, black.zero)
    chart = new Chart(chartCtx, {
      type: 'line',
      data: {
        labels: allData.map((d) => d.day),
        datasets: [
          {
            fill: true,
            backgroundColor: gradient,
            pointStyle: 'rect',
            label:
              CHART_TYPES_OPTIONS(user.isContentCreator)[chartToShow].label ||
              CHART_TYPES_OPTIONS(user.isContentCreator)[chartToShow].title,
            data: allData.map((d) => d[CHART_TYPES_OPTIONS(user.isContentCreator)[chartToShow].property]),
            borderColor: ['#111111'],
            tension: 0.5
          }
        ]
      },
      options: {
        ...options,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    })
  }

  const rows = useMemo(() => {
    return response.imageData.sort((c, c2) => {
      if (c[sortColumn] === c2[sortColumn]) {
        return c.video_id > c2.video_id ? 1 : -1
      }
      if (sortDirection === 'desc') {
        if (c[sortColumn] - c2[sortColumn] <= 0) {
          return 1
        } else {
          return -1
        }
      } else {
        if (c[sortColumn] - c2[sortColumn] >= 0) {
          return 1
        } else {
          return -1
        }
      }
    })
  }, [page, sortDirection, sortColumn, response])

  return (
    <>
      <ChartRow
        show={true}
        title={CHART_TYPES_OPTIONS(user.isContentCreator)[chartToShow].title}
        chartId="video-graph"
        setChartToShow={setChartToShow}
      />
      <Container p="0px" pt="16px">
        <SmallTitle ml="24px" mb="16px">
          Videos
        </SmallTitle>
        <Table>
          <HTr>
            <Th width="56px !important">
              <HeaderText left></HeaderText>
            </Th>
            <Th width="220px !important">
              <HeaderText left>NAME</HeaderText>
            </Th>
            <Th>
              <Header
                title="VIEWS"
                sortColumn="play"
                setSortDirection={setSortDirection}
                setSortColumn={setSortColumn}
                setPage={setPage}
              />
            </Th>

            {user.shopifyShopUrl ? (
              <>
                <Th>
                  <Header
                    title="SALES"
                    sortColumn="sales"
                    setSortDirection={setSortDirection}
                    setSortColumn={setSortColumn}
                    setPage={setPage}
                  />
                </Th>
                <Th>
                  <Header
                    title="CONVERSION"
                    sortColumn="conversion"
                    setSortDirection={setSortDirection}
                    setSortColumn={setSortColumn}
                    setPage={setPage}
                  />
                </Th>
              </>
            ) : (
              <>
                <Th>
                  <Header
                    title={isContentCreator ? 'DOWNLOAD RATE' : 'CTR'}
                    sortColumn="ctr"
                    setSortDirection={setSortDirection}
                    setSortColumn={setSortColumn}
                    setPage={setPage}
                  />
                </Th>
                <Th>
                  <Header
                    title={isContentCreator ? 'DOWNLOADS' : 'CLICKS'}
                    sortColumn="click"
                    setSortDirection={setSortDirection}
                    setSortColumn={setSortColumn}
                    setPage={setPage}
                  />
                </Th>
              </>
            )}
          </HTr>
          {rows.slice((page - 1) * PAGESIZE, page * PAGESIZE - 1).map((video) => {
            return (
              <Tr key={video.id} justifyContent="space-between">
                <Td
                  left
                  pointer
                  onClick={() => {
                    setVideoPopoverId(video.videoId)
                    setShowVideoPopover(true)
                  }}
                >
                  <VideoPreview loading="lazy" key={video.videoId} src={`https://clipara.b-cdn.net/${video.path}?width=60`} />
                </Td>

                <Td
                  left
                  data-tip={video.name && video.name.length > 40 ? video.name : null}
                  pointer
                  onClick={() => {
                    setVideoPopoverId(video.videoId)
                    setShowVideoPopover(true)
                  }}
                >
                  <LineClamp>{video.name}</LineClamp>
                </Td>
                <Td mb={0} lightLight>
                  {numberWithCommas(video.play) || '-'}
                </Td>

                {user.shopifyShopUrl ? (
                  <>
                    <Td mb={0} lightLight>
                      {video.sales ? moneyFormat(numberWithCommas(Math.round(video.sales))) : '-'}
                    </Td>
                    <Td mb={0} lightLight>
                      {video.conversion ? video.conversion + '%' : '-'}
                    </Td>
                    {/* <Td mb={0} lightLight>
                        {video.aov ? moneyFormat(video.aov) : '-'}
                      </Td> */}
                  </>
                ) : (
                  <>
                    <Td mb={0} lightLight>
                      {video.ctr && video.ctr !== 1 && video.click && video.click > 0
                        ? Math.round(video.ctr * 1000) / 10 + '%'
                        : '-'}
                    </Td>
                    <Td mb={0} lightLight>
                      {video.click && video.click > 0 ? numberWithCommas(video.click) : '-'}
                    </Td>
                  </>
                )}
              </Tr>
            )
          })}
        </Table>
        <Flex>
          {rows?.length && rows.length > PAGESIZE ? (
            <Flex mt="16px" mb="16px" justifyContent="space-between">
              <Text fontWeight="300" width="375px" lightLight px="16px">
                Showing {(page - 1) * PAGESIZE + 1} - {page * PAGESIZE > rows.length ? rows.length : page * PAGESIZE} of{' '}
                {rows.length}
              </Text>
              <Flex cursor="pointer" justifyContent="center">
                {page - 1 !== 0 ? (
                  <Text
                    onClick={() => {
                      setPage(page - 1)
                    }}
                    light
                    fontWeight="500"
                    px="4px"
                  >
                    {'<'}
                  </Text>
                ) : null}
                {Array.from({ length: Math.ceil(rows.length / PAGESIZE) }, (v, i) => i).map((i) => {
                  return (
                    <Text
                      onClick={() => {
                        setPage(i + 1)
                      }}
                      light
                      key={i}
                      fontWeight={i === page - 1 ? '700' : '500'}
                      px="4px"
                    >
                      {i + 1}
                    </Text>
                  )
                })}
                {page !== Math.ceil(rows.length / PAGESIZE) ? (
                  <Text
                    onClick={() => {
                      setPage(page + 1)
                    }}
                    light
                    fontWeight="500"
                    px="4px"
                  >
                    {'>'}
                  </Text>
                ) : null}
              </Flex>
              <Box width="375px" />
            </Flex>
          ) : null}
        </Flex>
      </Container>
    </>
  )
}

export default VideoAnalytics
