import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { colors, getDisplaySize } from '../ui/helpers'
import { Label, Error, Text } from '../ui/Typography'
import { Flex, Box } from '../ui/Layout'
import Toggle from '../ui/Toggle.js'
import Button from '../ui/Button'
import Icon from '../ui/Icon'
import VideoPreview from '../ui/VideoPreview'
import Image from '../ui/Image.js'
import { WIDGET_POSITIONS, WIDGET_TYPES } from '../Campaigns/types'
import ProcessingPreview from './ProcessingPreview'
import FloatingWidgetPreview from './FloatingWidgetPreview'
import EmbedPreview from './EmbedPreview'
import CarouselWidgetPreview from './EmbedPreview'

export default ({ videos, widget, campaign, openFullscreenDesktopPreview, isMobile, setIsMobile }) => {
  if (!widget || !widget.type) {
    return null
  }
  if (widget.type === WIDGET_TYPES.FLOATING) {
    return (
      <FloatingWidgetPreview
        videos={videos}
        widget={widget}
        campaign={campaign}
        openFullscreenDesktopPreview={openFullscreenDesktopPreview}
      />
    )
  }
  return (
    <Flex flexDirection="column" overflowY="scroll" alignItems="center" height="100%" px="24px" backgroundColor="#F7FAFC">
      <EmbedPreview
        isMobile={isMobile}
        videos={videos}
        widget={widget}
        campaign={{ ...campaign, widgetFontFamily: widget.fontFamily }}
        openFullscreenDesktopPreview={openFullscreenDesktopPreview}
      />
    </Flex>
  )
}
