const plans = {
  1: {
    noCliparaLogo: false,
    id: 1,
    name: 'STARTER',
    isFree: true,
    trialDays: 0,
    numberOfVideos: 5,
    duration: 5 * 60
  },
  2: {
    noCliparaLogo: false,
    id: 2,
    name: 'BASIC',
    trialDays: 14
  },
  3: {
    noCliparaLogo: true,
    id: 3,
    name: 'PRO',
    trialDays: 14
  },
  4: {
    noCliparaLogo: true,
    id: 4,
    name: 'SCALE',
    trialDays: 14,
    numberOfVideos: 250
  },
  5: {
    noCliparaLogo: false,
    id: 5,
    name: 'BASIC',
    trialDays: 14,
    numberOfVideos: 20,
    duration: 25 * 60
  },
  6: {
    noCliparaLogo: true,
    id: 6,
    name: 'PRO',
    trialDays: 14,
    numberOfVideos: 100,
    duration: 150 * 60
  },
  7: {
    noCliparaLogo: true,
    id: 7,
    name: 'SCALE',
    trialDays: 14,
    duration: 1000 * 60
  }
}

export const isProPlan = (user) => {
  if (!user) {
    return false
  }
  const planId = user.shopifyPlanId || user.stripePlanId
  if (!planId) {
    return false
  }
  return planId === 3 || planId === 4 || planId === 6 || planId === 7
}

export const isFreePlan = (user) => {
  if (!user) {
    return false
  }
  const planId = user.shopifyPlanId || user.stripePlanId
  if (!planId) {
    return true
  }
  return plans[planId].isFree
}

export const getNumberOfAllowedVideos = (user) => {
  if (!user) {
    return false
  }
  const planId = user.shopifyPlanId || user.stripePlanId
  if (!planId) {
    return plans[1].numberOfVideos
  }
  return plans[planId].numberOfVideos
}

export const getAllowedVideoDuration = (user) => {
  if (!user) {
    return false
  }
  const planId = user.shopifyPlanId || user.stripePlanId
  if (!planId) {
    return plans[1].duration
  }
  return plans[planId].duration
}

export const hasHitVideoLimit = (user, numberVideos) => {
  const limit = getNumberOfAllowedVideos(user)
  if (!limit) {
    return false
  }
  return numberVideos >= limit
}

export const getPlanName = (user) => {
  if (!user) {
    return false
  }
  const planId = user.shopifyPlanId || user.stripePlanId
  if (!planId) {
    return plans[1].name.toLowerCase()
  }
  return plans[planId].name.toLowerCase()
}

export const isScalePlan = (user) => {
  if (!user) {
    return false
  }
  const planId = user.shopifyPlanId || user.stripePlanId
  if (!planId) {
    return false
  }
  return planId === 4 || planId === 7
}

export default plans
