import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import hexToRgba from 'hex-to-rgba'

import { colors, getDisplaySize } from '../ui/helpers'
import { Label, Error } from '../ui/Typography'
import { Flex, Box } from '../ui/Layout'
import Button from '../ui/Button'
import ProcessingPreview from './ProcessingPreview'
import FullScreenPlayer from './FullScreenPlayer'
import CarouselPreview from './CarouselPreview'
import Icon from '../ui/Icon.js'
import { useAuth } from '../Auth.js'

const CustomerLogo = styled.img`
  position: absolute;
  top: 11px;
  left: 16px;
  max-height: 40px;
  object-fit: contain;
  z-index: 100002;
`

const Close = styled.img`
  width: 19px;
  height: 19px;
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 110001;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`

export default ({ videos, campaign, close }) => {
  const { user, setUser } = useAuth()
  return (
    <Flex
      width="100vw"
      height="100vh"
      zIndex="99999999"
      bg={campaign.fullscreenBackgroundColor || 'black'}
      position="fixed"
      left="0"
      top="0"
    >
      {user.logoUrl ? <CustomerLogo width="80px" alt="logo" src={user.logoUrl} /> : null}

      <Close onClick={close} width="20px" height="20px" alt="close preview" src="/__clipara-cross-white.svg" />
      <CarouselPreview autoplay videos={videos} campaign={campaign} idExtension="-preview" isFullScreen />
    </Flex>
  )
}
