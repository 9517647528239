import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize } from '../ui/helpers.js'
import { H1, H2, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input, TextArea } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import CodeCopy, { copyOnClick } from '../ui/CodeCopy.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import Toggle from '../ui/Toggle.js'
import Select from '../ui/Select.js'
import ColorPicker from '../ui/ColorPicker.js'
import formikFormWrapper from '../formikFormWrapper.js'
import { toast } from 'react-toastify'
import { useModal } from '../Modal.js'
import ModalContainer from '../ui/ModalContainer.js'
import { useAuth } from '../Auth.js'
import api from '../api.js'

const FormInput = formikFormWrapper(Input)

const ImageContainer = styled(Flex)`
  background-image: url('${(props) => props.src}');
  background-size: cover;
  background-position: center;
  border-radius: 16px;
`

const BigButton = styled(Flex)`
  box-shadow: 0px 1px 14px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
  margin-bottom: 16px;
  padding: 24px;
  border-radius: 10px;
  height: 82px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &&:hover {
    box-shadow: 0px 1px 14px rgba(50, 50, 71, 0.2), 0px 0px 1px rgba(50, 50, 71, 0.2);
  }
`

const WELCOME_TYPES = {
  NORMAL: 'NORMAL',
  SHOPIFY: 'SHOPIFY',
  CONTENT_CREATOR: 'CONTENT_CREATOR'
}

const Tab = styled(Text)`
  font-weight: 600;
  margin-right: 40px;
  padding-bottom: 20px;
  cursor: pointer;
  ${(props) => props.isActive && `color: #111111;`}
`

const Tabs = ({ activeTab, setActiveTab, welcomeType }) => {
  return (
    <Flex width="auto" mb="24px" flexDirection="column">
      <Tab isActive={activeTab === 1} onClick={() => setActiveTab(1)} lightLight>
        1. Add your content
      </Tab>
      <Tab isActive={activeTab === 2} onClick={() => setActiveTab(2)} lightLight>
        2. Create a campaign
      </Tab>
      <Tab isActive={activeTab === 3} onClick={() => setActiveTab(3)} lightLight>
        3. Customise
      </Tab>
      <Tab isActive={activeTab === 4} onClick={() => setActiveTab(4)} lightLight>
        4. Embed
      </Tab>
    </Flex>
  )
}

const ExplainerText = ({ activeTab, welcomeType }) => {
  if (welcomeType === WELCOME_TYPES.CONTENT_CREATOR) {
    switch (activeTab) {
      case 1:
        return (
          <>
            <Text light>Upload your first pieces of content to Clipara and organise your library with tags.</Text>
          </>
        )
      case 2:
        return (
          <>
            <Text light>Create a completely searchable library to be browsed by tags or a smaller collection of content.</Text>
          </>
        )
      case 3:
        return (
          <>
            <Text light>
              Customise your widgets to match your brand and website. From play buttons to thumbnails, fonts, colors. All download
              functionality is automatically added to videos.
            </Text>
          </>
        )
      case 4:
        return (
          <>
            <Text light>
              Add to your site with a simple copy and paste of a code snippet, et voila! Check the guides for multiple website
              builders like Squarespace, Webflow, Wordpress and more if you get stuck.
            </Text>
          </>
        )
      default:
    }
  }

  if (welcomeType === WELCOME_TYPES.SHOPIFY) {
    switch (activeTab) {
      case 1:
        return (
          <>
            <Text light>Upload your first pieces of content to Clipara or add them via Instagram, TikTok & YouTube.</Text>
          </>
        )
      case 2:
        return (
          <>
            <Text light>Create a campaign, select your videos and add interactions like buttons and shoppable links.</Text>
          </>
        )
      case 3:
        return (
          <>
            <Text light>
              Customise your widgets to match your brand and website. From play buttons to thumbnails, fonts, colors. Add
              shoppable products to each video on Shoppable campaigns.
            </Text>
          </>
        )
      case 4:
        return (
          <>
            <Text light>
              Add a Clipara section in your Shopify pages with app blocks or copy and paste the code for older or custom themes
              and define whcih page you want your collection to appear on.
            </Text>
          </>
        )
      default:
    }
  }

  switch (activeTab) {
    case 1:
      return (
        <>
          <Text light>Upload your first pieces of content to Clipara or add them via Instagram, TikTok & YouTube.</Text>
        </>
      )
    case 2:
      return (
        <>
          <Text light>
            Create a campaign, choose your preferred content experience and layout and select the content you want to display.
          </Text>
        </>
      )
    case 3:
      return (
        <>
          <Text light>
            Completely customise your widget to match your brand and website. From play buttons to thumbnails, fonts, colors and
            more. Add interactions, CTAs, captions and more.
          </Text>
        </>
      )
    case 4:
      return (
        <>
          <Text light>
            Add to your site with a simple copy and paste of a code snippet, et voila! For larger scale distribution of video to
            work with a CMS, chat to support to find out the best method for your use case.
          </Text>
        </>
      )
    default:
  }
}

const getImage = ({ activeTab, welcomeType }) => {
  if (welcomeType === WELCOME_TYPES.CONTENT_CREATOR) {
    switch (activeTab) {
      case 1:
        return 'creatorwoman'
      case 2:
        return 'searchcollections'
      case 3:
        return 'buttondesign'
      case 4:
        return 'installcode'
      default:
    }
  }
  if (welcomeType === WELCOME_TYPES.SHOPIFY) {
    switch (activeTab) {
      case 1:
        return 'woman'
      case 2:
        return 'threewidget'
      case 3:
        return 'travel'
      case 4:
        return 'pagerules'
      default:
    }
  }

  switch (activeTab) {
    case 1:
      return 'woman'
    case 2:
      return 'threewidget'
    case 3:
      return 'travel'
    case 4:
      return 'installcode'
    default:
  }
}

const CreateModal = ({ goToWidgetAppearance }) => {
  const [search, setSearch] = useSearchParams()
  const [showWelcomeModal, setShowWelcomeModal] = useState(false)

  useEffect(() => {
    if (search.get('welcomeFlow')) {
      setShowWelcomeModal(true)
    }
  }, [search])

  const [isOpen, setIsOpen] = useState(true)
  const [activeTab, setActiveTab] = useState(1)
  const { user } = useAuth()

  if (!showWelcomeModal || !user) {
    return null
  }
  const welcomeType = user?.shopifyShopUrl
    ? WELCOME_TYPES.SHOPIFY
    : user.isContentCreator
    ? WELCOME_TYPES.CONTENT_CREATOR
    : WELCOME_TYPES.NORMAL

  return (
    <ModalContainer interiorClose width="990px" height="570px" isOpen={isOpen} setIsOpen={setIsOpen} p="0px">
      <Flex height="100%" justifyContent="space-between">
        <Flex flexDirection="column" maxWidth="275px" borderRight="1px solid #EEF2F7" p="24px">
          <H3 fontSize="20px" lineHeight="32px">
            Ciao! Here’s a quick guide for you{user.firstName ? `, ${user.firstName}` : ''}
          </H3>
          <Tabs welcomeType={welcomeType} activeTab={activeTab} setActiveTab={setActiveTab} />
        </Flex>
        <Flex width="100%" flexDirection="column" justifyContent="center" p="24px">
          <Flex flexDirection="column" alignItems="center" height="100%">
            <Image
              mb="16px"
              borderRadius="16px"
              src={`/illustrations/welcome-2/${getImage({ activeTab, welcomeType })}.jpg`}
              width="596px"
              height="372px"
            />
            <Flex maxWidth="600px">{ExplainerText({ activeTab, welcomeType })}</Flex>
          </Flex>
          <Flex justifyContent="flex-end">
            {activeTab !== 1 ? (
              <Button
                mr="16px"
                variant="grey"
                label="Back"
                renderLeftIcon={() => <Icon mr="8px" width={12} height={12} icon="chevron-left-black" />}
                onClick={() => setActiveTab(activeTab - 1)}
              />
            ) : null}

            {activeTab !== 4 ? (
              <Button
                variant="black"
                renderRightIcon={() => <Icon ml="8px" width={16} height={16} icon="chevron-right-white" />}
                label="Next"
                onClick={() => setActiveTab(activeTab + 1)}
              />
            ) : null}
            {activeTab === 4 ? (
              <Button
                variant="black"
                label="Get started"
                onClick={() => {
                  setIsOpen(false)
                  // if (user.shopifyShopUrl) {
                  //   setShowInstallWidgetModal(true)
                  // }
                }}
              />
            ) : null}
          </Flex>
        </Flex>
      </Flex>
    </ModalContainer>
  )
}

export default CreateModal
