import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import hexToRgba from 'hex-to-rgba'
import { serverUrl } from '../baseUrl'
import { colors, getDisplaySize } from './helpers'
import { Label, Error } from './Typography'
import { Flex, Box } from './Layout'
import Button from './Button'
import ProcessingPreview from '../Components/ProcessingPreview'
import Icon from './Icon.js'

const ImageContainer = styled(Flex)`
  background-image: url('${(props) => props.src}');
  background-size: cover;
  background-position: center;
  border-radius: 8px;
`

const FullscreenIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1;
`

export default ({ useGif, maxWidth, maxHeight, video, campaign }) => {
  const { displayWidth, displayHeight } = getDisplaySize(maxWidth || 600, maxHeight || 500, video.aspectRatioCalc)
  const buttonLink =
    campaign.buttonLink && campaign.buttonLink.slice(0, 4) === 'http' ? campaign.buttonLink : `https://${campaign.buttonLink}`

  const src = useGif
    ? `https://image.mux.com/${video.playbackId}/animated.gif?width=${displayWidth}&height=${displayHeight}&start=0&end=3&fps=8`
    : `https://image.mux.com/${video.playbackId}/thumbnail.jpg?time=0`

  const backgroundColor = hexToRgba(campaign.buttonColor, campaign.buttonOpacity / 100)
  const videoObjects =
    campaign.buttonLinks && campaign.buttonTexts
      ? Object.keys(campaign.buttonLinks).map((videoId) => {
          return { videoId, buttonLink: campaign.buttonLinks[videoId], buttonText: campaign.buttonTexts[videoId] }
        })
      : []
  const videoObject = videoObjects.find((el) => el.videoId === campaign.videos[0].id)
  const buttonUrl = videoObject
    ? `${serverUrl}/campaign/click?url=${encodeURIComponent(videoObject.buttonLink)}&campaignId=${campaign.id}&type=WIDGET`
    : ''
  return (
    <Box position="relative" bg="black" borderRadius="8px">
      <FullscreenIcon icon="fullscreen-white" />
      {campaign.video.playbackId ? (
        <ImageContainer
          zIndex={1}
          width={displayWidth}
          height={displayHeight}
          src={src}
          boxShadow="0px 4px 20px -2px rgba(50, 50, 71, 0.4);"
        />
      ) : (
        <Box
          width="600px"
          height="500px"
          backgroundColor="white"
          boxShadow="0px 4px 20px -2px rgba(50, 50, 71, 0.4);"
          borderRadius="8px"
          position="relative"
        >
          <ProcessingPreview />
        </Box>
      )}
      {videoObject && videoObject.buttonText && videoObject.buttonLink ? (
        <a target="_blank" rel="noreferrer" href={buttonUrl}>
          <Flex width="calc(100% - 32px)" justifyContent="center" position="absolute" bottom="16px" mx="16px">
            <Button
              width="100%"
              maxWidth="300px"
              height="42px"
              fontColorOveride={campaign.buttonFontColor}
              borderColor={campaign.buttonBorderColor}
              backgroundColorOveride={backgroundColor}
              borderRadius={campaign.buttonBorderRadius}
              opacity={campaign.buttonOpacity / 100}
              label={videoObject.buttonText}
            />
          </Flex>
        </a>
      ) : null}
    </Box>
  )
}
