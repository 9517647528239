import React from 'react'
import styled from 'styled-components'
import { colors } from './helpers'
import { Box, Flex } from './Layout.js'
import { Text } from './Typography'
import Spinner from './Spinner'
import Button from './Button'

import {
  space,
  width,
  color,
  flex,
  order,
  fontSize,
  alignSelf,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  borders,
  minWidth,
  minHeight,
  borderRadius,
  size,
  height,
  maxWidth,
  textAlign,
  boxShadow,
  borderColor,
  position,
  display,
  top,
  bottom,
  left,
  right,
  zIndex
} from 'styled-system'

export default ({
  leftColor,
  leftFontColor,
  rightColor,
  rightFontColor,
  label1,
  label2,
  label3,
  renderCenterIcon1,
  renderCenterIcon2,
  value1,
  value2,
  value3,
  selected,
  setSelected,
  width = '133px',
  mb,
  mt
}) => {
  return (
    <Flex justifyContent="space-between" borderRadius="8px" p="2px" bg="#F6F7F9" width="fit-content" mb={mb} mt={mt}>
      <Button
        onClick={() => setSelected(value1)}
        color={selected === value1 ? leftFontColor || 'black' : '#7A7A9D'}
        bg={selected === value1 ? leftColor || '#FFFFFF' : '#F6F7F9'}
        disabled={selected === value1}
        width={width}
        minWidth={width}
        renderCenterIcon={renderCenterIcon1}
        height="34px"
        boxShadow={selected === value1 ? '0px 2px 5px 0px rgba(0,0,0,0.1)' : ''}
        label={label1}
        fontWeight="500"
      />
      <Button
        onClick={() => setSelected(value2)}
        color={selected === value2 ? rightFontColor || 'black' : '#7A7A9D'}
        bg={selected === value2 ? rightColor || '#FFFFFF' : '#F6F7F9'}
        disabled={selected === value2}
        width={width}
        minWidth={width}
        renderCenterIcon={renderCenterIcon2}
        height="34px"
        boxShadow={selected === value2 ? '0px 2px 5px 0px rgba(0,0,0,0.1)' : ''}
        label={label2}
        fontWeight="500"
      />
      <Button
        onClick={() => setSelected(value3)}
        color={selected === value3 ? rightFontColor || 'black' : '#7A7A9D'}
        bg={selected === value3 ? rightColor || '#FFFFFF' : '#F6F7F9'}
        disabled={selected === value3}
        width={width}
        minWidth={width}
        renderCenterIcon={renderCenterIcon2}
        height="34px"
        boxShadow={selected === value3 ? '0px 2px 5px 0px rgba(0,0,0,0.1)' : ''}
        label={label3}
        fontWeight="500"
      />
    </Flex>
  )
}
