export const STEPS = {
  SELECT_VIDEO: 'SELECT_VIDEO',
  VIDEO_APPEARANCE: 'VIDEO_APPEARANCE',
  CAMPAIGN_TYPE: 'CAMPAIGN_TYPE',
  DISPLAY_TYPE: 'DISPLAY_TYPE',
  WIDGET_TYPE: 'WIDGET_TYPE',
  WIDGET_SELECT: 'WIDGET_SELECT',
  EDIT_FLOATING: 'EDIT_FLOATING',
  EDIT_EMBED: 'EDIT_EMBED',
  SHOPPABLE_SELECT: 'SHOPPABLE_SELECT',
  ON_SITE_OFF_SITE: 'ON_SITE_OFF_SITE',
  DOWNLOAD_TYPE: 'DOWNLOAD_TYPE',
  EDIT_SEARCH: 'EDIT_SEARCH'
}

export const CAMPAIGN_RULE_TYPES = {
  URL_MATCH: 'URL_MATCH',
  EMBED: 'EMBED',
  SHARE: 'SHARE'
}

export const PLAYER_TYPES = {
  widget: 'widget',
  embed: 'embed',
  share: 'share'
}

export const playerTypeOptions = [
  { value: PLAYER_TYPES.widget, label: 'Widget' },
  // { value: PLAYER_TYPES.embed, label: 'Embed' },
  { value: PLAYER_TYPES.share, label: 'Share' }
]

export const campaignRuleTypesOptions = [
  { value: CAMPAIGN_RULE_TYPES.URL_MATCH, label: 'Specific or all pages' },
  { value: CAMPAIGN_RULE_TYPES.EMBED, label: 'Embed on site' },
  { value: CAMPAIGN_RULE_TYPES.SHARE, label: 'Share URL' }
]

export const WIDGET_POSITIONS = {
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT'
}

export const widgetPositionOptions = [
  { value: WIDGET_POSITIONS.BOTTOM_LEFT, label: 'Bottom left' },
  { value: WIDGET_POSITIONS.BOTTOM_RIGHT, label: 'Bottom right' }
]

export const WIDGET_ALIGN = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT'
}

export const widgetAlignOptions = [
  { value: WIDGET_ALIGN.CENTER, label: 'Center' },
  { value: WIDGET_ALIGN.LEFT, label: 'Left' },
  { value: WIDGET_ALIGN.RIGHT, label: 'Right' }
]

export const WIDGET_TEXT_ALIGN = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right'
}

export const widgetTextAlignOptions = [
  { value: WIDGET_TEXT_ALIGN.CENTER, label: 'Center' },
  { value: WIDGET_TEXT_ALIGN.LEFT, label: 'Left' },
  { value: WIDGET_TEXT_ALIGN.RIGHT, label: 'Right' }
]

export const landscapePortraitOptions = [
  { value: false, label: 'Portrait' },
  { value: true, label: 'Landscape' }
]

export const WIDGET_RULE_TYPES = {
  IS_EXACTLY: 'IS_EXACTLY',
  CONTAINS: 'CONTAINS',
  DOESNT_CONTAIN: 'DOESNT_CONTAIN',
  STARTS_WITH: 'STARTS_WITH',
  ENDS_WITH: 'ENDS_WITH',
  ALL_PAGES: 'ALL_PAGES'
}

export const widgetRuleTypeOptions = [
  { value: WIDGET_RULE_TYPES.CONTAINS, label: 'URL contains' },
  { value: WIDGET_RULE_TYPES.DOESNT_CONTAIN, label: `URL doesn't contain` },
  { value: WIDGET_RULE_TYPES.STARTS_WITH, label: 'URL starts with' },
  { value: WIDGET_RULE_TYPES.ENDS_WITH, label: 'URL ends with' },
  { value: WIDGET_RULE_TYPES.IS_EXACTLY, label: 'URL is exactly' }
]

export const RULE_TYPES = {
  URL_RULES: 'URL_RULES',
  SHOPIFY_PRODUCT: 'SHOPIFY_PRODUCT',
  HOMEPAGE: 'HOMEPAGE'
}

export const ruleTypeOptions = [
  { value: RULE_TYPES.HOMEPAGE, label: 'Homepage' },
  { value: RULE_TYPES.URL_RULES, label: 'URL Rules' }
]

export const ruleTypeOptionsShoppable = [{ value: RULE_TYPES.SHOPIFY_PRODUCT, label: 'Product page' }, ...ruleTypeOptions]

export const WIDGET_TYPES = {
  FLOATING: 'FLOATING',
  CAROUSEL: 'CAROUSEL',
  SINGLE_EMBED: 'SINGLE_EMBED',
  STORIES: 'STORIES',
  GRID: 'GRID',
  NAVIGATION_CAROUSEL: 'NAVIGATION_CAROUSEL',
  NAVIGATION_GRID: 'NAVIGATION_GRID',
  INLINE_CAROUSEL: 'INLINE_CAROUSEL',
  INLINE_SINGLE: 'INLINE_SINGLE',
  SEARCH: 'SEARCH'
}

export const embedWidgetSelectOptions = [
  { value: WIDGET_TYPES.CAROUSEL, label: 'Carousel' },
  { value: WIDGET_TYPES.SINGLE_EMBED, label: 'Single Embed' },
  { value: WIDGET_TYPES.STORIES, label: 'Stories' }
]

export const SINGLE_EMBED_DIMENSIONS = {
  LANDSCAPE: 'LANDSCAPE',
  PORTRAIT: 'PORTRAIT',
  SHORT_PORTRAIT: 'SHORT_PORTRAIT',
  SQUARE: 'SQUARE'
}

export const MOBILE_DIMENSIONS = {
  PORTRAIT: 'PORTRAIT',
  FOLLOW: 'FOLLOW'
}

export const mobileDimensionsOptions = [
  { value: MOBILE_DIMENSIONS.FOLLOW, label: 'Use Desktop Dimensions' },
  { value: MOBILE_DIMENSIONS.PORTRAIT, label: 'Portrait - 9:16' }
]

export const singleEmbedDimensionsOptions = [
  { value: SINGLE_EMBED_DIMENSIONS.LANDSCAPE, label: '16:9 - Landscape' },
  { value: SINGLE_EMBED_DIMENSIONS.PORTRAIT, label: '9:16 - Portrait' },
  { value: SINGLE_EMBED_DIMENSIONS.SQUARE, label: '1:1 - Square' }
]

export const singleInlineDimensionsOptions = [
  { value: SINGLE_EMBED_DIMENSIONS.LANDSCAPE, label: 'Landscape - 16:9' },
  { value: SINGLE_EMBED_DIMENSIONS.PORTRAIT, label: 'Portrait - 9:16' },
  { value: SINGLE_EMBED_DIMENSIONS.SHORT_PORTRAIT, label: 'Short Portrait - 2:3' },
  { value: SINGLE_EMBED_DIMENSIONS.SQUARE, label: 'Square - 1:1' }
]

export const widgetTypeDisplayName = {
  [WIDGET_TYPES.NAVIGATION_CAROUSEL]: 'Navigation carousel',
  [WIDGET_TYPES.NAVIGATION_GRID]: 'Navigation grid',
  [WIDGET_TYPES.FLOATING]: 'Floating widget',
  [WIDGET_TYPES.CAROUSEL]: 'Carousel',
  [WIDGET_TYPES.GRID]: 'Grid',
  [WIDGET_TYPES.SINGLE_EMBED]: 'Single Embed',
  [WIDGET_TYPES.STORIES]: 'Stories',
  [WIDGET_TYPES.INLINE_CAROUSEL]: 'Inline Carousel',
  [WIDGET_TYPES.INLINE_SINGLE]: 'Inline Single',
  [WIDGET_TYPES.SEARCH]: 'Search'
}

export const getSelectWidgetOptions = (widgets) =>
  widgets
    ? widgets
        .filter((widget) => {
          return (
            widget.type !== WIDGET_TYPES.NAVIGATION_CAROUSEL &&
            widget.type !== WIDGET_TYPES.NAVIGATION_GRID &&
            widget.type !== WIDGET_TYPES.INLINE_CAROUSEL &&
            widget.type !== WIDGET_TYPES.INLINE_SINGLE &&
            widget.type !== WIDGET_TYPES.SEARCH
          )
        })
        .map((widget) => {
          return { value: widget.id, label: `${widget.name} - ${widgetTypeDisplayName[widget.type]}` }
        })
    : []

export const getSelectNavigationWidgetOptions = (widgets) =>
  widgets
    ? widgets
        .filter((widget) => {
          return widget.type === WIDGET_TYPES.NAVIGATION_CAROUSEL || widget.type === WIDGET_TYPES.NAVIGATION_GRID
        })
        .map((widget) => {
          return { value: widget.id, label: `${widget.name} - ${widgetTypeDisplayName[widget.type]}` }
        })
    : []

export const getSelectInlineWidgetOptions = (widgets) =>
  widgets
    ? widgets
        .filter((widget) => {
          return widget.type === WIDGET_TYPES.INLINE_CAROUSEL || widget.type === WIDGET_TYPES.INLINE_SINGLE
        })
        .map((widget) => {
          return { value: widget.id, label: `${widget.name} - ${widgetTypeDisplayName[widget.type]}` }
        })
    : []

export const SINGLE_EMBED_HEIGHT_TO_WIDTH = {
  LANDSCAPE: (x) => (x * 16) / 9,
  PORTRAIT: (x) => (x * 9) / 16,
  SQUARE: (x) => x
}

export const SINGLE_EMBED_WIDTH_TO_HEIGHT = {
  LANDSCAPE: (x) => (x / 16) * 9,
  PORTRAIT: (x) => (x / 9) * 16,
  SHORT_PORTRAIT: (x) => (x / 2) * 3,
  SQUARE: (x) => x
}

export const VIDEO_OBJECT_TYPE = {
  BUTTON: 'BUTTON',
  TITLE: 'TITLE'
}

export const TITLE_POSITION = {
  ABOVE: 'ABOVE',
  INSIDE: 'INSIDE',
  BELOW: 'BELOW',
  BOTTOM: 'BOTTOM',
  MIDDLE: 'MIDDLE',
  TOP: 'TOP'
}

export const titlePositionOptions = [
  // { value: TITLE_POSITION.ABOVE, label: 'Above' },
  { value: TITLE_POSITION.INSIDE, label: 'Inside' },
  { value: TITLE_POSITION.BELOW, label: 'Below' }
]

export const navigationTitlePositionOptions = [
  // { value: TITLE_POSITION.ABOVE, label: 'Above' },
  { value: TITLE_POSITION.BOTTOM, label: 'Bottom' },
  { value: TITLE_POSITION.MIDDLE, label: 'Middle' },
  { value: TITLE_POSITION.TOP, label: 'Top' },
  { value: TITLE_POSITION.BELOW, label: 'Below' }
]

export const TITLE_ALIGNMENT = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT'
}

export const titleAlignmentOptions = [
  { value: TITLE_ALIGNMENT.LEFT, label: 'Left' },
  { value: TITLE_ALIGNMENT.CENTER, label: 'Center' },
  { value: TITLE_ALIGNMENT.RIGHT, label: 'Right' }
]

export const WIDGET_TITLE_ALIGNMENT = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT'
}

export const widgetTitleAlignmentOptions = [
  { value: WIDGET_TITLE_ALIGNMENT.LEFT, label: 'Left' },
  { value: WIDGET_TITLE_ALIGNMENT.CENTER, label: 'Center' },
  { value: WIDGET_TITLE_ALIGNMENT.RIGHT, label: 'Right' }
]

export const WIDGET_THUMBNAIL_DIMENSIONS = {
  PORTRAIT: 'PORTRAIT',
  LANDSCAPE: 'LANDSCAPE',
  SQUARE: 'SQUARE',
  SQUASHED_PORTRAIT: 'SQUASHED_PORTRAIT',
  CIRCLE: 'CIRCLE'
}

export const widgetThumbnailDimensionsOptions = [
  { value: WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT, label: 'Tall Portrait - 9:16' },
  { value: WIDGET_THUMBNAIL_DIMENSIONS.SQUASHED_PORTRAIT, label: 'Short Portrait - 2:3' },
  { value: WIDGET_THUMBNAIL_DIMENSIONS.LANDSCAPE, label: 'Landscape - 16:9' },
  { value: WIDGET_THUMBNAIL_DIMENSIONS.SQUARE, label: 'Square - 1:1' }
]

export const widgetThumbnailDimensionsStoriesOptions = [
  { value: WIDGET_THUMBNAIL_DIMENSIONS.CIRCLE, label: 'Circle' },
  { value: WIDGET_THUMBNAIL_DIMENSIONS.SQUASHED_PORTRAIT, label: 'Portrait' }
]

export const widgetDesktopVideosInlineOptions = [
  { value: 3, label: '3' },
  { value: 5, label: '5' },
  { value: 7, label: '7' }
]

export const WIDGET_ARROW_POSITION = {
  BELOW: 'BELOW',
  OVERLAP: 'OVERLAP',
  INSIDE: 'INSIDE',
  OUTSIDE: 'OUTSIDE',
  CENTER_BELOW: 'CENTER_BELOW'
}

export const widgetArrowPositionOptions = [
  { value: WIDGET_ARROW_POSITION.INSIDE, label: 'Inside' },
  { value: WIDGET_ARROW_POSITION.OVERLAP, label: 'Overlap' },
  { value: WIDGET_ARROW_POSITION.OUTSIDE, label: 'Outside' },
  { value: WIDGET_ARROW_POSITION.BELOW, label: 'Below Right' },
  { value: WIDGET_ARROW_POSITION.CENTER_BELOW, label: 'Below Center' }
]

export const WIDGET_THUMBNAIL_SIZE = {
  LARGE: 'LARGE',
  MEDIUM: 'MEDIUM',
  SMALL: 'SMALL'
}

export const widgetThumbnailSizeOptions = [
  { value: WIDGET_THUMBNAIL_SIZE.LARGE, label: 'Large' },
  { value: WIDGET_THUMBNAIL_SIZE.MEDIUM, label: 'Medium' },
  { value: WIDGET_THUMBNAIL_SIZE.SMALL, label: 'Small' }
]

export const WIDGET_PLAY_BUTTON_POSITION = {
  CENTER: 'CENTER',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT'
}

export const widgetPlayButtonPositionOptions = [
  { value: WIDGET_PLAY_BUTTON_POSITION.CENTER, label: 'Center' },
  { value: WIDGET_PLAY_BUTTON_POSITION.BOTTOM_LEFT, label: 'Bottom Left' },
  { value: WIDGET_PLAY_BUTTON_POSITION.BOTTOM_RIGHT, label: 'Bottom Right' }
]

export const BUTTON_STYLES = {
  CIRCLE_CHEVRON: 'CIRCLE_CHEVRON',
  CIRCLE_ARROW: 'CIRCLE_ARROW',
  SQUARE_CHEVRON: 'SQUARE_CHEVRON',
  SQUARE_ARROW: 'SQUARE_ARROW',
  NONE_CHEVRON: 'NONE_CHEVRON',
  NONE_ARROW: 'NONE_ARROW'
}

export const PLAY_BUTTON_STYLES = {
  FILLED: 'FILLED',
  LIGHT: 'LIGHT'
}

export const SEARCH_THUMBNAIL_SIZES = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE'
}

export const SEARCH_SHARPNESS = {
  ROUND: 'ROUND',
  SOFT: 'SOFT',
  SHARP: 'SHARP'
}

export const widgetSearchGridOffsetOptions = [
  { value: false, label: 'Standard' },
  { value: true, label: 'Offset' }
]

export const SEARCH_SEARCHABLE_CONTENT = {
  VIDEOS: 'VIDEOS',
  IMAGES: 'IMAGES',
  BOTH: 'BOTH'
}

export const widgetSearchSearchableContentOptions = [
  { value: SEARCH_SEARCHABLE_CONTENT.VIDEOS, label: 'Videos' },
  { value: SEARCH_SEARCHABLE_CONTENT.IMAGES, label: 'Images' },
  { value: SEARCH_SEARCHABLE_CONTENT.BOTH, label: 'Video & Images' }
]
