import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, shorten } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import SideNav, { TABS } from '../Components/SideNav.js'
import VideoPlayer from '../Components/VideoPlayer'
import StatusPill from '../Components/StatusPill'
import ProcessingPreview from '../Components/ProcessingPreview'

import formikFormWrapper from '../formikFormWrapper.js'
import { CAMPAIGN_STATUS } from './types.js'
import { toast } from 'react-toastify'
import api from '../api.js'
import { useAuth } from '../Auth.js'

const Popover = styled(Flex)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  right: 0;
  width: 186px;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`

const MenuText = styled(Text)`
  font-size: 16px;
  padding: 16px;
  cursor: pointer;
  :hover {
    color: ${colors.blue};
  }
  border-bottom: 1px solid #edf2f7;
`

const OptionsPopover = ({ id, isPaused, refresh }) => {
  const [showPopover, setShowPopover] = useState(false)
  const popover = useRef(null)
  const navigate = useNavigate()

  const closeMenu = (event) => {
    if (popover && popover.current && !popover.current.contains(event.target)) {
      document.removeEventListener('click', closeMenu)
      setShowPopover(false)
    }
  }

  const showPopoverFunc = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setShowPopover(true)
    document.addEventListener('click', closeMenu)
  }

  const deleteCampaign = async (e) => {
    e.stopPropagation()
    const res = await api.post('/campaign/delete', { id })
    console.log(res)
    if (res.data.success) {
      toast('Campaign deleted')
      refresh()
    } else {
      toast('Error deleting campaign')
    }
  }

  const duplicateCampaign = async (e) => {
    e.stopPropagation()
    const res = await api.post('/campaign/duplicate', { id })
    console.log(res)
    if (res.data.success) {
      toast('Campaign duplicated')
      refresh()
      closeMenu({})
    } else {
      toast('Error duplicating campaign')
    }
  }

  const editCampaign = async (e) => {
    e.stopPropagation()
    navigate(`/campaigns/edit/${id}`)
  }

  return (
    <Box position="relative">
      <Box cursor="pointer" onClick={showPopover ? closeMenu : showPopoverFunc}>
        <Icon width={24} height={24} icon="three-dots-grey" />
      </Box>
      {showPopover ? (
        <Popover ref={popover}>
          <MenuText onClick={editCampaign}>Edit Campaign</MenuText>
          <MenuText onClick={duplicateCampaign}>Duplicate Campaign</MenuText>
          <MenuText onClick={deleteCampaign} color={colors.error}>
            Delete Campaign
          </MenuText>
        </Popover>
      ) : null}
    </Box>
  )
}

export default OptionsPopover
