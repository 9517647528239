import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, shorten, stringPadLeft } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, Label } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import SideNav, { TABS } from '../Components/SideNav.js'
import VideoPlayer from '../Components/VideoPlayer'
import StatusPill from '../Components/StatusPill'
import ProcessingPreview from '../Components/ProcessingPreview'

import formikFormWrapper from '../formikFormWrapper.js'
import { toast } from 'react-toastify'
import api from '../api.js'
import { useAuth } from '../Auth.js'

const FormInput = formikFormWrapper(Input)

const Popover = styled(Flex)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  left: 0;
  width: 400px;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  z-index: 999999;
`

const LineClamp = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 600;
  word-break: break-all;
`

const OptionsPopover = ({ values, setFieldValue, video, videos, setShowVideoToProductModal }) => {
  const popover = useRef(null)
  const [showPopover, setShowPopover] = useState(false)
  const numberProducts = video.linkedProducts ? video.linkedProducts.length : 0
  const numberButton = values.buttonTexts && values.buttonTexts[video.id] ? 1 : 0
  const numberTitle = values.titles && values.titles[video.id] ? 1 : 0
  const numberSubtitle = values.subtitles && values.subtitles[video.id] ? 1 : 0
  const buttonNumber = values.isShoppable
    ? numberProducts + numberTitle + numberSubtitle
    : numberButton + numberTitle + numberSubtitle
  let baseButtonText = values.isShoppable
    ? 'Products & Titles'
    : !values.isNavigationCampaign
    ? 'Button & Titles'
    : 'Link & Titles'

  if (values.isInlineCampaign) {
    baseButtonText = 'Titles'
  }
  if (values.isDownloadCampaign) {
    baseButtonText = 'Titles'
  }
  let buttonText = (buttonNumber === 0 ? '+ Add ' : '') + baseButtonText + (buttonNumber ? ` (${buttonNumber})` : '')

  let { user } = useAuth()

  const closeMenu = (event) => {
    if (popover && popover.current && !popover.current.contains(event.target)) {
      document.removeEventListener('click', closeMenu)
      setShowPopover(false)
    }
  }

  const showPopoverFunc = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setShowPopover(true)
    document.addEventListener('click', closeMenu)
  }

  return (
    <Box position="absolute" top="-28px">
      <Box cursor="pointer" onClick={showPopover ? closeMenu : showPopoverFunc}>
        <Button
          variant="white"
          shadow
          pill
          label={buttonText}
          renderRightIcon={() => <Icon ml="16px" width="16px" height="16px" icon="pencil-square-black" />}
        />
      </Box>
      {showPopover ? (
        <Popover ref={popover}>
          <Flex flexDirection="column" width="400px" minWidth="" borderLeft="1px solid #A6B7D4" p="24px">
            {video && video.id ? (
              <>
                <LineClamp>{video.name}</LineClamp>
                {video.type === 'VIDEO' ? (
                  <Text mb="32px">
                    {stringPadLeft(Math.floor(video.duration / 60), '0', 2)}:
                    {stringPadLeft(Math.round(video.duration - Math.floor(video.duration / 60) * 60), '0', 2)}
                  </Text>
                ) : (
                  <Text mb="32px">
                    {video.width}px x {video.height}px
                  </Text>
                )}
                {!values.isShoppable && !values.isNavigationCampaign && !values.isInlineCampaign && !values.isDownloadCampaign
                  ? videos.map((sv) => {
                      return (
                        <Flex key={sv.id} flexDirection="column" display={video.id === sv.id ? 'flex' : 'none'}>
                          <Field
                            mb="16px"
                            width="100%"
                            name={`buttonTexts.${sv.id}`}
                            label="Button Text"
                            dark
                            component={FormInput}
                          />
                          <Field
                            mb="16px"
                            width="100%"
                            name={`buttonLinks.${sv.id}`}
                            label="Button Link"
                            placeholder="https://..."
                            dark
                            component={FormInput}
                          />
                        </Flex>
                      )
                    })
                  : null}
                {values.isNavigationCampaign
                  ? videos.map((sv) => {
                      return (
                        <Flex key={sv.id} flexDirection="column" display={video.id === sv.id ? 'flex' : 'none'}>
                          <Field
                            mb="16px"
                            width="100%"
                            name={`buttonLinks.${sv.id}`}
                            label="Link"
                            placeholder="https://..."
                            dark
                            component={FormInput}
                          />
                        </Flex>
                      )
                    })
                  : null}
                {videos.map((sv) => {
                  return (
                    <Flex key={sv.id} flexDirection="column" display={video.id === sv.id ? 'flex' : 'none'}>
                      <Field mb="16px" width="100%" name={`titles.${sv.id}`} label="Title" dark component={FormInput} />
                      <Field mb="16px" width="100%" name={`subtitles.${sv.id}`} label="Subtitle" dark component={FormInput} />
                    </Flex>
                  )
                })}
                {values.isShoppable && user.shopifyShopUrl && video ? (
                  <Flex flexDirection="column">
                    <Label mb="16px">Shoppable Items</Label>
                    {video.linkedProducts?.map((dbProduct) => {
                      const product = dbProduct?.product
                      return (
                        <Flex key={product?.id} alignItems="center" mb="16px">
                          <Image
                            height="60px"
                            width="60px"
                            borderRadius="4px"
                            src={product?.image ? product?.image.src : product?.media && product?.media[0]?.src}
                          />
                          <Flex flexDirection="column" ml="16px">
                            <Text fontWeight="600">{product.title}</Text>
                          </Flex>
                        </Flex>
                      )
                    })}
                    {values.isShoppable ? (
                      <Flex onClick={() => setShowVideoToProductModal(true)} cursor="pointer">
                        <Flex width="auto">
                          <Icon mt="6px" mr="8px" icon="plus-black" width="12px" height="12px" />
                          <Text fontSize="14px" fontWeight="600">
                            Add An item
                          </Text>
                        </Flex>
                      </Flex>
                    ) : null}
                  </Flex>
                ) : null}
              </>
            ) : null}
          </Flex>
        </Popover>
      ) : null}
    </Box>
  )
}

export default OptionsPopover
