import * as React from 'react'
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate, useLocation } from 'react-router-dom'
import axios from './api'
import { useCookies } from 'react-cookie'
import Spinner from './ui/Spinner'
import { Flex, Box } from './ui/Layout'
import { GradientFont } from './ui/Typography'
import { colors } from './ui/helpers'
import { initChat } from './Components/ChatButton'
import * as Sentry from '@sentry/react'

let AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  let [user, setUser] = React.useState()
  let [isCheckingAuth, setIsCheckingAuth] = React.useState(true)
  const [cookies, setCookie, removeCookie] = useCookies(['russwar_auth'])
  let navigate = useNavigate()

  React.useEffect(() => {
    const asyncFunc = async () => {
      try {
        const res = await axios.get('/auth')
        if (res.data.success) {
          setUser(res.data.payload)
          initChat(res.data.payload)

          try {
            Sentry.setContext('user', res.data.payload)
          } catch (e) {}

          if (!res.data.payload.shopifyPlanId && !res.data.payload.stripePlanId) {
            console.log('NO PLAN ID')
            if (res.data.payload.shopifyShopUrl) {
              navigate('/billing')
            } else {
              navigate('/account-type')
            }
          }
        } else {
          console.log('NO SUCCESS')
          removeCookie('russwar_auth', {
            path: '/',
            domain: process.env.NODE_ENV === 'production' ? '.getclipara.com' : 'localhost'
          })
          setUser()
        }
      } catch (e) {
        try {
          Sentry.captureException(e)
          console.log(e.message)
          if (e && e.message === 'Request failed with status code 401') {
            removeCookie('russwar_auth', {
              path: '/',
              domain: process.env.NODE_ENV === 'production' ? '.getclipara.com' : 'localhost'
            })
            setUser()
          }
        } catch (ee) {
          console.log('double error')

          Sentry.captureException(ee)
          setUser()
        }
      }
      setIsCheckingAuth(false)
    }
    if (cookies.russwar_auth && !user) {
      asyncFunc()
    } else {
      setIsCheckingAuth(false)
    }
  }, [])

  let value = { user, setUser }
  if (isCheckingAuth) {
    return (
      <Flex width="100vw" height="100vh" bg={colors.softBlue} justifyContent="center" alignItems="center" flexDirection="column">
        <GradientFont mb="25px">Clipara</GradientFont>
        <Spinner width="50px" height="50px" />
      </Flex>
    )
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return React.useContext(AuthContext)
}

export const RequireAuth = ({ children }) => {
  let auth = useAuth()
  let location = useLocation()
  console.log({ auth })
  if (!auth || !auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}
