import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate, useLocation } from 'react-router-dom'
import axios from './api'
import { useCookies } from 'react-cookie'
import Spinner from './ui/Spinner'
import { Flex, Box } from './ui/Layout'
import { GradientFont } from './ui/Typography'
import { colors } from './ui/helpers'
import InstallWidgetModal from './Install/WidgetModal.js'
import InstallEmbedModal from './Install/InstallEmbedModal.js'
import AddThumbnailModal from './Videos/AddThumbnailModal.js'
import AddLogoModal from './Account/AddLogoModal.js'
import ReferModal from './Account/ReferModal.js'
import { useVideos } from './VideoProvider'
import VideoPopover from './Videos/VideoPopover'

let ModalContext = React.createContext()

export const ModalProvider = ({ children }) => {
  const [showInstallWidgetModal, setShowInstallWidgetModal] = useState(false)
  const [showInstallEmbedModal, setShowInstallEmbedModal] = useState(false)
  const [showAddLogoModal, setShowAddLogoModal] = useState(false)
  const [showAddThumbnailModal, setShowAddThumbnailModal] = useState(false)
  const [addThumbnailVideo, setAddThumbnailVideo] = useState(false)
  const [showReferModal, setShowReferModal] = useState(false)
  const [showVideoPopover, setShowVideoPopover] = useState(false)
  const [videoPopoverId, setVideoPopoverId] = useState()
  const props = {
    showInstallWidgetModal,
    setShowInstallWidgetModal,
    showInstallEmbedModal,
    setShowInstallEmbedModal,
    showAddLogoModal,
    setShowAddLogoModal,
    addThumbnailVideo,
    setAddThumbnailVideo,
    showAddThumbnailModal,
    setShowAddThumbnailModal,
    showReferModal,
    setShowReferModal,
    showVideoPopover,
    setShowVideoPopover,
    videoPopoverId,
    setVideoPopoverId
  }
  return (
    <ModalContext.Provider value={props}>
      {children}
      <InstallWidgetModal showInstallWidgetModal={showInstallWidgetModal} setShowInstallWidgetModal={setShowInstallWidgetModal} />
      <InstallEmbedModal showInstallEmbedModal={showInstallEmbedModal} setShowInstallEmbedModal={setShowInstallEmbedModal} />
      <AddLogoModal showAddLogoModal={showAddLogoModal} setShowAddLogoModal={setShowAddLogoModal} />
      <ReferModal showReferModal={showReferModal} setShowReferModal={setShowReferModal} />
      <VideoPopover
        showVideoPopover={showVideoPopover}
        setShowVideoPopover={setShowVideoPopover}
        videoPopoverId={videoPopoverId}
        setVideoPopoverId={setVideoPopoverId}
      />
      <AddThumbnailModal video={addThumbnailVideo} show={showAddThumbnailModal} setShow={setShowAddThumbnailModal} />
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  return React.useContext(ModalContext)
}
