import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize } from '../../ui/helpers.js'
import { H1, H2, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../../ui/Typography.js'
import { Flex, Box, Container } from '../../ui/Layout.js'
import Image from '../../ui/Image.js'
import { Input, TextArea } from '../../ui/Input.js'
import Icon from '../../ui/Icon.js'
import CodeCopy from '../../ui/CodeCopy.js'
import VideoPreview from '../../ui/VideoPreview.js'
import CarouselPreview from '../../Components/CarouselPreview.js'

import WidgetPreview from '../../Components/WidgetPreview.js'
import Button from '../../ui/Button.js'
import { BigDarkSpinner } from '../../ui/Spinner.js'
import Toggle from '../../ui/Toggle.js'
import Select from '../../ui/Select.js'
import ColorPicker from '../../ui/ColorPicker.js'
import formikFormWrapper from '../../formikFormWrapper.js'
import { toast } from 'react-toastify'
import { useModal } from '../../Modal.js'
import ModalContainer from '../../ui/ModalContainer.js'
import CreateModal from './ShareModal.js'
import ChatButton from '../../Components/ChatButton'

const FormInput = formikFormWrapper(Input)

import api from '../../api.js'
import { useAuth } from '../../Auth.js'

import {
  STEPS,
  CAMPAIGN_RULE_TYPES,
  CAMPAIGN_STATUS,
  campaignRuleTypesOptions,
  WIDGET_POSITIONS,
  widgetPositionOptions,
  widgetRuleTypeOptions,
  getSelectWidgetOptions,
  WIDGET_TYPES
} from '../types'

const WidgetSelect = ({
  step,
  setStep,
  values,
  selectedVideos,
  openFullscreenDesktopPreview,
  openFullscreenMobilePreview,
  videoObjects,
  setFieldValue,
  submit,
  widgets,
  setWidgets,
  activeWidget
}) => {
  const [search, setSearch] = useSearchParams()
  const navigate = useNavigate()
  const [showIntroModal, setShowIntroModal] = useState(false)
  const widgetModalOptions = getSelectWidgetOptions(widgets) || []
  const { setShowInstallEmbedModal } = useModal()
  return (
    <>
      <Flex>
        <Flex
          flexDirection="column"
          borderRight="1px solid #A6B7D4;"
          minHeight="100vh"
          maxHeight="100vh"
          height="100%"
          width="380px"
          minWidth="380px"
          p="32px"
          overflowY="scroll"
          zIndex="1"
          bg="white"
        >
          <Flex onClick={() => setStep(STEPS.CAMPAIGN_TYPE)} cursor="pointer" width="auto">
            <Icon mt="4px" mr="16px" icon="chevron-left-lightlight" height="16px" width="16px" />
            <Text lightLight fontSize="14px" mb="30px" textAlign="left">
              Back to Campaign
            </Text>
          </Flex>
          <H1 fontSize="20px" mb="30px" textAlign="left">
            Select Widget
          </H1>
          <Select
            mb="16px"
            fullWidth
            value={values.widgetId}
            onChange={(option) => {
              setFieldValue(`widgetId`, option.value)
            }}
            options={widgetModalOptions}
            placeholder="Select"
          />
          <Flex flexDirection="row-reverse" mb="16px">
            <Button
              renderLeftIcon={() => <Icon width={12} height={12} icon="cog-black" />}
              label="Edit Widget"
              onClick={() => {
                // if (activeWidget.type === WIDGET_TYPES.FLOATING) {
                //   return setStep(STEPS.EDIT_FLOATING, { widgetId: values.widgetId })
                // }
                return setStep(STEPS.EDIT_EMBED, { widgetId: values.widgetId })
              }}
              width="160px"
              variant="grey"
            />
          </Flex>
          <Flex flexDirection="row-reverse">
            <Text
              lightLight
              cursor="pointer"
              fontWeight="600"
              onClick={() => {
                setStep(STEPS.WIDGET_TYPE)
              }}
            >
              or <Span underline>Create New Widget</Span>
            </Text>
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          maxHeight="100vh"
          position="relative"
          overflow="hidden"
          p="70px"
          pt="32px"
        >
          <Flex width="auto" position="absolute" top="24px" right="24px">
            {activeWidget && activeWidget.type !== WIDGET_TYPES.FLOATING ? (
              <Button
                mr="16px"
                onClick={() => {
                  setShowInstallEmbedModal(true)
                }}
                variant="grey"
                label="Install"
                zIndex={1}
              />
            ) : null}
            <Button
              zIndex={1}
              variant="black"
              label="Save"
              onClick={async () => {
                await submit()
                navigate(
                  `/campaigns/edit/${values.id}?step=${STEPS.CAMPAIGN_TYPE}${values.widgetRuleUrl0 ? '' : '&campaignRules=true'}`
                )
              }}
            />
          </Flex>
          <Flex position="absolute" left="16px" bottom="16px" alignItems="center">
            <Text fontWeight="500" mr="16px">
              Preview
            </Text>
            <Button
              renderCenterIcon={() => <Icon width={12} height={12} icon="desktop-black" />}
              variant="grey"
              onClick={openFullscreenDesktopPreview}
              zIndex={1}
              width="52px"
              minWidth="52px"
              mr="16px"
            />
            <Button
              renderCenterIcon={() => <Icon width={12} height={12} icon="phone-black" />}
              variant="grey"
              onClick={openFullscreenMobilePreview}
              zIndex={1}
              width="52px"
              minWidth="52px"
              mr="16px"
            />
          </Flex>
          <WidgetPreview
            openFullscreenDesktopPreview={openFullscreenDesktopPreview}
            campaign={{ ...values, videoObjects }}
            widget={activeWidget}
            videos={selectedVideos || []}
          />
        </Flex>
      </Flex>
    </>
  )
}

export default WidgetSelect
