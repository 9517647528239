import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ModalProvider } from './Modal'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  dsn: process.env.NODE_ENV === 'production' ? 'https://85358ae0a73b448aabcd912a9dff844b@o1261756.ingest.sentry.io/6439835' : '',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0
})

// Sentry.init({
//   dsn: 'https://85358ae0a73b448aabcd912a9dff844b@o1261756.ingest.sentry.io/6439835',
//   tracesSampleRate: 1.0
// })

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
