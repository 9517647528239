import React from 'react'
import styled from 'styled-components'
import {
  space,
  width,
  color,
  flex,
  order,
  fontSize,
  alignSelf,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  borders,
  minWidth,
  minHeight,
  borderRadius,
  size,
  height,
  maxWidth,
  maxHeight,
  textAlign,
  boxShadow,
  borderColor,
  position,
  display,
  top,
  bottom,
  left,
  right,
  zIndex,
  layout
} from 'styled-system'

// Note that Box and Flex have 100% width by default
export const Box = styled.div`
  ${position};
  ${width};
  ${space};
  ${fontSize};
  ${color};
  ${flex};
  ${order};
  ${alignSelf};
  ${borders};
  ${borderColor};
  ${minWidth};
  ${minHeight};
  ${borderRadius};
  ${size};
  ${height};
  ${textAlign};
  ${maxWidth};
  ${maxHeight};
  ${boxShadow};
  ${display};
  ${top};
  ${bottom};
  ${left};
  ${right};
  ${zIndex};
  ${layout};
  ${(props) => props.cursor && `cursor: ${props.cursor};`}
  ${(props) => props.overflowX && `overflow-x: ${props.overflowX};`}
  ${(props) => props.overflowY && `overflow-y: ${props.overflowY};`}
  ${(props) =>
    props.noScrollBar
      ? `::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */`
      : ''}
`

export const Flex = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  ${flexWrap};
  ${flexDirection};
  ${alignItems};
  ${justifyContent};
  ${width};
  ${display};
`

export const Ul = styled.ul`
  list-style: disc;
  margin-left: 30px;
`

export const Container = styled(Flex)`
  background-color: white;
  flex-direction: column;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1), 0px 4px 20px -2px rgba(50, 50, 71, 0.08);
  border-radius: 16px;
  padding: 48px;
  ${(props) =>
    props.hover &&
    `&&:hover {
    cursor: pointer;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.3), 0px 4px 20px -2px rgba(50, 50, 71, 0.24);

  }`}
  ${position};
  ${width};
  ${space};
  ${fontSize};
  ${color};
  ${flex};
  ${order};
  ${alignSelf};
  ${borders};
  ${borderColor};
  ${minWidth};
  ${minHeight};
  ${borderRadius};
  ${size};
  ${height};
  ${textAlign};
  ${maxWidth};
  ${maxHeight};
  ${boxShadow};
  ${display};
  ${top};
  ${bottom};
  ${left};
  ${right};
  ${zIndex};
`

export const MobileContainer = styled(Flex)`
  display: none;
  @media (max-width: 700px) {
    display: flex;
  }
`

export const DesktopContainer = styled(Flex)`
  @media (max-width: 700px) {
    display: none;
  }
`
