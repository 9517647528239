import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize } from '../ui/helpers.js'
import { H1, H2, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input, TextArea } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import CodeCopy, { copyOnClick } from '../ui/CodeCopy.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import Toggle from '../ui/Toggle.js'
import Select from '../ui/Select.js'
import ColorPicker from '../ui/ColorPicker.js'
import formikFormWrapper from '../formikFormWrapper.js'
import { toast } from 'react-toastify'
import { useModal } from '../Modal.js'
import ModalContainer from '../ui/ModalContainer.js'
import { useAuth } from '../Auth.js'
import api from '../api.js'
import { openChat } from '../Components/ChatButton'

const FormInput = formikFormWrapper(Input)

const ImageContainer = styled(Flex)`
  background-image: url('${(props) => props.src}');
  background-size: cover;
  background-position: center;
  border-radius: 16px;
`

const BigButton = styled(Flex)`
  box-shadow: 0px 1px 14px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
  margin-bottom: 16px;
  padding: 24px;
  border-radius: 10px;
  height: 82px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &&:hover {
    box-shadow: 0px 1px 14px rgba(50, 50, 71, 0.2), 0px 0px 1px rgba(50, 50, 71, 0.2);
  }
`

const TabText = styled(Text)`
  ${(props) => !props.isActive && `color: ${colors.textLight};`}
  padding-bottom: 20px;
  ${(props) => props.isActive && 'border-bottom: 1px solid black;'}
  font-weight: 600;
  margin-right: 30px;
  cursor: pointer;
`
const Ol = styled.ol`
  font-family: 'Inter', sans-serif;
  color: ${colors.textLightLight};
  li {
    margin-bottom: 16px;
  }
`

const Tab = ({ onClick, title, isActive }) => {
  return (
    <TabText onClick={onClick} isActive={isActive}>
      {title}
    </TabText>
  )
}

const TABS = {
  MANUAL: 'MANUAL',
  SHOPIFY: 'SHOPIFY'
}

const InstallEmbedModal = ({ showInstallEmbedModal, setShowInstallEmbedModal, video, campaignId }) => {
  const [search, setSearch] = useSearchParams()
  const { user } = useAuth()
  const [activeTab, setActiveTab] = useState(user && user.shopifyShopUrl ? TABS.SHOPIFY : TABS.MANUAL)

  return (
    <ModalContainer
      interiorClose
      width="990px"
      height="600px"
      isOpen={showInstallEmbedModal}
      setIsOpen={setShowInstallEmbedModal}
      p="24px"
    >
      <H3 textAlign="center" fontSize="28px" lineHeight="32px" fontWeight="600" mb="18px" mt="24px">
        Install your new widget
      </H3>
      <Flex flexDirection="column" height="100%">
        <Flex justifyContent="center" mb="16px">
          <Tab onClick={() => setActiveTab(TABS.MANUAL)} title="Manual Install" isActive={activeTab === TABS.MANUAL} />
          <Tab onClick={() => setActiveTab(TABS.SHOPIFY)} title="Shopify Install" isActive={activeTab === TABS.SHOPIFY} />
        </Flex>
        {activeTab === TABS.MANUAL && (
          <Flex>
            <Flex flexDirection="column" maxWidth="350px">
              <Flex width="auto" mb="24px" flexDirection="column">
                <Ol>
                  <li>
                    Select the Embed / custom code feature on your site builder (or do it manually through code if you’re a pro).
                  </li>
                  <li>Copy and paste the code from the block to the right.</li>
                  <li>Save and publish.</li>
                  <li>Depending on the URL rules you’ve used, you may only see the widget on the live version of your site.</li>
                </Ol>
              </Flex>
            </Flex>
            <Flex pl="24px" width="100%" ml="50px" flexDirection="column" justifyContent="space-between" alignItems="center">
              <Flex height="100%" flexDirection="column" alignItems="flex-end" justifyContent="center">
                <CodeCopy id="embed-code-snippet" width="100%" code={`<div id="__clipara-embed"></div>`} />
                <Button
                  width="166px"
                  variant="softBlue"
                  label="Copy Embed Code"
                  onClick={() => {
                    copyOnClick({
                      id: 'embed-code-snippet',
                      code: `<div id="__clipara-embed"></div>`,
                      toastMessage: 'Snippet copied!'
                    })
                  }}
                />
              </Flex>
              <Flex mt="50px" justifyContent="flex-end" alignItems="flex-end">
                <Button
                  position="absolute"
                  bottom="24px"
                  right="24px"
                  zIndex={1}
                  variant="black"
                  label="Done"
                  onClick={() => setShowInstallEmbedModal(false)}
                />
              </Flex>
              <Flex position="absolute" left="0px" bottom="24px" justifyContent="center">
                <Text textAlign="center" width="auto" cursor="pointer" underline onClick={openChat}>
                  Need help? Contact Support.
                </Text>
              </Flex>
            </Flex>
          </Flex>
        )}
        {activeTab === TABS.SHOPIFY && (
          <Flex>
            <Flex flexDirection="column" maxWidth="350px">
              <Flex width="auto" mb="24px" flexDirection="column">
                <Ol>
                  <li>Go to your theme editor and select the page type you want to place the widget on.</li>
                  <li>Select ‘Add Section’ or ‘Add Block’ depending on where you want to place your Clipara widget.</li>
                  <li>Select Clipara Embed.</li>
                  <li>Click and drag the Section or Block to the positioning on the page you would like.</li>
                </Ol>
              </Flex>
            </Flex>
            <Flex flexDirection="column" alignItems="center" justifyContent="space-between">
              <img width="350px" alt="shopify embed" src="/illustrations/shopifyEmbed.gif" />
              <Flex mt="50px" justifyContent="flex-end" alignItems="flex-end">
                <Button
                  zIndex={1}
                  position="absolute"
                  bottom="24px"
                  right="24px"
                  variant="black"
                  label="Done"
                  onClick={() => setShowInstallEmbedModal(false)}
                />
              </Flex>
            </Flex>
            <Flex position="absolute" left="0px" bottom="24px" justifyContent="center">
              <Text textAlign="center" width="auto" cursor="pointer" underline onClick={openChat}>
                Need help? Contact Support.
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </ModalContainer>
  )
}

export default InstallEmbedModal
