import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, formatSeconds } from '../ui/helpers'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography'
import { Flex, Box, Container } from '../ui/Layout'
import Image from '../ui/Image'
import { Input } from '../ui/Input'
import Icon from '../ui/Icon'
import Button from '../ui/Button'
import { BigDarkSpinner } from '../ui/Spinner'
import SideNav, { TABS } from '../Components/SideNav'
import VideoPlayer from '../Components/VideoPlayer'
import ProcessingPreview from '../Components/ProcessingPreview'
import formikFormWrapper from '../formikFormWrapper'
import api from '../api'
import ChatButton from '../Components/ChatButton'
import { useAuth } from '../Auth'
import { useVideos } from '../VideoProvider'
import OptionsPopover from './OptionsPopover'
import ModalContainer from '../ui/ModalContainer'
import { uniq, difference } from 'ramda'
import { toast } from 'react-toastify'

const BigInput = styled.input`
  border-radius: 8px;
  width: 100%;
  background: ${(props) => {
    if (props.disabled) {
      return '#EDF2F7'
    }
    return props.dark ? '#EDF2F7' : '#ffffff'
  }};
  height: ${(props) => (props.large ? '40px' : '36px')};
  margin: 0px;
  margin-bottom: 8px;
  border-radius: 6px;
  color: white;
  display: inline-block;
  border: none;
  font-size: ${(props) => (props.large ? '15px' : '14px')};
  padding-left: 24px;
  padding-right: 24px;
  border: 1px solid rgba(166, 183, 212, 1);
  text-decoration: none;
  color: ${colors.textDark};
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  ::-webkit-input-placeholder {
    font-family: 'Inter', sans-serif;
  }
  ::-moz-placeholder {
    font-family: 'Inter', sans-serif;
  }
  :-ms-input-placeholder {
    font-family: 'Inter', sans-serif;
  }
  :-moz-placeholder {
    font-family: 'Inter', sans-serif;
  }
  &&:focus {
    padding-left: 22.5px;
    border: 1.5px solid #7895ff;
    box-shadow: 0px 0px 0px 2px rgba(76, 111, 255, 0.3);
  }
`

const FullWidthForm = styled.form`
  width: 100%;
  position: relative;
`

const TagsModal = ({ id, ids, inputTags, inputAllTags, isOpen, setIsOpen, refresh, isImages, setSelectedIds }) => {
  const [isAddingNewTag, setIsAddingNewTag] = useState(false)
  const [tags, setTags] = useState(ids ? [] : inputTags)
  const [newTagValue, setNewTagValue] = useState('')
  const inputRef = useRef(null)
  useEffect(() => {
    setTags(inputTags)
  }, [id])

  const save = async () => {
    let res
    if (id) {
      res = await api.post('/video/update-tags', { id, tags, isImages })
    } else {
      res = await api.post('/video/add-multi-tags', { ids, tags, isImages })
    }
    if (res.data.success) {
      toast('Tags saved')
      refresh()
      setSelectedIds && setSelectedIds([])
    } else {
      toast('Error saving tags')
      refresh()
    }
  }

  return (
    <ModalContainer interiorClose p="24px" width="700px" autoHeight isOpen={isOpen} setIsOpen={setIsOpen} onClose={save}>
      <Flex flexDirection="column">
        <H3 mb="0px">Tags</H3>
        <Text lightLight mb="16px" fontWeight="500">
          Select an option or create one
        </Text>
        <FullWidthForm
          onSubmit={(e) => {
            e.preventDefault()
            setTags(uniq(tags.concat(newTagValue.trim())))
            setNewTagValue('')
            setIsAddingNewTag(false)
          }}
        >
          <BigInput value={newTagValue} ref={inputRef} autoFocus onChange={(e) => setNewTagValue(e.target.value)} />
          <Box position="absolute" top="6px" right="12px">
            <Text fontSize="12px">Press "enter" to add</Text>
          </Box>
        </FullWidthForm>
        <Flex
          flexWrap="wrap"
          mb="16px"
          minHeight="100px"
          borderRadius="4px"
          border="1px solid rgba(166, 183, 212, 1)"
          p="8px"
          onClick={(e) => {
            e.stopPropagation()
            inputRef.current && inputRef.current.focus()
          }}
        >
          {tags.map((l) => (
            <Flex
              alignItems="center"
              bg="rgba(225, 232, 255, 1)"
              width="auto"
              height="28px"
              mr="8px"
              mb="8px"
              borderRadius="14px"
              px="16px"
              key={l}
              onClick={(e) => {
                e.stopPropagation()
                setTags(uniq(tags).filter((el) => el !== l))
              }}
              cursor="pointer"
            >
              <Text mb="2px" mr="8px" light fontWeight="500">
                {l}
              </Text>
              <Icon width="14px" height="14px" icon="circle-cross-grey" />
            </Flex>
          ))}
        </Flex>

        <Flex flexWrap="wrap" width="auto">
          {difference(inputAllTags, tags).map((l) => (
            <Flex
              alignItems="center"
              bg="rgba(225, 232, 255, 1)"
              width="auto"
              height="28px"
              mr="8px"
              mb="8px"
              borderRadius="14px"
              px="16px"
              key={l}
              onClick={() => {
                setTags(uniq(tags).concat(l))
              }}
              cursor="pointer"
            >
              <Text mb="2px" light fontWeight="500">
                {l}
              </Text>
            </Flex>
          ))}
        </Flex>
        <Flex justifyContent="flex-end" mt="16px">
          <Button
            zIndex={1}
            borderColor="white"
            variant="black"
            label="Save"
            width="100px"
            onClick={() => {
              save()
              setIsOpen(false)
            }}
          />
        </Flex>
      </Flex>
    </ModalContainer>
  )
}

export default TagsModal
