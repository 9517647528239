import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize } from '../../ui/helpers.js'
import { H1, H2, H3, H6, Label, Text, Span, GradientFont, Error, SmallTitle } from '../../ui/Typography.js'
import { Flex, Box, Container } from '../../ui/Layout.js'
import Image from '../../ui/Image.js'
import { Input, SideInput } from '../../ui/Input.js'
import Icon from '../../ui/Icon.js'
import CodeCopy from '../../ui/CodeCopy.js'
import Tooltip from '../../ui/Tooltip.js'
import VideoPreview from '../../ui/VideoPreview.js'
import WidgetPreview from '../../Components/WidgetPreview.js'
import CollapsibleSection from '../../Components/CollapsibleSection.js'
import Button from '../../ui/Button.js'
import { BigDarkSpinner } from '../../ui/Spinner.js'
import Toggle from '../../ui/Toggle.js'
import Select, { SideSelect } from '../../ui/Select.js'
import ColorPicker, { ColorPickerRow } from '../../ui/ColorPicker.js'
import formikFormWrapper from '../../formikFormWrapper.js'
import { Tabs } from '../CampaignEdit.js'
import ButtonStyle from './ButtonStyle.js'
import PlayButtonStyle from './PlayButtonStyle.js'
import PartialStart from './PartialStart.js'
import { toast } from 'react-toastify'
import { useModal } from '../../Modal.js'
import ToggleRedGreen from '../../ui/ToggleRedGreen.js'
import { uniq, difference } from 'ramda'

const FormInput = formikFormWrapper(Input)
const FormSideInput = formikFormWrapper(SideInput)

import api from '../../api.js'
import { useAuth } from '../../Auth.js'

import {
  STEPS,
  CAMPAIGN_RULE_TYPES,
  CAMPAIGN_STATUS,
  campaignRuleTypesOptions,
  WIDGET_POSITIONS,
  widgetPositionOptions,
  widgetRuleTypeOptions,
  WIDGET_TYPES,
  widgetAlignOptions,
  landscapePortraitOptions,
  widgetTextAlignOptions,
  embedWidgetSelectOptions,
  singleEmbedDimensionsOptions,
  SINGLE_EMBED_HEIGHT_TO_WIDTH,
  SINGLE_EMBED_WIDTH_TO_HEIGHT,
  MOBILE_DIMENSIONS,
  mobileDimensionsOptions,
  widgetTitleAlignmentOptions,
  titlePositionOptions,
  widgetThumbnailDimensionsOptions,
  widgetArrowPositionOptions,
  widgetThumbnailSizeOptions,
  widgetPlayButtonPositionOptions,
  WIDGET_THUMBNAIL_DIMENSIONS,
  TITLE_POSITION,
  widgetThumbnailDimensionsStoriesOptions,
  getSelectWidgetOptions,
  getSelectNavigationWidgetOptions,
  navigationTitlePositionOptions,
  widgetDesktopVideosInlineOptions,
  SEARCH_THUMBNAIL_SIZES,
  widgetSearchGridOffsetOptions,
  widgetSearchSearchableContentOptions,
  singleInlineDimensionsOptions
} from '../types'
import PopoverDropdown from '../../ui/PopoverDropdown.js'
import {
  ChangeWidget,
  WidgetAlignment,
  WidgetEmbedTitles,
  WidgetPadding,
  WidgetThumbnailBorder,
  WidgetTitles
} from './WidgetOptionsOptions.js'
import TripleToggle from '../../ui/TripleToggle.js'
import SearchSharpnessStyle from './SearchSharpnessStyle.js'
import CampaignEditTagsModal from './CampaignEditTagsModal.js'
import { useVideos } from '../../VideoProvider.js'

const WidgetOptions = ({
  setFieldValue,
  values,
  widget,
  selectedVideos,
  widgets,
  setStep,
  isMobile,
  setIsMobile,
  promotedTagsOpen,
  setPromotedTagsOpen,
  filterTagsOpen,
  setFilterTagsOpen,
  videos,
  images,
  refresh
}) => {
  ReactTooltip.rebuild()

  if (widget.type === WIDGET_TYPES.FLOATING) {
    const [openCollapsible, setOpenCollapsible] = useState()
    return (
      <>
        <ChangeWidget
          setFieldValue={setFieldValue}
          values={values}
          widget={widget}
          selectedVideos={selectedVideos}
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          widgets={widgets}
          setStep={setStep}
        />
        <Flex flexDirection="column" px="24px" pt="16px" borderBottom="1px solid rgba(237, 242, 247, 1)">
          <Field
            mb="16px"
            maxLength={65}
            name="widget.text"
            label="Widget Prompt"
            placeholder={`eg. ‘Hear from our team’…`}
            dark
            component={FormInput}
          />
        </Flex>
        <SideSelect
          label="Widget Position"
          width="100%"
          value={widget && widget.position}
          onChange={(option) => setFieldValue('widget.position', option.value)}
          options={widgetPositionOptions}
          placeholder="Select option"
        />
        <Flex flexDirection="column" px="24px" pt="16px">
          <Flex alignItems="center" mb="16px" justifyContent="space-between">
            <Label mb="0px" mr="16px">
              Border
            </Label>
            <ToggleRedGreen
              setSelected={(selected) => {
                setFieldValue('widget.border', selected)
              }}
              label="Border"
              value1={false}
              value2={true}
              selected={values.widget.border}
            />
          </Flex>
        </Flex>
        <Flex borderBottom="1px solid rgba(237, 242, 247, 1)">
          {values.widget.border ? (
            <ColorPickerRow
              label="Border color"
              setFieldValue={setFieldValue}
              name="widget.borderColor"
              value={values.widget.borderColor}
            />
          ) : null}
        </Flex>
      </>
    )
  }

  // STORY

  const isStories = widget.type === WIDGET_TYPES.STORIES
  if (isStories) {
    const [openCollapsible, setOpenCollapsible] = useState()
    return (
      <>
        <ChangeWidget
          setFieldValue={setFieldValue}
          values={values}
          widget={widget}
          selectedVideos={selectedVideos}
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          widgets={widgets}
          setStep={setStep}
        />
        <CollapsibleSection
          label="Layout"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="layout-white"
          noContentPadding
        >
          <Flex
            flexDirection="column"
            px="24px"
            pt="16px"
            borderTop="1px solid rgba(237, 242, 247, 1)"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
          >
            <Toggle
              label1="Responsive"
              label2="Fixed"
              value1={false}
              value2={true}
              selected={values.widget.widgetFixedThumbnail}
              setSelected={(value) => {
                setFieldValue(`widget.widgetFixedThumbnail`, value)
                if (value && values.widget.widgetThumbnailDimensions === WIDGET_THUMBNAIL_DIMENSIONS.CIRCLE) {
                  setFieldValue(`widget.borderRadius`, values.widget.widgetFixedThumbnailWidth / 2)
                } else {
                  setFieldValue(`widget.borderRadius`, 4)
                }
              }}
              width="175px"
              mb="16px"
            />
          </Flex>
          <SideSelect
            label="Shape"
            value={widget && widget.widgetThumbnailDimensions}
            onChange={(option) => setFieldValue('widget.widgetThumbnailDimensions', option.value)}
            options={widgetThumbnailDimensionsStoriesOptions}
            placeholder="Select option"
          />
          <Flex flexDirection="column" pt="16px">
            {!values.widget.widgetFixedThumbnail ? (
              <Field
                name="widget.desktopVideos"
                type="number"
                min="2"
                max="12"
                label="Stories per row"
                placeholder="3"
                dark
                component={FormSideInput}
              />
            ) : (
              <Field
                textPostFix="px"
                name="widget.widgetFixedThumbnailWidth"
                min="0"
                type="number"
                label="Width"
                dark
                component={FormSideInput}
              />
            )}
            <Field
              textPostFix="px"
              name="widget.widgetGapWidth"
              type="number"
              min="0"
              label="Spacing"
              dark
              component={FormSideInput}
            />
          </Flex>
          {values.widget.widgetThumbnailDimensions !== WIDGET_THUMBNAIL_DIMENSIONS.CIRCLE ? (
            <Field
              textPostFix="px"
              name="widget.borderRadius"
              type="number"
              min="0"
              label="Round Corners"
              placeholder="4"
              dark
              component={FormSideInput}
            />
          ) : null}

          {!values.widget.widgetFixedThumbnail ? (
            <SideSelect
              label="Mobile Size"
              value={widget && widget.widgetMobileThumbnailSize}
              onChange={(option) => setFieldValue('widget.widgetMobileThumbnailSize', option.value)}
              options={widgetThumbnailSizeOptions}
              placeholder="Select option"
            />
          ) : null}

          <Flex borderTop="1px solid rgba(237, 242, 247, 1)">
            <WidgetAlignment setFieldValue={setFieldValue} values={values} />
          </Flex>

          <WidgetThumbnailBorder setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>

        <CollapsibleSection
          label="Embed Title"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="text-white"
          noContentPadding
        >
          <WidgetEmbedTitles setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Content Titles"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="text-white"
          noContentPadding
        >
          <WidgetTitles isMobile={isMobile} setIsMobile={setIsMobile} setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Padding"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="arrow-bar-right-white"
          noContentPadding
        >
          <WidgetPadding setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
      </>
    )
  }

  // CAROUSEL

  if (widget.type === WIDGET_TYPES.CAROUSEL) {
    const [openCollapsible, setOpenCollapsible] = useState()
    return (
      <>
        <ChangeWidget
          setFieldValue={setFieldValue}
          values={values}
          widget={widget}
          selectedVideos={selectedVideos}
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          widgets={widgets}
          setStep={setStep}
        />
        <CollapsibleSection
          label="Layout"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="layout-white"
          noContentPadding
        >
          <Flex
            flexDirection="column"
            borderTop="1px solid rgba(237, 242, 247, 1)"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
            pt="16px"
          >
            <Field
              name="widget.desktopVideos"
              type="number"
              min="2"
              max="8"
              label="Thumbnails in view"
              placeholder="3"
              dark
              component={FormSideInput}
            />
          </Flex>

          <Flex flexDirection="column" borderBottom="1px solid rgba(237, 242, 247, 1)" pt="16px">
            <Field
              textPostFix="px"
              name="widget.widgetGapWidth"
              type="number"
              min="0"
              label="Spacing"
              dark
              component={FormSideInput}
            />
          </Flex>

          <WidgetAlignment setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Thumbnails"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="image-white"
          noContentPadding
        >
          <Flex
            flexDirection="column"
            px="24px"
            borderTop="1px solid rgba(237, 242, 247, 1)"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
          >
            <Flex py="16px" pb="16px" justifyContent="center">
              <Button
                renderCenterIcon={() => <Icon width={12} height={12} icon="desktop-black" />}
                variant={isMobile ? 'grey' : 'white'}
                shadow
                onClick={() => {
                  setIsMobile(false)
                }}
                width="52px"
                minWidth="52px"
                mr="8px"
              />
              <Button
                renderCenterIcon={() => <Icon width={12} height={12} icon="phone-black" />}
                variant={!isMobile ? 'grey' : 'white'}
                shadow
                onClick={() => {
                  setIsMobile(true)
                }}
                width="52px"
                minWidth="52px"
                mr="16px"
              />
            </Flex>
          </Flex>

          <Flex flexDirection="column">
            {!isMobile ? (
              <SideSelect
                label="Shape"
                value={widget && widget.widgetThumbnailDimensions}
                onChange={(option) => setFieldValue('widget.widgetThumbnailDimensions', option.value)}
                options={widgetThumbnailDimensionsOptions}
                placeholder="Select option"
              />
            ) : null}
            {isMobile ? (
              <SideSelect
                label="Shape"
                value={widget && widget.widgetMobileThumbnailDimensions}
                onChange={(option) => setFieldValue('widget.widgetMobileThumbnailDimensions', option.value)}
                options={widgetThumbnailDimensionsOptions}
                placeholder="Select option"
              />
            ) : null}
            {isMobile ? (
              <SideSelect
                label="Thumbnail Size"
                value={widget && widget.widgetMobileThumbnailSize}
                onChange={(option) => setFieldValue('widget.widgetMobileThumbnailSize', option.value)}
                options={widgetThumbnailSizeOptions}
                placeholder="Select option"
              />
            ) : null}
          </Flex>

          <Flex flexDirection="column" borderBottom="1px solid rgba(237, 242, 247, 1)" pt="16px">
            <Field
              textPostFix="px"
              name="widget.borderRadius"
              min="0"
              type="number"
              label="Round Corners"
              placeholder="4"
              dark
              component={FormSideInput}
            />
          </Flex>

          <Flex flexDirection="column" borderBottom="1px solid rgba(237, 242, 247, 1)">
            <WidgetThumbnailBorder setFieldValue={setFieldValue} values={values} />
          </Flex>

          <Flex pt="24px" pb="16px" px="24px" justifyContent="space-between" borderBottom="1px solid rgba(237, 242, 247, 1)">
            <Label mt="2px" mr="16px">
              Duration Display
            </Label>
            <ToggleRedGreen
              setSelected={(selected) => {
                setFieldValue('widget.durationDisplay', selected)
              }}
              value1={false}
              value2={true}
              selected={values.widget.durationDisplay}
            />
          </Flex>
          <Flex pt="24px" pb="16px" px="24px" justifyContent="space-between">
            <Label mt="2px" mr="16px">
              Thumbnail Autoplay
            </Label>
            <ToggleRedGreen
              setSelected={(selected) => {
                setFieldValue('widget.widgetAutoplay', selected)
              }}
              value1={false}
              value2={true}
              selected={values.widget.widgetAutoplay}
            />
          </Flex>
        </CollapsibleSection>
        <CollapsibleSection
          label="Play Button"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="play-square-white"
          noContentPadding
        >
          <PlayButtonStyle values={values} setFieldValue={setFieldValue} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Navigation"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="chevron-right-white"
          noContentPadding
        >
          <ButtonStyle values={values} setFieldValue={setFieldValue} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Embed Title"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="text-white"
          noContentPadding
        >
          <WidgetEmbedTitles setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Content Titles"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="text-white"
          noContentPadding
        >
          <WidgetTitles isMobile={isMobile} setIsMobile={setIsMobile} setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Padding"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="arrow-bar-right-white"
          noContentPadding
        >
          <WidgetPadding setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
      </>
    )
  }

  // SINGLE EMBED / INLINE_SINGLE

  if (widget.type === WIDGET_TYPES.SINGLE_EMBED) {
    const [openCollapsible, setOpenCollapsible] = useState()
    return (
      <>
        <ChangeWidget
          setFieldValue={setFieldValue}
          values={values}
          widget={widget}
          selectedVideos={selectedVideos}
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          widgets={widgets}
          setStep={setStep}
        />
        <CollapsibleSection
          label="Thumbnail"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="thumbnail-white"
          noContentPadding
        >
          <Flex borderTop="1px solid rgba(237, 242, 247, 1)">
            <SideSelect
              label="Desktop Shape"
              value={values.widget.dimensions}
              onChange={(option) => {
                setFieldValue(`widget.dimensions`, option.value)
                setFieldValue(`widget.height`, Math.round(SINGLE_EMBED_WIDTH_TO_HEIGHT[option.value](values.widget.maxWidth)))
              }}
              options={singleEmbedDimensionsOptions}
              placeholder="Select"
            />
          </Flex>

          <SideSelect
            label="Mobile Shape"
            value={values.widget.mobileDimensions}
            onChange={(option) => {
              setFieldValue(`widget.mobileDimensions`, option.value)
            }}
            options={mobileDimensionsOptions}
            placeholder="Select"
          />
          <Flex pt="16px" px="24px" borderBottom="1px solid rgba(237, 242, 247, 1)">
            <Toggle
              width="174px"
              label1="Fixed"
              label2="Responsive"
              value1={false}
              value2={true}
              selected={values.widget.responsive}
              setSelected={(option) => {
                console.log(option)
                setFieldValue(`widget.responsive`, option)
                if (!values.widget || !values.widget.maxWidth) {
                  setFieldValue(`widget.maxWidth`, 500)
                }
              }}
              mb="16px"
            />
          </Flex>
          {!values.widget.responsive ? (
            <>
              <Field
                mt="16px"
                mb="16px"
                textPostFix="px"
                name="widget.maxWidth"
                type="number"
                min="0"
                label="Width"
                dark
                onChange={(e) => {
                  setFieldValue(
                    `widget.height`,
                    Math.round(SINGLE_EMBED_WIDTH_TO_HEIGHT[values.widget.dimensions](e.target.value || 500))
                  )
                }}
                component={FormSideInput}
              />
              <Field
                mb="0px"
                textPostFix="px"
                name="widget.height"
                type="number"
                min="0"
                label="Height"
                dark
                onChange={(e) => {
                  setFieldValue(
                    `widget.maxWidth`,
                    Math.round(SINGLE_EMBED_HEIGHT_TO_WIDTH[values.widget.dimensions](e.target.value))
                  )
                }}
                component={FormSideInput}
              />
            </>
          ) : null}
          <Flex pt="16px" mb="16px" borderBottom="1px solid rgba(237, 242, 247, 1)">
            <Field
              textPostFix="px"
              name="widget.borderRadius"
              type="number"
              min="0"
              label="Round Corners"
              placeholder="4"
              dark
              component={FormSideInput}
            />
          </Flex>

          <ColorPickerRow
            label="Play Button color"
            setFieldValue={setFieldValue}
            name="widget.playButtonColor"
            value={values.widget.playButtonColor}
          />
          <Flex
            px="24px"
            py="24px"
            justifyContent="space-between"
            borderTop="1px solid rgba(237, 242, 247, 1)"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
            alignItems="center"
          >
            <Label mb="0px">Play Button Animation</Label>
            <ToggleRedGreen
              setSelected={(selected) => {
                setFieldValue('widget.playButtonAnimation', selected)
              }}
              value1={false}
              value2={true}
              selected={values.widget.playButtonAnimation}
            />
          </Flex>
          <Flex
            px="24px"
            py="24px"
            justifyContent="space-between"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
            alignItems="center"
          >
            <Label mb="0px">Thumbnail Autoplay</Label>
            <ToggleRedGreen
              setSelected={(selected) => {
                setFieldValue('widget.widgetAutoplay', selected)
              }}
              value1={false}
              value2={true}
              selected={values.widget.widgetAutoplay}
            />
          </Flex>
          <Flex
            px="24px"
            py="24px"
            justifyContent="space-between"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
            alignItems="center"
          >
            <Label mb="0px">Duration Display</Label>
            <ToggleRedGreen
              setSelected={(selected) => {
                setFieldValue('widget.durationDisplay', selected)
              }}
              value1={false}
              value2={true}
              selected={values.widget.durationDisplay}
            />
          </Flex>
          <Flex
            px="24px"
            py="24px"
            justifyContent="space-between"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
            alignItems="center"
          >
            <Label mb="0px">Hide on Desktop</Label>
            <ToggleRedGreen
              setSelected={(selected) => {
                setFieldValue('widget.widgetHideDesktop', selected)
              }}
              value1={false}
              value2={true}
              selected={values.widget.widgetHideDesktop}
            />
          </Flex>
        </CollapsibleSection>
        <CollapsibleSection
          label="Buttons"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="play-square-white"
          noContentPadding
        >
          <PlayButtonStyle values={values} setFieldValue={setFieldValue} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Embed Title"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="text-white"
          noContentPadding
        >
          <WidgetTitles isMobile={isMobile} setIsMobile={setIsMobile} setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>

        <CollapsibleSection
          label="Padding"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="arrow-bar-right-white"
          noContentPadding
        >
          <WidgetPadding setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
      </>
    )
  }

  //INLINE SINGLE
  if (widget.type === WIDGET_TYPES.INLINE_SINGLE) {
    const [openCollapsible, setOpenCollapsible] = useState()
    return (
      <>
        <ChangeWidget
          setFieldValue={setFieldValue}
          values={values}
          widget={widget}
          selectedVideos={selectedVideos}
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          widgets={widgets}
          setStep={setStep}
        />
        <CollapsibleSection
          label="Thumbnail"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="thumbnail-white"
          noContentPadding
        >
          <Flex borderTop="1px solid rgba(237, 242, 247, 1)">
            <SideSelect
              label="Desktop Shape"
              value={values.widget.widgetThumbnailDimensions}
              onChange={(option) => {
                setFieldValue(`widget.widgetThumbnailDimensions`, option.value)
                setFieldValue(`widget.height`, Math.round(SINGLE_EMBED_WIDTH_TO_HEIGHT[option.value](values.widget.maxWidth)))
              }}
              options={singleInlineDimensionsOptions}
              placeholder="Select"
            />
          </Flex>

          <SideSelect
            label="Mobile Shape"
            value={values.widget.widgetMobileThumbnailDimensions}
            onChange={(option) => {
              setFieldValue(`widget.widgetMobileThumbnailDimensions`, option.value)
            }}
            options={mobileDimensionsOptions}
            placeholder="Select"
          />
          <Flex pt="16px" px="24px" borderBottom="1px solid rgba(237, 242, 247, 1)">
            <Toggle
              width="174px"
              label1="Fixed"
              label2="Responsive"
              value1={false}
              value2={true}
              selected={values.widget.responsive}
              setSelected={(option) => {
                console.log(option)
                setFieldValue(`widget.responsive`, option)
                if (!values.widget || !values.widget.maxWidth) {
                  setFieldValue(`widget.maxWidth`, 500)
                }
              }}
              mb="16px"
            />
          </Flex>
          {!values.widget.responsive ? (
            <>
              <Field
                mt="16px"
                mb="16px"
                textPostFix="px"
                name="widget.maxWidth"
                type="number"
                min="0"
                label="Width"
                dark
                onChange={(e) => {
                  setFieldValue(
                    `widget.height`,
                    Math.round(SINGLE_EMBED_WIDTH_TO_HEIGHT[values.widget.dimensions](e.target.value || 500))
                  )
                }}
                component={FormSideInput}
              />
              <Field
                mb="0px"
                textPostFix="px"
                name="widget.height"
                type="number"
                min="0"
                label="Height"
                dark
                onChange={(e) => {
                  setFieldValue(
                    `widget.maxWidth`,
                    Math.round(SINGLE_EMBED_HEIGHT_TO_WIDTH[values.widget.dimensions](e.target.value))
                  )
                }}
                component={FormSideInput}
              />
            </>
          ) : null}
          <Flex pt="16px" mb="16px" borderBottom="1px solid rgba(237, 242, 247, 1)">
            <Field
              textPostFix="px"
              name="widget.borderRadius"
              type="number"
              min="0"
              label="Round Corners"
              placeholder="4"
              dark
              component={FormSideInput}
            />
          </Flex>

          <ColorPickerRow
            label="Play Button color"
            setFieldValue={setFieldValue}
            name="widget.playButtonColor"
            value={values.widget.playButtonColor}
          />
          <Flex
            px="24px"
            py="24px"
            justifyContent="space-between"
            borderTop="1px solid rgba(237, 242, 247, 1)"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
            alignItems="center"
          >
            <Label mb="0px">Play Button Animation</Label>
            <ToggleRedGreen
              setSelected={(selected) => {
                setFieldValue('widget.playButtonAnimation', selected)
              }}
              value1={false}
              value2={true}
              selected={values.widget.playButtonAnimation}
            />
          </Flex>
          <Flex
            px="24px"
            py="24px"
            justifyContent="space-between"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
            alignItems="center"
          >
            <Label mb="0px">Thumbnail Autoplay</Label>
            <ToggleRedGreen
              setSelected={(selected) => {
                setFieldValue('widget.widgetAutoplay', selected)
              }}
              value1={false}
              value2={true}
              selected={values.widget.widgetAutoplay}
            />
          </Flex>
          <Flex
            px="24px"
            py="24px"
            justifyContent="space-between"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
            alignItems="center"
          >
            <Label mb="0px">Duration Display</Label>
            <ToggleRedGreen
              setSelected={(selected) => {
                setFieldValue('widget.durationDisplay', selected)
              }}
              value1={false}
              value2={true}
              selected={values.widget.durationDisplay}
            />
          </Flex>
          <Flex
            px="24px"
            py="24px"
            justifyContent="space-between"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
            alignItems="center"
          >
            <Label mb="0px">Show Progress Bar</Label>
            <ToggleRedGreen
              setSelected={(selected) => {
                setFieldValue('widget.widgetShowProgressBar', selected)
              }}
              value1={false}
              value2={true}
              selected={values.widget.widgetShowProgressBar}
            />
          </Flex>
          <Flex
            px="24px"
            py="24px"
            justifyContent="space-between"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
            alignItems="center"
          >
            <Label mb="0px">Hide on Desktop</Label>
            <ToggleRedGreen
              setSelected={(selected) => {
                setFieldValue('widget.widgetHideDesktop', selected)
              }}
              value1={false}
              value2={true}
              selected={values.widget.widgetHideDesktop}
            />
          </Flex>
        </CollapsibleSection>
        <CollapsibleSection
          label="Buttons"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="play-square-white"
          noContentPadding
        >
          <PlayButtonStyle values={values} setFieldValue={setFieldValue} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Embed Title"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="text-white"
          noContentPadding
        >
          <WidgetTitles isMobile={isMobile} setIsMobile={setIsMobile} setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>

        <CollapsibleSection
          label="Padding"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="arrow-bar-right-white"
          noContentPadding
        >
          <WidgetPadding setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
      </>
    )
  }

  // INLINE CAROUSEL

  if (widget.type === WIDGET_TYPES.INLINE_CAROUSEL) {
    const [openCollapsible, setOpenCollapsible] = useState()

    return (
      <>
        <ChangeWidget
          setFieldValue={setFieldValue}
          values={values}
          widget={widget}
          selectedVideos={selectedVideos}
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          widgets={widgets}
          setStep={setStep}
        />
        <CollapsibleSection
          label="Layout"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="layout-white"
          noContentPadding
        >
          <Flex
            flexDirection="column"
            px="24px"
            borderTop="1px solid rgba(237, 242, 247, 1)"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
          >
            <Flex py="16px" justifyContent="center">
              <Button
                renderCenterIcon={() => <Icon width={12} height={12} icon="desktop-black" />}
                variant={isMobile ? 'grey' : 'white'}
                shadow
                onClick={() => {
                  setIsMobile(false)
                }}
                width="52px"
                minWidth="52px"
                mr="8px"
              />
              <Button
                renderCenterIcon={() => <Icon width={12} height={12} icon="phone-black" />}
                variant={!isMobile ? 'grey' : 'white'}
                shadow
                onClick={() => {
                  setIsMobile(true)
                }}
                width="52px"
                minWidth="52px"
                mr="16px"
              />
            </Flex>
            <PartialStart values={values} setFieldValue={setFieldValue} />
          </Flex>
          <Flex flexDirection="column" borderBottom="1px solid rgba(237, 242, 247, 1)">
            <Flex mb="16px">
              {!isMobile ? (
                <SideSelect
                  label="Thumbnails in View"
                  value={widget && widget.desktopVideos}
                  onChange={(option) => setFieldValue('widget.desktopVideos', option.value)}
                  options={widgetDesktopVideosInlineOptions}
                  placeholder="Select option"
                />
              ) : null}
            </Flex>

            <Field
              textPostFix="px"
              name="widget.borderRadius"
              min="0"
              type="number"
              label="Round Corners"
              placeholder="4"
              dark
              component={FormSideInput}
            />
            {!isMobile ? (
              <Field
                textPostFix="px"
                name="widget.widgetGapWidth"
                type="number"
                min="0"
                label="Spacing"
                dark
                component={FormSideInput}
              />
            ) : null}
          </Flex>
          <Flex pt="24px" pb="16px" justifyContent="space-between" px="24px" borderBottom="1px solid rgba(237, 242, 247, 1)">
            <Label mt="2px" mr="16px">
              Stretch Width to Fill
            </Label>
            <ToggleRedGreen
              setSelected={(selected) => {
                setFieldValue('widget.widgetNoMaxThumbnailWidth', selected)
              }}
              value1={false}
              value2={true}
              selected={values.widget.widgetNoMaxThumbnailWidth}
            />
          </Flex>
          <WidgetAlignment setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Thumbnails"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="image-white"
          noContentPadding
        >
          <Flex py="16px" justifyContent="center" borderBottom="1px solid rgba(237, 242, 247, 1)">
            <Button
              renderCenterIcon={() => <Icon width={12} height={12} icon="desktop-black" />}
              variant={isMobile ? 'grey' : 'white'}
              shadow
              onClick={() => {
                setIsMobile(false)
              }}
              width="52px"
              minWidth="52px"
              mr="8px"
            />
            <Button
              renderCenterIcon={() => <Icon width={12} height={12} icon="phone-black" />}
              variant={!isMobile ? 'grey' : 'white'}
              shadow
              onClick={() => {
                setIsMobile(true)
              }}
              width="52px"
              minWidth="52px"
              mr="16px"
            />
          </Flex>
          {!isMobile ? (
            <SideSelect
              label="Thumbnail Shape"
              value={widget && widget.widgetThumbnailDimensions}
              onChange={(option) => setFieldValue('widget.widgetThumbnailDimensions', option.value)}
              options={widgetThumbnailDimensionsOptions}
              placeholder="Select option"
            />
          ) : null}
          {isMobile ? (
            <SideSelect
              label="Thumbnail Shape"
              value={widget && widget.widgetMobileThumbnailDimensions}
              onChange={(option) => setFieldValue('widget.widgetMobileThumbnailDimensions', option.value)}
              options={widgetThumbnailDimensionsOptions}
              placeholder="Select option"
            />
          ) : null}

          {!isMobile ? (
            !values.widget.widgetNoMaxThumbnailWidth ? (
              <Flex flexDirection="column" borderBottom="1px solid rgba(237, 242, 247, 1)" pt="16px">
                <Field
                  textPostFix="px"
                  name="widget.widgetMaxThumbnailWidth"
                  min="100"
                  type="number"
                  label="Max Width"
                  placeholder="400"
                  dark
                  component={FormSideInput}
                />
              </Flex>
            ) : null
          ) : null}
          <WidgetThumbnailBorder setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Play Button"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="play-square-white"
          noContentPadding
        >
          <PlayButtonStyle hidePosition values={values} setFieldValue={setFieldValue} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Navigation"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="chevron-right-white"
          noContentPadding
        >
          <ButtonStyle values={values} setFieldValue={setFieldValue} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Embed Title"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="text-white"
          noContentPadding
        >
          <WidgetEmbedTitles setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Content Titles"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="text-white"
          noContentPadding
        >
          <WidgetTitles isMobile={isMobile} setIsMobile={setIsMobile} setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Padding"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="arrow-bar-right-white"
          noContentPadding
        >
          <WidgetPadding setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
      </>
    )
  }

  // NAVIGATION CAROUSEL

  if (widget.type === WIDGET_TYPES.NAVIGATION_CAROUSEL) {
    const [openCollapsible, setOpenCollapsible] = useState()

    return (
      <>
        <ChangeWidget
          setFieldValue={setFieldValue}
          values={values}
          widget={widget}
          selectedVideos={selectedVideos}
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          widgets={widgets}
          setStep={setStep}
        />
        <CollapsibleSection
          label="Layout"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="layout-white"
          noContentPadding
        >
          <Flex
            flexDirection="column"
            px="24px"
            borderTop="1px solid rgba(237, 242, 247, 1)"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
          >
            <Flex py="16px" justifyContent="center">
              <Button
                renderCenterIcon={() => <Icon width={12} height={12} icon="desktop-black" />}
                variant={isMobile ? 'grey' : 'white'}
                shadow
                onClick={() => {
                  setIsMobile(false)
                }}
                width="52px"
                minWidth="52px"
                mr="8px"
              />
              <Button
                renderCenterIcon={() => <Icon width={12} height={12} icon="phone-black" />}
                variant={!isMobile ? 'grey' : 'white'}
                shadow
                onClick={() => {
                  setIsMobile(true)
                }}
                width="52px"
                minWidth="52px"
                mr="16px"
              />
            </Flex>
            <PartialStart values={values} setFieldValue={setFieldValue} />
          </Flex>
          <Flex flexDirection="column" pt="16px" borderBottom="1px solid rgba(237, 242, 247, 1)">
            {!isMobile ? (
              <Field
                name="widget.desktopVideos"
                type="number"
                min="2"
                max="8"
                label="Thumbnails in View"
                placeholder="3"
                dark
                component={FormSideInput}
              />
            ) : null}

            <Field
              textPostFix="px"
              name="widget.borderRadius"
              min="0"
              type="number"
              label="Round Corners"
              placeholder="4"
              dark
              component={FormSideInput}
            />
            {!isMobile ? (
              <Field
                textPostFix="px"
                name="widget.widgetGapWidth"
                type="number"
                min="0"
                label="Spacing"
                dark
                component={FormSideInput}
              />
            ) : null}
          </Flex>

          <WidgetAlignment setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Thumbnails"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="image-white"
          noContentPadding
        >
          <Flex py="16px" justifyContent="center" borderBottom="1px solid rgba(237, 242, 247, 1)">
            <Button
              renderCenterIcon={() => <Icon width={12} height={12} icon="desktop-black" />}
              variant={isMobile ? 'grey' : 'white'}
              shadow
              onClick={() => {
                setIsMobile(false)
              }}
              width="52px"
              minWidth="52px"
              mr="8px"
            />
            <Button
              renderCenterIcon={() => <Icon width={12} height={12} icon="phone-black" />}
              variant={!isMobile ? 'grey' : 'white'}
              shadow
              onClick={() => {
                setIsMobile(true)
              }}
              width="52px"
              minWidth="52px"
              mr="16px"
            />
          </Flex>
          {!isMobile ? (
            <SideSelect
              label="Thumbnail Shape"
              value={widget && widget.widgetThumbnailDimensions}
              onChange={(option) => setFieldValue('widget.widgetThumbnailDimensions', option.value)}
              options={widgetThumbnailDimensionsOptions}
              placeholder="Select option"
            />
          ) : null}
          {isMobile ? (
            <SideSelect
              label="Thumbnail Shape"
              value={widget && widget.widgetMobileThumbnailDimensions}
              onChange={(option) => setFieldValue('widget.widgetMobileThumbnailDimensions', option.value)}
              options={widgetThumbnailDimensionsOptions}
              placeholder="Select option"
            />
          ) : null}
          {isMobile ? (
            <SideSelect
              label="Thumbnail Size"
              value={widget && widget.widgetMobileThumbnailSize}
              onChange={(option) => setFieldValue('widget.widgetMobileThumbnailSize', option.value)}
              options={widgetThumbnailSizeOptions}
              placeholder="Select option"
            />
          ) : null}

          <WidgetThumbnailBorder setFieldValue={setFieldValue} values={values} />
          <Flex pt="24px" pb="16px" px="24px" justifyContent="space-between" borderTop="1px solid rgba(237, 242, 247, 1)">
            <Label mt="2px" mr="16px">
              Autoplay
            </Label>
            <ToggleRedGreen
              setSelected={(selected) => {
                setFieldValue('widget.widgetAutoplay', selected)
              }}
              value1={false}
              value2={true}
              selected={values.widget.widgetAutoplay}
            />
          </Flex>
        </CollapsibleSection>
        <CollapsibleSection
          label="Buttons"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="play-square-white"
          noContentPadding
        >
          <ButtonStyle values={values} setFieldValue={setFieldValue} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Embed Title"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="text-white"
          noContentPadding
        >
          <WidgetEmbedTitles setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Content Titles"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="text-white"
          noContentPadding
        >
          <WidgetTitles isMobile={isMobile} setIsMobile={setIsMobile} setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Padding"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="arrow-bar-right-white"
          noContentPadding
        >
          <WidgetPadding setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
      </>
    )
  }

  // NAVIGATION GRID

  if (widget.type === WIDGET_TYPES.NAVIGATION_GRID || widget.type === WIDGET_TYPES.GRID) {
    const [openCollapsible, setOpenCollapsible] = useState()
    return (
      <>
        <ChangeWidget
          setFieldValue={setFieldValue}
          values={values}
          widget={widget}
          selectedVideos={selectedVideos}
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          widgets={widgets}
          setStep={setStep}
        />
        <CollapsibleSection
          label="Layout"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="layout-white"
          noContentPadding
        >
          <Flex flexDirection="column" borderTop="1px solid rgba(237, 242, 247, 1)" pt="16px">
            <Field
              name="widget.desktopVideos"
              type="number"
              min="2"
              max="8"
              label="Desktop Thumbnails"
              placeholder="3"
              dark
              component={FormSideInput}
            />
            <Field
              name="widget.mobileVideos"
              type="number"
              min="1"
              max="2"
              label="Mobile Thumbnails"
              dark
              component={FormSideInput}
            />
          </Flex>
          <Flex
            flexDirection="column"
            borderTop="1px solid rgba(237, 242, 247, 1)"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
            pt="16px"
          >
            <Field
              textPostFix="px"
              name="widget.widgetGapWidth"
              type="number"
              min="0"
              label="Spacing"
              dark
              component={FormSideInput}
            />
          </Flex>
          <WidgetAlignment setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Thumbnails"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="image-white"
          noContentPadding
        >
          <Flex flexDirection="column" borderTop="1px solid rgba(237, 242, 247, 1)">
            <SideSelect
              label="Desktop Shape"
              value={widget && widget.widgetThumbnailDimensions}
              onChange={(option) => setFieldValue('widget.widgetThumbnailDimensions', option.value)}
              options={widgetThumbnailDimensionsOptions}
              placeholder="Select option"
            />
            <SideSelect
              label="Mobile Shape"
              value={widget && widget.widgetMobileThumbnailDimensions}
              onChange={(option) => setFieldValue('widget.widgetMobileThumbnailDimensions', option.value)}
              options={widgetThumbnailDimensionsOptions}
              placeholder="Select option"
            />
          </Flex>
          <Flex flexDirection="column" borderBottom="1px solid rgba(237, 242, 247, 1)" pt="16px">
            <Field
              textPostFix="px"
              name="widget.borderRadius"
              min="0"
              type="number"
              label="Round Corners"
              placeholder="4"
              dark
              component={FormSideInput}
            />
          </Flex>

          <WidgetThumbnailBorder setFieldValue={setFieldValue} values={values} />
          <Flex pt="24px" pb="16px" px="24px" justifyContent="space-between" borderTop="1px solid rgba(237, 242, 247, 1)">
            <Label mt="2px" mr="16px">
              Autoplay
            </Label>
            <ToggleRedGreen
              setSelected={(selected) => {
                setFieldValue('widget.widgetAutoplay', selected)
              }}
              value1={false}
              value2={true}
              selected={values.widget.widgetAutoplay}
            />
          </Flex>

          {values.isDownloadCampaign ? (
            <>
              <Flex flexDirection="column" pt="24px" pb="16px" px="24px" borderTop="1px solid rgba(237, 242, 247, 1)">
                <Flex justifyContent="space-between" mb="8px">
                  <Label mt="2px" mr="16px">
                    Flag popular content
                  </Label>
                  <ToggleRedGreen
                    setSelected={(selected) => {
                      setFieldValue('widget.widgetSearchShowPopular', selected)
                    }}
                    value1={false}
                    value2={true}
                    selected={values.widget.widgetSearchShowPopular}
                  />
                </Flex>
                <Text fontWeight="400" lightLight>
                  Popular labels will show on the most downloaded videos in the past 7 days.
                </Text>
              </Flex>
              {values.widget.widgetSearchShowPopular ? (
                <>
                  <Flex flexDirection="column" borderTop="1px solid rgba(237, 242, 247, 1)" pt="16px" px="24px">
                    <Field mb="24px" name="widget.widgetSearchPopularText" label="Text" dark component={FormInput} />
                  </Flex>
                  <Flex borderTop="1px solid rgba(237, 242, 247, 1)" pt="16px" flexDirection="column">
                    <ColorPickerRow
                      label="Label Color"
                      name="widget.widgetSearchPopularTextColor"
                      setFieldValue={setFieldValue}
                      value={values.widget.widgetSearchPopularTextColor}
                    />
                    <ColorPickerRow
                      label="Button Color"
                      name="widget.widgetSearchPopularButtonColor"
                      setFieldValue={setFieldValue}
                      value={values.widget.widgetSearchPopularButtonColor}
                    />
                  </Flex>
                </>
              ) : null}
            </>
          ) : null}
        </CollapsibleSection>
        {widget.type === WIDGET_TYPES.GRID ? (
          <CollapsibleSection
            label="Play Button"
            openCollapsible={openCollapsible}
            setOpenCollapsible={setOpenCollapsible}
            icon="play-square-white"
            noContentPadding
          >
            <PlayButtonStyle values={values} setFieldValue={setFieldValue} />
          </CollapsibleSection>
        ) : null}

        <CollapsibleSection
          label="Embed Title"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="text-white"
          noContentPadding
        >
          <WidgetEmbedTitles setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Content Titles"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="text-white"
          noContentPadding
        >
          <WidgetTitles isMobile={isMobile} setIsMobile={setIsMobile} setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
        <CollapsibleSection
          label="Padding"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="arrow-bar-right-white"
          noContentPadding
        >
          <WidgetPadding setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
      </>
    )
  }

  // SEARCH

  if (widget.type === WIDGET_TYPES.SEARCH) {
    const [openCollapsible, setOpenCollapsible] = useState()
    let inputAllTags = []
    videos.forEach((v) => (inputAllTags = inputAllTags.concat(v.tagsArray)))
    images.forEach((v) => (inputAllTags = inputAllTags.concat(v.tagsArray)))
    inputAllTags = uniq(inputAllTags.filter((el) => el))

    return (
      <>
        <CollapsibleSection
          label="Search"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="search-white"
          iconBgColor="purple"
          noContentPadding
        >
          <Flex flexDirection="column" borderTop="1px solid rgba(237, 242, 247, 1)" pt="24px" px="24px">
            <Flex pb="16px" justifyContent="space-between">
              <Label mt="2px" mr="16px">
                Include whole library
              </Label>
              <ToggleRedGreen
                setSelected={(selected) => {
                  setFieldValue('searchUseFilterTags', !selected)
                }}
                value1={false}
                value2={true}
                selected={!values.searchUseFilterTags}
              />
            </Flex>
            {values.searchUseFilterTags ? (
              <>
                <Label mr="16px">Content Selection</Label>
                <Text mb="16px" fontSize="11px" lineHeight="12px" fontWeight="400" lightLight>
                  Only include content with these tags:
                </Text>
                <Flex flexWrap="wrap">
                  {(values.searchFilterTags || []).map((tag) => {
                    return (
                      <Flex
                        alignItems="center"
                        bg="rgba(225, 232, 255, 1)"
                        width="auto"
                        height="28px"
                        mr="8px"
                        mb="8px"
                        borderRadius="14px"
                        px="16px"
                        key={tag}
                        onClick={() => setFilterTagsOpen(true)}
                        cursor="pointer"
                      >
                        <Text mb="2px" light fontWeight="500">
                          {tag}
                        </Text>
                      </Flex>
                    )
                  })}
                </Flex>
                <Text onClick={() => setFilterTagsOpen(true)} light cursor="pointer" mb="16px" mt="8px" fontWeight="400">
                  + Select a tag
                </Text>
              </>
            ) : null}
            <CampaignEditTagsModal
              header="Content Selection"
              subHeader="Select tags from below. Only content with these tags will appear."
              saveTags={(tags) => setFieldValue('searchFilterTags', tags)}
              inputTags={values.searchFilterTags || []}
              inputAllTags={inputAllTags || []}
              isOpen={filterTagsOpen}
              setIsOpen={setFilterTagsOpen}
              refresh={refresh}
            />
          </Flex>
          <Flex
            flexDirection="column"
            borderTop="1px solid rgba(237, 242, 247, 1)"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
            pt="16px"
            px="24px"
          >
            <Flex>
              <Label mb="16px" mr="16px">
                Promoted Tags
              </Label>
              <Tooltip tooltip={'Without promoted tags, tags will be shown based on most common tags.'} />
            </Flex>
            <Flex flexWrap="wrap">
              {(values.searchPromotedTags || []).map((tag) => {
                return (
                  <Flex
                    alignItems="center"
                    bg="rgba(225, 232, 255, 1)"
                    width="auto"
                    height="28px"
                    mr="8px"
                    mb="8px"
                    borderRadius="14px"
                    px="16px"
                    key={tag}
                    onClick={() => setPromotedTagsOpen(true)}
                    cursor="pointer"
                  >
                    <Text mb="2px" light fontWeight="500">
                      {tag}
                    </Text>
                  </Flex>
                )
              })}
            </Flex>
            <Text onClick={() => setPromotedTagsOpen(true)} light cursor="pointer" mb="16px" mt="8px" fontWeight="400">
              + Select a promoted tag
            </Text>
            <CampaignEditTagsModal
              header="Promoted Tags"
              subHeader="Select tags to promote in search"
              saveTags={(tags) => setFieldValue('searchPromotedTags', tags)}
              inputTags={values.searchPromotedTags || []}
              inputAllTags={inputAllTags || []}
              isOpen={promotedTagsOpen}
              setIsOpen={setPromotedTagsOpen}
              refresh={refresh}
              hasReorder
            />
          </Flex>
          <Flex pt="24px" pb="16px" justifyContent="space-between" px="24px">
            <Label mt="2px" mr="16px">
              Show search bar
            </Label>
            <ToggleRedGreen
              setSelected={(selected) => {
                setFieldValue('widget.widgetSearchShowSearchBar', selected)
              }}
              value1={false}
              value2={true}
              selected={values.widget.widgetSearchShowSearchBar}
            />
          </Flex>
          <Flex justifyContent="space-between" borderTop="1px solid rgba(237, 242, 247, 1)">
            <SideSelect
              label="Searchable content"
              value={widget && widget.widgetSearchSearchableContent}
              onChange={(option) => setFieldValue('widget.widgetSearchSearchableContent', option.value)}
              options={widgetSearchSearchableContentOptions}
              placeholder="Select option"
            />
          </Flex>
          {values.widget.widgetSearchShowSearchBar ? (
            <Flex pt="24px" pb="16px" justifyContent="space-between" px="24px">
              <Label mt="2px" mr="16px">
                Search content names
              </Label>
              <ToggleRedGreen
                setSelected={(selected) => {
                  setFieldValue('widget.widgetSearchSearchVideoNames', selected)
                }}
                value1={false}
                value2={true}
                selected={values.widget.widgetSearchSearchVideoNames}
              />
            </Flex>
          ) : null}
          <Flex flexDirection="column" pt="24px" pb="16px" px="24px" borderTop="1px solid rgba(237, 242, 247, 1)">
            <Flex justifyContent="space-between" mb="8px">
              <Label mt="2px" mr="16px">
                Flag popular content
              </Label>
              <ToggleRedGreen
                setSelected={(selected) => {
                  setFieldValue('widget.widgetSearchShowPopular', selected)
                }}
                value1={false}
                value2={true}
                selected={values.widget.widgetSearchShowPopular}
              />
            </Flex>
            <Text fontWeight="400" lightLight>
              Popular labels will show on the most downloaded videos in the past 7 days.
            </Text>
          </Flex>
          {values.widget.widgetSearchShowPopular ? (
            <>
              <Flex flexDirection="column" borderTop="1px solid rgba(237, 242, 247, 1)" pt="16px" px="24px">
                <Field mb="24px" name="widget.widgetSearchPopularText" label="Text" dark component={FormInput} />
              </Flex>
              <Flex borderTop="1px solid rgba(237, 242, 247, 1)" pt="16px" flexDirection="column">
                <ColorPickerRow
                  label="Label Color"
                  name="widget.widgetSearchPopularTextColor"
                  setFieldValue={setFieldValue}
                  value={values.widget.widgetSearchPopularTextColor}
                />
                <ColorPickerRow
                  label="Button Color"
                  name="widget.widgetSearchPopularButtonColor"
                  setFieldValue={setFieldValue}
                  value={values.widget.widgetSearchPopularButtonColor}
                />
              </Flex>
            </>
          ) : null}
        </CollapsibleSection>
        <CollapsibleSection
          label="Layout"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="layout-white"
          noContentPadding
        >
          <SearchSharpnessStyle setFieldValue={setFieldValue} values={values} />
          <Flex borderTop="1px solid rgba(237, 242, 247, 1)">
            <SideSelect
              label="Grid"
              value={widget && widget.widgetSearchGridOffset}
              onChange={(option) => setFieldValue('widget.widgetSearchGridOffset', option.value)}
              options={widgetSearchGridOffsetOptions}
              placeholder="Select option"
            />
          </Flex>

          <Flex flexDirection="column" pt="16px" px="24px">
            <Label mb="24px">Theme Colors</Label>
          </Flex>
          <ColorPickerRow label="Primary" name="buttonColor" setFieldValue={setFieldValue} value={values.buttonColor} />
          <ColorPickerRow label="Secondary" name="buttonFontColor" setFieldValue={setFieldValue} value={values.buttonFontColor} />
          <Flex flexDirection="column" borderTop="1px solid rgba(237, 242, 247, 1)" pt="16px">
            <ColorPickerRow
              label="Overlay background color"
              name="widget.widgetSearchOverlayBackgroundColor"
              setFieldValue={setFieldValue}
              value={values.widget.widgetSearchOverlayBackgroundColor}
            />
          </Flex>
        </CollapsibleSection>
        <CollapsibleSection
          label="Thumbnails"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="image-white"
          noContentPadding
        >
          <Flex
            flexDirection="column"
            borderTop="1px solid rgba(237, 242, 247, 1)"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
            py="16px"
            px="24px"
          >
            <Label>Size</Label>
            <TripleToggle
              width="116px"
              label1="Small"
              label2="Medium"
              label3="Large"
              value1={SEARCH_THUMBNAIL_SIZES.SMALL}
              value2={SEARCH_THUMBNAIL_SIZES.MEDIUM}
              value3={SEARCH_THUMBNAIL_SIZES.LARGE}
              setSelected={(selected) => {
                setFieldValue('widget.widgetSearchThumbnailSize', selected)
              }}
              label="Size"
              selected={values.widget.widgetSearchThumbnailSize}
            />
          </Flex>

          <Flex flexDirection="column" borderBottom="1px solid rgba(237, 242, 247, 1)" pt="16px">
            <Field
              textPostFix="px"
              name="widget.borderRadius"
              min="0"
              type="number"
              label="Round Corners"
              placeholder="4"
              dark
              component={FormSideInput}
            />
          </Flex>

          <WidgetThumbnailBorder setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>

        <CollapsibleSection
          label="Padding"
          openCollapsible={openCollapsible}
          setOpenCollapsible={setOpenCollapsible}
          icon="arrow-bar-right-white"
          noContentPadding
        >
          <WidgetPadding setFieldValue={setFieldValue} values={values} />
        </CollapsibleSection>
      </>
    )
  }

  return <p></p>
}

export default WidgetOptions
