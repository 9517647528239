import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize } from '../../ui/helpers.js'
import { H1, H2, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../../ui/Typography.js'
import { Flex, Box, Container } from '../../ui/Layout.js'
import Image from '../../ui/Image.js'
import { Input, TextArea } from '../../ui/Input.js'
import Icon from '../../ui/Icon.js'
import CodeCopy from '../../ui/CodeCopy.js'
import VideoPreview from '../../ui/VideoPreview.js'
import CarouselPreview from '../../Components/CarouselPreview.js'

import WidgetPreview from '../../Components/WidgetPreview.js'
import Button from '../../ui/Button.js'
import { BigDarkSpinner } from '../../ui/Spinner.js'
import Toggle from '../../ui/Toggle.js'
import Select from '../../ui/Select.js'
import ColorPicker from '../../ui/ColorPicker.js'
import formikFormWrapper from '../../formikFormWrapper.js'
import { toast } from 'react-toastify'
import { useModal } from '../../Modal.js'
import ModalContainer from '../../ui/ModalContainer.js'
import ChatButton from '../../Components/ChatButton.js'
import Tooltip from '../../ui/Tooltip.js'

const FormInput = formikFormWrapper(Input)

import api from '../../api.js'
import { useAuth } from '../../Auth.js'

import {
  STEPS,
  WIDGET_TYPES,
  WIDGET_THUMBNAIL_DIMENSIONS,
  TITLE_POSITION,
  TITLE_ALIGNMENT,
  WIDGET_THUMBNAIL_SIZE
} from '../types.js'
import { isScalePlan } from '../../Plans.js'

const ChoiceContainer = styled(Container)`
  ${(props) => (props.disabled ? 'background: rgba(250, 250, 250, 1);cursor: initial !important;' : '')}
  padding: 8px;
  cursor: pointer;
  width: 30%;
  max-width: 350px;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
  &&:hover {
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.2), 0px 4px 20px -2px rgba(50, 50, 71, 0.2);
  }
`

const InnerContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  height: 135px;
  background: linear-gradient(225deg, #f1f1f5 0%, #e4ecf7 100%);
  margin-bottom: 24px;
  position: relative;
`

const UpgradeContainer = styled(Flex)`
  background: linear-gradient(225deg, #bb65ff 0%, #4c6fff 100%);
  height: 37px;
  box-sizing: border-box;
  align-items: center;
  border-radius: 8px;
  width: 90px;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 8px;
`

const DownloadType = ({
  step,
  setStep,
  values,
  selectedVideos,
  openFullscreenDesktopPreview,
  openFullscreenMobilePreview,
  videoObjects,
  setFieldValue,
  widgets,
  submit
}) => {
  const { user } = useAuth()
  const [showSearchModal, setShowSearchModal] = useState(false)

  return (
    <Flex height="100vh" flexDirection="column" alignItems="center">
      <Flex onClick={() => setStep(STEPS.SHOPPABLE_SELECT)} cursor="pointer" position="fixed" left="24px" top="24px" width="auto">
        <Icon mt="4px" mr="16px" icon="chevron-left-lightlight" height="16px" width="16px" />
        <Text lightLight fontSize="14px" mb="30px" textAlign="left">
          Back to Campaign
        </Text>
      </Flex>
      <ChatButton topRightFixed />

      <H1 fontSize="28px" mt="72px" mb="72px" textAlign="left">
        How do you want to display your campaign?
      </H1>
      <Flex justifyContent="center" px="50px" mb="32px">
        <ChoiceContainer
          onClick={() => {
            setStep(STEPS.SELECT_VIDEO, { isNew: true })
          }}
        >
          <InnerContainer>
            <svg width="179" height="100" viewBox="0 0 179 100" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_6573_77878)">
                <rect x="92.9521" y="13.1953" width="53.1712" height="73.6098" rx="3" stroke="#505780" stroke-width="2" />
                <rect x="32.8767" y="13.1953" width="53.1712" height="73.6098" rx="3" stroke="#505780" stroke-width="2" />
                <rect x="-27.1987" y="13.1953" width="53.1712" height="73.6098" rx="3" stroke="#505780" stroke-width="2" />
                <rect x="153.027" y="13.1953" width="53.1712" height="73.6098" rx="3" stroke="#505780" stroke-width="2" />
                <path
                  opacity="0.9"
                  d="M66.3866 48.6608C67.7214 49.4303 67.7214 51.3566 66.3866 52.1261L57.0144 57.5294C55.681 58.298 54.0154 57.3357 54.0154 55.7967V44.9902C54.0154 43.4512 55.681 42.4888 57.0143 43.2575L66.3866 48.6608Z"
                  fill="#505780"
                />
                <path
                  opacity="0.9"
                  d="M126.316 48.6608C127.651 49.4303 127.651 51.3566 126.316 52.1261L116.944 57.5294C115.61 58.298 113.945 57.3357 113.945 55.7967V44.9902C113.945 43.4512 115.61 42.4888 116.944 43.2575L126.316 48.6608Z"
                  fill="#505780"
                />
              </g>
              <defs>
                <clipPath id="clip0_6573_77878">
                  <rect width="179" height="100" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </InnerContainer>
          <H3 fontSize="16px" mb="16px">
            Collection
          </H3>
        </ChoiceContainer>
        <ChoiceContainer
          onClick={() => {
            setShowSearchModal(true)
          }}
        >
          <InnerContainer>
            <svg width="185" height="40" viewBox="0 0 185 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="1" y="1" width="183" height="38" rx="19" stroke="#505780" stroke-width="2" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24.1562 26.75C28.9024 26.75 32.75 22.9024 32.75 18.1562C32.75 13.4101 28.9024 9.5625 24.1562 9.5625C19.4101 9.5625 15.5625 13.4101 15.5625 18.1562C15.5625 22.9024 19.4101 26.75 24.1562 26.75ZM34.3125 18.1562C34.3125 23.7654 29.7654 28.3125 24.1562 28.3125C18.5471 28.3125 14 23.7654 14 18.1562C14 12.5471 18.5471 8 24.1562 8C29.7654 8 34.3125 12.5471 34.3125 18.1562Z"
                fill="#505780"
              />
              <path
                d="M30.1623 26.3471C30.2084 26.4096 30.2597 26.4694 30.3163 26.526L36.3327 32.5424C36.9428 33.1526 37.9322 33.1526 38.5424 32.5424C39.1526 31.9322 39.1526 30.9428 38.5424 30.3327L32.526 24.3163C32.4694 24.2597 32.4096 24.2084 32.3471 24.1623C31.7342 24.9968 30.9968 25.7342 30.1623 26.3471Z"
                fill="#505780"
              />
            </svg>
          </InnerContainer>
          <H3 fontSize="16px" mb="16px">
            Search
          </H3>
        </ChoiceContainer>
      </Flex>
      <ModalContainer p="0px" width="800px" height="500px" isOpen={showSearchModal} setIsOpen={setShowSearchModal}>
        <Flex height="100%">
          <Flex flexDirection="column" alignItems="left" p="24px" pt="40px" width="284px" minWidth="284px">
            <Text fontWeight="900" fontSize="24px" mb="16px">
              New: Search
            </Text>
            <Text fontWeight="400" fontSize="14px" lineHeight="23px" mb="16px">
              Embed your entire library into your site in one go with the new search embed.
            </Text>
            <Text fontWeight="400" fontSize="14px" lineHeight="23px" mb="16px">
              Add tags to the content in your library to create your own browsable experience.
            </Text>
            <Text fontWeight="400" fontSize="14px" lineHeight="23px" mb="24px">
              Check out the full guide{' '}
              <Span underline>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://clipara.notion.site/Clipara-Search-Widget-New-669ea2432ee546cda799d51ee3dbe89f"
                >
                  here
                </a>
              </Span>
              .
            </Text>
            <Button
              mr="16px"
              variant="black"
              width="120px"
              onClick={() => {
                setFieldValue('widget.type', WIDGET_TYPES.SEARCH)
                setFieldValue(`widget.id`, null)
                setFieldValue(`widgetId`, undefined)
                setFieldValue(`widget.name`, null)
                setFieldValue(`showInstallModal`, true)
                submit(STEPS.EDIT_SEARCH, { isNew: true })
              }}
              label="Create now"
            />
          </Flex>
          <Flex backgroundColor="#E1E8FF" position="relative">
            <Image
              position="absolute"
              bottom="0px"
              left="38px"
              borderRadius="30px 30px 0px 0px"
              src="/illustrations/search.jpg"
              width={439}
              height={433}
            />
          </Flex>
        </Flex>
      </ModalContainer>
    </Flex>
  )
}

export default DownloadType
