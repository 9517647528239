import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import ModalContainer from '../ui/ModalContainer.js'
import SideNav, { TABS } from '../Components/SideNav.js'
import VideoPlayer from '../Components/VideoPlayer'
import ProcessingPreview from '../Components/ProcessingPreview'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import { toast } from 'react-toastify'
import { useAuth } from '../Auth.js'
import { useVideos } from '../VideoProvider.js'
import ChatButton from '../Components/ChatButton'

const Grid = styled(Flex)`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  padding: 30px;
`

const ShopifyConnectModal = ({ isOpen, setIsOpen }) => {
  let { user, setUser } = useAuth()
  const [search, setSearch] = useSearchParams()
  const [error, setError] = useState('')
  const [shopifyShopName, setShopifyShopName] = useState('')
  const submitShopifyConnect = async () => {
    const res = await api.post('/auth/shopify/install', { shop: shopifyShopName })
    if (res.data.success) {
      setError('')
      return (window.location.href = res.data.payload.url)
    } else {
      setError(
        'Invalid shop name. You can find your shopify shop name as the first part of your admin URL. e.g *clipara*.myshopify.com/admin'
      )
    }
  }
  return (
    <ModalContainer interiorClose p="24px" width="650px" height="350px" overflowY="unset" isOpen={isOpen} setIsOpen={setIsOpen}>
      <Flex flexDirection="column">
        <Icon icon="shopify-logo" width="150px" height="41px" mb="22px" />
        <Text fontSize="16px" mb="16px" light>
          Enter your Shopify store name to connect Clipara to your Shopify store
        </Text>
        <Flex mb="32px" flexDirection="column">
          <Input
            width="300px"
            height="36px"
            mr={22}
            value={shopifyShopName}
            onChange={(e) => setShopifyShopName(e.target.value)}
            label="Store Name"
            textPostFix=".myshopify.com"
            largeTextPostFix
            dark
          />
          <Error mt="8px">{error}</Error>
        </Flex>
        <Button
          mr="16px"
          zIndex={1}
          position="absolute"
          bottom="24px"
          right="8px"
          variant="black"
          label="Connect"
          renderRightIcon={() => <Icon ml="16px" width={16} height={16} icon="chevron-right-white" />}
          onClick={submitShopifyConnect}
        />
      </Flex>
    </ModalContainer>
  )
}

export default ShopifyConnectModal
