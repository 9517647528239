import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, getDisplaySize, capitalizeFirstLetter } from '../../ui/helpers.js'
import { H1, H2, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../../ui/Typography.js'
import { Flex, Box, Container } from '../../ui/Layout.js'
import Image from '../../ui/Image.js'
import { Input, TextArea } from '../../ui/Input.js'
import Icon from '../../ui/Icon.js'
import CodeCopy from '../../ui/CodeCopy.js'
import VideoPreview from '../../ui/VideoPreview.js'
import CarouselPreview from '../../Components/CarouselPreview.js'

import WidgetPreview from '../../Components/WidgetPreview.js'
import Button from '../../ui/Button.js'
import { BigDarkSpinner } from '../../ui/Spinner.js'
import Toggle from '../../ui/Toggle.js'
import ToggleRedGreen from '../../ui/ToggleRedGreen.js'
import Select from '../../ui/Select.js'
import ColorPicker from '../../ui/ColorPicker.js'
import formikFormWrapper from '../../formikFormWrapper.js'
import { toast } from 'react-toastify'
import { useModal } from '../../Modal.js'
import ModalContainer from '../../ui/ModalContainer.js'
import ChatButton from '../../Components/ChatButton'
import InstallBanner from '../../Components/InstallBanner'
import { F, uniqBy } from 'ramda'

const FormInput = formikFormWrapper(Input)

import api from '../../api.js'
import { useAuth } from '../../Auth.js'

import {
  STEPS,
  CAMPAIGN_RULE_TYPES,
  CAMPAIGN_STATUS,
  campaignRuleTypesOptions,
  WIDGET_POSITIONS,
  widgetPositionOptions,
  widgetRuleTypeOptions,
  widgetTypeDisplayName,
  WIDGET_RULE_TYPES,
  RULE_TYPES,
  ruleTypeOptions,
  ruleTypeOptionsShoppable,
  WIDGET_TYPES
} from '../types'

const CampaignType = ({
  step,
  setStep,
  values,
  selectedVideos,
  openFullscreenDesktopPreview,
  openFullscreenMobilePreview,
  videoObjects,
  setFieldValue,
  submit,
  widgets,
  videos,
  activeWidget,
  setShowUltimateInstallModal,
  setShowShareModal,
  showCampaignNameModal,
  setShowCampaignNameModal
}) => {
  const { user } = useAuth()
  const [toggleInteracted, setToggleInteracted] = useState(false)

  useEffect(() => {
    if (values.showInstallModal) {
      setShowUltimateInstallModal(true)
      setFieldValue('showInstallModal', false)
    }
    if (values.showShareModal) {
      setShowShareModal(true)
      setFieldValue('showShareModal', false)
    }
  }, [step])

  useEffect(() => {
    if (typeof values.widgetActive !== 'undefined' && toggleInteracted) {
      submit()
    }
  }, [values.widgetActive])
  console.log({ activeWidget })
  return (
    <>
      <Flex>
        <Flex
          flexDirection="column"
          borderRight="1px solid #A6B7D4;"
          minHeight="100vh"
          maxHeight="100vh"
          height="100%"
          width="400px"
          minWidth="400px"
          p="32px"
          zIndex="1"
          bg="white"
        >
          <Link to="/campaigns">
            <Flex>
              <Icon mt="4px" mr="16px" icon="chevron-left-lightlight" height="16px" width="16px" />
              <Text lightLight fontSize="14px" mb="30px" textAlign="left">
                All campaigns
              </Text>
            </Flex>
          </Link>
          <Flex
            mt="32px"
            py="18px"
            cursor="pointer"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => {
              if (activeWidget && activeWidget.type === WIDGET_TYPES.CUSTOM_LAUNCHER) {
                return setStep(STEPS.VIDEO_APPEARANCE)
              }
              return values.widgetId ? setStep(STEPS.EDIT_EMBED) : setStep(STEPS.VIDEO_APPEARANCE)
            }}
          >
            <H3 mb={0} lightLight>
              Edit Campaign
            </H3>
            <Icon icon="chevron-right-grey" width={18} height={18} />
          </Flex>

          {!values.isShareCampaign && values.widgetId && !values.isCustomLauncher ? (
            <>
              <Flex
                pt="18px"
                mb="8px"
                cursor="pointer"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => {
                  setShowUltimateInstallModal(true)
                }}
              >
                <H3 mb={0} lightLight>
                  Pages / Rules
                </H3>
                <Icon icon="chevron-right-grey" width={18} height={18} />
              </Flex>
              <Flex
                cursor="pointer"
                onClick={() => {
                  setShowUltimateInstallModal(true)
                }}
              >
                <Text lightLight fontWeight="500" wordBreak="break-all">
                  {values.matchType === RULE_TYPES.SHOPIFY_PRODUCT && values.widgetRuleUrl0
                    ? `Product - ${capitalizeFirstLetter(values.widgetRuleUrl0 && values.widgetRuleUrl0.replace(/-/g, ' '))}`
                    : ''}

                  {values.matchType === RULE_TYPES.URL_RULES && values.widgetRuleUrl0 && values.widgetRuleType0
                    ? `${capitalizeFirstLetter(
                        values.widgetRuleUrl0 && values.widgetRuleType0 && values.widgetRuleType0.toLowerCase().replace(/_/g, ' ')
                      )} - ${values.widgetRuleUrl0}`
                    : ''}

                  {values.matchType === RULE_TYPES.HOMEPAGE ? `Homepage` : ''}
                </Text>
              </Flex>
            </>
          ) : null}

          <Box mt="24px">
            <InstallBanner />
          </Box>
        </Flex>
        <Flex height="100vh" position="relative" overflowX="hidden" flexDirection="column">
          <Flex
            height="60px"
            alignItems="center"
            justifyContent="flex-end"
            borderBottom="1px solid rgba(237, 242, 247, 1)"
            pr="24px"
            zIndex={1}
          >
            <Flex pl="24px" alignItems="center">
              <Flex
                width="auto"
                mr="24px"
                alignItems="center"
                onClick={() => {
                  setShowCampaignNameModal(true)
                }}
                cursor="pointer"
              >
                <Text light fontSize="16px" fontWeight="500" mr="16px" maxWidth="300px">
                  {values.name || 'Add campaign name'}
                </Text>
                <Icon icon="pencil-square-grey" width="16px" height="16px" />
              </Flex>
              {values.widgetId ? (
                <>
                  <ToggleRedGreen
                    setSelected={async (selected) => {
                      setToggleInteracted(true)
                      await setFieldValue('widgetActive', selected)
                    }}
                    value1={false}
                    value2={true}
                    selected={values.widgetActive}
                  />
                  {values.widgetActive ? (
                    <Text color={colors.green} fontWeight="500" ml="8px">
                      Published
                    </Text>
                  ) : (
                    <Text fontWeight="500" lightLight ml="8px">
                      Unpublished
                    </Text>
                  )}
                </>
              ) : null}
            </Flex>
            {values.type !== WIDGET_TYPES.SEARCH ? (
              <>
                <Text fontWeight="500" mr="16px">
                  Preview
                </Text>
                <Button
                  renderCenterIcon={() => <Icon width={12} height={12} icon="desktop-black" />}
                  variant="white"
                  shadow
                  onClick={openFullscreenDesktopPreview}
                  zIndex={1}
                  width="52px"
                  minWidth="52px"
                  mr="16px"
                />
                <Button
                  renderCenterIcon={() => <Icon width={12} height={12} icon="phone-black" />}
                  variant="white"
                  shadow
                  onClick={openFullscreenMobilePreview}
                  zIndex={1}
                  width="52px"
                  minWidth="52px"
                  mr="16px"
                />
              </>
            ) : null}
            {values.widgetId || values.isCustomLauncher ? (
              <Button
                mr={values.type !== WIDGET_TYPES.SEARCH ? '16px' : '0px'}
                onClick={() => {
                  submit(undefined, true)
                  setShowUltimateInstallModal(true)
                }}
                variant="grey"
                label="Install"
                zIndex={1}
              />
            ) : null}
            {values.type !== WIDGET_TYPES.SEARCH ? (
              <Button
                renderLeftIcon={() => <Icon width={12} height={12} icon="share-white" />}
                variant="black"
                label="Share"
                onClick={() => {
                  setShowShareModal(true)
                }}
              />
            ) : null}
          </Flex>
          <Flex height="100%" backgroundColor="#F7FAFC">
            <CarouselPreview
              values={values}
              setFieldValue={setFieldValue}
              widget={activeWidget}
              py="60px"
              isLight
              campaign={{ ...values, videoObjects }}
              videos={selectedVideos || []}
              noModalInteraction
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default CampaignType
