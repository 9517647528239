import { STEPS, WIDGET_TYPES } from '../Campaigns/types'

export const GUIDES = [
  {
    title: 'Clipara for Email & SMS',
    subtitle: 'Learn how to create off-site campaigns for marketing',
    url: 'https://clipara.notion.site/Create-Shareable-Links-for-Marketing-f24414767a3d4d18bbd974888be87faa'
  },
  {
    title: 'Add titles to videos',
    subtitle: 'Increase engagement by adding titles to each video',
    url: 'https://clipara.notion.site/Add-Titles-Subtitles-to-Videos-0509bb353f7f491f9ef59f18cf533b74'
  },
  {
    title: 'Changing thumbnails',
    subtitle: 'Clean up your designs by changing thumbnails',
    url: 'https://clipara.notion.site/Changing-Video-Thumbnails-2834ed53cab14bca82d3836a46b40138'
  },
  {
    title: 'Add CTAs to videos',
    subtitle: 'Drive clicks to high value pages with buttons',
    url: 'https://clipara.notion.site/Customising-Buttons-CTAs-6d693e3fcd2042b0939c798f681e0d77'
  },
  {
    title: 'How to customise widgets',
    subtitle: 'Format widget layouts to match your brand',
    url: 'https://clipara.notion.site/Widgets-1a42b1b7d79b491ebf12db04b4c1a082'
  }
]

export const TEMPLATES = {
  OFF_SITE: 'OFF_SITE',
  VIDEO_PLAYER: 'VIDEO_PLAYER',
  INLINE_CAROUSEL: 'INLINE_CAROUSEL',
  STORIES: 'STORIES',
  SINGLE_EMBED: 'SINGLE_EMBED',
  GRID: 'GRID',
  NAVIGATION: 'NAVIGATION',
  SEARCH: 'SEARCH'
}

export const HOMEPAGE_WIDGET_TYPES = [
  {
    image: '/illustrations/homepage-widgets/inline-carousel.jpg',
    title: 'In-line carousel',
    template: TEMPLATES.INLINE_CAROUSEL,
    step: STEPS.SELECT_VIDEO
  },
  {
    image: '/illustrations/homepage-widgets/video-player.jpg',
    title: 'Full screen swipeable video',
    template: TEMPLATES.VIDEO_PLAYER,
    step: STEPS.SELECT_VIDEO
  },
  {
    image: '/illustrations/homepage-widgets/search.jpg',
    title: 'Search',
    template: TEMPLATES.SEARCH,
    step: STEPS.EDIT_SEARCH
  },
  {
    image: '/illustrations/homepage-widgets/off-site.jpg',
    title: 'Off site',
    template: TEMPLATES.OFF_SITE,
    step: STEPS.SELECT_VIDEO
  },
  {
    image: '/illustrations/homepage-widgets/stories.jpg',
    title: 'Stories',
    template: TEMPLATES.STORIES,
    step: STEPS.SELECT_VIDEO
  },
  {
    image: '/illustrations/homepage-widgets/single-embed.jpg',
    title: 'Single embed',
    template: TEMPLATES.SINGLE_EMBED,
    step: STEPS.SELECT_VIDEO
  },
  {
    image: '/illustrations/homepage-widgets/grid.jpg',
    title: 'Grid',
    template: TEMPLATES.GRID,
    step: STEPS.SELECT_VIDEO
  },
  {
    image: '/illustrations/homepage-widgets/navigation.jpg',
    title: 'Navigation',
    template: TEMPLATES.NAVIGATION,
    step: STEPS.SELECT_VIDEO
  }
]

const MODAL_TEMPLATES = {
  [TEMPLATES.OFF_SITE]: {
    title: 'Video is made for sharing',
    subtitle:
      'Create a campaign to be shared on it’s own standalone Clipara link. Perfect for your marketing emails, SMS and more.',
    image: '/illustrations/home-modal/off-site.jpg'
  },
  [TEMPLATES.GRID]: {
    title: 'Simple Grids',
    subtitle: 'Make a grid layout of your videos for bigger impact and engagement. Videos open up to a full screen player.',
    image: '/illustrations/home-modal/grid.jpg'
  },
  [TEMPLATES.INLINE_CAROUSEL]: {
    title: 'Keep it in the flow',
    subtitle: 'A collection of videos that all play in the page without expanding to full screen.',
    image: '/illustrations/home-modal/inline.gif'
  },
  [TEMPLATES.NAVIGATION]: {
    title: 'Make a merchandising grid',
    subtitle: 'Make an autoplaying merchandising grid, with autoplaying video cards linking to other parts of your site."',
    image: '/illustrations/home-modal/navigation.gif'
  },

  [TEMPLATES.SINGLE_EMBED]: {
    title: 'The classic',
    subtitle:
      'Just a gold old streamlined video player that will play your videos in the page, with autoplaying previews for better engagement.',
    image: '/illustrations/home-modal/single-embed.jpg'
  },
  [TEMPLATES.STORIES]: {
    title: 'For smaller previews, with a big impact',
    subtitle: 'Choose from circle or portrait stories layouts that will expand to a full screen experience.',
    image: '/illustrations/home-modal/stories.jpg'
  },
  [TEMPLATES.SEARCH]: {
    title: 'Searchable Libraries',
    subtitle:
      'Create libraries based on tags that automatically populates with new content. Include your whole library or filter for particular tags.',
    image: '/illustrations/home-modal/search.jpg'
  },
  [TEMPLATES.VIDEO_PLAYER]: {
    title: 'Swipeable video feed',
    subtitle:
      'Bring video and image collections to your homepage with a full screen swipeable experience. Choose from multiple layouts.',
    image: '/illustrations/home-modal/video-player.jpg'
  }
}

export const getModalContent = (type, isShoppable) => {
  console.log(type)
  if (isShoppable && type === TEMPLATES.VIDEO_PLAYER) {
    return {
      title: 'Swipeable shopping feed',
      subtitle:
        'Bring video and image collections to your homepage and product pages with a full screen swipeable experience. Choose from multiple layouts.',
      image: '/illustrations/home-modal/video-player.jpg'
    }
  }

  return MODAL_TEMPLATES[type]
}
