import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import { Link, useSearchParams, useNavigate, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { colors, getDisplaySize, capitalizeFirstLetter, numberWithCommas } from '../ui/helpers'
import { H1, H2, H3, H6, Text, Span, GradientFont, Error, SmallTitle, Label } from '../ui/Typography'
import { Flex, Box, Container } from '../ui/Layout'
import Image from '../ui/Image'
import { Input, TextArea } from '../ui/Input'
import SlideOverContainer from '../ui/SlideOverContainer'
import api from '../api'
import { useAuth } from '../Auth'
import { useVideos } from '../VideoProvider'
import Icon from '../ui/Icon'
import { stripHtml } from 'string-strip-html'
import Button from '../ui/Button'
import VideoPlayer from '../Components/VideoPlayer'
import { toast } from 'react-toastify'
import { uniq } from 'ramda'
import TagsModal from './TagsModal'
import ModalContainer from '../ui/ModalContainer'
import VideoProductModal from './VideoProductModal'
import { useModal } from '../Modal'
import { BigDarkSpinner } from '../ui/Spinner'

const ThumbnailContainer = styled(Flex)`
  .hover-text {
    display: none;
  }
  &&:hover {
    .hover-text {
      display: block;
    }
  }
`

const VideoImage = styled.img`
  width: 100%;
  border-radius: 16px;
  height: ${(props) => props.height}px;
`

const VideoDisplay = ({
  refresh,
  video,
  setShowVideoToProductModal,
  shopifyShop,
  setShowAddThumbnailModal,
  setTagsModalId,
  setShowTagsModal,
  isImage,
  image
}) => {
  const [name, setName] = useState((video && video.name) || (image && image.name) || '')

  let { user, setUser } = useAuth()
  if (!video && !image) {
    return null
  }

  let displayWidth, displayHeight
  if (!isImage) {
    displayWidth = getDisplaySize(500, 500, video.aspectRatioCalc).displayWidth
    displayHeight = getDisplaySize(500, 500, video.aspectRatioCalc).displayHeight
  }

  const [isSubmitting, setIsSubmitting] = useState(false)
  const updateName = async () => {
    setIsSubmitting(true)
    const res = await api.post('/video/update', { name, id: video.id })
    if (res.data.success) {
      toast('Video name saved')
    } else {
      toast(res.data.message)
    }
    refresh()
    setIsSubmitting(false)
  }

  const updateImageName = async () => {
    setIsSubmitting(true)
    const res = await api.post('/image/update', { name, id: image.id })
    if (res.data.success) {
      toast('Image name saved')
    } else {
      toast(res.data.message)
    }
    refresh()
    setIsSubmitting(false)
  }

  return (
    <Flex>
      <Flex flexDirection="column" alignItems="center" px="48px">
        {!isImage ? (
          <Box position="relative">
            <VideoPlayer
              id="video-player-popover"
              poster={video.customThumbnail && video.customThumbnail + `-/scale_crop/${displayWidth}x${displayHeight}/center/`}
              videoStyle={{ width: `${displayWidth}px`, height: `${displayHeight}px`, borderRadius: '16px' }}
              src={`https://stream.mux.com/${video.playbackId}.m3u8`}
            />
            <ThumbnailContainer
              position="absolute"
              top="16px"
              right="16px"
              bg="white"
              borderRadius="20px"
              alignItems="center"
              justifyContent="center"
              width="40px"
              height="40px"
              boxShadow="0px 3px 8px -1px rgba(50, 50, 71, 0.05);filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))"
              cursor="pointer"
              minWidth="40px"
              onClick={setShowAddThumbnailModal}
            >
              <Icon width="16px" height="16px" icon="image-black" />
            </ThumbnailContainer>
          </Box>
        ) : (
          <VideoImage
            height={456 / image.aspectRatioCalc}
            loading="lazy"
            src={`https://clipara.b-cdn.net/${image.path}?width=800`}
          />
        )}
        <Flex alignItems="center" mt="32px" pb="24px" justifyContent="center" borderBottom="1px solid #E4ECF7">
          <Input
            label={isImage ? 'Image name' : 'Video name'}
            value={name}
            width="100%"
            mr="16px"
            dark
            onChange={(e) => setName(e.target.value)}
          />
          <Button
            mt="26px"
            isLoading={isSubmitting}
            isDisabled={name.length < 4 || name.length > 100}
            variant="black"
            label="Save"
            onClick={isImage ? updateImageName : updateName}
          />
        </Flex>
        {user.shopifyShopUrl && !isImage ? (
          <Flex flexDirection="column">
            <Text mt="24px" mb="8px" color="black" fontSize="14px" fontWeight="600">
              Shoppable Items
            </Text>
            {video.linkedProducts.map((dbProduct) => {
              console.log(dbProduct)
              const product = dbProduct.product
              if (!product) {
                return null
              }
              const price =
                shopifyShop &&
                stripHtml(
                  shopifyShop.moneyFormat
                    .replace('{{amount}}', product.price)
                    .replace('{{amount_no_decimals}}', product.price)
                    .replace('{{amount_with_comma_separator}}', product.price)
                    .replace('{{amount_no_decimals_with_comma_separator}}', product.price)
                    .replace('{{amount_with_apostrophe_separator}}', product.price)
                ).result

              const image = product.media && product.media[0] && product.media[0].src
              return (
                <Flex key={product.id} alignItems="center" mb="16px">
                  <Image objectFit="cover" height="60px" width="60px" borderRadius="4px" src={image} />
                  <Flex flexDirection="column" ml="16px">
                    <Text fontWeight="600">{product.title}</Text>
                    <Text>{price}</Text>
                  </Flex>
                </Flex>
              )
            })}
            <Flex onClick={() => setShowVideoToProductModal(true)} cursor="pointer">
              <Flex width="auto">
                <Icon mt="6px" mr="8px" icon="plus-dark-grey" width="12px" height="12px" />
                <Text fontSize="14px" fontWeight="400" light>
                  Add a product
                </Text>
              </Flex>
            </Flex>
          </Flex>
        ) : null}
        <Text mt="24px" mb="8px" color="black" fontSize="14px" fontWeight="600" width="100%">
          Tags
        </Text>
        <Flex overflowX="scroll" noScrollBar borderBottom="1px solid #E4ECF7" pb="24px" mb="24px">
          {((isImage ? image : video).tagsArray || []).map((tag) => {
            return (
              <Flex
                alignItems="center"
                bg="rgba(225, 232, 255, 1)"
                width="auto"
                height="28px"
                mr="8px"
                borderRadius="14px"
                px="16px"
                key={tag}
                cursor="pointer"
                onClick={(e) => {
                  e.stopPropagation()
                  setTagsModalId((isImage ? image : video).id)
                  setShowTagsModal(true)
                }}
              >
                <Text whiteSpace="nowrap" mb="2px" light fontWeight="500">
                  {tag}
                </Text>
              </Flex>
            )
          })}
          {!(isImage ? image : video).tagsArray || !(isImage ? image : video).tagsArray.length ? (
            <Flex cursor="pointer">
              <Icon mt="6px" mr="8px" icon="plus-dark-grey" width="12px" height="12px" />
              <Text
                onClick={(e) => {
                  e.stopPropagation()
                  setTagsModalId((isImage ? image : video).id)
                  setShowTagsModal(true)
                }}
                fontWeight="400"
                light
              >
                Add a tag
              </Text>
            </Flex>
          ) : null}
        </Flex>

        {!isImage ? (
          <>
            <Text mb="8px" color="black" fontSize="14px" fontWeight="600" width="100%">
              Video Metadata
            </Text>
            <Flex>
              <Flex flexDirection="column">
                <Text light mb="8px" fontSize="14px" fontWeight="400">
                  Date Uploaded: {dayjs(video.createdAt).format('DD MMM YYYY, HH:MM')}
                </Text>
                <Text light mb="8px" fontSize="14px" fontWeight="400">
                  Video length: {Math.round(video.duration)} seconds
                </Text>
                <Text light mb="8px" fontSize="14px" fontWeight="400">
                  Aspect ratio: {video.aspectRatio}
                </Text>
                <Text light mb="8px" fontSize="14px" fontWeight="400">
                  Frame rate: {video.maxStoredFrameRate} fps
                </Text>
                <Text light mb="8px" fontSize="14px" fontWeight="400">
                  Resolution: {video.maxStoredResolution}
                </Text>
              </Flex>
            </Flex>
          </>
        ) : (
          <>
            <Text mb="8px" color="black" fontSize="14px" fontWeight="600" width="100%">
              Image Metadata
            </Text>
            <Flex>
              <Flex flexDirection="column">
                <Text light mb="8px" fontSize="14px" fontWeight="400">
                  Date Uploaded: {dayjs(image.createdAt).format('DD MMM YYYY, HH:MM')}
                </Text>
                <Text light mb="8px" fontSize="14px" fontWeight="400">
                  Width: {numberWithCommas(Math.round(image.width))}px
                </Text>
                <Text light mb="8px" fontSize="14px" fontWeight="400">
                  Height: {numberWithCommas(Math.round(image.height))}px
                </Text>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  )
}

const VideoPopover = ({ showVideoPopover, setShowVideoPopover, videoPopoverId, setVideoPopoverId }) => {
  const { videos, images, refresh } = useVideos()
  const [showVideoToProductModal, setShowVideoToProductModal] = useState(false)
  const { setShowAddThumbnailModal, setAddThumbnailVideo } = useModal()
  const [shopifyShop, setShopifyShop] = useState()

  const video = videos.find((v) => v.id === videoPopoverId)
  const image = images.find((v) => v.id === videoPopoverId)

  const isImage = !video && image

  const [showTagsModal, setShowTagsModal] = useState(false)
  const [tagsModalId, setTagsModalId] = useState()

  let inputAllTags = []
  videos.forEach((v) => (inputAllTags = inputAllTags.concat(v.tagsArray)))
  images.forEach((v) => (inputAllTags = inputAllTags.concat(v.tagsArray)))
  inputAllTags = uniq(inputAllTags)

  return (
    <>
      <SlideOverContainer
        internalClose
        padding="0px"
        autoHeight
        overflowY="unset"
        isOpen={showVideoPopover}
        setIsOpen={setShowVideoPopover}
        onClose={() => {
          setTimeout(() => setVideoPopoverId(), 400)
        }}
      >
        <Flex flexDirection="column" alignItems="center" pb="24px" overflowY="scroll" noScrollBar px="24px" pt="24px">
          {video?.id || image?.id ? (
            <VideoDisplay
              image={image}
              isImage={isImage}
              setShowAddThumbnailModal={() => {
                setAddThumbnailVideo(video)
                setShowAddThumbnailModal(true)
              }}
              shopifyShop={shopifyShop}
              setShowVideoToProductModal={() => {
                setShowVideoToProductModal(true)
              }}
              video={video}
              refresh={refresh}
              setShowTagsModal={setShowTagsModal}
              setTagsModalId={setTagsModalId}
            />
          ) : (
            <BigDarkSpinner />
          )}
        </Flex>
      </SlideOverContainer>
      <ModalContainer p="0px" width="90%" height="90%" isOpen={showVideoToProductModal} setIsOpen={setShowVideoToProductModal}>
        <VideoProductModal
          shopifyShop={shopifyShop}
          close={() => setShowVideoToProductModal(false)}
          video={video}
          refresh={refresh}
        />
      </ModalContainer>
      <TagsModal
        id={tagsModalId}
        inputTags={(videos.find((v) => v.id === tagsModalId) && videos.find((v) => v.id === tagsModalId).tagsArray) || []}
        inputAllTags={inputAllTags || []}
        isOpen={showTagsModal}
        setIsOpen={setShowTagsModal}
        refresh={refresh}
        isImage={isImage}
      />
    </>
  )
}

export default VideoPopover
