import { TEMPLATES } from '../Home/types'
import { STEPS, TITLE_ALIGNMENT, TITLE_POSITION, WIDGET_THUMBNAIL_DIMENSIONS, WIDGET_THUMBNAIL_SIZE, WIDGET_TYPES } from './types'

const applyTemplate = ({ values, template, setFieldValue, setStep, user, submit }) => {
  const delaySetStep = (x) => setTimeout(() => setStep(x), 1)
  if (template === TEMPLATES.OFF_SITE) {
    setFieldValue(`isShareCampaign`, true)
    if (user.shopifyShopUrl) {
      setFieldValue(`isShoppable`, true)
    } else if (user.isContentCreator) {
      setFieldValue(`isDownloadCampaign`, true)
    } else {
      setFieldValue('isShoppable', false)
    }
    return
  }
  if (template === TEMPLATES.VIDEO_PLAYER) {
    if (user.shopifyShopUrl) {
      setFieldValue('isShoppable', true)
    }
    setFieldValue(`isShareCampaign`, false)
    return
  }
  if (template === TEMPLATES.INLINE_CAROUSEL) {
    setFieldValue('isShoppable', false)
    setFieldValue('isInlineCampaign', true)
    setFieldValue('templatePassThrough', TEMPLATES.INLINE_CAROUSEL)
    setFieldValue('widget.type', WIDGET_TYPES.INLINE_CAROUSEL)
    setFieldValue(`widget.widgetThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT)
    setFieldValue(`widget.widgetMobileThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT)
    setFieldValue(`widget.widgetFixedThumbnailWidth`, 200)
    setFieldValue(`widget.widgetFixedThumbnail`, false)
    setFieldValue(`widget.widgetThumbnailBorder`, false)
    setFieldValue(`widget.widgetMobileThumbnailSize`, WIDGET_THUMBNAIL_SIZE.LARGE)
    setFieldValue(`widget.desktopVideos`, 3)
    setFieldValue(`widget.widgetIsFixedCircle`, false)
    setFieldValue(`widget.widgetDesktopTitleFontSize`, 18)
    setFieldValue(`widget.widgetMobileTitleFontSize`, 14)
    setFieldValue(`widget.widgetTitleFontWeight`, 700)
    setFieldValue(`widget.id`, null)
    setFieldValue(`widgetId`, undefined)
    setFieldValue(`widget.name`, null)
    setFieldValue(`showInstallModal`, true)
    setFieldValue(`widget.widgetTitlePosition`, TITLE_POSITION.BELOW)
    return
  }
  if (template === TEMPLATES.STORIES) {
    if (user.shopifyShopUrl) {
      setFieldValue(`isShoppable`, true)
    } else if (user.isContentCreator) {
      setFieldValue(`isDownloadCampaign`, true)
    } else {
      setFieldValue('isShoppable', false)
    }
    setFieldValue('templatePassThrough', TEMPLATES.STORIES)
    setFieldValue('widget.type', WIDGET_TYPES.STORIES)
    setFieldValue(`widget.widgetThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.CIRCLE)
    setFieldValue(`widget.widgetMobileThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.CIRCLE)
    setFieldValue(`widget.widgetFixedThumbnailWidth`, 100)
    setFieldValue(`widget.durationDisplay`, false)
    setFieldValue(`widget.widgetFixedThumbnailHeight`, 100)
    setFieldValue(`widget.widgetFixedThumbnail`, true)
    setFieldValue(`widget.widgetThumbnailBorderSize`, 4)
    setFieldValue(`widget.widgetThumbnailBorderColor`, '#4c6fff')
    setFieldValue(`widget.widgetThumbnailBorder`, true)
    setFieldValue(`widget.widgetMobileThumbnailSize`, WIDGET_THUMBNAIL_SIZE.LARGE)
    setFieldValue(`widget.desktopVideos`, 4)
    setFieldValue(`widget.widgetTitlePosition`, TITLE_POSITION.BELOW)
    setFieldValue(`widget.widgetTitleAlignment`, TITLE_ALIGNMENT.CENTER)
    setFieldValue(`widget.widgetIsFixedCircle`, true)
    setFieldValue(`widget.widgetDesktopTitleFontSize`, 14)
    setFieldValue(`widget.widgetMobileTitleFontSize`, 12)
    setFieldValue(`widget.widgetTitleFontWeight`, 500)
    setFieldValue(`widget.id`, null)
    setFieldValue(`widgetId`, undefined)
    setFieldValue(`widget.name`, null)
    setFieldValue(`showInstallModal`, true)
    return
  }
  if (template === TEMPLATES.SINGLE_EMBED) {
    setFieldValue('isInlineCampaign', true)
    setFieldValue('templatePassThrough', TEMPLATES.SINGLE_EMBED)
    setFieldValue('widget.type', WIDGET_TYPES.SINGLE_EMBED)
    setFieldValue(`widget.widgetAutoplay`, false)
    setFieldValue(`widget.id`, null)
    setFieldValue(`widgetId`, undefined)
    setFieldValue(`widget.name`, null)
    setFieldValue(`showInstallModal`, true)
    return
  }
  if (template === TEMPLATES.GRID) {
    if (user.shopifyShopUrl) {
      setFieldValue(`isShoppable`, true)
    } else if (user.isContentCreator) {
      setFieldValue(`isDownloadCampaign`, true)
    } else {
      setFieldValue('isShoppable', false)
    }
    setFieldValue('templatePassThrough', TEMPLATES.GRID)
    setFieldValue('widget.type', WIDGET_TYPES.GRID)
    setFieldValue('type', WIDGET_TYPES.GRID)
    setFieldValue(`widget.widgetThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT)
    setFieldValue(`widget.widgetMobileThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT)
    setFieldValue(`widget.widgetFixedThumbnailWidth`, 200)
    setFieldValue(`widget.widgetFixedThumbnail`, false)
    setFieldValue(`widget.widgetThumbnailBorder`, false)
    setFieldValue(`widget.widgetMobileThumbnailSize`, WIDGET_THUMBNAIL_SIZE.LARGE)
    setFieldValue(`widget.desktopVideos`, 4)
    setFieldValue(`widget.widgetIsFixedCircle`, false)
    setFieldValue(`widget.widgetDesktopTitleFontSize`, 18)
    setFieldValue(`widget.widgetMobileTitleFontSize`, 14)
    setFieldValue(`widget.widgetTitleFontWeight`, 700)
    setFieldValue(`widget.id`, null)
    setFieldValue(`widgetId`, undefined)
    setFieldValue(`widget.name`, null)
    setFieldValue(`widget.marginX`, 16)
    setFieldValue(`showInstallModal`, true)
    setFieldValue(`widget.widgetSearchShowPopular`, false)
    return
  }
  if (template === TEMPLATES.NAVIGATION) {
    setFieldValue('templatePassThrough', TEMPLATES.NAVIGATION)
    setFieldValue('isNavigationCampaign', true)
    setFieldValue('type', WIDGET_TYPES.NAVIGATION_CAROUSEL)
    setFieldValue('widget.type', WIDGET_TYPES.NAVIGATION_CAROUSEL)
    setFieldValue(`widget.widgetThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT)
    setFieldValue(`widget.widgetMobileThumbnailDimensions`, WIDGET_THUMBNAIL_DIMENSIONS.PORTRAIT)
    setFieldValue(`widget.widgetFixedThumbnailWidth`, 200)
    setFieldValue(`widget.widgetFixedThumbnail`, false)
    setFieldValue(`widget.widgetThumbnailBorder`, false)
    setFieldValue(`widget.widgetMobileThumbnailSize`, WIDGET_THUMBNAIL_SIZE.LARGE)
    setFieldValue(`widget.desktopVideos`, 4)
    setFieldValue(`widget.widgetIsFixedCircle`, false)
    setFieldValue(`widget.widgetDesktopTitleFontSize`, 18)
    setFieldValue(`widget.widgetMobileTitleFontSize`, 14)
    setFieldValue(`widget.widgetTitleFontWeight`, 700)
    setFieldValue(`widget.id`, null)
    setFieldValue(`widgetId`, undefined)
    setFieldValue(`widget.name`, null)
    setFieldValue(`showInstallModal`, true)
    return
  }
  if (template === TEMPLATES.SEARCH) {
    values.isDownloadCampaign = true
    // setFieldValue('templatePassThrough', TEMPLATES.SEARCH)
    setFieldValue('type', WIDGET_TYPES.SEARCH)
    setFieldValue('widget.type', WIDGET_TYPES.SEARCH)
    setFieldValue(`widget.id`, null)
    setFieldValue(`widgetId`, undefined)
    setFieldValue(`widget.name`, null)
    setFieldValue('isDownloadCampaign', true)
    submit(STEPS.EDIT_SEARCH)
    return
  }
}

export default applyTemplate
