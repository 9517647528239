import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, shorten } from './helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from './Typography.js'
import { Flex, Box, Container } from './Layout.js'
import Icon from './Icon.js'

const SlideOverContainerComponent = styled(Flex)`
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1300000;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  @keyframes fadeIn {
    0% {
      display: block;
      opacity: 0;
    }
    1% {
      display: block;
      opacity: 0;
    }
    100% {
      display: block;
      opacity: 1;
    }
  }
  ${(props) =>
    props.isOpen
      ? `animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;`
      : 'none'}
`

const SlideOver = styled(Flex)`
  position: fixed;
  right: -600px;
  top: 0;
  background-color: white;
  width: 600px;
  max-width: 600px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  z-index: 1300002;
  ${(props) => (props.isOpen ? 'transform: translateX(-600px);' : '')}
  transition: all 0.5s ease-in-out;
  padding: ${(props) => props.padding || '24px'};
`

const SlideOverContainer = ({ isOpen, setIsOpen, width, children, internalClose, onClose, padding }) => {
  const close = () => {
    setIsOpen(false)
    onClose && onClose()
  }
  return (
    <>
      <SlideOverContainerComponent onClick={close} isOpen={isOpen}></SlideOverContainerComponent>
      <SlideOver padding={padding} onClick={(e) => e.stopPropagation()} maxWidth={width || '500px'} isOpen={isOpen}>
        {internalClose ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            bg="white"
            position="absolute"
            width="auto"
            top="24px"
            left="24px"
            zIndex="1"
            cursor="pointer"
            onClick={close}
          >
            <Icon width="14px" height="14px" icon="close-cross-grey" />
          </Flex>
        ) : null}
        {children}
      </SlideOver>
    </>
  )
}

export default SlideOverContainer
