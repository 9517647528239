import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, formatSeconds } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import { BigDarkSpinner } from '../ui/Spinner.js'
import SideNav, { TABS } from '../Components/SideNav.js'
import VideoPlayer from '../Components/VideoPlayer.js'
import ProcessingPreview from '../Components/ProcessingPreview.js'
import formikFormWrapper from '../formikFormWrapper.js'
import api from '../api.js'
import ChatButton from '../Components/ChatButton.js'
import { useAuth } from '../Auth.js'
import { useVideos } from '../VideoProvider.js'
import OptionsPopover from './OptionsPopover.js'
import TagsModal from './TagsModal.js'
import ModalContainer from '../ui/ModalContainer.js'
import { uniq, difference, intersection } from 'ramda'
import { getAllowedVideoDuration, getNumberOfAllowedVideos, getPlanName, hasHitVideoLimit, isFreePlan } from '../Plans.js'
import Tooltip from '../ui/Tooltip.js'
import ReactTooltip from 'react-tooltip'

const InputContainer = styled(Flex)`
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  height: 36px;
  border-radius: 36px;
  background-color: white;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 16px;
`

const TagContainer = styled(Flex)`
  cursor: pointer;
  width: 118px;
  min-width: 118px;
  justify-content: space-between;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  height: 36px;
  border-radius: 36px;
  background-color: white;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 16px;
`

const VerticalDivider = styled(Box)`
  border-right: 1px solid rgba(166, 183, 212, 1);
  width: 0px;
  margin-right: 16px;
  margin-left: 8px;
  height: 40px;
`

const InputComponent = styled.input`
  ::-webkit-input-placeholder {
    font-family: 'Inter', sans-serif;
  }
  ::-moz-placeholder {
    font-family: 'Inter', sans-serif;
  }
  :-ms-input-placeholder {
    font-family: 'Inter', sans-serif;
  }
  :-moz-placeholder {
    font-family: 'Inter', sans-serif;
  }
  background: white;
  height: 36px;
  margin: 0px;
  margin-bottom: 0px;
  border-radius: 6px;
  color: white;
  display: inline-block;
  border: none;
  font-size: 14px;
  text-decoration: none;
  color: ${colors.textDark};
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
`

const FullWidthForm = styled.form`
  margin-left: 16px;
  width: 100%;
`

const UpgradeContainer = styled(Flex)`
  background: linear-gradient(225deg, #bb65ff 0%, #4c6fff 100%);
  height: 40px;
  box-sizing: border-box;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  padding-left: 24px;
`

const VideoSearch = ({ searchTerm, setSearchTerm, selectedTags, setPage, setSelectedTags, selectAll }) => {
  let { user } = useAuth()
  let navigate = useNavigate()
  const { videos, setVideos, refresh } = useVideos()
  const [tempSearch, setTempSearch] = useState('')
  const [tagModalOpen, setTagModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [editTagModalOpen, setEditTagModalOpen] = useState(false)
  const [activeTag, setActiveTag] = useState()
  const [editTagText, setEditTagText] = useState('')

  useEffect(() => {
    if (searchTerm !== tempSearch) {
      setTempSearch(searchTerm)
    }
  }, [searchTerm])

  const submit = async (e) => {
    e?.preventDefault()
    setPage(1)
    setSearchTerm(tempSearch)
  }

  let inputAllTags = []
  videos.forEach((v) => (inputAllTags = inputAllTags.concat(v.tagsArray)))
  inputAllTags = uniq(inputAllTags.filter((el) => el))

  const deleteTag = async () => {
    const res = await api.post('/video/delete-tag', {
      tag: activeTag
    })
    refresh()
    setDeleteModalOpen(false)
    setSelectedTags([])
  }

  const editTag = async () => {
    const res = await api.post('/video/edit-tag', {
      oldTag: activeTag,
      newTag: editTagText.trim()
    })
    refresh()
    setEditTagModalOpen(false)
    setSelectedTags([])
  }

  return (
    <>
      <Flex mb="24px">
        <Flex alignItems="center">
          <TagContainer onClick={() => setTagModalOpen(true)}>
            <Text fontWeight="500" fontSize="13px" lightLight>
              {'Manage Tags'}
            </Text>
          </TagContainer>
          <InputContainer>
            <Icon icon="search-grey" width={14} height={14} />
            <FullWidthForm onSubmit={submit}>
              <InputComponent placeholder="Search ..." onChange={(e) => setTempSearch(e.target.value)} value={tempSearch} />
            </FullWidthForm>
            {tempSearch || searchTerm ? (
              <Icon
                cursor="pointer"
                icon="circle-cross-grey"
                width={14}
                height={14}
                onClick={() => {
                  setSearchTerm('')
                  setTempSearch('')
                }}
              />
            ) : null}
          </InputContainer>
        </Flex>
      </Flex>
      <Flex mb="24px" flexWrap="wrap">
        {selectedTags.map((tag) => (
          <Flex
            alignItems="center"
            bg="rgba(166, 183, 212, 1)"
            width="auto"
            height="36px"
            mr="8px"
            mb="8px"
            borderRadius="20px"
            px="16px"
            key={tag}
            onClick={(e) => {
              setPage(1)
              setSelectedTags(selectedTags.filter((el) => el !== tag))
              setSearchTerm('')
            }}
            cursor="pointer"
          >
            <Text mb="2px" whiteSpace="nowrap" mr="8px" color="white" fontWeight="500">
              {tag}
            </Text>
            <Icon width="14px" height="14px" icon="circle-cross-white" />
          </Flex>
        ))}
        {selectedTags && selectedTags.length ? <VerticalDivider /> : null}
        {difference(inputAllTags, selectedTags).map((tag) => (
          <Flex
            alignItems="center"
            bg="rgba(225, 232, 255, 1)"
            width="auto"
            height="36px"
            mr="8px"
            mb="8px"
            borderRadius="20px"
            px="16px"
            key={tag}
            position="relative"
            onClick={() => {
              setPage(1)
              setSelectedTags(selectedTags.concat(tag))
              setSearchTerm('')
            }}
            cursor="pointer"
          >
            <Text mb="2px" whiteSpace="nowrap" light fontWeight="500">
              {tag}
            </Text>
          </Flex>
        ))}
      </Flex>
      {isFreePlan(user) && user.isContentCreator ? (
        <Link to="/account?tab=BILLING">
          <UpgradeContainer mb="32px" mt="-16px">
            <Icon mr="16px" icon="lightening-white" height="16px" width="11px" />
            <Text mr="20px" fontWeight="400" color="white">
              Upgrade for higher and original quality uploads and downloads.
            </Text>
          </UpgradeContainer>
        </Link>
      ) : null}
      <ModalContainer interiorClose p="0px" width="400px" autoHeight isOpen={tagModalOpen} setIsOpen={setTagModalOpen}>
        <Flex p="16px" borderBottom="1px solid #EDF2F7;">
          <Text fontWeight="600" fontSize="18px" width="100%">
            Manage Tags
          </Text>
        </Flex>
        <Flex p="16px" flexDirection="column" maxHeight="400px" overflowY="scroll" noScrollBar>
          {inputAllTags.map((tag) => {
            return (
              <Flex mb="16px" key={tag} alignItems="center" justifyContent="space-between">
                <Flex
                  alignItems="center"
                  bg="rgba(225, 232, 255, 1)"
                  width="auto"
                  height="36px"
                  mr="8px"
                  borderRadius="20px"
                  px="16px"
                  key={tag}
                  position="relative"
                  onClick={() => {
                    setPage(1)
                    setSelectedTags([tag])
                    setSearchTerm('')
                    setTagModalOpen(false)
                  }}
                  cursor="pointer"
                >
                  <Text mb="2px" whiteSpace="nowrap" light fontWeight="500">
                    {tag}
                  </Text>
                </Flex>
                <Flex width="auto" alignItems="center">
                  <Box borderRight="1px solid #EDF2F7;" pr="8px" mr="8px">
                    <Text
                      fontWeight="400"
                      cursor="pointer"
                      light
                      onClick={() => {
                        setActiveTag(tag)
                        setTagModalOpen(false)
                        setEditTagModalOpen(true)
                        setEditTagText(tag)
                      }}
                    >
                      Edit
                    </Text>
                  </Box>
                  <Text
                    fontWeight="400"
                    cursor="pointer"
                    light
                    onClick={() => {
                      setActiveTag(tag)
                      setTagModalOpen(false)
                      setDeleteModalOpen(true)
                    }}
                  >
                    Delete
                  </Text>
                </Flex>
              </Flex>
            )
          })}
        </Flex>
      </ModalContainer>
      <ModalContainer interiorClose p="24px" width="400px" autoHeight isOpen={deleteModalOpen} setIsOpen={setDeleteModalOpen}>
        <Text fontWeight="600" fontSize="18px" width="100%" mb="8px">
          Delete Tag
        </Text>
        <Text width="100%" fontWeight="400" fontSize="14px" lightLight mb="24px">
          Deleting the "{activeTag}" tag will remove it from all content.
        </Text>
        <Flex justifyContent="flex-end">
          <Button
            zIndex={1}
            variant="grey"
            label="Cancel"
            width="80px"
            mr="16px"
            onClick={() => {
              setTagModalOpen(true)
              setDeleteModalOpen(false)
            }}
          />

          <Button zIndex={1} variant="red" label="Delete" width="80px" onClick={deleteTag} />
        </Flex>
      </ModalContainer>
      <ModalContainer interiorClose p="24px" width="400px" autoHeight isOpen={editTagModalOpen} setIsOpen={setEditTagModalOpen}>
        <Flex flexDirection="column">
          <Text fontWeight="600" fontSize="18px" width="100%" mb="8px">
            Edit Tag
          </Text>
          <Text width="100%" fontWeight="400" fontSize="14px" lightLight mb="16px">
            Edit the "{activeTag}" tag.
          </Text>
          <Input autofocus dark width="100%" mb="24px" value={editTagText} onChange={(e) => setEditTagText(e.target.value)} />
        </Flex>
        <Flex justifyContent="flex-end">
          <Button
            zIndex={1}
            variant="grey"
            label="Cancel"
            width="80px"
            mr="16px"
            onClick={() => {
              setTagModalOpen(true)
              setEditTagModalOpen(false)
            }}
          />

          <Button
            isDisabled={!editTagText || !editTagText.length}
            zIndex={1}
            variant="black"
            label="Save"
            width="80px"
            onClick={editTag}
          />
        </Flex>
      </ModalContainer>
    </>
  )
}

export default VideoSearch
