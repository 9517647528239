import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { colors, getDisplaySize } from '../ui/helpers'
import { Label, Error, Text } from '../ui/Typography'
import { Flex, Box } from '../ui/Layout'
import Toggle from '../ui/Toggle.js'
import Button from '../ui/Button'
import Icon from '../ui/Icon'
import VideoPreview from '../ui/VideoPreview'
import Image from '../ui/Image.js'
import { WIDGET_POSITIONS } from '../Campaigns/types'
import ProcessingPreview from './ProcessingPreview'

const VideoContainer = styled(Flex)`
  border-radius: 16px;
  position: absolute;
  bottom: 25px;
  margin-left: 25px;
  margin-right: 25px;
  width: auto;
  ${(props) => props.isRight && `flex-direction: row-reverse;`}
  align-items: end;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.4);
`

const PageBackground = styled(Flex)`
  ${(props) => props.isRight && `flex-direction: row-reverse;`}
`

const CallOutContainer = styled(Text)`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  font-family: Inter, sans-serif;
  margin-bottom: 2px;
  word-break: break-word;
  z-index: 1;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: white;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 8px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  width: 100%;
  margin-bottom: 0px;
  border-radius: 8px;
  text-shadow: 0px 0px 3px black;
`

const LinearGradient = styled(Flex)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  border-radius: 16px;
  ${(props) =>
    props.border &&
    `width: calc(100% - 12px);
  left: 6px;
  border-radius: 11px;
  bottom: 6px;`}
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 70%, rgba(0, 0, 0, 0.5) 100%);
`

const PlayButton = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  cursor: pointer;
`

const Image2 = styled.img`
  border-radius: 16px !important;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 10000;
`

const CallOut = ({ callOutText, isMinimised }) => {
  if (!callOutText || !isMinimised) {
    return null
  }
  return <CallOutContainer>{callOutText}</CallOutContainer>
}

const PreviewImage = styled(Image)`
  object-fit: cover;
  ${(props) => props.border && `border: 6px solid ${props.borderColor || 'black'};border-radius: 16px;`}
`

export default ({ videos, campaign, widget, openFullscreenDesktopPreview }) => {
  const [isMinimised, setIsMinimised] = useState(true)
  const widgetVideo = videos[0]
  const video = campaign.videos[0]
  const isVideo = video.type === 'VIDEO'
  const videoObject = campaign.videoObjects.find((el) => el.videoId === video.id && el.type === 'BUTTON')
  const { displayWidth, displayHeight, isLandscape } = getDisplaySize(600, 500, widgetVideo.aspectRatioCalc)
  const previewWidth = isLandscape ? 230 : 142
  const previewHeight = isLandscape ? 140 : 200

  const minimisedGif = isVideo
    ? `https://image.mux.com/${widgetVideo.playbackId}/animated.gif?${
        isLandscape ? `height=${previewWidth}` : `width=${previewHeight}`
      }&start=${widgetVideo.thumbnailTime}&end=${widgetVideo.thumbnailTime + 3}&fps=8`
    : `https://clipara.b-cdn.net/${video.path}?aspect_ratio=${Math.round(previewWidth)}x${Math.round(
        previewHeight
      )}&width=${Math.round(previewWidth * 2)}`
  const isRight = widget.position === WIDGET_POSITIONS.BOTTOM_RIGHT
  return (
    <>
      <PageBackground isRight={isRight} position="relative" height="100%" minHeight="500px" mb="60px" p="32px" pb="8px">
        <Image
          width="calc(100% - 48px)"
          height="calc(100% - 48px)"
          position="absolute"
          left="24px"
          top="24px"
          src="/webbrowser.jpg"
        />
        <VideoContainer
          isRight={isRight}
          position="relative"
          onClick={() => {
            if (videos.length === 1) {
              setIsMinimised(!isMinimised)
            } else {
              openFullscreenDesktopPreview()
            }
          }}
        >
          {isMinimised ? (
            <>
              {(widgetVideo.playbackId && isVideo) || !isVideo ? (
                <>
                  <PreviewImage
                    cursor="pointer"
                    borderRadius="8px"
                    width={previewWidth}
                    height={previewHeight}
                    src={minimisedGif}
                    border={widget.border}
                    borderColor={widget.borderColor}
                  />

                  {isVideo ? <PlayButton src={`/__clipara-preview-play-button.svg`} alt="play button" /> : null}
                  <CallOut isMinimised={isMinimised} isRight={isRight} callOutText={widget.text} />
                </>
              ) : (
                <Box
                  width="140px"
                  cursor="pointer"
                  height="230px"
                  backgroundColor="white"
                  boxShadow="0px 4px 20px -2px rgba(50, 50, 71, 0.4);"
                  borderRadius="8px"
                >
                  {campaign.text ? <LinearGradient border={campaign.border} /> : null}
                  <ProcessingPreview small />
                  <CallOut
                    isMinimised={isMinimised}
                    isRight={isRight}
                    callOut={campaign.callOut}
                    callOutText={campaign.callOutText}
                  />
                </Box>
              )}
            </>
          ) : (
            <Box cursor="pointer" backgroundImage={`https://image.mux.com/${widgetVideo.playbackId}/thumbnail.jpg?time=0`}>
              <VideoPreview useGif campaign={campaign} video={widgetVideo} />
            </Box>
          )}
        </VideoContainer>
      </PageBackground>
    </>
  )
}
