import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import ReactTooltip from 'react-tooltip'

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field } from 'formik'
import * as UpChunk from '@mux/upchunk'
import { colors, numberWithCommas, shorten } from '../ui/helpers.js'
import { H1, H3, H6, Text, Span, GradientFont, Error, SmallTitle } from '../ui/Typography.js'
import { Flex, Box, Container } from '../ui/Layout.js'
import Image from '../ui/Image.js'
import { Input } from '../ui/Input.js'
import Icon from '../ui/Icon.js'
import Button from '../ui/Button.js'
import ChatButton from '../Components/ChatButton'
import api from '../api.js'
import { useAuth } from '../Auth.js'
import Carousel from '../ui/Carousel.js'
import { BigDarkSpinner, BigSpinner } from '../ui/Spinner.js'
import { GUIDES, HOMEPAGE_WIDGET_TYPES, TEMPLATES } from './types.js'
import WidgetDetailsModal from './WidgetDetailsModal.js'
import { useVideos } from '../VideoProvider.js'

const CampaignCard = styled(Flex)`
  background-color: rgba(228, 236, 247, 1);
  border-radius: 16px 0px 0px 16px;
  height: 100px;
  width: 230px;
  padding: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  ${(props) => props.noContent && 'width: 350px; border-radius: 16px;'}
`

const LineClamp1 = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const VideoImage = styled(Box)`
  position: absolute;
  left: 0px;
  top: 0px;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  width: 70px;
  min-width: 70px;
  height: 120px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 10px;
  z-index: 2;
  ${(props) =>
    props.index === 1 ? 'transform: rotate(5deg) translateX(15px) translateY(3px); z-index: 1;height: 110px;width: 63px;' : ''}
  ${(props) =>
    props.index === 2 ? 'transform: rotate(10deg) translateX(30px) translateY(7px); z-index: 0;height: 100px;width: 58px;' : ''}
`

const CampaignCardComp = (c) => {
  const { videos } = useVideos()
  console.log(c)
  let widgetText = c.widgetName
  if (c.isShareCampaign) widgetText = 'Off-site'
  let content = c.content

  if (c.type === 'SEARCH') {
    content = videos ? videos.slice(0, 3).map((el) => ({ ...el, type: 'VIDEO' })) : []
  }
  const noContent = !content || !content.length

  return (
    <Link to={`/campaigns/edit/${c.id}`}>
      <Flex alignItems="center">
        <CampaignCard key={c.id} noContent={noContent}>
          <Flex flexDirection="column">
            <LineClamp1 color="rgba(39, 39, 46, 1)" fontWeight="600" fontSize="14px" mb="2px">
              {c.name}
            </LineClamp1>
            <LineClamp1 light fontWeight="400" fontSize="14px" mb="2px">
              {widgetText}
            </LineClamp1>
            {c.plays ? (
              <Flex alignItems="center">
                <Icon icon="play-grey" width="16px" height="16px" mr="8px" />
                <Text light fontWeight="400" fontSize="14px">
                  {numberWithCommas(c.plays)}
                </Text>
              </Flex>
            ) : null}
          </Flex>
        </CampaignCard>
        <Flex position="relative" height="140px" width="50px" ml="-10px">
          {content[0] ? (
            <VideoImage>
              <Image
                src={
                  content[0].type === 'VIDEO'
                    ? content[0].customThumbnail
                      ? content[0].customThumbnail + `-/scale_crop/${300}x${300}/center/`
                      : `https://image.mux.com/${content[0].playbackId}/thumbnail.jpg?width=300&height=300&time=${content[0].thumbnailTime}`
                    : `https://clipara.b-cdn.net/${content[0].path}?width=300`
                }
              />
            </VideoImage>
          ) : null}
          {content[1] ? (
            <VideoImage index={1}>
              <Image
                src={
                  content[1].type === 'VIDEO'
                    ? content[1].customThumbnail
                      ? content[1].customThumbnail + `-/scale_crop/${300}x${300}/center/`
                      : `https://image.mux.com/${content[1].playbackId}/thumbnail.jpg?width=300&height=300&time=${content[1].thumbnailTime}`
                    : `https://clipara.b-cdn.net/${content[1].path}?width=300`
                }
              />
            </VideoImage>
          ) : null}
          {content[2] ? (
            <VideoImage index={2}>
              <Image
                loading="lazy"
                src={
                  content[2].type === 'VIDEO'
                    ? content[2].customThumbnail
                      ? content[2].customThumbnail + `-/scale_crop/${300}x${300}/center/`
                      : `https://image.mux.com/${content[2].playbackId}/thumbnail.jpg?width=300&height=300&time=${content[2].thumbnailTime}`
                    : `https://clipara.b-cdn.net/${content[2].path}?width=300`
                }
              />
            </VideoImage>
          ) : null}
        </Flex>
      </Flex>
    </Link>
  )
}

const Gradient = styled(Flex)`
  background: linear-gradient(225deg, rgba(41, 39, 46, 0.74) 0%, #27272e 100%);
  height: 100px;
  padding: 16px;
  padding-bottom: 12px;
  align-items: end;
`

const GuideCard = (g) => {
  return (
    <a href={g.url} target="_blank" rel="noreferrer">
      <Flex overflow="hidden" height="182px" key={g} flexDirection="column" borderRadius="24px" backgroundColor="white">
        <Gradient>
          <Text fontWeight="600" fontSize="16px" color="white">
            {g.title}
          </Text>
        </Gradient>
        <Text fontWeight="400" light p="16px">
          {g.subtitle}
        </Text>
      </Flex>
    </a>
  )
}

const Home = (props) => {
  let { user } = useAuth()
  const [campaigns, setCampaigns] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [widgetDetailsType, setWidgetDetailsType] = useState()
  const [widgetDetailsLink, setWidgetDetailsLink] = useState()
  const navigate = useNavigate()

  const refresh = async () => {
    const res = await api.get('/homepage/get')
    if (res.data.success) {
      setCampaigns(res.data.payload)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    refresh()
  }, [])

  return (
    <Flex maxWidth="calc(100vw - 250px)">
      <Flex position="relative" flexDirection="column" width="100%" py="30px">
        <Flex justifyContent="space-between" px="30px">
          <H1 mb="32px" width="auto">
            Home
          </H1>
          <Flex width="auto">
            <ChatButton showCallButton />
            <Link to="/campaigns/create?step=ON_SITE_OFF_SITE">
              <Button
                renderLeftIcon={() => <Icon width={12} height={12} icon="plus-white" />}
                mb="8px"
                variant="black"
                label="New campaign"
              />
            </Link>
          </Flex>
        </Flex>
        <Flex px="30px">
          <Container mb="48px" alignItems="center">
            <Text mb="32px" fontWeight="900" fontSize="40px" color="black">
              Ready to create?
            </Text>
            <Text mb="32px" light>
              Choose layouts, pick a player style and share your content with campaigns
            </Text>
            <Link to="/campaigns/create?step=ON_SITE_OFF_SITE">
              <Button mb="8px" variant="black" label="Create a campaign" />
            </Link>
          </Container>
        </Flex>
        {isLoading ? (
          <Flex height="120px" justifyContent="center" alignItems="center">
            <BigDarkSpinner />
          </Flex>
        ) : campaigns && campaigns.length ? (
          <>
            <Flex flexDirection="column" mb="60px">
              <Flex justifyContent="space-between" mb="16px" px="30px">
                <Text fontWeight="600" fontSize="16px">
                  Your campaigns
                </Text>
                <Link to="/campaigns">
                  <Flex width="auto" alignItems="center">
                    <Text light mr="8px">
                      See all
                    </Text>
                    <Icon icon="chevron-right-grey" width="16px" height="16px" />
                  </Flex>
                </Link>
              </Flex>
              <Carousel
                slideWidth={350}
                pl="30px"
                spacing={16}
                cards={campaigns.map((c) => {
                  return <CampaignCardComp key={c.id} {...c} />
                })}
              />
            </Flex>
          </>
        ) : null}
        <Flex flexDirection="column" mb="60px">
          <Flex justifyContent="space-between" mb="16px" px="30px">
            <Text fontWeight="600" fontSize="16px">
              Maybe you could try...
            </Text>
          </Flex>
          <Carousel
            slideWidth={240}
            spacing={16}
            pl="30px"
            cards={HOMEPAGE_WIDGET_TYPES.filter(({ image, title, template, step }) => {
              if (template === TEMPLATES.SEARCH && !user.isContentCreator) {
                return false
              }
              return true
            }).map(({ image, title, template, step }) => {
              return (
                <Flex
                  key={template}
                  cursor="pointer"
                  onClick={() => {
                    setWidgetDetailsType(template)
                    setWidgetDetailsLink(`/campaigns/create?step=${step}&template=${template}&homeback=true`)
                  }}
                  flexDirection="column"
                >
                  <Image height={160} width={240} borderRadius="16px" overflow="hidden" src={image} mb="16px" />
                  <Text fontWeight="500">{title}</Text>
                </Flex>
              )
            })}
          />
        </Flex>
        <Flex flexDirection="column" mb="60px">
          <Flex justifyContent="space-between" mb="16px" px="30px">
            <Text fontWeight="600" fontSize="16px">
              Perhaps some help...
            </Text>
            <a
              href="https://clipara.notion.site/Clipara-Guides-1b7ea70c03d9433699335b05f336dd37"
              target="_blank"
              rel="noreferrer"
            >
              <Flex width="auto" alignItems="center">
                <Text light mr="8px">
                  See all
                </Text>
                <Icon icon="chevron-right-grey" width="16px" height="16px" />
              </Flex>
            </a>
          </Flex>
          <Carousel
            slideWidth={260}
            spacing={16}
            pl="30px"
            cards={GUIDES.map((g) => {
              return <GuideCard key={g.url} {...g} />
            })}
          />
        </Flex>
      </Flex>
      <WidgetDetailsModal
        type={widgetDetailsType}
        link={widgetDetailsLink}
        onClose={() => {
          setWidgetDetailsType()
          setWidgetDetailsLink()
        }}
        setIsOpen={() => {
          setWidgetDetailsType()
          setWidgetDetailsLink()
        }}
      />
    </Flex>
  )
}

export default Home
