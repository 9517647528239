import React from 'react'
import styled from 'styled-components'
import { Formik, Field } from 'formik'
import { Flex, Box, Container } from '../../ui/Layout.js'
import { space } from 'styled-system'
import formikFormWrapper from '../../formikFormWrapper.js'
import { Input, TextArea } from '../../ui/Input.js'
import { H1, H2, H3, H6, Label, Text, Span, GradientFont, Error, SmallTitle } from '../../ui/Typography.js'
import { BUTTON_STYLES, PLAY_BUTTON_STYLES, SEARCH_SHARPNESS, widgetPlayButtonPositionOptions } from '../types.js'
import ColorPicker, { ColorPickerRow } from '../../ui/ColorPicker.js'
import { getChevronStyling, getChevronImg, getChevronImgSize } from '../chevronStyles.js'
import { SideSelect } from '../../ui/Select.js'
import ToggleRedGreen from '../../ui/ToggleRedGreen.js'
const baseUrl = 'https://widget-v2.getclipara.com'

const FormInput = formikFormWrapper(Input)

const SearchSharpnessStyle = ({ values, setFieldValue }) => {
  return (
    <Flex flexDirection="column" borderTop="1px solid rgba(237, 242, 247, 1)" pt="16px">
      <Flex flexWrap="wrap" mb="16px" px="24px">
        <Flex
          boxSizing="border-box"
          cursor="pointer"
          justifyContent="center"
          alignItems="center"
          mt="8px"
          borderRadius="8px"
          width="167px"
          height="125px"
          mr={'16px'}
          bg={values.widget.widgetSearchBorderRadius === SEARCH_SHARPNESS.ROUND ? 'rgba(235, 242, 250, 1)' : 'white'}
          border={
            values.widget.widgetSearchBorderRadius === SEARCH_SHARPNESS.ROUND
              ? '3px solid rgba(120, 149, 255, 1)'
              : '1px solid rgba(166, 183, 212, 1)'
          }
          onClick={() => {
            setFieldValue(`widget.widgetSearchBorderRadius`, SEARCH_SHARPNESS.ROUND)
          }}
        >
          <Flex flexDirection="column" width="auto">
            <Box width="90px" height="40px" backgroundColor="#E1E8FF" border="1px solid #A6B7D4" borderRadius="20px" />
            <Label textAlign="center" mt="16px" mb="0px">
              Round
            </Label>
          </Flex>
        </Flex>
        <Flex
          boxSizing="border-box"
          cursor="pointer"
          justifyContent="center"
          alignItems="center"
          mt="8px"
          borderRadius="8px"
          width="167px"
          height="125px"
          bg={values.widget.widgetSearchBorderRadius === SEARCH_SHARPNESS.SOFT ? 'rgba(235, 242, 250, 1)' : 'white'}
          border={
            values.widget.widgetSearchBorderRadius === SEARCH_SHARPNESS.SOFT
              ? '3px solid rgba(120, 149, 255, 1)'
              : '1px solid rgba(166, 183, 212, 1)'
          }
          onClick={() => {
            setFieldValue(`widget.widgetSearchBorderRadius`, SEARCH_SHARPNESS.SOFT)
          }}
        >
          <Flex flexDirection="column" width="auto">
            <Box width="90px" height="40px" backgroundColor="#E1E8FF" border="1px solid #A6B7D4" borderRadius="8px" />
            <Label textAlign="center" mt="16px" mb="0px">
              Soft
            </Label>
          </Flex>
        </Flex>
        <Flex
          boxSizing="border-box"
          cursor="pointer"
          justifyContent="center"
          alignItems="center"
          mt="16px"
          borderRadius="8px"
          width="167px"
          height="125px"
          mr={'16px'}
          bg={values.widget.widgetSearchBorderRadius === SEARCH_SHARPNESS.SHARP ? 'rgba(235, 242, 250, 1)' : 'white'}
          border={
            values.widget.widgetSearchBorderRadius === SEARCH_SHARPNESS.SHARP
              ? '3px solid rgba(120, 149, 255, 1)'
              : '1px solid rgba(166, 183, 212, 1)'
          }
          onClick={() => {
            setFieldValue(`widget.widgetSearchBorderRadius`, SEARCH_SHARPNESS.SHARP)
          }}
        >
          <Flex flexDirection="column" width="auto">
            <Box width="90px" height="40px" backgroundColor="#E1E8FF" border="1px solid #A6B7D4" />
            <Label textAlign="center" mt="16px" mb="0px">
              Sharp
            </Label>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SearchSharpnessStyle
