import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { colors } from './helpers'
import { Box, Flex } from './Layout.js'
import { Text } from './Typography'
import Spinner from './Spinner'
import Icon from './Icon'

const Container = styled(Flex)`
  position: relative;
  padding-left: ${(props) => props.pl || '0px'};
  overflow-x: scroll;
  scroll-behavior: smooth;
`

const LeftChevron = styled(Flex)`
  display: none;
  position: absolute;
  left: 16px;
  top: 50%;
  height: 40px;
  width: 40px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 4px 20px -2px rgba(50, 50, 71, 0.15);
  z-index: 2;
  width: 40px;
  min-width: 40px;
  height: 40px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${(props) => (props.isDisabled ? `cursor: auto; display: none !important;` : '')}
  transform: translate(0%, -50%);
  img {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`

const RightChevron = styled(LeftChevron)`
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  right: 16px;
  left: unset;
  img {
    transform: rotate(180deg);
  }
`

const HoverControl = styled(Flex)`
  &&:hover {
    .hover {
      display: flex;
    }
  }
`

export default ({ cards, slides, spacing, slideWidth, pl }) => {
  const slider = useRef()
  const [scrollLeft, setScrollLeft] = useState(0)
  const leftChevronDisabled = scrollLeft === 0
  const rightChevronDisabled = scrollLeft >= cards.length * (spacing + slideWidth) - slider.current?.offsetWidth - 100
  const leftChevronOnClick = () => {
    slider.current.scrollBy(-(slideWidth + spacing) * 2, 0)
  }
  const rightChevronOnClick = () => {
    slider.current.scrollBy((slideWidth + spacing) * 2, 0)
  }

  useEffect(() => {
    if (slider.current) {
      slider.current.onscroll = () => {
        setScrollLeft(slider.current.scrollLeft)
      }
    }
  }, [slider.current])

  return (
    <HoverControl position="relative">
      <LeftChevron className="hover" isDisabled={leftChevronDisabled} onClick={leftChevronOnClick}>
        <Icon icon="chevron-left-black" width={16} height={16} />
      </LeftChevron>
      <Container ref={slider} width="auto" pl={pl} noScrollBar>
        {cards.map((card, i) => {
          return (
            <Box key={card} mr={spacing} minWidth={`${slideWidth}px`}>
              {card}
            </Box>
          )
        })}
      </Container>
      <RightChevron className="hover" isDisabled={rightChevronDisabled} onClick={rightChevronOnClick}>
        <Icon icon="chevron-left-black" width={16} height={16} />
      </RightChevron>
    </HoverControl>
  )
}
